import Chart from "react-apexcharts";
import constant from "../../../constant";
import Shimmer from "../../common/Shimmer";
import { getFormattedFloat, getFormattedNumber } from "../../../utils";

export function Chart1(props: any) {
    const series: any = [
        {
            name: '2021',
            type: 'column',
            data: [4, 2, 1, 2, 2]
        },
        {
            name: '2022',
            type: 'column',
            data: [4, 3, 3, 3, 2]
        },
        {
            name: '2023',
            type: 'column',
            data: [5, 4, 3, 3, 2]
        },
    ];
    // const xaxis = ['2021', '2022', '2023'];
    const xaxis = [
        'Non-executive Board Members',
        'Women in Board',
        'Independent Board Members',
        'Independent Members in Nomination Committee',
        'KMPS'
    ];
    const options = {
        chart: {
            toolbar: {
                show: true,
                tools: {
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false
                },
            },
            // height: 300,
            // type: 'line',
            stacked: false,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                // text: "Millions of Hours worked"
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
            min: 0,
            max: 6
        }
        ],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            offsetX: 40,
            fontSize: '13px',
            fontWeight: 'normal',
            labels: {
                colors: '#acb1b1',
            },
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: 1,//[0, 0, 1.5],
            curve: 'straight',
            // dashArray: [0, 0, 5],
        },
        plotOptions: {
            bar: {
                columnWidth: "20%",
                borderRadius: 1
            }
        },
        colors: ["rgba(132, 90, 223, 1)", "rgba(132, 90, 223, 0.5)", "rgba(132, 90, 223, 0.3)", "#ebd1ff"],
    };

    return (
        <div>
            <Chart
                options={options}
                series={series}
                // type="bar"
                width="100%"
                height="310"
            />
        </div>
    )
}

export function Chart2(props: any) {
    const series = [100]
    var options = {
        series: [100],
        chart: {
            type: 'radialBar',
            height: 320,
            offsetY: -20,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#fff",
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: false,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '22px'
                    }
                }
            }
        },
        colors: ["#845adf"],
        grid: {
            padding: {
                top: -10
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 53, 91]
            },
        },
        labels: ['Average Results'],
    };
    return (
        <div>
            <Chart
                options={options}
                series={series}
                type="radialBar"
                width="100%"
                height="310"
            />
        </div>
    )
}

export function Chart3(props: any) {
    const series =
        [{
            name: 'PY',
            data: [
                742, 529
            ]
        },
        {
            name: 'CY',
            data: [
                789, 544
            ]
        }];

    const options = {
        chart: {
            stacked: false,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        grid: {
            borderColor: '#f1f1f1',
            strokeDashArray: 3
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        colors: ["rgba(132, 90, 223, 1)", "rgba(132, 90, 223, 0.5)", "rgba(132, 90, 223, 0.3)"],
        legend: {
            show: true,
            position: 'bottom'
        },
        plotOptions: {
            bar: {
                columnWidth: "20%",
                borderRadius: 1
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: ['CEO Remuneration', 'Average salaries for all employees'],
        },
        yaxis: [{
            title: {
                text: "In Thousand USD"
            },
            // 
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        }],
        fill: {
            opacity: 1
        }
    };
    return (
        <div>
            <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
                height="310"
            />
        </div>
    )
}