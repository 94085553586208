import React from "react";

import Loader from "../../component/common/Loader";
import constant from "../../constant";

import WithUIContext from "../../context/UIContext";
import PasswordInput from "../../component/common/PasswordInput";
import { checkVersion } from "../../utils";

class Login extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            showLoader: false,
            email: { name: "email", error: "", value: "", type: constant.fieldType.string, required: true },
            password: { name: "password", error: "", value: "", type: constant.fieldType.string, required: true },
            rememberMe: { name: "rememberMe", error: "", value: false, type: constant.fieldType.string, required: false },
        }
    }

    componentDidMount() {
        if (this.props.context) {
            this.props.context.setShowSideBar(false);
            this.props.context.setShowHeader(false);
        }

        checkVersion();
    }

    render() {
        const { showLoader, email, password, rememberMe } = this.state;
        return (
            <div className="">
                {
                    showLoader && <Loader />
                }
                <div className="container">
                    <div className="row justify-content-center align-items-center authentication authentication-basic h-100">
                        <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12">
                            {/* <div className="my-5 d-flex justify-content-center">
                                <a href="index.html">
                                    <img src="/assets/images/brand-logos/desktop-logo.png" alt="logo" className="desktop-logo" />
                                    <img src="/assets/images/brand-logos/desktop-dark.png" alt="logo" className="desktop-dark" />
                                </a>
                            </div> */}
                            <div className="card custom-card">
                                <div className="card-body p-5">
                                    <p className="h5 fw-semibold mb-2 text-center">Sign In</p>
                                    <p className="mb-4 text-muted op-7 fw-normal text-center">
                                        Welcome back{/*  Jhon  */} !
                                    </p>
                                    <form onSubmit={this.onSubmit}>
                                        <div className="row gy-3">
                                            <div className="col-xl-12">
                                                <label htmlFor="signin-username" className="form-label text-default">Email *</label>
                                                <input
                                                    type="email"
                                                    name={email.name}
                                                    className={email.error.length === 0 ? "form-control form-control-lg" : "form-control form-control-lg is-invalid"}
                                                    value={email.value}
                                                    // onChange={this.onChange}
                                                    id="signin-username"
                                                    placeholder="Login Email"
                                                />
                                            </div>
                                            <div className="col-xl-12 mb-2">
                                                <label htmlFor="signin-password" className="form-label text-default d-block">
                                                    Password *
                                                    {/* <a href="reset-password-basic.html" className="float-end text-danger">Forget password ?</a> */}
                                                </label>
                                                <PasswordInput
                                                    css={password.error.length === 0 ? "form-control form-control-lg" : "form-control form-control-lg is-invalid"}
                                                    value={password.value} name={password.name} placeholder="password" onChange={null} />
                                                {/* <div className="input-group">
                                                <input type="password" className="form-control form-control-lg" id="signin-password" placeholder="password" />
                                                <button className="btn btn-light" type="button" id="button-addon2"><i className="ri-eye-off-line align-middle"></i></button>
                                            </div> */}
                                                <div className="mt-2">
                                                    <div className="form-check">
                                                        <input name={rememberMe.name} value={rememberMe.value} className="form-check-input" type="checkbox" id="defaultCheck1" />
                                                        <label className="form-check-label text-muted fw-normal" htmlFor="defaultCheck1">
                                                            Remember password ?
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 d-grid mt-2">
                                                <button type="submit" className="btn btn-lg btn-primary">Sign In</button>
                                            </div>
                                        </div>
                                    </form>
                                    {/* <div className="text-center">
                                        <p className="fs-12 text-muted mt-3">Dont have an account? <a href="sign-up-basic.html"
                                            className="text-primary">Sign Up</a></p>
                                    </div>
                                    <div className="text-center my-3 authentication-barrier">
                                        <span>OR</span>
                                    </div>
                                    <div className="btn-list text-center">
                                        <button className="btn btn-icon btn-light">
                                            <i className="ri-facebook-line fw-bold text-dark op-7"></i>
                                        </button>
                                        <button className="btn btn-icon btn-light">
                                            <i className="ri-google-line fw-bold text-dark op-7"></i>
                                        </button>
                                        <button className="btn btn-icon btn-light">
                                            <i className="ri-twitter-line fw-bold text-dark op-7"></i>
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onSubmit = (e: any) => {
        e.preventDefault();


        window.location.href = constant.component.welcome.url;
    }

    // onChange = (e: any) => {
    //     const name = e.target.name;
    //     const value = e.target.value;
    //     onChange(this, name, value);
    // };

    // onChangeBoolean = (e: any) => {
    //     const name = e.target.name;
    //     const value = e.target.checked;
    //     onChange(this, name, value);
    // };
}

export default WithUIContext(Login);