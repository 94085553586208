import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, number } from "../../common/Controls";
import { getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? JSON.parse(props.value.answer["304-3-a-a1"]) : []);
    const [a2, setA2] = useState(props.value ? props.value.answer["304-3-a-a2"] : false);

    const [a3, setA3] = useState(props.value ? props.value.answer["304-3-a-a3"] : '');
    const [a4, setA4] = useState(props.value ? props.value.answer["304-3-a-a4"] : '');
    const [a5, setA5] = useState(props.value ? props.value.answer["304-3-a-a5"] : '');
    const [a6, setA6] = useState(props.value ? props.value.answer["304-3-a-a6"] : '');
    const [a7, setA7] = useState(props.value ? props.value.answer["304-3-a-a7"] : '');

    const columns: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: '304-3-a-i', label: 'Location', type: controlType.string, value: '', show: true, band: '', },
        { name: '304-3-a-ii', label: 'Size', type: controlType.string, value: '', show: true, band: '', },
    ]

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "304-3-a-a1": JSON.stringify(a1),
                "304-3-a-a2": a2,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setA1(value)}
                    columns={columns}
                    obj={{ name: "302-2-a", value: a1 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            {
                a1.length > 0 && <div className="mb-3">
                    <label className="fw-bold d-block">Whether the success of the restoration measure was or is approved by independent external professionals</label>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="1" checked={a2} onChange={(e: any) => setA2(e.target.checked)} />
                        <label className="form-check-label" htmlFor="1">
                            {a2 ? 'Yes' : 'No'}
                        </label>
                    </div>
                </div>
            }
            {
                <div className="mb-3">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Level of Extinction Risk</th>
                                <th>Number of Species Affected</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Critically endangered</td>
                                <td>
                                    {number({ name: '', value: a3, onChange: (a: any, b: any) => { setA3(b) } })}
                                </td>
                            </tr>
                            <tr>
                                <td>Endangered</td>
                                <td>
                                    {number({ name: '', value: a4, onChange: (a: any, b: any) => { setA4(b) } })}
                                </td>
                            </tr>
                            <tr>
                                <td>Vulnerable</td>
                                <td>
                                    {number({ name: '', value: a5, onChange: (a: any, b: any) => { setA5(b) } })}
                                </td>
                            </tr>
                            <tr>
                                <td>Near threatened</td>
                                <td>
                                    {number({ name: '', value: a6, onChange: (a: any, b: any) => { setA6(b) } })}
                                </td>
                            </tr>
                            <tr>
                                <td>Least concern</td>
                                <td>
                                    {number({ name: '', value: a7, onChange: (a: any, b: any) => { setA7(b) } })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1JSON = answer ? JSON.parse(answer.answer["304-3-a-a1"]) : [];
    const a2JSON = answer ? answer.answer["304-3-a-a2"] : '';

    const a3JSON = answer ? answer.answer["304-3-a-a3"] : '';
    const a4JSON = answer ? answer.answer["304-3-a-a4"] : '';
    const a5JSON = answer ? answer.answer["304-3-a-a5"] : '';
    const a6JSON = answer ? answer.answer["304-3-a-a6"] : '';
    const a7JSON = answer ? answer.answer["304-3-a-a7"] : '';
    return (
        <>
            <div style={{ overflow: 'auto' }} className="mb-3">
                <table className="table text-nowrap table-bordered border-primary">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Location
                            </th>
                            <th>
                                Size
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            a1JSON.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            a1JSON.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td className="">{item['304-3-a-i'] ? item['304-3-a-i'] : '-'}</td>
                                    <td className="">{item['304-3-a-ii'] ? item['304-3-a-ii'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                a1JSON.length > 0 && <div className="">
                    <label className="fw-bold d-block">Whether the success of the restoration measure was or is approved by independent external professionals</label>
                    <div className=""> {a2JSON ? 'Yes' : 'No'} </div>
                </div>
            }
            {
                a1JSON.length > 0 && <div className="mb-3">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Level of Extinction Risk</th>
                                <th>Number of Species Affected</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Critically endangered</td>
                                <td>
                                    {a3JSON ? a3JSON : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Endangered</td>
                                <td>
                                    {a4JSON ? a4JSON : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Vulnerable</td>
                                <td>
                                    {a5JSON ? a5JSON : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Near threatened</td>
                                <td>
                                    {a6JSON ? a6JSON : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Least concern</td>
                                <td>
                                    {a7JSON ? a7JSON : '-'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}