import $ from 'jquery';
import { Link } from 'react-router-dom';
import constant from '../../../constant';

function InvestOnline() {
    const enumTabId = { billing: 'confirmed-tab', payment: 'shipped-tab', confirmation: 'delivered-tab' }
    const onChangeTab = (tabId: any) => {
        $(`#${tabId}`).click();
    }
    const title = constant.component.offsetCheckout.title;
    return (
        <>

            <div className="container-fluid">

                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">{title}</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Offset Emission</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-xl-9">
                            <div className="card custom-card">
                                <div className="card-body p-0 product-checkout">
                                    <ul className="nav nav-tabs tab-style-2 d-sm-flex d-block border-bottom border-block-end-dashed" id="myTab1" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="confirmed-tab" data-bs-toggle="tab"
                                                data-bs-target="#confirm-tab-pane" type="button" role="tab"
                                                aria-controls="confirmed-tab" aria-selected="false"><i
                                                    className="ri-user-3-line me-2 align-middle"></i>Billing Details</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="shipped-tab" data-bs-toggle="tab"
                                                data-bs-target="#shipped-tab-pane" type="button" role="tab"
                                                aria-controls="shipped-tab" aria-selected="false"><i
                                                    className="ri-bank-card-line me-2 align-middle"></i>Payment</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="delivered-tab" data-bs-toggle="tab"
                                                data-bs-target="#delivery-tab-pane" type="button" role="tab"
                                                aria-controls="delivered-tab" aria-selected="false"><i
                                                    className="ri-checkbox-circle-line me-2 align-middle"></i>Confirmation</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active border-0 p-0" id="confirm-tab-pane"
                                            role="tabpanel" aria-labelledby="confirm-tab-pane" tabIndex={0}>
                                            <div className="p-4">
                                                <p className="mb-1 fw-semibold text-muted op-5 fs-20">01</p>
                                                <div className="fs-15 fw-semibold d-sm-flex d-block align-items-center justify-content-between mb-3">
                                                    <div>Personal Details :</div>
                                                </div>
                                                <div className="row gy-4">
                                                    <div className="col-xl-6">
                                                        <label htmlFor="firstname-personal" className="form-label">First Name</label>
                                                        <input type="text" className="form-control" id="firstname-personal" placeholder="First Name" value="Json" disabled={true} />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label htmlFor="lastname-personal" className="form-label">Last Name</label>
                                                        <input type="text" className="form-control" id="lastname-personal" placeholder="Last Name" value="Taylor" disabled={true} />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label htmlFor="email-personal" className="form-label">Email</label>
                                                        <input type="email" className="form-control" id="email-personal" placeholder="xyz@example.com" value="Json@gmail.com" disabled={true} />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label htmlFor="phoneno-personal" className="form-label">Phone no</label>
                                                        <input type="text" className="form-control" id="phoneno-personal" placeholder="(555)-555-1234" value="(981)-000-0000" disabled={true} />
                                                    </div>
                                                    <div className="col-xxl-2">
                                                        <label htmlFor="pincode-personal" className="form-label">Pincode</label>
                                                        <input type="text" className="form-control" id="pincode-personal" placeholder="200017" value="110090" disabled={true} />
                                                    </div>
                                                    <div className="col-xxl-4">
                                                        <label htmlFor="choices-single-default" className="form-label">City</label>
                                                        <select className="form-control" data-trigger name="choices-single-default" id="choices-single-default" disabled={true}>
                                                            <option value="Choice 1">Delhi</option>
                                                            <option value="Choice 2">Alexandria</option>
                                                            <option value="Choice 3">Rockville</option>
                                                            <option value="Choice 4">Frederick</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-xxl-4">
                                                        <label htmlFor="choices-single-default1" className="form-label">State</label>
                                                        <select className="form-control" data-trigger id="choices-single-default1" disabled={true}>
                                                            <option value="Choice 1">Delhi</option>
                                                            <option value="Choice 2">California</option>
                                                            <option value="Choice 3">Texas</option>
                                                            <option value="Choice 4">Alaska</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-xxl-2">
                                                        <label htmlFor="country-personal" className="form-label">Country</label>
                                                        <input type="text" className="form-control" id="country-personal" placeholder="Country" value="India" disabled={true} />
                                                    </div>
                                                    <div className="col-xl-12">
                                                        <label htmlFor="text-area" className="form-label">Address</label>
                                                        <textarea className="form-control" id="text-area" rows={2} disabled={true} value="114, Block A, Punjabi Bagh"></textarea>
                                                        {/* <div className="form-check mt-1">
                                                            <input className="form-check-input form-checked-outline form-checked-success" type="checkbox" value="" id="invalidCheck1" required checked />
                                                            <label className="form-check-label text-success fs-12" htmlFor="invalidCheck1">
                                                                Same as Aadhaar and PAN card
                                                            </label>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-between">
                                                <button type="button" className="btn btn-danger-light m-1" id="back-shipping-trigger"><i className="ri-truck-line me-2 align-middle d-inline-block"></i>Back To Cart</button>
                                                <button type="button" onClick={() => onChangeTab(enumTabId.payment)} className="btn btn-primary m-1" id="payment-trigger">Continue To Payment<i className="bi bi-credit-card-2-front align-middle ms-2 d-inline-block"></i></button>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade border-0 p-0" id="shipped-tab-pane" role="tabpanel"
                                            aria-labelledby="shipped-tab-pane" tabIndex={0}>
                                            <div className="p-4">
                                                <p className="mb-1 fw-semibold text-muted op-5 fs-20">02</p>
                                                <div className="fs-15 fw-semibold d-sm-flex d-block align-items-center justify-content-between mb-3">
                                                    <div>Payment Details :</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="card custom-card border shadow-none mb-3">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    Payment Methods
                                                                </div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="btn-group mb-3 d-sm-flex d-block" role="group" aria-label="Basic radio toggle button group">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" />
                                                                    <label className="btn btn-outline-primary text-default mt-sm-0 mt-1" htmlFor="btnradio2">UPI</label>
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" checked />
                                                                    <label className="btn btn-outline-primary text-default mt-sm-0 mt-1" htmlFor="btnradio3">Credit/Debit Card</label>
                                                                </div>
                                                                <div className="row gy-3">
                                                                    <div className="col-xl-12">
                                                                        <label htmlFor="payment-card-number" className="form-label">Card Number</label>
                                                                        <input type="text" className="form-control" id="payment-card-number" placeholder="Card Number" value="1245 - 5447 - 8934 - XXXX" />
                                                                    </div>
                                                                    <div className="col-xl-12">
                                                                        <label htmlFor="payment-card-name" className="form-label">Name On Card</label>
                                                                        <input type="text" className="form-control" id="payment-card-name" placeholder="Name On Card" value="JSON TAYLOR" />
                                                                    </div>
                                                                    <div className="col-xl-4">
                                                                        <label htmlFor="payment-cardexpiry-date" className="form-label">Expiration Date</label>
                                                                        <input type="text" className="form-control" id="payment-cardexpiry-date" placeholder="MM/YY" value="08/2024" />
                                                                    </div>
                                                                    <div className="col-xl-4">
                                                                        <label htmlFor="payment-cvv" className="form-label">CVV</label>
                                                                        <input type="text" className="form-control" id="payment-cvv" placeholder="XXX" />
                                                                    </div>
                                                                    <div className="col-xl-4">
                                                                        <label htmlFor="payment-security" className="form-label">OTP</label>
                                                                        <input type="text" className="form-control" id="payment-security" placeholder="XXXXXX" />
                                                                        <label htmlFor="payment-security" className="form-label mt-1 text-success fs-11"><sup><i className="ri-star-s-fill"></i></sup>Do not share O.T.P with anyone</label>
                                                                    </div>
                                                                    <div className="col-xl-12">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input form-checked-success" type="checkbox" value="" id="payment-card-save" checked />
                                                                            <label className="form-check-label" htmlFor="payment-card-save">
                                                                                Save this card
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-footer">
                                                                <div className="row gy-3">
                                                                    <p className="fs-15 fw-semibold mb-1">Saved Cards :</p>
                                                                    <div className="col-xl-6">
                                                                        <div className="form-check payment-card-container mb-0 lh-1">
                                                                            <input id="payment-card1" name="payment-cards" type="radio" className="form-check-input" checked />
                                                                            <div className="form-check-label">
                                                                                <div className="d-sm-flex d-block align-items-center justify-content-between">
                                                                                    <div className="me-2 lh-1">
                                                                                        <span className="avatar avatar-md">
                                                                                            <img src="/assets/images/visa.jpeg" alt="" />
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="saved-card-details">
                                                                                        <p className="mb-0 fw-semibold">XXXX - XXXX - XXXX - 7646</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-6">
                                                                        <div className="form-check payment-card-container mb-0 lh-1">
                                                                            <input id="payment-card2" name="payment-cards" type="radio" className="form-check-input" />
                                                                            <div className="form-check-label">
                                                                                <div className="d-sm-flex d-block align-items-center justify-content-between">
                                                                                    <div className="me-2 lh-1">
                                                                                        <span className="avatar avatar-md">
                                                                                            <img src="../assets/images/visa.jpeg" alt="/" />
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="saved-card-details">
                                                                                        <p className="mb-0 fw-semibold">XXXX - XXXX - XXXX - 9556</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-4 py-3 border-top border-block-start-dashed d-sm-flex justify-content-between">
                                                <button type="button" onClick={() => onChangeTab(enumTabId.billing)} className="btn btn-danger-light m-1" id="back-personal-trigger"><i className="ri-user-3-line me-2 align-middle d-inline-block"></i>Back To Billing Info</button>
                                                <button type="button" onClick={() => onChangeTab(enumTabId.confirmation)} className="btn btn-primary m-1" id="continue-payment-trigger">Confirm Payment<i className="bi bi-credit-card-2-front align-middle ms-2 d-inline-block"></i></button>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade border-0 p-0" id="delivery-tab-pane" role="tabpanel"
                                            aria-labelledby="delivery-tab-pane" tabIndex={0}>
                                            <div className="p-5 checkout-payment-success my-3">
                                                <div className="mb-5">
                                                    <h5 className="text-success fw-semibold">Payment Successful...&#129309;</h5>
                                                </div>
                                                <div className="mb-5">
                                                    <img src="/assets/images/success.png" alt="" className="img-fluid" />
                                                </div>
                                                <div className="mb-4">
                                                    <p className="mb-1 fs-14">You can track your order with Order Id <b>SPK1FRA5678GGH</b> from <a className="link-success" href="javascript:void(0);"><u>Track Order</u></a></p>
                                                    <p className="text-muted">Thankyou for investing with us.</p>
                                                </div>
                                                <Link to={constant.component.offsetPurchaseHistory.url} className="btn btn-success">Purchased History<i className="bi bi-cart ms-2"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="card custom-card">
                                <div className="card-header">
                                    <div className="card-title me-1">Order Summary</div><span className="badge bg-primary-transparent rounded-pill">02</span>
                                </div>
                                <div className="card-body p-0">
                                    <ul className="list-group mb-0 border-0 rounded-0">
                                        <li className="list-group-item border-top-0 border-start-0 border-end-0">
                                            <div className="d-flex align-items-center flex-wrap">
                                                <div className="me-2">
                                                    <span className="avatar avatar-lg bg-light">
                                                        <img src="https://marketplace.goldstandard.org/cdn/shop/files/MicrosoleSEPT2013_36_540x.jpg?v=1684274864" alt="" />
                                                    </span>
                                                </div>
                                                <div className="flex-fill">
                                                    <p className="mb-0 fw-semibold">Carbon Credits</p>
                                                    <p className="mb-0 text-muted fs-12">Quantity : 100</p>
                                                </div>
                                                <div>
                                                    <p className="mb-0 text-end">
                                                        <a aria-label="anchor" href="javascript:void(0)">
                                                            {/* <i className="ri-close-line fs-16 text-muted" /> */}
                                                            &nbsp;
                                                        </a>
                                                    </p>
                                                    <p className="mb-0 fs-14 fw-semibold">USD 1,600</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="p-3 border-bottom border-block-end-dashed">
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <div className="fs-12 fw-semibold bg-primary-transparent p-1 rounded">FIRSTINVEST</div>
                                            <div className="text-success">COUPON APPLIED</div>
                                        </div>
                                    </div>
                                    <div className="p-3 border-bottom border-block-end-dashed">
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="text-muted op-7">Sub Total</div>
                                            <div className="fw-semibold fs-14">USD 1,600.00</div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="text-muted op-7">Discount (0%)</div>
                                            <div className="fw-semibold fs-14 text-success">-USD 0.00</div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="text-muted op-7">Platform Charges (5%)</div>
                                            <div className="fw-semibold fs-14">USD 200.00</div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="text-muted op-7">GST (18%)</div>
                                            <div className="fw-semibold fs-14">USD 288.00</div>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="fs-15">Total :</div>
                                            <div className="fw-semibold fs-16 text-primary">USD 2,088.00</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default InvestOnline