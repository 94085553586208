import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { GridComponent, LegendComponent, TooltipComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, LineChart, CanvasRenderer]);

export default function StackedLineChart() {
    const option = {
        // title: {
        //   text: 'Stacked Line'
        // },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Predicted GHG Emission without any corrective actions(t)', 'Emissions after GHG Reduction Initiative(t)', 'SbTi Goal', 'Carbon Credits(t)']
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['2024-25', '2025-26', '2026-27', '2027-28', '2028-29']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: 'Predicted GHG Emission without any corrective actions(t)',
                type: 'line',
                // stack: 'Total',
                data: [2890, 3006, 3126, 3251, 3381]
            },
            {
                name: 'Emissions after GHG Reduction Initiative(t)',
                type: 'line',
                //   stack: 'Total',
                data: [2883.2, 2870.7, 2380, 1940, 1755]
            },
            {
                name: 'SbTi Goal',
                type: 'line',
                // stack: 'Total',
                data: [2774.4, 2663.4, 2556.9, 2454.6, 2356.4]
            },
            {
                name: 'Carbon Credits(t)',
                type: 'line',
                // stack: 'Total',
                data: [0, 0, 475, 605, 850]
            },
            // {
            //   name: 'Search Engine',
            //   type: 'line',
            //   stack: 'Total',
            //   data: [820, 932, 901, 934, 1290, 1330, 1320]
            // }
        ]
    };
    // if (!chartData) return <span>"No Records found"</span>;
    return <div className="position-relative">
        <ReactEChartsCore
            echarts={echarts}
            option={option}
            lazyUpdate={true}
            theme={'theme_name'}
            opts={{}}
        />
    </div>
}