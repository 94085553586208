import React, { useState } from "react"
// import { getDetail } from "../../action/StationaryCombustionAction";
import ModalWindow from "../../../common/ModalWindow";
import Shimmer from "../../../common/Shimmer";
import AddDetailForm from "./AddDetailForm";

interface IPropType {
    id: any,
    children: any,
    css: any,
    onSubmitComplete: any,
}

function EditDetailButton(props: IPropType) {
    const [showModal, setShowModal] = useState(false);
    const [detail, setDetail] = useState(undefined);
    const { css, id } = props;

    const onShowModal = () => {
        // getDetail(id).then((res: any) => {
        //     if (res.result) {
        //         setDetail(res.result)
        //     }
        // })
        setShowModal(true);
    }

    const onHideModal = () => {
        setShowModal(false);
    }

    const onSubmitComplete = (detail: any) => {
        onHideModal();
        props.onSubmitComplete(detail);
    }

    return (
        <React.Fragment>
            {
                showModal && renderModal()
            }
            <button type="button" className={css} onClick={onShowModal}>
                {props.children}
            </button>
        </React.Fragment>
    )

    function renderModal() {
        return (
            <ModalWindow
                title="Stationary Combustion Detail"
                backdrop="static"
                css="modal-md"
                onHideModal={onHideModal}
            >
                {
                    detail === undefined
                        ? <Shimmer />
                        : <AddDetailForm
                            detail={detail}
                            onSubmitComplete={onSubmitComplete}
                            onCancel={onHideModal}
                        />
                }
            </ModalWindow>
        )
    }
}

export default EditDetailButton;