import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { GridComponent, LegendComponent, TooltipComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import echartConstant from '../../echart-constant';
import moment from 'moment';
import { getFormattedFloat } from '../../utils';


echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, LineChart, CanvasRenderer]);

export default function StackedAreaChart(props: { data: any, color: any, startDate: any, endDate: any }) {
  const { data, color, startDate, endDate } = props;

  let monthArr: any = [];
  let dataArr: any = [];

  const a = moment(startDate);
  const b = moment(endDate);
  // If you want an inclusive end date (fully-closed interval)
  for (var m = moment(a); m.diff(b, 'months') <= 0; m.add(1, 'months')) {
    monthArr.push(m.format('YYYY-MM'));
  }

  for (let x = 0; x < monthArr.length; x++) {
    const filteredData = data.filter((i: any) => moment(monthArr[x]).format('MM-YYYY') === moment(i.dated).format('MM-YYYY'))
    const totalMonthlyEmission = filteredData.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    dataArr.push(totalMonthlyEmission);
  }

  // data attributes: value and name
  let option: any;
  if (true) option = {
    color: echartConstant.utils.getGrays()['100'],
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: echartConstant.utils.getGrays()['100'],
      borderColor: echartConstant.utils.getGrays()['300'],
      textStyle: {
        color: echartConstant.utils.getColors().dark
      },
      borderWidth: 1,
      formatter: function formatter(params: any) {
        return "\n      <div>\n        <p class='mb-2 text-600'>"
          .concat(moment(params[0].axisValue).format('MMM YYYY'), "</p>\n        <div class='ms-1'>\n          <h6 class=\"fs--1 text-700\">")
          .concat(getFormattedFloat(params[0].value), "</h6>");
        // .concat('Scope 2: ', getFormattedFloat(params[1].value), "</h6>\n          <h6 class=\"fs--1 text-700\"><span class=\"fas fa-circle text-danger me-2\"></span>")
        // .concat('Scope 3: ', getFormattedFloat(params[2].value), "</h6>\n        </div>\n      </div>\n      ");
      },
      transitionDuration: 0,
      position: function position(pos: any, params: any, dom: any, rect: any, size: any) {
        return echartConstant.getPosition(pos, params, dom, rect, size);
      }
    },
    // tooltip: {
    //   trigger: 'axis',
    //   padding: [7, 10],
    //   backgroundColor: echartConstant.utils.getGrays()['100'],
    //   borderColor: echartConstant.utils.getGrays()['300'],
    //   textStyle: {
    //     color: echartConstant.utils.getColors().dark
    //   },
    //   borderWidth: 1,
    //   formatter: function formatter(params: any) {
    //     return echartConstant.utils.getFormatter(params);
    //   },
    //   transitionDuration: 0,
    //   position: function position(pos: any, params: any, dom: any, rect: any, size: any) {
    //     return echartConstant.utils.getPosition(pos, params, dom, rect, size);
    //   }
    // },
    legend: {
      data: ['Scope 1', 'Scope 2', 'Scope 3'],
      show: false
    },
    xAxis: {
      type: 'category',
      data: monthArr,
      boundaryGap: false,
      axisPointer: {
        lineStyle: {
          color: echartConstant.utils.getColor('gray-300'),
          type: 'dashed'
        }
      },
      splitLine: {
        show: true
      },
      axisLine: {
        lineStyle: {
          // color: utils.getGrays()['300'],
          color: echartConstant.utils.rgbaColor('#000', 0.01),
          type: 'dashed'
        }
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: echartConstant.utils.getColor('gray-300'),
          type: 'dashed'
        }
      },
      axisLabel: {
        color: echartConstant.utils.getColor('gray-400'),
        formatter: function formatter(value: any) {
          var date = new Date(value);
          return moment(value).format('MM-YYYY')
          // return "".concat(monthArr[date.getMonth()], " ").concat(date.getFullYear().toString().substr(-2));
        },
        margin: 15 // showMaxLabel: false

      }
    },
    yAxis: {
      type: 'value',
      axisPointer: { show: false },
      splitLine: {
        lineStyle: {
          color: echartConstant.utils.getColor('gray-300'),
          type: 'dashed'
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: echartConstant.utils.getColor('gray-400'),
        margin: 15
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      }
    },
    series: [
      {
        name: 'Scope 1',
        type: 'line',
        data: dataArr,//[6851.88, 7225.62, 7723.92, 8428.87, 9011.25, 9426.54, 10049.51, 10547.80, 10007.95, 10007.95, 10007.00],
        lineStyle: {
          color
        },
        itemStyle: {
          borderColor: color,
          borderWidth: 2
        },
        symbol: 'circle',
        symbolSize: 10,
        hoverAnimation: true,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0,
              color: echartConstant.utils.rgbaColor(color, 0.3)
            }, {
              offset: 1,
              color: echartConstant.utils.rgbaColor(color, 0)
            }]
          }
        }
      }],
    grid: {
      right: '28px',
      left: '48px',
      bottom: '15%',
      top: '5%'
    }
  }
  // if (!chartData) return <span>"No Records found"</span>;
  return <div className="position-relative">
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      lazyUpdate={true}
      theme={'theme_name'}
      opts={{}}
    />
  </div>
}