import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment";

// import WithToastContext from "../../../../context/ToastContext";
import Loader from "../../common/Loader";
import constant from "../../../constant";

import { getStorage, onChange, validateForm } from "../../../utils";
import WithUIContext from "../../../context/UIContext";
import DropZone from "../../common/DropZone";
// import { addKnownDetail, updateKnownDetail, } from "../../action/StationaryCombustionAction";


interface IPropType {
    detail: any;
    onSubmitComplete: any;
    onCancel: any;
    context: any;
}

class AddComponent extends React.Component<IPropType, any> {
    render() {
        return (
            <form onSubmit={this.onSubmit}>

                <div className="mb-3">
                    <DropZone />
                </div>
                <div className="mb-3">
                    <a className="btn btn-link p-0" href="https://opendatabarometer.org/assets/data/primary_data_datasets.csv">Click here to download Sample CSV file</a>
                </div>
                <div className="row">
                    <div className="col">
                        <button type="button" onClick={this.props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-primary px-5" type="submit">Next</button>
                    </div>
                </div>
            </form >
        );
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    };

    onSwitchChange = (name: any, value: any) => {
        onChange(this, name, value);
    };

    onTypeaheadChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    };

    onSubmit = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { detail } = this.props;
            const { id, dated, remarks, quantity, fuelTypeId, doYouKnowAmountOfFuelCombusted, attachment, } = this.state;
            const fuelTypeObj = fuelTypeId.options.find((i: any) => i.code === fuelTypeId.value);
            const branchId = parseFloat(getStorage(constant.key.activeBranch));

            const model: any = {
                id: detail ? detail.id : undefined,
                branchId,
                dated: moment(new Date(dated.value)).format("YYYY-MM-DD"),
                doYouKnowAmountOfFuelCombusted,
                remarks: remarks.value,
                quantity: parseFloat(quantity.value),
                quantityUnit: fuelTypeObj.measuringUnit,
                fuelTypeId: fuelTypeId.value,
                fuelTypeName: fuelTypeObj.name,
                attachment: attachment.value ? attachment.value : undefined,
            };

            const woceModel = {
                key: process.env.REACT_APP_WOCE_KEY,
                fuelCombustedCode: model.fuelTypeId,
                fuelCombustedUnit: "kg",
                quantityCombusted: model.quantity,
            };
            //   const res = await stationary_calculateEmissionKnown(woceModel);
            //   if (res.result) {
            //     const { co2, ch4, n2o, co2e, emission, fuelRelatedEmission, upstream } = res.result;
            //     model.co2 = co2;
            //     model.ch4 = ch4;
            //     model.n2o = n2o;
            //     model.co2e = emission;
            //     model.fuelRelatedEmission = fuelRelatedEmission;
            //     if (upstream) {
            //       model.upstream = {
            //         fuelCode: upstream.input.code,
            //         fuelName: upstream.input.name,
            //         fuelQuantity: upstream.input.quantity,
            //         fuelUnit: upstream.input.unit,

            //         emission: upstream.emission,
            //         emissionUnit: upstream.emissionUnit,
            //       };
            //     } else {
            //       model.upstream = null;
            //     }


            //     if (id) {
            //       this.onUpdateDetail(model);
            //     } else {
            //       this.onAddDetail(model);
            //     }
            //   } else {
            //     this.props.context.toast.error(res.error);
            //   }
        }
    };

    onAddDetail = (model: any) => {
        this.setState({ showLoader: true });
        // addKnownDetail(model).then((res: any) => {
        //   this.setState({ showLoader: false });
        //   if (res.error) {
        //     this.props.context.toast.error(res.error);
        //   } else {
        //     this.props.context.toast.success(constant.message.recordUpdated);
        //     this.props.onSubmitComplete(res.result);
        //   }
        // });
    };

    onUpdateDetail = (model: any) => {
        this.setState({ showLoader: true });
        // updateKnownDetail(model).then((res: any) => {
        //   this.setState({ showLoader: false });
        //   if (res.error) {
        //     this.props.context.toast.error(res.error);
        //   } else {
        //     this.props.context.toast.success(constant.message.recordUpdated);
        //     this.props.onSubmitComplete(res.result);
        //   }
        // });
    };
}

export default WithUIContext(AddComponent);
