import React, { useState } from "react"
import ModalWindow from "../../common/ModalWindow";
import AddDetailForm from "./ManageApprovalForm";

function ManageApprovalButton(props: { css: string, children: any, ids: any, disabled: boolean, approved: boolean, manageApprovalAPI: any, onSubmitComplete: any }) {
    const { css, children, ids, disabled, approved, manageApprovalAPI } = props;
    const [showModal, setShowModal] = useState(false);

    const onShowModal = () => {
        setShowModal(true);
    }

    const onHideModal = () => {
        setShowModal(false);
    }

    const onSubmitComplete = (detail: any) => {
        onHideModal();
        props.onSubmitComplete(detail);
    }

    return (
        <React.Fragment>
            {
                showModal && renderModal()
            }
            <button type="button" className={css} onClick={onShowModal} disabled={disabled}>
                {children}
            </button>
        </React.Fragment>
    )

    function renderModal() {
        return (
            <ModalWindow
                title="Manage Approval"
                backdrop="static"
                onHideModal={onHideModal}
            >
                <AddDetailForm
                    ids={ids}
                    approved={approved}
                    onSubmitComplete={onSubmitComplete}
                    onCancel={onHideModal}
                    manageApprovalAPI={manageApprovalAPI}
                />
            </ModalWindow>
        )
    }
}

export default ManageApprovalButton;