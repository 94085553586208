const keyPrefix = 'esg-wireframe';

const key = {
    token: keyPrefix + '.token',
    permission: keyPrefix + '.permission',
    userDetail: keyPrefix + '.user-detail',
    version: keyPrefix + '.version',
    activeBranch: keyPrefix + '.branch',
    language: keyPrefix + '.language',
    direction: keyPrefix + '.direction',
    themeRTL: 'ynexrtl',
    openapi: 'sk-proj-4kvDOkAvu0gYNjqI7C85T3BlbkFJJlYEE5Fo1RSXxu3GMuOW',
    laama: 'LL-9ynTwgUKaM1JwSQTAGl5ifVOcq0T4uycF3QqdIEqQf2o9UPWKraR7zP9ZnyLszPV'
}

const permission = {
    public: "0",

    // emission
    captureScope1StationaryEmission: "1",
    captureScope1MobileSourcesEmission: "2",
    captureScope1FugutiveEmission: "3",
    captureScope2PurchasedElectricityFromGridEmission: "4",
    captureScope3PurchasedGoodsAndServicesEmission: "5",
    captureScope3CapitalGoodsEmission: "6",
    captureScope3FuelAndEnergyRelatedEmission: "7",
    captureScope3WasteGeneratedInOperationEmission: "8",
    captureScope3BusinessTravelEmission: "9",
    captureScope3EmployeeCommutingEmission: "10",
    captureScope3UseOfSoldProductsEmission: "11",
    // emission

    // report
    reportScope1StationaryEmission: "101",
    reportScope1MobileSourcesEmission: "102",
    reportScope1FugutiveEmission: "103",
    reportScope2PurchasedElectricityFromGridEmission: "104",
    reportScope3PurchasedGoodsAndServicesEmission: "105",
    reportScope3CapitalGoodsEmission: "106",
    reportScope3FuelAndEnergyRelatedEmission: "107",
    reportScope3WasteGeneratedInOperationEmission: "108",
    reportScope3BusinessTravelEmission: "109",
    reportScope3EmployeeCommutingEmission: "110",
    reportScope3UseOfSoldProductsEmission: "111",
    // report

    // report
    approvalScope1StationaryEmission: "301",
    approvalScope1MobileSourcesEmission: "302",
    approvalScope1FugutiveEmission: "303",
    approvalScope2PurchasedElectricityFromGridEmission: "304",
    approvalScope3PurchasedGoodsAndServicesEmission: "305",
    approvalScope3CapitalGoodsEmission: "306",
    approvalScope3FuelAndEnergyRelatedEmission: "307",
    approvalScope3WasteGeneratedInOperationEmission: "308",
    approvalScope3BusinessTravelEmission: "309",
    approvalScope3EmployeeCommutingEmission: "310",
    approvalScope3UseOfSoldProductsEmission: "311",
    // report

    // Config Permission
    organizationEmissionDashboard: "201",
    organizationEmissionTrend: "202",
    organizationOffsetAnalytics: "203",
    organizationNetZeroTarget: "204",
    branchEmissionDashboard: "205",
    branchEmissionTrends: "206",
    branchNetZeroTarget: "207",
    // approveCapturedEmissions: "208",
    manageUser: "209",
    manageRole: "210",
    manageEmissionConfiguration: "211",
    //Decarbonization Permission
    actionLibrary: "201",
    createRoadMap: "202",
    netZeroGoalTracker: "203"
};

const component = {
    home: { url: '/', title: 'Home', permission: permission.public },
    comingSoon: { url: '/coming-soon', title: 'Coming Soon', permission: permission.public },

    // auth
    login: { url: '/login', title: 'Login', permission: permission.public },
    logout: { url: '/logout', title: 'Logout', permission: permission.public },
    forgotPassword: { url: '/forgot-password', title: 'Forgot Password', permission: permission.public },
    resetPassword: { url: '/reset-password', title: 'Reset Password', permission: permission.public },
    register: { url: '/register', title: 'Register', permission: permission.public },
    // auth

    // profile
    welcome: { url: '/welcome', title: 'Welcome', permission: permission.public },
    profile: { url: '/profile', title: 'Profile', permission: permission.public },
    changePassword: { url: '/change-password', title: 'Change Password', permission: permission.public },
    // profile

    esgDetail: { url: '/esg/detail/:id', title: 'ESG Detail', permission: permission.public },
    esgEnergyDashboard: { url: '/esg/environment/energy/dashboard', title: 'ESG Environment Energy Dashboard', permission: permission.public },
    esgWasteDashboard: { url: '/esg/environment/waste/dashboard', title: 'ESG Environment Waste Dashboard', permission: permission.public },
    esgWaterDashboard: { url: '/esg/environment/water/dashboard', title: 'ESG Environment Water Dashboard', permission: permission.public },

    esgSocialEmployementDashboard: { url: '/esg/social/employement/dashboard', title: 'ESG Social Employement Dashboard', permission: permission.public },
    esgSocialOccupationalDashboard: { url: '/esg/social/occupational/dashboard', title: 'ESG Social Occupational Dashboard', permission: permission.public },
    esgSocialDiversityDashboard: { url: '/esg/social/diversity/dashboard', title: 'ESG Social Diversity Dashboard', permission: permission.public },

    esgGovernanceSummaryDashboard: { url: '/esg/governance/summary/dashboard', title: 'ESG Governance Summary Dashboard', permission: permission.public },

    ghgStationaryEmission: { url: '/ghg/emission/stationary', title: 'Stationary Emission', permission: permission.public },
    ghgMobileCombustion: { url: '/ghg/emission/mobile', title: 'Mobile Combustion', permission: permission.public },
    ghgFugutiveEmission: { url: '/ghg/emission/fugutive', title: 'Fugutive Emission', permission: permission.public },

    ghgPurchasedElectricity: { url: '/ghg/emission/purchased-electricity', title: 'Purchased Electricity', permission: permission.public },

    ghgCategory1Emission: { url: '/ghg/emission/category-1', title: 'Category 1: Purchased Goods and Services', permission: permission.public },
    ghgCategory2Emission: { url: '/ghg/emission/category-2', title: 'Category 2: Capital Goods', permission: permission.public },
    ghgCategory3Emission: { url: '/ghg/emission/category-3', title: 'Category 3: Fuel and Energy Related', permission: permission.public },
    ghgCategory4Emission: { url: '/ghg/emission/category-4', title: 'Category 4: Upstream Transportation and Distribution', permission: permission.public },
    ghgCategory5Emission: { url: '/ghg/emission/category-5', title: 'Category 5: Waste Generated in Operations', permission: permission.public },
    ghgCategory6Emission: { url: '/ghg/emission/category-6', title: 'Category 6: Business Travel', permission: permission.public },
    ghgCategory7Emission: { url: '/ghg/emission/category-7', title: 'Category 7: Employee Commutting', permission: permission.public },
    ghgCategory8Emission: { url: '/ghg/emission/category-8', title: 'Category 8: Downstream Transportation and Distribution', permission: permission.public },
    ghgCategory9Emission: { url: '/ghg/emission/category-9', title: 'Category 9', permission: permission.public },
    ghgCategory10Emission: { url: '/ghg/emission/category-10', title: 'Category 10', permission: permission.public },
    ghgCategory11Emission: { url: '/ghg/emission/category-11', title: 'Category 11', permission: permission.public },
    ghgCategory12Emission: { url: '/ghg/emission/category-12', title: 'Category 12', permission: permission.public },
    ghgCategory13Emission: { url: '/ghg/emission/category-13', title: 'Category 13', permission: permission.public },
    ghgCategory14Emission: { url: '/ghg/emission/category-14', title: 'Category 14', permission: permission.public },
    ghgCategory15Emission: { url: '/ghg/emission/category-15', title: 'Category 15', permission: permission.public },

    ghgOrganisationEmissionDashboard: { url: '/ghg/dashboard/organisation-emission', title: 'Organisation Emission Dashboard', permission: permission.public },
    ghgBranchEmissionDashboard: { url: '/ghg/dashboard/branch-emission', title: 'Branch Emission Dashboard', permission: permission.public },


    offsetMarketPlace: { url: '/offset/marketplace', title: 'Marketplace', permission: permission.public },
    offsetFavourite: { url: '/offset/favourite', title: 'Favourite', permission: permission.public },
    offsetCart: { url: '/offset/cart', title: 'Card', permission: permission.public },
    offsetCheckout: { url: '/offset/checkout', title: 'Checkout', permission: permission.public },
    offsetPurchaseHistory: { url: '/offset/purchase-history', title: 'Offsetting Purchase History', permission: permission.public },
    offsetDetail: { url: '/offset/detail/:id', title: 'Project Detail', permission: permission.public },

    decarbonizationActionLibrary: { url: '/decarbonization/action-library', title: 'Action Library', permission: permission.public },
    decarbonizationNetZeroGoalTracker: { url: '/decarbonization/net-zero-goal-tracker', title: 'Net Zero Goal Tracker', permission: permission.public },
    decarbonizationNetZeroRoadMap: { url: '/decarbonization/net-zero-road-map', title: 'Net Zero Roadmap', permission: permission.public },

    complianceGRI: { url: '/compliance/gri', title: 'GRI', permission: permission.public },
};

const fieldType = {
    string: 'string',
    array: 'array',
    object: 'object',
    date: 'date',
    number: 'number',
}

const dateTimeFormat = {
    dateFormat: 'DD MMM YYYY',
    timeFormat: 'hh:mm:ss A',
    dateTimeFormat: 'DD MMM YYYY hh:mm A',
};

const ghgListInitialState = { pending: undefined, approved: undefined, rejected: undefined }
const customForm = { dateForm: 'date-form', numberForm: 'number-form' }
const colors = [
    '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0',
    '#3F51B5', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800',
    '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B',
    '#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A'
]
export default { component, key, fieldType, dateTimeFormat, ghgListInitialState, customForm, colors }