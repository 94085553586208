import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, input, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? props.value.answer["405-1-a-a1"] : '');
    const [a2, setA2] = useState(props.value ? props.value.answer["405-1-a-a2"] : '');
    const [a3, setA3] = useState(props.value ? props.value.answer["405-1-a-a3"] : '');
    const [a4, setA4] = useState(props.value ? props.value.answer["405-1-a-a4"] : '');
    const [a5, setA5] = useState(props.value ? props.value.answer["405-1-a-a5"] : '');
    const [a6, setA6] = useState(props.value ? props.value.answer["405-1-a-a6"] : '');

    const [a7, setA7] = useState(props.value ? JSON.parse(props.value.answer["405-1-a-a7"]) : []);

    const columns: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: '405-1-a-a7-i', label: 'Other Indicator', type: controlType.string, value: '', show: true, band: '', },
        { name: '405-1-a-a7-ii', label: '', type: controlType.string, value: '', show: true, band: '', },
    ]

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "405-1-a-a1": a1,
                "405-1-a-a2": a2,
                "405-1-a-a3": a3,
                "405-1-a-a4": a4,
                "405-1-a-a5": a5,
                "405-1-a-a6": a6,
                "405-1-a-a7": JSON.stringify(a7)
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>

            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Age Group</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Under 30 years old</td>
                            <td>
                                {input({ name: '', value: a1, onChange: (a: any, b: any) => { setA1(b) } })}
                            </td>

                        </tr>
                        <tr>
                            <td>30-50 years  old</td>
                            <td>
                                {input({ name: '', value: a2, onChange: (a: any, b: any) => { setA2(b) } })}
                            </td>

                        </tr>
                        <tr>
                            <td>Over 50 years old</td>
                            <td>
                                {input({ name: '', value: a3, onChange: (a: any, b: any) => { setA3(b) } })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Gender</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Male</td>
                            <td>
                                {input({ name: '', value: a4, onChange: (a: any, b: any) => { setA4(b) } })}
                            </td>

                        </tr>
                        <tr>
                            <td>Female</td>
                            <td>
                                {input({ name: '', value: a5, onChange: (a: any, b: any) => { setA5(b) } })}
                            </td>

                        </tr>
                        <tr>
                            <td>Transgender</td>
                            <td>
                                {input({ name: '', value: a6, onChange: (a: any, b: any) => { setA6(b) } })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setA7(value)}
                    columns={columns}
                    obj={{ name: "", value: a7 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1JSON = answer ? answer.answer["405-1-a-a1"] : '';
    const a2JSON = answer ? answer.answer["405-1-a-a2"] : '';
    const a3JSON = answer ? answer.answer["405-1-a-a3"] : '';
    const a4JSON = answer ? answer.answer["405-1-a-a4"] : '';
    const a5JSON = answer ? answer.answer["405-1-a-a5"] : '';
    const a6JSON = answer ? answer.answer["405-1-a-a6"] : '';

    const a7JSON = answer ? JSON.parse(answer.answer["405-1-a-a7"]) : [];
    return (
        <>
            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Age Group</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Under 30 years old</td>
                            <td>
                                {a1JSON ? (a1JSON) : '-'}
                            </td>

                        </tr>
                        <tr>
                            <td>30-50 years  old</td>
                            <td>
                                {a2JSON ? (a2JSON) : '-'}
                            </td>

                        </tr>
                        <tr>
                            <td>Over 50 years old</td>
                            <td>
                                {a3JSON ? (a3JSON) : '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Gender</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Male </td>
                            <td>
                                {a4JSON ? (a4JSON) : '-'}
                            </td>

                        </tr>
                        <tr>
                            <td>Female</td>
                            <td>
                                {a5JSON ? (a5JSON) : '-'}
                            </td>

                        </tr>
                        <tr>
                            <td>Transgender</td>
                            <td>
                                {a6JSON ? (a6JSON) : '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Other Indicator</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            a7JSON.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            a7JSON.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td className="">{item['405-1-a7-i'] ? item['405-1-a7-i'] : '-'}</td>
                                    <td className="">{item['405-1-a7-ii'] ? item['405-1-a7-ii'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

        </>
    )
}