import moment from "moment";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getFormattedNumber } from "../../utils";

function App(props: any) {
    const { series, legends } = props;
    // const [series, setSeries] = useState<any>([]);
    // const series2 = [
    //     {
    //         type: 'line',
    //         name: 'Profit',
    //         data: [
    //             {
    //                 x: 'Jan',
    //                 y: 100
    //             },
    //             {
    //                 x: 'Feb',
    //                 y: 210
    //             },
    //             {
    //                 x: 'Mar',
    //                 y: 180
    //             },
    //             {
    //                 x: 'Apr',
    //                 y: 454
    //             },
    //             {
    //                 x: 'May',
    //                 y: 230
    //             },
    //             {
    //                 x: 'Jun',
    //                 y: 320
    //             },
    //             {
    //                 x: 'Jul',
    //                 y: 656
    //             },
    //             {
    //                 x: 'Aug',
    //                 y: 830
    //             },
    //             {
    //                 x: 'Sep',
    //                 y: 350
    //             },
    //             {
    //                 x: 'Oct',
    //                 y: 350
    //             },
    //             {
    //                 x: 'Nov',
    //                 y: 210
    //             },
    //             {
    //                 x: 'Dec',
    //                 y: 410
    //             }
    //         ]
    //     },
    //     {
    //         type: 'line',
    //         name: 'Revenue',
    //         // chart: {
    //         //     dropShadow: {
    //         //         enabled: true,
    //         //         enabledOnSeries: undefined,
    //         //         top: 5,
    //         //         left: 0,
    //         //         blur: 3,
    //         //         color: '#000',
    //         //         opacity: 0.1
    //         //     }
    //         // },
    //         data: [
    //             {
    //                 x: 'Jan',
    //                 y: 180
    //             },
    //             {
    //                 x: 'Feb',
    //                 y: 620
    //             },
    //             {
    //                 x: 'Mar',
    //                 y: 476
    //             },
    //             {
    //                 x: 'Apr',
    //                 y: 220
    //             },
    //             {
    //                 x: 'May',
    //                 y: 520
    //             },
    //             {
    //                 x: 'Jun',
    //                 y: 780
    //             },
    //             {
    //                 x: 'Jul',
    //                 y: 435
    //             },
    //             {
    //                 x: 'Aug',
    //                 y: 515
    //             },
    //             {
    //                 x: 'Sep',
    //                 y: 738
    //             },
    //             {
    //                 x: 'Oct',
    //                 y: 454
    //             },
    //             {
    //                 x: 'Nov',
    //                 y: 525
    //             },
    //             {
    //                 x: 'Dec',
    //                 y: 230
    //             }
    //         ]
    //     },
    //     {
    //         type: 'area',
    //         name: 'Sales',
    //         chart: {
    //             dropShadow: {
    //                 enabled: true,
    //                 enabledOnSeries: undefined,
    //                 top: 5,
    //                 left: 0,
    //                 blur: 3,
    //                 color: '#000',
    //                 opacity: 0.1
    //             }
    //         },
    //         data: [
    //             {
    //                 x: 'Jan',
    //                 y: 200
    //             },
    //             {
    //                 x: 'Feb',
    //                 y: 530
    //             },
    //             {
    //                 x: 'Mar',
    //                 y: 110
    //             },
    //             {
    //                 x: 'Apr',
    //                 y: 130
    //             },
    //             {
    //                 x: 'May',
    //                 y: 480
    //             },
    //             {
    //                 x: 'Jun',
    //                 y: 520
    //             },
    //             {
    //                 x: 'Jul',
    //                 y: 780
    //             },
    //             {
    //                 x: 'Aug',
    //                 y: 435
    //             },
    //             {
    //                 x: 'Sep',
    //                 y: 475
    //             },
    //             {
    //                 x: 'Oct',
    //                 y: 738
    //             },
    //             {
    //                 x: 'Nov',
    //                 y: 454
    //             },
    //             {
    //                 x: 'Dec',
    //                 y: 480
    //             }
    //         ]
    //     }
    // ];
    const options = {
        chart: {
            height: 350,
            animations: {
                speed: 500
            },
            dropShadow: {
                enabled: true,
                enabledOnSeries: undefined,
                top: 8,
                left: 0,
                blur: 3,
                color: '#000',
                opacity: 0.1
            },
        },
        // colors: ["rgb(132, 90, 223)", "rgba(35, 183, 229, 0.85)", "rgba(119, 119, 142, 0.05)"],
        dataLabels: {
            enabled: false
        },
        grid: {
            borderColor: '#f1f1f1',
            strokeDashArray: 3
        },
        stroke: {
            curve: 'smooth',
            width: 2,
            // width: [2, 2, 2, 2, 2, 2],
            // dashArray: [0, 5, 0],
        },
        xaxis: {
            axisTicks: {
                show: false,
            },
            labels: {
                formatter: function (value: any) {
                    return "" + moment(value).format('MMM YY');
                }
            },
        },
        yaxis: {
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        },
        tooltip: {
            y: [{
                formatter: function (e: any) {
                    return void 0 !== e ? "" + e.toFixed(0) : e
                }
            }, {
                formatter: function (e: any) {
                    return void 0 !== e ? "" + e.toFixed(0) : e
                }
            }, {
                formatter: function (e: any) {
                    return void 0 !== e ? e.toFixed(0) : e
                }
            }]
        },
        legend: {
            show: true,
            customLegendItems: legends,
            inverseOrder: false
        },
        // title: {
        //     text: 'Revenue Analytics with sales & profit (USD)',
        //     align: 'left',
        //     style: {
        //         fontSize: '.8125rem',
        //         fontWeight: 'semibold',
        //         color: '#8c9097'
        //     },
        // },
        markers: {
            hover: {
                sizeOffset: 5
            }
        }
    };

    // useEffect(() => {
    //     if (emissionList && emissionCategory) {
    //         const months = [];
    //         // find min and max date
    //         const datesArray = emissionList.map((i: any) => new Date(i.dated));
    //         const minDate = new Date(Math.min(...datesArray));
    //         const maxDate = new Date(Math.max(...datesArray));
    //         var a = moment(minDate).add(-1, 'months');
    //         var b = moment(maxDate).add(1, 'months');

    //         for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'months')) {
    //             months.push(m.format('YYYY-MM'))
    //         }

    //         const cat1Series: any = { type: 'line', name: 'Scope 1', data: [] }
    //         const cat2Series: any = { type: 'line', name: 'Scope 2', data: [] }
    //         const cat3Series: any = { type: 'line', name: 'Scope 3', data: [] }

    //         months.forEach((m: any) => {
    //             const dt_filter = emissionList.filter((d: any) => moment(d.dated).format('YYYY-MM') === m);
    //             const cat1_filter = dt_filter.filter((c: any) => c.categoryId === 1);
    //             const cat2_filter = dt_filter.filter((c: any) => c.categoryId === 2);
    //             const cat3_filter = dt_filter.filter((c: any) => c.categoryId === 3);

    //             if (cat1_filter.length > 0) {
    //                 const total = cat1_filter.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    //                 cat1Series.data.push({ x: m, y: total })
    //             } else {
    //                 cat1Series.data.push({ x: m, y: 0 })
    //             }

    //             if (cat2_filter.length > 0) {
    //                 const total = cat2_filter.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    //                 cat2Series.data.push({ x: m, y: total })
    //             } else {
    //                 cat2Series.data.push({ x: m, y: 0 })
    //             }

    //             if (cat3_filter.length > 0) {
    //                 const total = cat3_filter.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    //                 cat3Series.data.push({ x: m, y: total })
    //             } else {
    //                 cat3Series.data.push({ x: m, y: 0 })
    //             }
    //         })

    //         setSeries([cat1Series, cat2Series, cat3Series]);
    //     }
    // }, [emissionList])

    return (
        <div className="">
            <Chart
                options={options}
                series={series}
                type="line"
                width="100%"
                height="300"
            />
        </div>
    )
}

export default App;