import moment from "moment";
import { useState } from "react";
import { Table, controlType, input, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const answerJSON = props.value ? JSON.parse(props.value.answer) : null;
    const [table1Cell1a, setTable1Cell1a] = useState(answerJSON ? answerJSON.table1Cell1a : 'Electricity');
    const [table1Cell1b, setTable1Cell1b] = useState(answerJSON ? answerJSON.table1Cell1b : '');
    const [table1Cell1c, setTable1Cell1c] = useState(answerJSON ? answerJSON.table1Cell1c : '');

    const [table1Cell2a, setTable1Cell2a] = useState(answerJSON ? answerJSON.table1Cell2a : 'Heating');
    const [table1Cell2b, setTable1Cell2b] = useState(answerJSON ? answerJSON.table1Cell2b : '');
    const [table1Cell2c, setTable1Cell2c] = useState(answerJSON ? answerJSON.table1Cell2c : '');

    const [table1Cell3a, setTable1Cell3a] = useState(answerJSON ? answerJSON.table1Cell3a : 'Cooling');
    const [table1Cell3b, setTable1Cell3b] = useState(answerJSON ? answerJSON.table1Cell3b : '');
    const [table1Cell3c, setTable1Cell3c] = useState(answerJSON ? answerJSON.table1Cell3c : '');

    const [table1Cell4a, setTable1Cell4a] = useState(answerJSON ? answerJSON.table1Cell4a : 'Steam');
    const [table1Cell4b, setTable1Cell4b] = useState(answerJSON ? answerJSON.table1Cell4b : '');
    const [table1Cell4c, setTable1Cell4c] = useState(answerJSON ? answerJSON.table1Cell4c : '');

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: JSON.stringify({
                table1Cell1a, table1Cell1b, table1Cell1c,
                table1Cell2a, table1Cell2b, table1Cell2c,
                table1Cell3a, table1Cell3b, table1Cell3c,
                table1Cell4a, table1Cell4b, table1Cell4c,
            }),
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <table className="table table-bordered table-stripped table-hover mt-3 fs-14">
                <thead>
                    <tr>
                        <th>Energy Consumed</th>
                        <th>UoM(Joules/watt-hours/other(mention))</th>
                        <th>Total Sold</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{table1Cell1a}</td>
                        <td>{input({ name: "", value: table1Cell1b, onChange: (a: any, b: any) => setTable1Cell1b(b) })}</td>
                        <td>{number({ name: "", value: table1Cell1c, onChange: (a: any, b: any) => setTable1Cell1c(b) })}</td>
                    </tr>
                    <tr>
                        <td>{table1Cell2a}</td>
                        <td>{input({ name: "", value: table1Cell2b, onChange: (a: any, b: any) => setTable1Cell2b(b) })}</td>
                        <td>{number({ name: "", value: table1Cell2c, onChange: (a: any, b: any) => setTable1Cell2c(b) })}</td>
                    </tr>
                    <tr>
                        <td>{table1Cell3a}</td>
                        <td>{input({ name: "", value: table1Cell3b, onChange: (a: any, b: any) => setTable1Cell3b(b) })}</td>
                        <td>{number({ name: "", value: table1Cell3c, onChange: (a: any, b: any) => setTable1Cell3c(b) })}</td>
                    </tr>

                    <tr>
                        <td>{table1Cell4a}</td>
                        <td>{input({ name: "", value: table1Cell4b, onChange: (a: any, b: any) => setTable1Cell4b(b) })}</td>
                        <td>{number({ name: "", value: table1Cell4c, onChange: (a: any, b: any) => setTable1Cell4c(b) })}</td>
                    </tr>
                </tbody>
            </table>

            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    let answerJSON = answer ? JSON.parse(answer.answer) : null;
    return (
        <>
            <div style={{ overflow: 'auto' }}>
                <table className="table table-bordered table-stripped table-hover mt-3 fs-14">
                    <thead>
                        <tr>
                            <th>Energy Consumed</th>
                            <th>UoM(Joules/watt-hours/other(mention))</th>
                            <th>Total Sold</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            answerJSON
                                ? <>
                                    <tr>
                                        <td>{answerJSON ? answerJSON.table1Cell1a : '-'}</td>
                                        <td>{answerJSON ? answerJSON.table1Cell1b : '-'}</td>
                                        <td>{answerJSON ? answerJSON.table1Cell1c : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>{answerJSON ? answerJSON.table1Cell2a : '-'}</td>
                                        <td>{answerJSON ? answerJSON.table1Cell2b : '-'}</td>
                                        <td>{answerJSON ? answerJSON.table1Cell2c : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>{answerJSON ? answerJSON.table1Cell3a : '-'}</td>
                                        <td>{answerJSON ? answerJSON.table1Cell3b : '-'}</td>
                                        <td>{answerJSON ? answerJSON.table1Cell3c : '-'}</td>
                                    </tr>

                                    <tr>
                                        <td>{answerJSON ? answerJSON.table1Cell4a : '-'}</td>
                                        <td>{answerJSON ? answerJSON.table1Cell4b : '-'}</td>
                                        <td>{answerJSON ? answerJSON.table1Cell4c : '-'}</td>
                                    </tr>
                                </>
                                : <tr>
                                    <td colSpan={10}>No Records Found</td>
                                </tr>
                        }

                    </tbody>
                </table>
            </div>
        </>
    )
}