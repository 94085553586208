import React, { useState } from "react"
import ModalWindow from "../../common/ModalWindow";
import moment from "moment";

interface IPropType {
    id: any,
    children: any,
    css: any,
}

function AddDetailButton(props: IPropType) {
    const [showModal, setShowModal] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const { css } = props;

    const onShowModal = () => {
        setShowModal(true);
    }

    const onHideModal = () => {
        setShowModal(false);
    }

    const onSubmitComplete = (detail: any) => {
        onHideModal();
    }

    return (
        <React.Fragment>
            {
                showModal && renderModal()
            }
            <button type="button" className={css} onClick={onShowModal}>
                {props.children}
            </button>
        </React.Fragment>
    )

    function renderModal() {
        return (
            <ModalWindow
                title="Audit History"
                backdrop={false}
                css="modal-md"
                onHideModal={onHideModal}
            >
                <ul className="list-unstyled timeline-widget mb-0 my-3">
                    <li className="timeline-widget-list">
                        <div className="d-flex align-items-top">
                            <div className="me-5 text-center">
                                <span className="d-block fs-20 fw-semibold text-primary">{moment().format('DD')}</span>
                                <span className="d-block fs-12 text-muted">{moment().format('MMM')}</span>
                            </div>
                            <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between">
                                <div>
                                    <p className="mb-1 text-truncate text-wrap w-100">Record was approved </p>
                                    <p className="mb-0 fs-12 lh-1 text-muted">
                                        <span className="badge bg-primary-transparent me-2">Brendon McCullum</span>10:00 AM
                                    </p>
                                    <p className="mb-0">
                                        <button onClick={() => setShowDetail(!showDetail)} type="button" className="btn btn-link p-0 btn-sm">
                                            {
                                                showDetail
                                                    ? 'Hide Detail'
                                                    : 'View Detail'
                                            }

                                        </button>
                                        {
                                            showDetail && <p style={{ whiteSpace: 'pre-wrap' }}>
                                                {
                                                    JSON.stringify({
                                                        "id": "10",
                                                        "activityPeriod": "20204-01-24",
                                                        "location": "Central Station",
                                                        "activityData": "108",
                                                        "uom": "kg",
                                                        "emissionFactor": "0.02",
                                                        "emission": "10.64",
                                                        "modifiedOn": "2024-05-14 11:44 AM",
                                                        "modifiedBy": "James Anderson",
                                                    }, undefined, 2)
                                                }
                                            </p>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-widget-list">
                        <div className="d-flex align-items-top">
                            <div className="me-5 text-center">
                                <span className="d-block fs-20 fw-semibold text-primary">{moment().add(-1, 'd').format('DD')}</span>
                                <span className="d-block fs-12 text-muted">{moment().format('MMM')}</span>
                            </div>
                            <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between">
                                <div>
                                    <p className="mb-1 text-truncate text-wrap w-100">Record was updated</p>
                                    <p className="mb-0 fs-12 lh-1 text-muted">
                                        <span className="badge bg-success-transparent me-2">James Anderson</span>03:15 PM
                                    </p>
                                    <p className="mb-0">
                                        <button onClick={() => setShowDetail(!showDetail)} type="button" className="btn btn-link p-0 btn-sm">
                                            {
                                                showDetail
                                                    ? 'Hide Detail'
                                                    : 'View Detail'
                                            }

                                        </button>
                                        {
                                            showDetail && <p style={{ whiteSpace: 'pre-wrap' }}>
                                                {
                                                    JSON.stringify({
                                                        "id": "10",
                                                        "activityPeriod": "20204-01-24",
                                                        "location": "Central Station",
                                                        "activityData": "108",
                                                        "uom": "kg",
                                                        "emissionFactor": "0.02",
                                                        "emission": "10.64",
                                                        "modifiedOn": "2024-05-14 11:44 AM",
                                                        "modifiedBy": "James Anderson",
                                                    }, undefined, 2)
                                                }
                                            </p>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-widget-list">
                        <div className="d-flex align-items-top">
                            <div className="me-5 text-center">
                                <span className="d-block fs-20 fw-semibold text-primary">{moment().add(-2, 'd').format('DD')}</span>
                                <span className="d-block fs-12 text-muted">{moment().format('MMM')}</span>
                            </div>
                            <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between">
                                <div>
                                    <p className="mb-1 text-truncate text-wrap w-100">Record was Rejected </p>
                                    <p className="mb-0 fs-12 lh-1 text-muted">
                                        <span className="badge bg-primary-transparent me-2">Brendon McCullum</span>10:00 AM
                                    </p>
                                    <p className="mb-0">
                                        <button onClick={() => setShowDetail(!showDetail)} type="button" className="btn btn-link p-0 btn-sm">
                                            {
                                                showDetail
                                                    ? 'Hide Detail'
                                                    : 'View Detail'
                                            }

                                        </button>
                                        {
                                            showDetail && <p style={{ whiteSpace: 'pre-wrap' }}>
                                                {
                                                    JSON.stringify({
                                                        "id": "10",
                                                        "activityPeriod": "20204-01-24",
                                                        "location": "Central Station",
                                                        "activityData": "108",
                                                        "uom": "kg",
                                                        "emissionFactor": "0.02",
                                                        "emission": "10.64",
                                                        "modifiedOn": "2024-05-14 11:44 AM",
                                                        "modifiedBy": "James Anderson",
                                                    }, undefined, 2)
                                                }
                                            </p>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-widget-list">
                        <div className="d-flex align-items-top">
                            <div className="me-5 text-center">
                                <span className="d-block fs-20 fw-semibold text-primary">{moment().add(-3, 'd').format('DD')}</span>
                                <span className="d-block fs-12 text-muted">{moment().format('MMM')}</span>
                            </div>
                            <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between">
                                <div>
                                    <p className="mb-1 text-truncate text-wrap w-100">Record was added</p>
                                    <p className="mb-0 fs-12 lh-1 text-muted">
                                        <span className="badge bg-success-transparent me-2">James Anderson</span>03:00 PM
                                    </p>
                                    <p className="mb-0">
                                        <button onClick={() => setShowDetail(!showDetail)} type="button" className="btn btn-link p-0 btn-sm">
                                            {
                                                showDetail
                                                    ? 'Hide Detail'
                                                    : 'View Detail'
                                            }

                                        </button>
                                        {
                                            showDetail && <p style={{ whiteSpace: 'pre-wrap' }}>
                                                {
                                                    JSON.stringify({
                                                        "id": "10",
                                                        "activityPeriod": "20204-01-24",
                                                        "location": "Central Station",
                                                        "activityData": "108",
                                                        "uom": "kg",
                                                        "emissionFactor": "0.02",
                                                        "emission": "10.64",
                                                        "modifiedOn": "2024-05-14 11:44 AM",
                                                        "modifiedBy": "James Anderson",
                                                    }, undefined, 2)
                                                }
                                            </p>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>


                    {/* <li className="timeline-widget-list">
                        <div className="d-flex align-items-top">
                            <div className="me-5 text-center"> <span className="d-block fs-20 fw-semibold text-primary">02</span> <span className="d-block fs-12 text-muted">Mon</span> </div>
                            <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between">
                                <div> <p className="mb-1 text-truncate timeline-widget-content text-wrap">You have an announcement - Ipsum Est Diam Eirmod</p><p className="mb-0 fs-12 lh-1 text-muted">10:00AM<span className="badge bg-primary-transparent ms-2">Announcement</span></p></div>
                                <div className="dropdown"> <a aria-label="anchor" href="javascript:void(0);" className="p-2 fs-16 text-muted" data-bs-toggle="dropdown"> <i className="fe fe-more-vertical"></i> </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="javascript:void(0);">Action</a></li>
                                        <li><a className="dropdown-item" href="javascript:void(0);">Another action</a></li>
                                        <li><a className="dropdown-item" href="javascript:void(0);">Something else here</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-widget-list">
                        <div className="d-flex align-items-top">
                            <div className="me-5 text-center"> <span className="d-block fs-20 fw-semibold text-primary">15</span> <span className="d-block fs-12 text-muted">Sun</span> </div>
                            <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between">
                                <div> <p className="mb-1 text-truncate timeline-widget-content text-wrap">National holiday - Vero Jayanti</p><p className="mb-0 fs-12 lh-1 text-muted"><span className="badge bg-warning-transparent">Holiday</span></p></div>
                                <div className="dropdown"> <a aria-label="anchor" href="javascript:void(0);" className="p-2 fs-16 text-muted" data-bs-toggle="dropdown"> <i className="fe fe-more-vertical"></i> </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="javascript:void(0);">Action</a></li> <li><a className="dropdown-item" href="javascript:void(0);">Another action</a></li>
                                        <li><a className="dropdown-item" href="javascript:void(0);">Something else here</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="timeline-widget-list">
                        <div className="d-flex align-items-top">
                            <div className="me-5 text-center"> <span className="d-block fs-20 fw-semibold text-primary">23</span> <span className="d-block fs-12 text-muted">Mon</span> </div>
                            <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between"> <div> <p className="mb-1 text-truncate timeline-widget-content text-wrap">John pup birthday - Team Member</p><p className="mb-4 fs-12 lh-1 text-muted">09:00AM<span className="badge bg-success-transparent ms-2">Birthday</span></p><p className="mb-1 text-truncate timeline-widget-content text-wrap">Amet sed no dolor kasd - Et Dolores Tempor Erat</p><p className="mb-0 fs-12 lh-1 text-muted">04:00PM<span className="badge bg-primary-transparent ms-2">Announcement</span></p></div>
                                <div className="dropdown"> <a aria-label="anchor" href="javascript:void(0);" className="p-2 fs-16 text-muted" data-bs-toggle="dropdown"> <i className="fe fe-more-vertical"></i> </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="javascript:void(0);">Action</a></li> <li><a className="dropdown-item" href="javascript:void(0);">Another action</a></li> <li><a className="dropdown-item" href="javascript:void(0);">Something else here</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="timeline-widget-list"> <div className="d-flex align-items-top"> <div className="me-5 text-center"> <span className="d-block fs-20 fw-semibold text-primary">31</span> <span className="d-block fs-12 text-muted">Tue</span> </div> <div className="d-flex flex-wrap flex-fill align-items-top justify-content-between"> <div> <p className="mb-1 text-truncate timeline-widget-content text-wrap">National Holiday - Dolore Ipsum</p><p className="mb-0 fs-12 lh-1 text-muted"><span className="badge bg-warning-transparent">Holiday</span></p></div> <div className="dropdown"> <a aria-label="anchor" href="javascript:void(0);" className="p-2 fs-16 text-muted" data-bs-toggle="dropdown"> <i className="fe fe-more-vertical"></i> </a> <ul className="dropdown-menu"> <li><a className="dropdown-item" href="javascript:void(0);">Action</a></li> <li><a className="dropdown-item" href="javascript:void(0);">Another action</a></li> <li><a className="dropdown-item" href="javascript:void(0);">Something else here</a></li> </ul> </div> </div> </div>
                    </li> */}
                </ul>
            </ModalWindow>
        )
    }
}

export default AddDetailButton;