import moment from "moment";
import { useState } from "react";
import { Table, controlType, input, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? props.value.answer["306-3-a-i"] : '');
    const [a2, setA2] = useState(props.value ? props.value.answer["306-3-a-ii"] : '');

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "306-3-a-i": a1,
                "306-3-a-ii": a2,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-3">Total number of recorded significant spills</label>
                <div className="input-group mb-3">
                    {number({ name: '', value: a1, onChange: (a: any, b: any) => setA1(b) })}
                    {/* <span className="input-group-text">kg/multiple</span> */}
                </div>
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-3">Total volume of recorded significant spills</label>
                <div className="input-group mb-3">
                    {number({ name: '', value: a2, onChange: (a: any, b: any) => setA2(b) })}
                    {/* <span className="input-group-text">kg/multiple</span> */}
                </div>
            </div>


            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1 = answer ? answer.answer["306-3-a-i"] : '';
    const a2 = answer ? answer.answer["306-3-a-ii"] : '';
    // const a3 = answer ? answer.answer["306-3-a-iii"] : '';
    // const a4 = answer ? answer.answer["306-3-a-iv"] : '';
    // const a5 = answer ? answer.answer["306-3-a-v"] : '';
    // const a6 = answer ? answer.answer["306-3-a-vi"] : '';
    // const a7 = answer ? answer.answer["306-3-a-vii"] : '';
    return (
        <>

            <div className="mb-3">
                <label className="fw-bold mb-1">Total number of recorded significant spills</label>
                <div className="">
                    {
                        a1 ? `${getFormattedFloat(a1)}` : '-'
                    }
                </div>
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-1">Total volume of recorded significant spills </label>
                <div className="">
                    {
                        a2 ? `${getFormattedFloat(a2)}` : '-'
                    }
                </div>
            </div>
        </>
    )
}