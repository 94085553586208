import { Chart1, Chart2, Chart3, Chart4, Chart5, Chart6, Chart7 } from "../../../../component/esg/environment-dashboard/WasteChart";

function Home() {

    return (
        <>
            <div className="container-fluid">
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Waste Dashboard</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">ESG</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Environment</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Waste</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                {renderTopCards()}

                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Waste Generated (KT)
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart1 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Waste Disposal (KT)
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart2 />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Waste Diverted From Disposal
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart3 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Waste Directed To Disposal
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart4 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Hazardous Waste Handling
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart5 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Non-Hazardous Waste Handling
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart6 />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Distribution of Waste By Site
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart7 />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

    function renderTopCards() {
        return (
            <>
                <div className="row">
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card card-bg-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-light"><i className="ti ti-trash fs-25 text-primary"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-fixed-white op-9">Total Waste Generated</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">120</span>
                                            <span className="fw-semibold fs-14">KT</span>
                                        </div>
                                        <div>
                                            <span className="fs-12 mb-0 text-fixed-white op-8"></span>
                                            <span className="badge bg-light fw-semibold text-danger mx-1 fs-12">+7.8%</span>
                                            <span className="fs-12 mb-0 text-fixed-white op-9"> v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card border border-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-info"><i className="ti ti-trash-x fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Total Hazardous Waste</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">30</span>
                                            <span className="text-muted fw-semibold fs-14">KT</span>
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">25.0%</span>
                                            <span className="fs-12 mb-0 text-muted">of Total Waste</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card border border-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-secondary"><i className="ti ti-trash fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Total Non-Hazardous Waste</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">90</span>
                                            <span className="text-muted fw-semibold fs-14">KT</span>
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">75.0%</span>
                                            <span className="fs-12 mb-0 text-muted">of Total Waste</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card border border-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-teal"><i className="ti ti-recycle fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Total Waste Diverted From Disposal</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">48</span>
                                            <span className="text-muted fw-semibold fs-14">KT</span>
                                        </div>
                                        <div>
                                            <span className="badge bg-success-transparent fw-semibold text-success mx-1 fs-12">40.0%</span>
                                            <span className="fs-12 mb-0 text-muted">of Total Waste</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;