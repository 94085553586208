import React from "react";
import WithToastContext from "../../../context/UIContext";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { onChange } from "../../../utils";

;

interface IPropType {
    detail: any,
    onSubmitComplete: any,
    onCancel: any,
    context: any
}

class AddDetailForm extends React.Component<IPropType, any>{
    constructor(props: any) {
        super(props);
        const id = props.id;
        this.state = {
            projectCategory: id ? '1' : '',
            projectType: id ? 'Wind Energy' : '',
            scopeId: id ? '2' : '',
            scopeType: id ? 'Purchased Electricity from Grid' : '',
            ghgStrategyReduction: id ? true : false,
            ghgStrategyGenerateCarbonCredit: false,

            ghgStrategy: id ? '1' : '',
            tags: id ? ['Capital Intensive', 'Generate Carbon Credits'] : [],
            tagOptions: ['Capital Intensive', 'Generate Carbon Credits', 'Economically Viable', 'Helps in Decarbonization'],
            description: id ? 'Wind is one of the predominant sources of power generation in the Net Zero Emission' : '',
            reduction: id ? '100' : '',
        };
    }

    render() {
        const { projectCategory, projectType, ghgStrategy, scopeType, scopeId, tags, description, reduction, tagOptions,
            ghgStrategyReduction, ghgStrategyGenerateCarbonCredit,
        } = this.state;
        const scopeTypeOptions = [
            { id: '1', options: ['Stationary Combustion', 'Mobile Combustion', 'Fugitive Emission'] },
            { id: '2', options: ['Purchased Electricity from Grid'] },
            {
                id: '3', options: ['Purchased Goods & Services',
                    'Capital Goods',
                    'Fuel & Energy Related Activities',
                    'Upstream Transportation & Distribution',
                    'Waste Generated in Operations',
                    'Business Travel',
                    'Employee Commuting',
                    'Work From Home',
                    'Use of Sold Products',
                    'End of life treatment of Sold Products'
                ]
            },
        ];
        const selectedOption = scopeTypeOptions.find((i: any) => i.id === scopeId);
        let options: any = [];
        if (selectedOption) {
            options = selectedOption.options;
        }
        return (
            <>
                <form>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label" htmlFor="location">Action Name<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className={"form-control"}
                                value={projectType}
                                onChange={(e: any) => this.setState({ projectType: e.target.value })}
                            />
                        </div>
                        <div className="col-6">
                            <label className="form-label" htmlFor="location">Action Category<span className="text-danger">*</span></label>
                            <select
                                className={"form-select"}
                                // name={purchasedGoodCode.name}
                                value={projectCategory}
                                onChange={(e: any) => this.setState({ projectCategory: e.target.value })}
                            >
                                <option value="">Select an option</option>
                                <option value="1">Energy Sources</option>
                                <option value="2">Nature Investments</option>
                                <option value="3">Waste Management</option>
                                <option value="4">Digital </option>
                                <option value="5">Travel & Transport</option>
                                <option value="6">Carbon Capture</option>
                                <option value="other">Others</option>
                            </select>
                            {
                                projectCategory === 'other' && <input
                                    type="text"
                                    placeholder="Enter other product category here"
                                    className={"form-control mt-3"}
                                />
                            }
                        </div>

                    </div>

                    <div className="mb-3 row">
                        <div className="col-6">
                            <label className="form-label" htmlFor="location">Reduction in Scope<span className="text-danger">*</span></label>
                            <select
                                className={"form-select"}
                                value={scopeId}
                                onChange={(e: any) => this.setState({ scopeId: e.target.value })}
                            >
                                <option value="">Select an option</option>
                                <option value="1">Scope 1</option>
                                <option value="2">Scope 2</option>
                                <option value="3">Scope 3</option>
                            </select>
                        </div>
                        <div className="col-6">
                            <label className="form-label" htmlFor="location">Scope Category<span className="text-danger">*</span></label>
                            <select
                                className={"form-select"}
                                value={scopeType}
                                onChange={(e: any) => this.setState({ scopeType: e.target.value })}
                            >
                                <option value="">Select an option</option>
                                {
                                    options.map((i: any) => <option value={i}>{i}</option>)
                                }
                            </select>
                        </div>
                    </div>



                    <div className="mb-3">
                        <label className="form-label" htmlFor="location">GHG Strategy<span className="text-danger">*</span></label>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                                    checked={ghgStrategyReduction}
                                    onChange={(e: any) => this.setState({ ghgStrategyReduction: e.target.checked })}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Reduction</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
                                    checked={ghgStrategyGenerateCarbonCredit}
                                    onChange={(e: any) => this.setState({ ghgStrategyGenerateCarbonCredit: e.target.checked })}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Generate Carbon Credit</label>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label className="form-label" htmlFor="location">Expected Annual Carbon Reduction<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                disabled={!ghgStrategyReduction}
                                className={"form-control"}
                                value={reduction}
                                onChange={(e: any) => this.setState({ reduction: e.target.value })}
                            />
                        </div>
                        <div className="col">
                            <label className="form-label" htmlFor="location">Expected Carbon Credits Generation<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                disabled={!ghgStrategyGenerateCarbonCredit}
                                className={"form-control"}
                            />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="location">Project Desciption</label>
                        <textarea rows={3}
                            value={description}
                            onChange={(e: any) => this.setState({ description: e.target.value })}
                            className={"form-control"}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="form-label" htmlFor="location">Tags</label>
                        {/* <Select options={tagOptions} isMulti={true} /> */}
                        <Typeahead
                            allowNew
                            id="custom-selections-example"
                            multiple={true}
                            newSelectionPrefix="Add a new item: "
                            options={tagOptions}
                            onChange={(e: any) => { this.setState({ tags: e }) }}
                            placeholder="Select Tags"
                            selected={tags}
                        />
                        {/* <Typeahead
                            id="ta-exchange-id"
                            // allowNew={true}
                            labelKey="name"
                            // name={countryId.name}
                            selected={tags}
                            multiple={true}
                            options={['Capital IntensiveGenerate', 'Carbon Credits', 'Helps in Decarbonization']}
                            placeholder="Type anything..."
                        // onChange={(e: any) => this.onTypeaheadChange(countryId.name, e)}
                        // placeholder=""
                        // isInvalid={countryId.error.length > 0}
                        /> */}
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-secondary px-5" type="button" onClick={this.props.onCancel} >Cancel</button>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary px-5" type="button" onClick={this.props.onCancel}>Save</button>
                        </div>
                    </div>
                </form>
            </>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    };

    onSwitchChange = (name: any, value: any) => {
        onChange(this, name, value);
    };

    onTypeaheadChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    };

}

export default WithToastContext(AddDetailForm)