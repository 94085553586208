// import Switch from "rc-switch";
import React from "react";
import NumberFormat from "react-number-format";
import uniqid from 'uniqid';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import BalloonEditor from '@ckeditor/ckeditor5-build-balloon-block';

export const controlType = { string: 'string', number: 'number', year: 'year', date: 'date', month: 'month', float: 'float', description: 'description', boolean: 'boolean', richtext: 'richtext', select: 'select', checkbox: 'checkbox' }

export function input(obj: { name: any, value: any, onChange: any }) {
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        obj.onChange(name, value)
    }
    return <input name={obj.name} type="text" className="form-control fs--1" value={obj.value} onChange={onChange} />
}

export function number(obj: { name: any, value: any, onChange: any }) {
    // const onChange = (e: any) => {
    //     const name = e.target.name;
    //     const value = e.target.value;
    //     obj.onChange(name, value)
    // }
    // return <input name={obj.name} type="number" className="form-control fs--1" value={obj.value} onChange={onChange} />
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        obj.onChange(name, value)
    }
    // return <input name={obj.name} type="number" className="form-control fs--1" value={obj.value} onChange={onChange} />
    return <NumberFormat
        decimalScale={0}
        thousandSeparator={true}
        allowNegative={false}
        className={"form-control fs--1"}
        value={obj.value}
        //   onValueChange={(e: any) => { this.onChange({ target: { name: noOfPessenger.name, value: e.value }, }) }}
        onValueChange={(e: any) => { onChange({ target: { name: obj.name, value: e.value }, }) }}
    />
}

export function year(obj: { name: any, value: any, onChange: any }) {
    // const onChange = (e: any) => {
    //     const name = e.target.name;
    //     const value = e.target.value;
    //     obj.onChange(name, value)
    // }
    // return <input name={obj.name} type="number" className="form-control fs--1" value={obj.value} onChange={onChange} />
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        obj.onChange(name, value)
    }
    // return <input name={obj.name} type="number" className="form-control fs--1" value={obj.value} onChange={onChange} />
    return <NumberFormat
        decimalScale={0}
        maxLength={4}
        thousandSeparator={false}
        allowNegative={false}
        className={"form-control fs--1"}
        value={obj.value}
        //   onValueChange={(e: any) => { this.onChange({ target: { name: noOfPessenger.name, value: e.value }, }) }}
        onValueChange={(e: any) => { onChange({ target: { name: obj.name, value: e.value }, }) }}
    />
}

export function float(obj: { name: any, value: any, onChange: any }) {
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        obj.onChange(name, value)
    }
    // return <input name={obj.name} type="number" className="form-control fs--1" value={obj.value} onChange={onChange} />
    return <NumberFormat
        decimalScale={2}
        thousandSeparator={true}
        allowNegative={false}
        className={"form-control fs--1"}
        value={obj.value}
        //   onValueChange={(e: any) => { this.onChange({ target: { name: noOfPessenger.name, value: e.value }, }) }}
        onValueChange={(e: any) => { onChange({ target: { name: obj.name, value: e.value }, }) }}
    />
}

export function money(obj: { name: any, value: any, onChange: any }) {
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        obj.onChange(name, value)
    }

    return <NumberFormat
        decimalScale={2}
        thousandSeparator={true}
        allowNegative={false}
        className={"form-control fs--1"}
        value={obj.value}
        onValueChange={(e: any) => { onChange({ target: { name: obj.name, value: e.value }, }) }}
    />
}

export function select(obj: { name: any, value: any, onChange: any, options: any }) {
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        obj.onChange(name, value)
    }

    return <select name={obj.name} className="form-control fs--1" value={obj.value} onChange={onChange} >
        <option value=''>Select an option</option>
        {obj.options.map((item: any) => <option value={item}>{item}</option>
        )}
    </select >
}

export function textarea(obj: { name: any, value: any, onChange: any }) {
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        obj.onChange(name, value)
    }
    return <textarea name={obj.name} rows={3} className="form-control fs--1" value={obj.value} onChange={onChange} />
}

export function boolean(obj: { name: any, value: any, onChange: any, trueLabel: 'Yes', falseLabel: 'No' }) {
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.checked;
        obj.onChange(name, value)
    }
    return <>
        <div className="form-check form-switch">
            <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id={obj.name}
                onChange={onChange}
                checked={obj.value}
            />
            <label className="form-check-label" htmlFor={obj.name}>
                {obj.value ? obj.trueLabel : obj.falseLabel}
            </label>
        </div>
        {/* <input
            className="form-control fs--1"
            onChange={onChange}
            checked={obj.value}
            type="checkbox"
            role="switch"
        />
        <span className="btn btn-link text-dark">
            {
                obj.value ? obj.trueLabel : obj.falseLabel
            }
        </span> */}
    </>
}

export function checkbox(obj: { name: any, value: any, onChange: any }) {
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.checked;
        obj.onChange(name, value)
    }
    return <>
        <input
            name={obj.name}
            // className="form-control fs--1"
            onChange={onChange}
            checked={obj.value}
            type="checkbox"
        />
    </>
}

export function date(obj: { name: any, value: any, onChange: any }) {
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        obj.onChange(name, value)
    }
    return <>
        <input
            name={obj.name}
            className="form-control fs--1"
            onChange={onChange}
            checked={obj.value}
            type="date"
        />
    </>
}

export function month(obj: { name: any, value: any, onChange: any }) {
    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        obj.onChange(name, value)
    }
    return <>
        <input
            name={obj.name}
            className="form-control fs--1"
            onChange={onChange}
            checked={obj.value}
            type="month"
        />
    </>
}
export class Table extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
        let rows: any = [];
        if (this.props.obj.value) {
            rows = this.props.obj.value.map((item: any) => {
                const keys = Object.keys(item);
                const keysMapping = keys.map((j: any) => {
                    return { name: j, value: item[j] }
                })
                return keysMapping
            })
        }
        this.state = {
            rows
        }
    }

    render() {
        const { rows } = this.state;
        const { columns, obj, showDeleteButton, showAddButton, showSerial } = this.props;

        const columnWithBands = columns.filter((i: any) => i.band && i.band.length > 0);
        const colBandExists = columnWithBands.length > 0;

        return <>
            {
                showAddButton && <div className="text-end">
                    <button onClick={this.onAddRow} type="button" className="btn btn-link p-0 btn-sm">Add New Row</button>
                </div>
            }
            <table className="table table-bordered table-striped table-hover mt-3 fs-14">
                <thead>
                    {
                        <tr className="bg-light">
                            {
                                showSerial && <th rowSpan={colBandExists ? 2 : 1}>S.No.</th>
                            }
                            {
                                columns.map((item: any, index: number) => {
                                    if (!item.show) return;

                                    let css = "";
                                    const hasBand = item.band && item.band.length > 0;
                                    const rowSpan = hasBand ? 1 : 2;
                                    let colSpan = 1;

                                    const bandOccurrence = columnWithBands.filter((i: any) => i.band === item.band);
                                    if (hasBand) {
                                        const bandFirstColName = bandOccurrence[0].name;
                                        if (bandFirstColName === item.name) {
                                            colSpan = bandOccurrence.length;
                                            css = "text-center";
                                        } else {
                                            return;
                                        }
                                    }

                                    return (
                                        <th className={css} rowSpan={rowSpan} colSpan={colSpan} key={index} dangerouslySetInnerHTML={{ __html: hasBand ? item.band : item.label }} />
                                    )
                                })
                            }
                            {
                                showDeleteButton && <th rowSpan={colBandExists ? 2 : 1}></th>
                            }
                        </tr>
                    }
                    <tr className="bg-light">
                        {columns.map((item: any) => {
                            if (!item.show) return;
                            if (!item.band) return;

                            return <th dangerouslySetInnerHTML={{ __html: item.label }} />
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.length === 0 && <tr>
                            <td colSpan={20} className="text-center">No Records Found. Click <button className="btn btn-link p-0" type="button" onClick={this.onAddRow}>here</button> to add a row </td>
                        </tr>
                    }
                    {
                        rows.map((rowItem: any, index: number) => {
                            const uid = rowItem.find((i: any) => i.name === 'uid').value;
                            return (
                                <tr key={index}>
                                    {
                                        showSerial && <td>
                                            {index + 1}
                                        </td>
                                    }
                                    {
                                        columns.map((col: any, index: number) => {

                                            const colName = col.name;
                                            const rowObj = rowItem.find((i: any) => i.name === colName);
                                            const colValue = rowObj.value;
                                            const colOptions = col.options;

                                            if (!col.show) return;
                                            if (col.editable === false) {
                                                return <td>{colValue}</td>
                                            }


                                            return <td key={index}>
                                                {
                                                    col.type === controlType.string && input({ name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value) })
                                                }
                                                {
                                                    col.type === controlType.description && textarea({ name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value) })
                                                }
                                                {/* {
                                                    col.type === controlType.richtext && editor({ name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value) })
                                                } */}
                                                {
                                                    col.type === controlType.number && number({ name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value) })
                                                }
                                                {
                                                    col.type === controlType.float && float({ name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value) })
                                                }
                                                {
                                                    col.type === controlType.boolean && boolean({ trueLabel: 'Yes', falseLabel: 'No', name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value) })
                                                }
                                                {
                                                    col.type === controlType.checkbox && checkbox({ name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value) })
                                                }
                                                {
                                                    col.type === controlType.select && select({ name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value), options: colOptions })
                                                }
                                                {
                                                    col.type === controlType.date && date({ name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value) })
                                                }
                                                {
                                                    col.type === controlType.month && month({ name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value) })
                                                }
                                                {
                                                    col.type === controlType.year && year({ name: colName, value: colValue, onChange: (name: string, value: any) => this.onChange(uid, name, value) })
                                                }
                                            </td>
                                        })
                                    }
                                    {
                                        showDeleteButton && <td className="text-center">
                                            <button type="button" onClick={() => this.onDeleteRow(uid)} className="btn btn-link text-danger p-0 btn-sm">Delete</button>
                                        </td>
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {/* {
                showAddButton && <div className="text-end mt-3">
                    <button onClick={this.onAddRow} type="button" className="btn btn-link p-0 btn-sm">Add New Row</button>
                </div>
            } */}
        </>
    }

    onAddRow = () => {
        const { rows } = this.state;
        const { columns } = this.props;

        const uid = uniqid();
        const clonedColumns = JSON.parse(JSON.stringify(columns));
        clonedColumns[0].value = uid;
        const newRows = rows.concat([clonedColumns]);
        // setRows(newRows)
        this.setState({ rows: newRows })
        this.onChangeParent(this.props.obj.name, newRows)
    }

    onDeleteRow = (uid: string) => {
        const { rows } = this.state;

        const clonedRows = JSON.parse(JSON.stringify(rows));
        const filteredRows = clonedRows.filter((i: any) => {
            const uidColumn = i.find((j: any) => j.name === 'uid');
            if (uidColumn.value !== uid) {
                return true;
            } else {
                return false;
            }
        });
        this.setState({ rows: filteredRows })
        this.onChangeParent(this.props.obj.name, filteredRows)
    }

    onChange = (uid: string, name: string, value: any) => {
        const clonedRows = JSON.parse(JSON.stringify(this.state.rows));
        for (let i = 0; i < clonedRows.length; i++) {
            const colArr = clonedRows[i];
            const uidObj = colArr.find((j: any) => j.name === 'uid')
            if (uidObj.value === uid) {
                for (let x = 0; x < colArr.length; x++) {
                    if (colArr[x].name === name) {
                        colArr[x].value = value;
                        break;
                    }
                }
                break;
            }
        }
        this.setState({ rows: clonedRows });
        this.onChangeParent(this.props.obj.name, clonedRows)
    }

    onChangeParent = (name: string, value: any) => {
        const objList = value.map((item: any) => {
            const object = item.reduce((obj: any, item: any) => Object.assign(obj, { [item.name]: item.value }), {});
            return object;
        })

        this.props.onChange(name, objList)
    }
}

// export function editor(obj: { name: any, value: any, onChange: any, placeholder?: any }) {
//     const { name, value, placeholder } = obj;
//     const editorConfiguration = {
//         placeholder,
//     };

//     const data = value;
//     return <div className="form-control p-0">
//         <CKEditor
//             config={editorConfiguration}
//             // editor={GeneralHtmlSupport}
//             editor={BalloonEditor}
//             data={data}
//             onReady={editor => {
//                 // You can store the "editor" and use when it is needed.
//             }}
//             onChange={(event: any, editor: any) => {
//                 const data = editor.getData();
//                 obj.onChange(name, data);
//             }}
//             onBlur={(event, editor) => {
//             }}
//             onFocus={(event, editor) => {
//             }}
//         />
//     </div>
// }