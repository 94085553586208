import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

export const UIContext = React.createContext({});

export const UIContextProvider = (props: any) => {
    const [showSideBar, setShowSideBar] = useState(true);
    const [showHeader, setShowHeader] = useState(true);

    return (
        <UIContext.Provider value={{
            toast,
            showSideBar, setShowSideBar,
            showHeader, setShowHeader
        }}>
            {props.children}
            <Toaster
                position="top-center"
            />
        </UIContext.Provider>
    )
}

const WithUIContext = (Component: any) => {
    return (props: any) => (
        <UIContext.Consumer>
            {(value: any) => <Component {...props} context={value} />}
        </UIContext.Consumer>
    )
}

export default WithUIContext;