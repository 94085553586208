import moment from "moment";
import { useState } from "react";
import { Table, controlType } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const options = [
        'Within the organization',
        'Outside the Organization',
        'Both'
    ]
    const [stateValue, setStateValue] = useState(props.value ? props.value.answer["302-3-d-radio"] : '');
    const [remarks, setRemarks] = useState(props.value ? props.value.answer["302-3-d-remarks"] : '');

    const columns: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        // { name: '302-3-d-i', label: 'Organization - Specific Metric', type: controlType.radio, value: '', show: true, band: '', },
    ]

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "302-3-d-radio": stateValue,
                "302-3-d-remarks": remarks,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }
    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                {
                    options.map((i: any, index: number) => {
                        const checked = i === stateValue;
                        return (
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" id={`radio-id-${index}`} type="radio" name="radio-option" value={i} onChange={() => setStateValue(i)} checked={checked} />
                                <label className="form-check-label" htmlFor={`radio-id-${index}`}>{i}</label>
                            </div>
                        )
                    })
                }
            </div>
            <div className="">
                <label className="fw-bold mb-3">Remarks</label>
                <textarea className="form-control" rows={3} onChange={(e: any) => setRemarks(e.target.value)} value={remarks} />
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const answerJSON1 = answer ? answer.answer["302-3-d-radio"] : "";
    const answerJSON2 = answer ? answer.answer["302-3-d-remarks"] : '';

    return (
        <>
            {
                answerJSON2 && <div className="mb-3">
                    <div className=""> {answerJSON1} </div>
                </div>
            }
            {
                answerJSON2 && <div className="">
                    <label className="fw-bold mb-1">Remarks</label>
                    <div className=""> {answerJSON2} </div>
                </div>
            }
        </>
    )
}