import React, { useState } from "react"
// import { getDetail } from "../../../action/CapitalGoodsAction";
import ModalWindow from "../../common/ModalWindow";
import Shimmer from "../../common/Shimmer";
import AddDetailForm from "./AddDetailForm";

interface IPropType {
    id: any,
    children: any,
    css: any,
    onSubmitComplete: any,
}

function EditDetailButton(props: IPropType) {
    const [showModal, setShowModal] = useState(false);
    const [detail, setDetail] = useState(undefined);
    const { css, id } = props;

    const onShowModal = () => {
        setShowModal(true);
    }

    const onHideModal = () => {
        setShowModal(false);
    }

    const onSubmitComplete = (detail: any) => {
        onHideModal();
        props.onSubmitComplete(detail);
    }

    return (
        <React.Fragment>
            {
                showModal && renderModal()
            }
            <button type="button" className={css} onClick={onShowModal}>
                {props.children}
            </button>
        </React.Fragment>
    )

    function renderModal() {
        return (
            <ModalWindow
                title="Detail"
                backdrop="static"
                css="modal-lg"
                onHideModal={onHideModal}
            >
                {
                    <AddDetailForm
                        id={props.id}
                        detail={detail}
                        onSubmitComplete={onSubmitComplete}
                        onCancel={onHideModal}
                    />
                }
            </ModalWindow>
        )
    }
}

export default EditDetailButton;