import { useEffect, useState } from "react";
import moment from "moment";

import DatePicker from "../../../component/common/DatePicker";
import DashboardWrapper from "../../../component/ghg/dashboard/DashboardWrapper";
import constant from "../../../constant";
// import { getCompanyReport } from "../../action/ReportAction";

function CompanyDashboard() {
    const [startDate, setStartDate] = useState(moment().subtract(1, 'year').startOf('year'));
    const [endDate, setEndDate] = useState(moment());

    const [emissionList, setEmissionList]: any = useState(undefined);
    const [emissionCategory, setEmissionCategory]: any = useState(undefined);
    const [emissionMapping, setEmissionMapping]: any = useState(undefined);
    const [branchList, setBranchList]: any = useState(undefined);

    function onCallbackDateRange(startDate: any, endDate: any) {
        setStartDate(startDate);
        setEndDate(endDate);
        onLoadList();
    }

    function onLoadList() {
        const sd = startDate.format('YYYY-MM-DD');
        const ed = endDate.format('YYYY-MM-DD');

        const _emission_list = [
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "34.64604",
                "emissionUnit": "kgCO2e",
                "dated": "2023-01-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "1.6152",
                "emissionUnit": "kgCO2e",
                "dated": "2023-02-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "34.64604",
                "emissionUnit": "kgCO2e",
                "dated": "2023-01-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "1.6152",
                "emissionUnit": "kgCO2e",
                "dated": "2023-02-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "34.64604",
                "emissionUnit": "kgCO2e",
                "dated": "2023-01-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "1.6152",
                "emissionUnit": "kgCO2e",
                "dated": "2023-02-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "34.64604",
                "emissionUnit": "kgCO2e",
                "dated": "2023-01-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "1.6152",
                "emissionUnit": "kgCO2e",
                "dated": "2023-02-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "34.64604",
                "emissionUnit": "kgCO2e",
                "dated": "2023-01-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "1.6152",
                "emissionUnit": "kgCO2e",
                "dated": "2023-02-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "34.64604",
                "emissionUnit": "kgCO2e",
                "dated": "2023-01-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "1.6152",
                "emissionUnit": "kgCO2e",
                "dated": "2023-02-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "34.64604",
                "emissionUnit": "kgCO2e",
                "dated": "2023-01-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "1.6152",
                "emissionUnit": "kgCO2e",
                "dated": "2023-02-01",
                "branchId": 3,
                "name": "se"
            },
            {
                "emissionId": 1,
                "categoryId": 1,
                "emissionName": "Stationary Emission",
                "emission": "705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "me"
            },
            {
                "emissionId": 2,
                "categoryId": 1,
                "emissionName": "Mobile Combustion",
                "emission": "705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "me"
            },
            {
                "emissionId": 2,
                "categoryId": 1,
                "emissionName": "Mobile Combustion",
                "emission": "1705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "me"
            },
            {
                "emissionId": 2,
                "categoryId": 1,
                "emissionName": "Mobile Combustion",
                "emission": "1705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "me"
            },
            {
                "emissionId": 2,
                "categoryId": 1,
                "emissionName": "Mobile Combustion",
                "emission": "1705.053040000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-03-01",
                "branchId": 3,
                "name": "me"
            },
            {
                "emissionId": 3,
                "categoryId": 1,
                "emissionName": "Fugutive Emission",
                "emission": "15794.999999999998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "ace"
            },
            {
                "emissionId": 3,
                "categoryId": 1,
                "emissionName": "Fugutive Emission",
                "emission": "15794.999999999998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "ace"
            },
            {
                "emissionId": 3,
                "categoryId": 1,
                "emissionName": "Fugutive Emission",
                "emission": "15794.999999999998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "ace"
            },
            {
                "emissionId": 3,
                "categoryId": 1,
                "emissionName": "Fugutive Emission",
                "emission": "15794.999999999998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "ace"
            },
            {
                "emissionId": 3,
                "categoryId": 1,
                "emissionName": "Fugutive Emission",
                "emission": "15794.999999999998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "ace"
            },
            {
                "emissionId": 3,
                "categoryId": 1,
                "emissionName": "Fugutive Emission",
                "emission": "15794.999999999998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "ace"
            },
            {
                "emissionId": 3,
                "categoryId": 1,
                "emissionName": "Fugutive Emission",
                "emission": "15794.999999999998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "ace"
            },
            {
                "emissionId": 3,
                "categoryId": 1,
                "emissionName": "Fugutive Emission",
                "emission": "15794.999999999998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "ace"
            },
            {
                "emissionId": 3,
                "categoryId": 1,
                "emissionName": "Fugutive Emission",
                "emission": "135720",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "ace"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5710.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5710.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5710.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5710.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5710.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5710.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5710.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5710.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5710.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5953.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5953.5",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "6004.530000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "5753.43",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "6564.240000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "6280.740000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 7,
                "categoryId": 2,
                "emissionName": "Purchased Electricity from Grid",
                "emission": "6187.59",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-01",
                "branchId": 1,
                "name": "pee"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "13.171817548943903",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "36.99888587935056",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "90.93908506148912",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "8.338421084697291",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "39.34218675416267",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "783.9530824405585",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "395.2616144158695",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "138.00628351088608",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "120.28118269084382",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "7.2013636640567515",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "523.4137612518139",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "37.83417187025919",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "46.36908129018464",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "375.63239362951646",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "70.92413317297924",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "181.46814588934754",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "12.183358896961176",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "145.2814198902052",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "15.528107115878468",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "36.81745475363257",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "66.67299013757305",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "8.92941832199639",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "10.980157614590476",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "31.857147260692525",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "61.336126004838256",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "378.9521200036406",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "30.091713262221436",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "4.069342006990799",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "36.13327440977877",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "617.7186376391263",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "132.65987807639385",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "12.603884771931707",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "14.377876959146729",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "34.42282355014428",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "637.7656262009535",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "34.63662990759859",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.161772902495487",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "482.2081682845832",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "40.62320791631933",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "217.19518821996314",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "16.864276734036764",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "70.91644575338537",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "808.8606803917634",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "22.385765857328803",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "12.107685860334088",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "43.99065804622475",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "66.21943238153513",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "140.78079606579192",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "49.656526518764025",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "33.15199699853381",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "16.575998499266905",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "4.596836685267713",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "8.287999249633453",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "406.75590473895636",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "19.319326250895582",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "5.409300843594101",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "51.79459009330715",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "19.777088064523884",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "709.8157261125713",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.7026781972515319",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "27.85896837629689",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "557.3302331354673",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "62.02522429749599",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "575.0749596448637",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "109.49023565235332",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "379.20695558096827",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "504.657835817286",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "152.67911985812438",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "37.95062844814043",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "5.7727716512664315",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "15.178174064170824",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "24.29567144568919",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "29.715487086020865",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "14.239221360110882",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "6.953510451133095",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "50.34530366012888",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "112.25674634985731",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.349581802719959",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "54.4779926530558",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "16.38122973525914",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "290.52767589857814",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "14.750853301693812",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "371.87114954052737",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "28.432804190221407",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "43.18365749191522",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "19.85420176689145",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "22.873190526414955",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "29.07414563812114",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "38.26670639135062",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "20.59268122285509",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "225.53840917807207",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "308.47454741563894",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "24.99934551745783",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "571.5207422717136",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "18.554674650480013",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "40.17751958162865",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "466.4048789609477",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "864.8275644445239",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "171.09920927551656",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "45.89770881019951",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "18.91596967119993",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "27.163332447843096",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "179.38320297755476",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1056.3321207873835",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "25.226096950722752",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "171.90088608539122",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "494.83768315116913",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "179.81076394282124",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "20.656238880950326",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "52.964715079359806",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "18.447574231722598",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "47.320189729473746",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "120.39047879491116",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "221.30555562193385",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "58.362071758875516",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "50.60184023928878",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "411.08276566511694",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "54.40713283016051",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "26.6336852970495",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "127.57350301138815",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "41.259633148216025",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "10.62320856734588",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "881.5238201381802",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "25.672754037752547",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "42.5957611646738",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "126.67142143551271",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "40.642481158372014",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "30.824113341691614",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "31.444445948845715",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "77.00680640533645",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.35634083224765",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "36.952571684782974",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "199.25511157380805",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "80.04429710243583",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "31.444445948845715",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "65.57716304774749",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "333.0806809667253",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "163.27484361113986",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "31.960182153669926",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "120.77528366365101",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "136.28065655788848",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "23.46977105331181",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "4.042857336280163",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "32.51398492669761",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "20.321240579186007",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "25.027212081734344",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "74.68686820674871",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "101.40299049013817",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "10.411961949388198",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "217.82230822931697",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "20.279396368839038",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "30.14129081673044",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "196.43152867227906",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "46.03295760674556",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "54.22562617709108",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "184.26017510432447",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "57.57917165079164",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "147.1792587421993",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "14.492253149893179",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "54.22562617709108",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "10.406614254498939",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "7.861111487211429",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "249.1170187212634",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "180.55740712841745",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "15.331060123534327",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "57.27381226396898",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "498.2126466629698",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.7311320383428012",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.30307610136432356",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "130.4837552979312",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "621.091979828374",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "82.65397220839445",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "539.1866849044759",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "95.61678461995942",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "17.882691709683684",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.4530519041013084",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "246.05813724460697",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "306.90420969460126",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "25.027212081734344",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "54.55718326022517",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "65.83012408678414",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "684.220376353353",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "105.58927180161908",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "371.9092265204943",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "36.952571684782974",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "207.10552767124094",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "571.3584173582609",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "179.68254827911838",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "302.6153583934152",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "77.63362835010898",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "22.674827195054473",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "64.54667731336187",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "39.01678191203713",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "61.32736499002767",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "34.6530628824014",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "44.920637069779595",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.380433259091483",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "25.79564872060736",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "20.900256001220388",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "273.6754547359802",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "199.28126145163625",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "108.84210240635541",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "878.5834887462178",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "363.37586772518137",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "125.86334691361101",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "270.0083596275202",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "308.5833858898288",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "46.471468587664845",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "20.716970000991207",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "68.15102366872276",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "3.2582963201902553",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "112.7863558465857",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "47.418471912881174",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "36.49506240213098",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "355.636612500766",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "33.729797761969515",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "40.82600971744005",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "276.67651713615544",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "2.7428650572388107",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "68.11339840397721",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "100.31051072585724",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "692.1521702804155",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "20.74273635357074",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "56.58074432330381",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "14.27648256083362",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.4696686741847278",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "187.01441889091998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "107.43128996582358",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "135.76591936792752",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "388.33073062222564",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "41.736203522437016",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "28.456502401661602",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "82.81583432393684",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "43.68689408255092",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "32.95809600192446",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "148.62761536867853",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "28.456502401661602",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "189.06693121103982",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "306.537088017899",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "38.9066304022718",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "12.670215695121849",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "9.104346607273186",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "62.70076800366117",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "111.46803200650874",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "18.39222528107394",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.380433259091483",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "94.81213568553619",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "194.85062154097753",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "174.40459777018367",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "294.6400192172044",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "33.42692168105295",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "998.9527544673188",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "78.64675238086474",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "78.64675238086474",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "49.96685320003021",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "8.686517386322032",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "646.8231712419549",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "60.87359642541315",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1734.135246109348",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "97.10580480567012",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "515.5857357741056",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "574.606201723871",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "34.232343243347174",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "19.72608431575857",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1.6613024000970051",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "113.20436096661012",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "49.762687563298904",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "22.819222556012345",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "621.8885294835587",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "26.80159947023198",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "90.11395503008372",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "31.294933791102643",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "7.7690397556176",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "59.04685125465935",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "457.71657696475324",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "25.692633548660694",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "28.31583961386314",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "102.24400176307256",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "67.87970143324533",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "63.62675373729012",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "312.6938567929684",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "61.90529090552492",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "172.05790396535133",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "53.373195665494634",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "35.036803343779546",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "226.44655503458853",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "55.08646772446512",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "361.0508102195731",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "5.705892268648611",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "150.17994415561765",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "4.754910223873842",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "157.04635688568635",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "220.43441431084293",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "458.4593100600621",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "137.74733143466392",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "474.4594486438997",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "502.7954899102394",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "2.7078810766467982",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1.6763073331623037",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "292.67681303424143",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "65.3867315531578",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "441.31917503407584",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "189.22930857043696",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "649.9666773140274",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "280.9164618539855",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "9.165962533252852",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "64.88169024124352",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "9.079394371492743",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "51.11729278891256",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.667855495043014",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "117.13852297257375",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "58.492600149262934",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "250.38203042281702",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "54.92018184394753",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "63.27681881107513",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "78.35457909486915",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "13.975361739463478",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "437.23696223872173",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "46.24382207810522",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "24.430386208923053",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "9.105376077374178",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "410.1687641931942",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "86.84196258071437",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.2341212895778608",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "318.400086439413",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "17.590734730444677",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.9491403631534896",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "810.4473492723479",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1.3212520593744346",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "6.010613876662387",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "32.045777407286494",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "345.7204833694764",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "75.48356334945797",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "4.602722337984711",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "93.48399816819772",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "83.55294785306364",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "391.1339293144843",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "48.95130580633152",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "25.61691144428771",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "276.1416804092569",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "26.63555565264966",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "333.56199531748024",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "533.3958460310579",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "95.3359170383045",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "378.2651508548755",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "79.60002160985324",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "22.453704054926355",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "57.63496421819716",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "440.25851407942224",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "2.183022835253026",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "510.07910446292215",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "76.07487779570259",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "210.09857132337834",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.499200553590356",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "68.01199125304467",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.04745701815767449",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "19.648300679234723",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "56.099062848848945",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "531.5221589917762",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.455587374313675",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "45.23018884412206",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "16.098880775026498",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "48.71061354500875",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "514.5735274517777",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "42.001258504862506",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "14.78146480228477",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "59.82235209797102",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "19.971786989189226",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "66.0011077947506",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "15.328926461628651",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "33.56548262510591",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "45.986779384885956",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "22.157963200284215",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "60.63442022599777",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "5.512573935153313",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "157.48891350346747",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1.5534263943612114",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1752.2328480886936",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "42.768921409409145",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "157.45459623686781",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "108.18815653807245",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "64.69476086613156",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "268.3722749502823",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "213.4981246457543",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "268.5671713010087",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "243.8469658768486",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "123.59676908567225",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "45.85478029591082",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "34.49665407857659",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "72.35018124691499",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.948933542862726",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "23.441699962372354",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "681.0099885832806",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "54.44216459343132",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "45.785860423075746",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "329.0729903858415",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "164.41795710133368",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "227.92084608216757",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "39.49147123271555",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "43.53246520420075",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "4.8669836874261705",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "25.986180096856188",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "404.58316895797014",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "2.219652883273133",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "19.056532071027874",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "10.01550691232999",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "53.86128614084962",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "264.0176417639117",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "29.62844676233145",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "62.94588973660626",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "46.67415725203087",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "15.438072256515817",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "149.11356466343247",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "344.04166910450334",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "68.35408201007422",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "279.4081257354838",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "7.227197349778009",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "17.02678697659565",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "45.20887069653644",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "167.11058878760392",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "13.775726939277142",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "60.22470507936976",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "41.96421490491899",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "50.18400957701652",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "467.6738702844334",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "3.893586949940936",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "678.5224191430406",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "282.9339850290414",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "92.47269006109724",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "40.88266297437983",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "554.8745415362521",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "70.08434878855074",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "7.279695151350245",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "103.8289853317583",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "17.75542522154066",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "43.48816424855398",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "67.70515085175073",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1749.900470173792",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "13.820046263891482",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "37.86646983775268",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "94.10814240729903",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "97.33967374852341",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "84.09066259941883",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "41.639749325757236",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "42.93761164240422",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "22.653895082619837",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "255.30227756678732",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "27.10199006166753",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1.4911482653588257",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "480.5839569393179",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "805.7047304019005",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "695.1518447246889",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "484.0081748159175",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "91.28714028404326",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "85.20298206960095",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "27.31990543055226",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "62.544775548482114",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "7.9275503007701085",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "106.96807105922973",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "170.95767191664854",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "19.057371392686544",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "158.8969568051547",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "51.36055353200007",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "235.64778792479925",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "201.21107177465026",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "125.8279269194116",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.557427560857276",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "74.03650937807754",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "261.23534069458975",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "52.120646290401766",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "459.7041002813436",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "39.2002894501547",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "18.139741784777467",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "242.2741375065509",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "6.447323946122699",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "78.06836712267943",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "41.045008953691394",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.529496897104323",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1756.8493645182273",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "205.6593834875436",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "741.3944765450108",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "10.862811364357901",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "28.723905339999956",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "126.97819249344228",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.337338615485917",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "11.08964476040092",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "4.8863105897251655",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "19.493555491093637",
                "emissionUnit": "kgCO2e",
                "dated": "2023-02-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.23473811671988062",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "7.440368664264657",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "65.39479763844915",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1.5744778566892201",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "811.8841125002922",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "17.417226645892264",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "205.5171253565169",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "7.071424763557318",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "51.4148458718859",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "500.509897185498",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "62.85710900939839",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "22.748490412164937",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "402.4582571057189",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "17.75542522154066",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "335.7786123551842",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "10.761441708864535",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "282.73177127483723",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "132.23321252687052",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "25.276719275521685",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "227.92084608216757",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "260.56705848471955",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "69.67430450146493",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "24.56787743160766",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "86.57823203965887",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "14.765006692191754",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-20",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "21.2452040737648",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "38.98994709593632",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "19.545587747863618",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "92.74307804373203",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "451.4382616343381",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "512.7939539112955",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "31.884167040187595",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "59.90280098052088",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "226.79438679531236",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "64.74889874133333",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "17.800425090596086",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "50.60978895267872",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "121.83187435409411",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "8.814132715436473",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "72.99951092208518",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "32.63431117163523",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "62.32627542376944",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "10.034969016433182",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "363.88862885669215",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "91.1077400897796",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "6.132906961914856",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "535.5944311177268",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "21.811488914591077",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "61.78607827031373",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "247.03081536336768",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "79.60002160985324",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "358.7502579379152",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "45.21497826137922",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "39.80256618282742",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "2.14432711275523",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "33.605288034744845",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "168.43797770041462",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "34.252285382294474",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "29.196313232352004",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "59.61879169554314",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1738.5813537275433",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "200.51289857898658",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "78.59302458172493",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "4.298223931185394",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-04",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "68.90052961690186",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "5.77000341773455",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "236.40231621519666",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "111.02312414251872",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "28.382462084796515",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "299.15638561050343",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "63.91244074476122",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "29.036866678319484",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "32.85968048065777",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "413.77927229538994",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "15.212815037341564",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "214.06229733286057",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "320.2284284331398",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "28.58318914238287",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "244.63042135720562",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "189.806585911883",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.06262248989129704",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "630.2280343535997",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "64.30848000375504",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "555.1965440324185",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "75.09086848438464",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "156.98771776916666",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-12",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "28.488656641663482",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "16.407092351519825",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "88.3920057651613",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "113.71882880664016",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "40.032028802337514",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "89.79607424524329",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "47.74904640278812",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "47.74904640278812",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "63.34385280369871",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "178.71326593043526",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "35.262483202059016",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "1730.4640742301206",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "207.48200539144483",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "20.321240579186007",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "215.81157495095542",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "185.76822506309566",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "193.9715890232197",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-07",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "46.31103774098706",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "77.41323121692017",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "77.77668219271963",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "31.123584255490147",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "0.9091081311741108",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "41.94275214043465",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "69.32919896522388",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "86.64335259578202",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "80.21542333889212",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "22.659685407278143",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "13.756314194795987",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "115.94872058892395",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "170.5593807980417",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "56.47165803058006",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "29.22028556658922",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "126.44089796165103",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "69.38724248503314",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "21.043720167370907",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "288.7755240200117",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "358.2096158891052",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "232.70005534628572",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "125.53189940224067",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "172.95909947442578",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "29.001123990120572",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "30.745957052871",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "44.241870880949776",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "653.0095386012956",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-10",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "85.64394428332481",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "35.551694261908416",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "59.30534811314602",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-15",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "492.82814661441665",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "53.71234626160247",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-17",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "30.036157809970735",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "36.53508448202134",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-21",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "505.00294510037986",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-23",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "325.4382689153763",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "51.8096999661666",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "63.06524237680688",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-26",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "251.0531097803497",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-29",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "265.85740820270183",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "9.155329320860766",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "35.808230841068315",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-30",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "368.81596660868865",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "110.11748104484005",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-02",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "20.568171587104843",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-03",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "79.4932037015133",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "306.4757983397066",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "30.307051169148764",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "47.465011354857324",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "370.46227556108687",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-11",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "39.981788843956394",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "148.64887002011062",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-14",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "32.75513396200064",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-16",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "28.43624554142353",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-19",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "477.76099604953345",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-22",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "42.24813623297211",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-24",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "30.146696401058033",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-25",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "16.249283166527732",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-27",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "167.0041458075633",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-28",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "31.857147260692525",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-31",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "781.8383962852402",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-05",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "510.6810042878348",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-06",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "42.72809107340339",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-08",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "85.60105168484394",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-09",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "18.601176489850047",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-13",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 15,
                "categoryId": 3,
                "emissionName": "Category 1: Purchased Goods and Services",
                "emission": "138.00628351088608",
                "emissionUnit": "kgCO2e",
                "dated": "2024-01-18",
                "branchId": 1,
                "name": "pge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "4.5377294161446695",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-30",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "204.89874428959556",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-03",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "58.58690156982464",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-16",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "393.99262543727855",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-18",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "41.83131578716785",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-22",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "71.86392021832899",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-09",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "7.783512338786199",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-11",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "70.59645691279084",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-13",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "44.46331423531617",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-13",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "192.6140634889307",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-20",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "140.91424486530414",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-21",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "34.05286648218962",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-22",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "9.071155120758238",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-25",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "195.86235861513123",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-31",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "75.13621420605726",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-28",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "364.9771653387346",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-31",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "5.852794247532763",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "141.1038844918347",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-03",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "103.4918536402003",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-06",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "43.76830331940961",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-06",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "250.53138675080857",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-09",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "10.730122787143399",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-21",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "10.730122787143399",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-21",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "164.32156509878797",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-04",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "25.26948068615563",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-21",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "35.28793641273753",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-23",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "3026.323027749744",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-24",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "496.4891266606831",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-24",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "49.13444122255537",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-10",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "65.93970146015955",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-14",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "43.540901524701766",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-16",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "197.11929588964588",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-16",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "168.21322612980632",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-18",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "24.641144043407905",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-19",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "23.322812415920094",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-24",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "30.243019140604574",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-25",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "32.565735967767885",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-29",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "15.647466895551322",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-08",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "105.9826114268129",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-08",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "42.85474143344574",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-10",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "18.356434601843645",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-14",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "25.40757437165146",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-21",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "50.59038144844605",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "78.74670235835602",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-11",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "885.9004015315053",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-11",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "78.74670235835602",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-11",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "196.85297522297734",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-15",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "19.686675589589004",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-19",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "19.686675589589004",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-19",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "4.4344394406854315",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-08",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "4.4344394406854315",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-08",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "242.87929697942167",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-16",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "23.139890432483398",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-21",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "23.139890432483398",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-21",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "26.416849935540043",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-23",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "116.2808564446402",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-26",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "28.989261768608433",
                "emissionUnit": "kgCO2e",
                "dated": "2023-01-16",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "17.49189386389256",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-08",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "17.49189386389256",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-08",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "40.39594098022478",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-09",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "40.39594098022478",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-09",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "183.30579600457267",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "50.59038144844605",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "183.30579600457267",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "885.9004015315053",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-11",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "196.85336895648913",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-15",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "242.87929697942167",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-16",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "26.416849935540043",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-23",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "116.2808564446402",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-26",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "68.03912693265791",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-14",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "40.754870347191414",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-18",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "50.3287761795235",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-29",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "18.74894937713251",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-21",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "57.005677833855415",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-28",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "31.31244922430028",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "21.94797842824786",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-09",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "25.294356480359596",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-16",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "213.46555922897508",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-25",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "20.10072327307834",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-29",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "11.35634083224765",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-31",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "4.535028665298473",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-30",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "16.072952979593502",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-16",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 16,
                "categoryId": 3,
                "emissionName": "Category 2: Capital Goods",
                "emission": "11.480680699709644",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-29",
                "branchId": 1,
                "name": "cge"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "186.74745",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "796.1424",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "186.74745",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "796.1424",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "194.69414999999998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "194.69414999999998",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "830.0208",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "830.0208",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "196.362957",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "837.135264",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "188.151367",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "802.127584",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "214.666856",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "915.168512",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "202.349471",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "205.395706",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "875.643712",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 13,
                "categoryId": 3,
                "emissionName": "Category 3: Fuel- and Energy-Related Activities",
                "emission": "862.6569920000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-01",
                "branchId": 1,
                "name": "upse"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.387405",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.387405",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.3426500000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.3426500000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.387405",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.387405",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.3426500000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.3426500000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.387405",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.387405",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.3426500000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.3426500000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.387405",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.387405",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.3426500000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.3426500000000001",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.387405",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 14,
                "categoryId": 3,
                "emissionName": "Category 5: Waste Generated in Operations",
                "emission": "1.387405",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-01",
                "branchId": 1,
                "name": "opwe"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "370.69869687999994",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "btecar"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "370.69869687999994",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "btecar"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "370.69869687999994",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "btecar"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "358.74067439999993",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "btecar"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "358.74067439999993",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "btecar"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "370.69869687999994",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-01",
                "branchId": 1,
                "name": "btecar"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "358.74067439999993",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "btecar"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "358.74067439999993",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "btecar"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "370.69869687999994",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "btecar"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "421.605",
                "emissionUnit": "kgCO2e",
                "dated": "2023-11-01",
                "branchId": 1,
                "name": "btebike"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "435.65850000000006",
                "emissionUnit": "kgCO2e",
                "dated": "2023-10-01",
                "branchId": 1,
                "name": "btebike"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "435.65850000000006",
                "emissionUnit": "kgCO2e",
                "dated": "2023-12-01",
                "branchId": 1,
                "name": "btebike"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "421.605",
                "emissionUnit": "kgCO2e",
                "dated": "2023-09-01",
                "branchId": 1,
                "name": "btebike"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "435.65850000000006",
                "emissionUnit": "kgCO2e",
                "dated": "2023-07-01",
                "branchId": 1,
                "name": "btebike"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "435.65850000000006",
                "emissionUnit": "kgCO2e",
                "dated": "2023-08-01",
                "branchId": 1,
                "name": "btebike"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "421.605",
                "emissionUnit": "kgCO2e",
                "dated": "2023-06-01",
                "branchId": 1,
                "name": "btebike"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "435.65850000000006",
                "emissionUnit": "kgCO2e",
                "dated": "2023-05-01",
                "branchId": 1,
                "name": "btebike"
            },
            {
                "emissionId": 10,
                "categoryId": 3,
                "emissionName": "Category 7: Employee Commuting",
                "emission": "421.605",
                "emissionUnit": "kgCO2e",
                "dated": "2023-04-01",
                "branchId": 1,
                "name": "btebike"
            }
        ]

        const emissionList = _emission_list.map((i: any) => {
            i.branchId = Math.floor(Math.random() * 3) + 1
            return i;
        })


        // id, "branchName", "noOfEmployee" , "branchLocation"
        const branchList = [
            { id: 1, branchName: 'Golden Ridge Oil Field' },
            { id: 2, branchName: 'Silver Creek Oil Field' },
            { id: 3, branchName: 'Emerald Valley Oil Field' },
        ]

        const emissionCategory = [
            { id: 1, name: "Scope 1" },
            { id: 2, name: "Scope 2" },
            { id: 3, name: "Scope 3" }
        ]

        const emissionMapping = [
            { emissionId: 1, name: "Stationary Combustion", categoryId: 1, categoryName: "Scope 1" },
            { emissionId: 2, name: "Mobile Combustion", categoryId: 1, categoryName: "Scope 1" },
            { emissionId: 3, name: "Fugutive Emission", categoryId: 1, categoryName: "Scope 1" },
            // { emissionId: 4, name: "Fire Supression", categoryId: 1, categoryName: "Scope 1" },
            // { emissionId: 5, name: "Purchased Gases", categoryId: 1, categoryName: "Scope 1" },
            // { emissionId: 6, name: "Waste Gases", categoryId: 1, categoryName: "Scope 1" },

            { emissionId: 7, name: "Purchased Electricity from Grid", categoryId: 2, categoryName: "Scope 2" },
            // { emissionId: 8, name: "Purchased Steam", categoryId: 2, categoryName: "Scope 2" },

            { emissionId: 15, name: "Category 1: Purchased Goods and Services", categoryId: 3, categoryName: "Scope 3" },
            { emissionId: 16, name: "Category 2: Capital Goods", categoryId: 3, categoryName: "Scope 3" },
            { emissionId: 13, name: "Category 3: Fuel and Energy Related Activities", categoryId: 3, categoryName: "Scope 3" },
            { emissionId: 11, name: "Category 4: Upstream Transportation and Distribution", categoryId: 3, categoryName: "Scope 3" },
            { emissionId: 14, name: "Category 5: Waste Generated in Operations", categoryId: 3, categoryName: "Scope 3" },
            { emissionId: 9, name: "Category 6: Business Travel", categoryId: 3, categoryName: "Scope 3" },
            { emissionId: 10, name: "Category 7: Employee Commuting", categoryId: 3, categoryName: "Scope 3" },
            { emissionId: 17, name: "Category 8: Downstream Transportation and Distribution", categoryId: 3, categoryName: "Scope 3" },
        ]
        setEmissionList(emissionList);
        setBranchList(branchList);
        setEmissionCategory(emissionCategory);
        setEmissionMapping(emissionMapping);
        //     }
        // });
    }

    useEffect(() => {
        document.title = constant.component.ghgOrganisationEmissionDashboard.title;
        onLoadList();
    }, [endDate]);

    return (
        <>
            <div className="container-fluid">
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Organisation Dashboard</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">GHG</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Organisation</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="d-md-flex d-block flex-wrap align-items-center justify-content-between">
                                    <div className="flex-fill">
                                        <ul className="nav nav-pills nav-style-3" role="tablist">
                                            <li className="nav-item" role="presentation"> <a className="nav-link active" data-bs-toggle="tab" role="tab" aria-current="page" href="#div-1" aria-selected="true">Emission Dashboard</a> </li>
                                            <li className="nav-item" role="presentation"> <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#div-2" aria-selected="false" tabIndex={-1}>Scope 1 Emission Trend</a> </li>
                                            <li className="nav-item" role="presentation"> <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#div-3" aria-selected="false" tabIndex={-1}>Scope 2 Emission Trend</a> </li>
                                            <li className="nav-item" role="presentation"> <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#div-4" aria-selected="false" tabIndex={-1}>Scope 3 Emission Trend</a> </li>
                                        </ul>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center mt-md-0 mt-3 justify-content-evenly gap-4">
                                        <DatePicker startDate={startDate} endDate={endDate} onDateChanged={onCallbackDateRange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DashboardWrapper
                    startDate={startDate}
                    endDate={endDate}
                    emissionList={emissionList}
                    emissionCategory={emissionCategory}
                    branchList={branchList}
                    emissionMapping={emissionMapping}
                    showBranchSection={true}
                />
            </div>
        </>
    )
}

export default CompanyDashboard;
