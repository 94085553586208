import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

import constant from '../../constant';

function DatePicker(props: { startDate: any, endDate: any, onDateChanged: any }) {
    const { startDate, endDate, onDateChanged } = props;
    return (
        <>
            <DateRangePicker
                initialSettings={{
                    opens: 'left',
                    maxSpan: {
                        "years": 4
                    },
                    startDate: startDate,
                    endDate: endDate,
                    ranges: {
                        'Since Jan of Last Year': [moment().subtract(1, 'year').startOf('year'), moment()],
                        'This Year Q1': [moment().quarter(1).startOf('quarter'), moment().quarter(1).endOf('quarter')],
                        'This Year Q2': [moment().quarter(2).startOf('quarter'), moment().quarter(2).endOf('quarter')],
                        'This Year Q3': [moment().quarter(3).startOf('quarter'), moment().quarter(3).endOf('quarter')],
                        'This Year Q4': [moment().quarter(4).startOf('quarter'), moment().quarter(4).endOf('quarter')],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        'This Year': [moment().startOf('year'), moment().endOf('year')]
                    }

                }}
                onCallback={onDateChanged}
            >
                <div className="input-group">
                    <input type="text"
                        className="form-control"
                        placeholder="Select date range" value={`${startDate.format(constant.dateTimeFormat.dateFormat)} - ${endDate.format(constant.dateTimeFormat.dateFormat)}`}
                    />
                    <span className="input-group-text" id="basic-addon1">
                        <svg style={{ height: '18px' }} className="mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                    </span>
                </div>
            </DateRangePicker>
        </>
    )
}
export default DatePicker;