import React, { useState } from "react"
import ModalWindow from "../../common/ModalWindow";
import AddDetailForm from "./BulkUploadForm";

interface IPropType {
    children: any,
    css: any,
}

function AddDetailButton(props: IPropType) {
    const [showModal, setShowModal] = useState(false);
    const { css } = props;

    const onShowModal = () => {
        setShowModal(true);
    }

    const onHideModal = () => {
        setShowModal(false);
    }

    const onSubmitComplete = (detail: any) => {
        onHideModal();
    }

    return (
        <React.Fragment>
            {
                showModal && renderModal()
            }
            <button type="button" className={css} onClick={onShowModal}>
                {props.children}
            </button>
        </React.Fragment>
    )

    function renderModal() {
        return (
            <ModalWindow
                title="Help"
                backdrop={false}
                css="modal-md"
                onHideModal={onHideModal}
            >
                Coming Soon
            </ModalWindow>
        )
    }
}

export default AddDetailButton;