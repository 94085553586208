import _ from 'lodash';
import { dynamicSort, getFormattedFloat, getFormattedNumber } from '../../../../utils';
import Shimmer from '../../../common/Shimmer';
import PieChart from '../../../charts/PieChart';

export default function Analytics(props: any) {
    const { list } = props;
    const totalEmission = list && list.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    return (
        <>
            <div className="row">
                <div className="col-8">
                    <div className="card custom-card">
                        <div className="card-header">
                            <div className="card-title">Summary</div>
                        </div>
                        <div className="card-body p-0">
                            {RenderSummary()}
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card custom-card">
                        <div className="card-header">
                            <div className="card-title">Emission Distribution By Fuel</div>
                        </div>
                        <div className="card-body p-0">
                            {RenderChart1()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    function RenderSummary() {
        const _list = [];
        const uniqueEmissionSourceId = _.groupBy(list, function (item: any) {
            return item.emissionSourceName;
        });
        for (const key in uniqueEmissionSourceId) {
            const arrayObj = uniqueEmissionSourceId[key];
            const id = arrayObj[0].emissionSourceName;
            const name = arrayObj[0].emissionSourceName;
            const unit = arrayObj[0].uom;
            const quantity = arrayObj.reduce((acc: any, item: any) => acc + parseFloat(item.activityData), 0);
            const emission = arrayObj.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
            const value = emission;

            const obj = {
                id, name, unit, quantity, emission,
            }
            _list.push(obj);
        }

        _list.sort(dynamicSort('-emission'));

        return (
            <>
                <table className="table fs--1 mb-0">
                    <thead className="bg-light text-900">
                        <tr>
                            <th className="align-middle white-space-nowrap" style={{ minWidth: 150 }}>Emission Source</th>
                            <th className="align-middle text-right white-space-nowrap">Quantity</th>
                            <th className="align-middle text-right white-space-nowrap">Emission (kgCO2e)</th>
                            <th className="align-middle text-right white-space-nowrap">Total %</th>
                        </tr>
                    </thead>
                    <tbody className="list" id="table-customers-body">
                        {
                            list === undefined && <tr>
                                <td colSpan={5} className="text-center">Loading...</td>
                            </tr>
                        }
                        {
                            list && _list.map((item: any, index: number) => {
                                const percentage = getFormattedNumber((item.emission / totalEmission) * 100);
                                return (
                                    <tr className="btn-reveal-trigger" key={index}>
                                        <td className="align-middle text-left date"><span className="fs--1 fw-semi-bold">{item.name}</span></td>
                                        <td className="align-middle text-right"><span className="fs--1 fw-semi-bold">{item.quantity === 0 ? '-' : `${getFormattedFloat(item.quantity)} ${item.unit}`}</span></td>
                                        <td className="align-middle text-right"><span className="fs--1 fw-semi-bold">{item.emission === 0 ? '-' : getFormattedFloat(item.emission)}</span></td>
                                        <td className="align-middle text-right emission"><span className="badge rounded-pill bg-soft-info text-primary fs--2 ms-2">{item.emission === 0 ? '0' : `${percentage}`}%</span></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    {
                        list && <tfoot className="bg-light">
                            <tr className="text-700 fw-bold">
                                <td className="" colSpan={2}>Total</td>
                                <td className="text-right">{getFormattedFloat(totalEmission)}</td>
                                <td className="text-right"></td></tr>
                        </tfoot>
                    }
                </table>
            </>
        )
    }

    function RenderChart1() {
        const _list = [];
        const uniqueEmissionSourceId = _.groupBy(list, function (item: any) {
            return item.fuelTypeName;
        });
        
        for (const key in uniqueEmissionSourceId) {
            const arrayObj = uniqueEmissionSourceId[key];
            const id = arrayObj[0].emissionSourceId;
            const name = arrayObj[0].fuelTypeName;
            const unit = arrayObj[0].quantityUnit;
            const quantity = arrayObj.reduce((acc: any, item: any) => acc + parseFloat(item.quantity), 0);
            const emission = arrayObj.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
            const value = emission;

            const obj = {
                id, name, unit, quantity, emission,
            }
            _list.push({ name, value })
        }
        _list.sort(dynamicSort('-value'));
        return (
            <>
                {
                    list === undefined
                        ? <Shimmer />
                        : <PieChart
                            data={_list}
                            total={getFormattedNumber(totalEmission)}
                            showLegend={true}
                        />
                }

            </>
        )
    }
}