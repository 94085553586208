import WithToastContext from "../../../context/UIContext";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { onChange } from "../../../utils";
import React from "react";

interface IPropType {
    detail: any,
    onSubmitComplete: any,
    onCancel: any,
    context: any
}

class AddDetailForm extends React.Component<IPropType, any>{
    constructor(props: any) {
        super(props);
        const id = props.id;
        this.state = {
            year: id ? '2024' : '',
            location: id ? 'Pune' : '',
            actionName: id ? 'Wind Energy' : '',
            expectedCarbonCreditGeneration: id ? '' : '',
            expectedGHGReduction: id ? '5000' : '',
            description: id ? '' : '',
        };
    }

    render() {
        const { year, location, actionName, expectedCarbonCreditGeneration, expectedGHGReduction, description } = this.state;
        const yearList = [];
        for (let i = 0; i <= 20; i++) {
            yearList.push(i + 2020);
        }

        const actionNameList = [
            {
                name: 'Wind Energy', actionCategory: 'Energy Sources',
                reductionInScope: 'Scope 2', scopeCategory: 'Purchased Electricity from Grid',
                ghgStrategyReduction: true, ghgStrategyGenerateCarbonCredit: false,
                // expectedAnnualCarbonReduction: '100', expectedCarbonCreditsGeneration: ''
            },
            { name: 'Solar Energy' },
            { name: 'Nature investment' },
            { name: 'Sustainable Water Management' },
            { name: 'Waste To Energy Technology' },
            { name: 'Waste Recycling' },
            { name: 'Move to cloud computing' },
            { name: 'Encourage Digitization for Day To Day Work' },
            { name: 'Use Electrical Vehicles' },
            { name: 'Use Greener Business Travel Approach' },
        ]
        const actionNameObj = actionNameList.find((i: any) => i.name === actionName);
        return (
            <>
                <form>
                    <div className="mb-3 row">
                        <div className="col-4">
                            <label className="form-label" htmlFor="location">Implementation Year<span className="text-danger">*</span></label>
                            <select
                                className={"form-select"}
                                value={year}
                                onChange={(e: any) => this.setState({ year: e.target.value })}
                            >
                                <option value="">Select an option</option>
                                {
                                    yearList.map((i: any) => <option value={i}>{i}</option>)
                                }
                            </select>
                        </div>
                        <div className="col-4">
                            <label className="form-label" htmlFor="location">Location<span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className={"form-control"}
                                value={location}
                                onChange={(e: any) => this.setState({ location: e.target.value })}
                            />
                        </div>
                        <div className="col-4">
                            <label className="form-label" htmlFor="location">Action <span className="text-danger">*</span></label>
                            <select
                                className={"form-select"}
                                value={actionName}
                                onChange={(e: any) => this.setState({ actionName: e.target.value })}
                            >
                                <option value="">Select an option</option>
                                {
                                    actionNameList.map((item: any) => <option value={item.name}>{item.name}</option>)
                                }
                            </select>
                        </div>
                    </div>
                    {
                        actionName !== "" && this.renderActionDetail(actionNameObj)
                    }

                    <div className="row mb-3">
                        <div className="col">
                            <label className="form-label" htmlFor="location">Expected GHG Reduction<span className="text-danger">*</span></label>
                            <input
                                value={expectedGHGReduction}
                                onChange={(e: any) => this.setState({ expectedGHGReduction: e.target.value })}
                                type="text"
                                className={"form-control"}
                            />
                        </div>
                        <div className="col">
                            <label className="form-label" htmlFor="location">Expected Carbon Credits Generation<span className="text-danger">*</span></label>
                            <input
                                value={expectedCarbonCreditGeneration}
                                onChange={(e: any) => this.setState({ expectedCarbonCreditGeneration: e.target.value })}
                                type="text"
                                className={"form-control"}
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="form-label" htmlFor="location">Action Desciption</label>
                        <textarea rows={3}
                            value={description}
                            onChange={(e: any) => this.setState({ description: e.target.value })}
                            className={"form-control"}
                        />
                    </div>

                    <div className="row">
                        <div className="col">
                            <button className="btn btn-secondary  px-5" type="button" onClick={this.props.onCancel} >Cancel</button>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary  px-5" type="button" onClick={this.props.onCancel}>Save</button>
                        </div>
                    </div>
                </form>
            </>
        )
    }

    renderActionDetail = (obj: any) => {
        return (
            <>
                <div className="row mb-3">
                    <div className="col">
                        <label className="form-label" htmlFor="location">Action Category<span className="text-danger">*</span></label>
                        <input type="text" disabled className="form-control" value={obj.actionCategory} />
                    </div>
                    <div className="col">
                        <label className="form-label" htmlFor="location">Reduction in Scope<span className="text-danger">*</span></label>
                        <input type="text" disabled className="form-control" value={obj.reductionInScope} />
                    </div>
                    <div className="col">
                        <label className="form-label" htmlFor="location">Scope Category<span className="text-danger">*</span></label>
                        <input type="text" disabled className="form-control" value={obj.scopeCategory} />
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="location">GHG Stratergy<span className="text-danger">*</span></label>
                    <div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                                checked={obj.ghgStrategyReduction}
                                disabled
                            />
                            <label className="form-check-label" htmlFor="inlineCheckbox1">Reduction</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
                                checked={obj.ghgStrategyGenerateCarbonCredit}
                                disabled
                            />
                            <label className="form-check-label" htmlFor="inlineCheckbox2">Generate Carbon Credit</label>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    };

    onSwitchChange = (name: any, value: any) => {
        onChange(this, name, value);
    };

    onTypeaheadChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    };

}

export default WithToastContext(AddDetailForm)