import React, { useState } from "react"
import { useRecoilValue } from 'recoil';
import { answerListAtom, questionListAtom, subTopicListAtom } from '../../atom/ESGAtom';
import ModalWindow from "../common/ModalWindow";
import AddDetailForm from "./EditAnswerForm";
import Shimmer from "../common/Shimmer";
import { useRecoilState } from "recoil";

interface IPropType {
    questionId: any,
    children: any,
    css: any,
    onSubmitComplete: any,
}

function EditDetailButton(props: IPropType) {
    const questionList = useRecoilValue(questionListAtom);
    const [answerList, setAnswerList] = useRecoilState(answerListAtom);

    const [showModal, setShowModal] = useState(false);
    const [question, setQuestion] = useState(null);
    const [answer, setAnswer] = useState(null);
    const { css } = props;

    const onShowModal = () => {
        setShowModal(true);
        const questionDetail = questionList.find((i: any) => i.id === props.questionId);
        setQuestion(questionDetail);

        const answerDetail = answerList.find((i: any) => i.questionId === props.questionId);
        setAnswer(answerDetail);
    }

    const onHideModal = () => {
        setShowModal(false);
        setQuestion(null);
        setAnswer(null);
    }

    const onSubmitComplete = (detail: any) => {
        console.log(JSON.stringify(detail));
        const clonedAnswerList = JSON.parse(JSON.stringify(answerList));
        let matchFound = false;

        for (let i = 0; i < clonedAnswerList.length; i++) {
            if (clonedAnswerList[i].questionId === detail.questionId) {
                clonedAnswerList[i] = detail;
                matchFound = true;
                break;
            }
        }
        if (!matchFound) {
            clonedAnswerList.push(detail);
        }
        setAnswerList(clonedAnswerList);
        onHideModal();
    }

    return (
        <React.Fragment>
            {
                showModal && renderModal()
            }
            <button type="button" className={css} onClick={onShowModal}>
                {props.children}
            </button>
        </React.Fragment>
    )

    function renderModal() {
        return (
            <ModalWindow
                title="Detail"
                backdrop="static"
                css="modal-lg"
                onHideModal={onHideModal}
            >
                <div className="" >
                    {
                        question === null
                            ? <Shimmer />
                            : <AddDetailForm
                                questionId={props.questionId}
                                question={question}
                                answer={answer}
                                onSubmitComplete={onSubmitComplete}
                                onCancel={onHideModal}
                            />
                    }
                </div>
            </ModalWindow>
        )
    }
}

export default EditDetailButton;