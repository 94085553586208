import Chart from "react-apexcharts";
import { getFormattedFloat, getFormattedNumber } from "../../../utils";
import { min } from "moment";

// export function Chart1() {
//     const series = [{
//         name: 'Renewable Energy',
//         type: 'column',
//         data: [10000, 12000, 15000, 20000, 15000, 18000]
//     }, {
//         name: 'Non-Renewable Energy',
//         type: 'column',
//         data: [20000, 19000, 19500, 21000, 40000, 45000]
//     },
//     {
//         name: 'Energy Purchased',
//         type: 'column',
//         data: [40000, 38000, 35000, 38000, 38000, 37000]
//     },
//     {
//         name: 'Emissions',
//         type: 'line',
//         data: [6000, 15000, 25000, 18000, 35000, 30000],
//     },
//     ]
//     const options = {
//         chart: {
//             toolbar: {
//                 show: false
//             },
//             stacked: false,
//         },
//         grid: {
//             borderColor: '#f5f4f4',
//             strokeDashArray: 3
//         },
//         dataLabels: {
//             enabled: false
//         },
//         xaxis: {
//             categories: ['2018', '2019', '2020', '2021', '2022', '2023'],
//         },
//         yaxis: [{
//             title: {
//                 text: "Energy Consumption (GJ)"
//             },
//             labels: {
//                 formatter: function (value: any) {
//                     return "" + getFormattedNumber(value);
//                 }
//             },
//         },
//         ],
//         tooltip: {
//             enabled: true,
//         },
//         legend: {
//             show: true,
//             position: 'bottom',
//             markers: {
//                 width: 10,
//                 height: 10,
//             },
//         },
//         stroke: {
//             width: [1, 1, 1, 2.0],
//             curve: 'straight',
//             dashArray: [0, 0, 0, 4],
//         },
//         plotOptions: {
//             bar: {
//                 columnWidth: "40%",
//                 borderRadiusApplication: "end",
//                 borderRadius: 5,                
//             }
//         },
//         colors: ["rgba(20, 150, 241, 1.0)", "rgba(66, 182, 245, 1)", "rgba(15, 115, 198, 1.0)", "rgba(230, 83, 60, 0.8)"]
//     };
//     return (
//         <div>
//             <Chart
//                 options={options}
//                 series={series}
//                 width="100%"
//                 height="310"
//             />
//         </div>
//     )
// }

// export function Chart2() {
//     const series = [
//         {
//             data: [
//                 {
//                     x: 'Galveston Bay',
//                     y: 100
//                 },
//                 {
//                     x: 'Beaumont',
//                     y: 149
//                 },
//                 {
//                     x: 'Port Arthur',
//                     y: 120
//                 },
//                 {
//                     x: 'Garyville',
//                     y: 55
//                 },
//                 {
//                     x: 'Baytown',
//                     y: 84
//                 },
//                 {
//                     x: 'Baton Rouge',
//                     y: 40
//                 },
//                 {
//                     x: 'Lake Charles',
//                     y: 70
//                 },
//                 {
//                     x: 'Los Angeles',
//                     y: 42
//                 },
//                 {
//                     x: 'Pascagoula',
//                     y: 44
//                 },
//                 {
//                     x: 'Whiting',
//                     y: 43
//                 }
//             ]
//         }
//     ]
//     const options = {
//         legend: {
//             show: false
//         },
//         chart: {
//             height: 350,
//             type: 'treemap',
//             toolbar: {
//                 show: false
//             },
//         },
//         colors: [
//             'rgba(15, 115, 198, 1.0)',
//             'rgba(20, 150, 241, 1.0)',
//             'rgba(66, 182, 245, 1.0)',
//             'rgba(113, 202, 248, 1.0)',
//             'rgba(166, 223, 252, 1.0)',
//             'rgba(23, 175, 202, 1.0)',
//             'rgba(67, 199, 217, 1.0)',
//             'rgba(113, 215, 229, 1.0)',
//             'rgba(54, 146, 243, 1.0)',
//             'rgba(128, 189, 248, 1.0)',
//         ],
//         plotOptions: {
//             treemap: {
//                 distributed: true,
//                 enableShades: false
//             }
//         }
//     };
//     return (
//         <div>
//             <Chart
//                 options={options}
//                 series={series}
//                 type='treemap'
//                 width="100%"
//                 height="310"
//             />
//         </div>
//     )
// }

export function Chart1(props: any) {
    const colors =
        [
            '#094679',
            'rgba(15, 115, 198, 1.0)',

            '#1394ff',
            '#008FFB', '#00E396', '#FEB019'];
    // [
    // '#05457a',
    // '#0f73c6',
    // "#659ed9",
    // '#008FFB', '#00E396', '#FEB019'];
    const { xAxis, yAxis } = props;
    const series = yAxis;
    const categories = xAxis;
    // const categories = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    // const categories = [1, 2, 3, 4, 5, 6, 7];
    // const series = [{
    //     name: 'Projects',
    //     type: 'column',
    //     data: [1.8, 2.5, 2.5, 1.5, 2.5, 2.8, 3.8]
    // }, {
    //     name: 'Tasks',
    //     type: 'column',
    //     data: [1.1, 2.2, 3.1, 4, 4.1, 4.9, 6.5]
    // }, {
    //     name: 'Revenue',
    //     type: 'column',
    //     data: [20, 29, 37, 35, 44, 43, 50],
    // }];
    const options = {
        colors,
        // colors: ["rgb(132, 90, 223)", "rgba(35, 183, 229, 0.85)", "rgba(119, 119, 142, 0.05)", "rgb(132, 90, 223)", "rgba(119, 119, 142, 0.05)", "rgba(35, 183, 229, 0.85)"],
        chart: {
            toolbar: {
                show: false
            },
            height: 300,
            type: 'line',
            stacked: false,
            fontFamily: 'Poppins, Arial, sans-serif',
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        title: {
            text: undefined,
        },
        xaxis: {
            // categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            categories
        },
        yaxis: {
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        },
        // yaxis: [
        //     {
        //         show: true,
        //         axisTicks: {
        //             show: true,
        //         },
        //         axisBorder: {
        //             show: false,
        //             color: '#4eb6d0'
        //         },
        //         labels: {
        //             style: {
        //                 colors: '#4eb6d0',
        //             }
        //         },
        //         title: {
        //             text: undefined,
        //         },
        //         tooltip: {
        //             enabled: true
        //         }
        //     },
        //     {
        //         seriesName: 'Projects',
        //         opposite: true,
        //         axisTicks: {
        //             show: true,
        //         },
        //         axisBorder: {
        //             show: false,
        //         },
        //         labels: {
        //             style: {
        //                 colors: '#00E396',
        //             }
        //         },
        //         title: {
        //             text: undefined,
        //         },
        //     },
        //     {
        //         seriesName: 'Revenue',
        //         opposite: true,
        //         axisTicks: {
        //             show: true,
        //         },
        //         axisBorder: {
        //             show: false,
        //         },
        //         labels: {
        //             show: false,
        //         },
        //         title: {
        //             text: undefined,
        //         }
        //     },
        // ],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'top',
            offsetX: 40,
            fontSize: '13px',
            fontWeight: 'normal',
            labels: {
                colors: '#acb1b1',
            },
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 0, 1.5],
            curve: 'straight',
            dashArray: [0, 0, 0],
        },
        plotOptions: {
            bar: {
                columnWidth: "35%",
                borderRadius: 3
            }
        },
        // colors: ["rgb(132, 90, 223)", "#ededed", "#23b7e5"]
    };

    // useEffect(() => {
    //     if (data && data.emissionList && data.emissionCategory) {
    //         const months = [];
    //         // find min and max date
    //         const datesArray = data.emissionList.map((i: any) => new Date(i.dated));
    //         const minDate = new Date(Math.min(...datesArray));
    //         const maxDate = new Date(Math.max(...datesArray));
    //         var a = moment(minDate).add(-1, 'months');
    //         var b = moment(maxDate).add(1, 'months');

    //         for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'months')) {
    //             months.push(m.format('YYYY-MM'))
    //         }
    //         const cat1Series: any = { type: 'line', name: 'Scope 1', data: [] }
    //         const cat2Series: any = { type: 'line', name: 'Scope 2', data: [] }
    //         const cat3Series: any = { type: 'line', name: 'Scope 3', data: [] }

    //         months.forEach((m: any) => {
    //             const dt_filter = data.emissionList.filter((d: any) => moment(d.dated).format('YYYY-MM') === m);
    //             const cat1_filter = dt_filter.filter((c: any) => c.categoryId === 1);
    //             const cat2_filter = dt_filter.filter((c: any) => c.categoryId === 2);
    //             const cat3_filter = dt_filter.filter((c: any) => c.categoryId === 3);

    //             if (cat1_filter.length > 0) {
    //                 const total = cat1_filter.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    //                 cat1Series.data.push({ x: m, y: total })
    //             } else {
    //                 cat1Series.data.push({ x: m, y: 0 })
    //             }

    //             if (cat2_filter.length > 0) {
    //                 const total = cat2_filter.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    //                 cat2Series.data.push({ x: m, y: total })
    //             } else {
    //                 cat2Series.data.push({ x: m, y: 0 })
    //             }

    //             if (cat3_filter.length > 0) {
    //                 const total = cat3_filter.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    //                 cat3Series.data.push({ x: m, y: total })
    //             } else {
    //                 cat3Series.data.push({ x: m, y: 0 })
    //             }
    //         })

    //         setSeries([cat1Series, cat2Series, cat3Series]);
    //         data.emissionCategory.map((cat: any) => {
    //             // let scope = cat.name.split(" ")[1];
    //             // let scopeId = cat.id;
    //             // let filt = data.emissionList.filter((l: any) => l.categoryId === scopeId);
    //             // if (filt.length > 0) {
    //             //     let scopeData: any = [];
    //             //     init.months.map((m: any) => {
    //             //         let filt2 = filt.filter((d: any) => moment(d.dated).format('YYYY-MM') == m);
    //             //         if (filt2.length > 0) {
    //             //             const totalMonthlyValue = filt2.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    //             //             scopeData.push(parseFloat(totalMonthlyValue))
    //             //         } else {
    //             //             scopeData.push(0);
    //             //         }
    //             //     })
    //             //     init[scope] = scopeData;
    //             // } else {
    //             //     init[scope] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //             // }

    //         })
    //         // setChartData(init)
    //     }
    // }, [data])

    return (
        <div className="">
            <Chart
                options={options}
                series={series}
                type="line"
                width="100%"
                height="300"
            />
        </div>
    )
}

export function Chart2(props: { series: any, labels: any, total: any }) {
    const { series, labels, total } = props;
    // const series = [1754, 1234, 878, 800, 500, 270];
    var options = {
        chart: {
            height: 250,
            width: '100%',
            type: "donut",
        },
        legend: {
            show: false,
            position: "bottom",
        },
        labels,
        colors: [
            'rgba(15, 115, 198, 1.0)',
            'rgba(15, 115, 198, 0.8)',
            'rgba(20, 150, 241, 1.0)',
            'rgba(66, 182, 245, 1.0)',
            'rgba(113, 202, 248, 1.0)',
            'rgba(166, 223, 252, 1.0)',
        ],
        dataLabels: {
            enabled: false,
            dropShadow: {
                enabled: false,
            },
        },
        tooltip: {
            y: {
                formatter: function (value: any) {
                    return getFormattedFloat(value);
                }
            }
        },
    };
    return <Chart
        options={options}
        series={series}
        type="donut"
        // width="100%"
        height="300"
    />
}

export function Chart3(props: { series: any, labels: any, total: any }) {
    const { series, labels, total } = props;
    const options = {
        // series: [1754, 1234],
        // labels: ["Female", "Male"],
        labels,
        chart: {
            height: 250,
            type: 'donut'
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        // stroke: {
        //     show: true,
        //     curve: 'smooth',
        //     lineCap: 'round',
        //     colors: "#fff",
        //     width: 0,
        //     dashArray: 0,
        // },
        tooltip: {
            y: {
                formatter: function (value: any) {
                    return getFormattedFloat(value);
                }
            }
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: '80%',
                    background: 'transparent',
                    labels: {
                        show: false,
                        name: {
                            show: true,
                            fontSize: '20px',
                            color: '#495057',
                            offsetY: -4
                        },
                        value: {
                            show: true,
                            fontSize: '18px',
                            color: undefined,
                            offsetY: 8,
                            formatter: function (val: any) {
                                return val + "%"
                            }
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            label: 'Total',
                            fontSize: '22px',
                            fontWeight: 600,
                            color: '#495057',
                        }

                    }
                }
            }
        },
        // colors: ["rgb(132, 90, 223)", "#23b7e5"],
        colors: [
            'rgba(15, 115, 198, 1.0)',
            'rgba(15, 115, 198, 0.8)',
            'rgba(20, 150, 241, 1.0)',
            'rgba(66, 182, 245, 1.0)',
            'rgba(113, 202, 248, 1.0)',
            'rgba(166, 223, 252, 1.0)',
        ],
    };

    return (
        <div className="leads-source-chart d-flex align-items-center justify-content-center">
            <Chart
                options={options}
                series={series}
                type="donut"
                width="100%"
                height="300"
            />
            <div className="lead-source-value">
                <span className="d-block fs-14">Total</span>
                <span className="d-block fs-25 fw-bold">{total}</span>
            </div>
        </div>
    )
}