import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, number } from "../../common/Controls";
import { getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? JSON.parse(props.value.answer["403-9-a-1"]) : []);
    const [a2, setA2] = useState(props.value ? props.value.answer["403-9-a-2"] : '');

    const columns: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: '403-9-a-i', label: 'Total Number', type: controlType.string, value: '', show: true, band: 'Fatalities (Work Related Injury)', },
        { name: '403-9-a-ii', label: 'Rate', type: controlType.string, value: '', show: true, band: 'Fatalities (Work Related Injury)', },
        { name: '403-9-a-iii', label: 'Total Number', type: controlType.string, value: '', show: true, band: 'High - consequence work related injury - (Excluding Fatalities)- 403-9-a-ii', },
        { name: '403-9-a-iv', label: 'Rate', type: controlType.string, value: '', show: true, band: 'High - consequence work related injury - (Excluding Fatalities)- 403-9-a-ii', },
        { name: '403-9-a-v', label: 'Total Number', type: controlType.string, value: '', show: true, band: 'Recordable work related injury', },
        { name: '403-9-a-vi', label: 'Rate', type: controlType.string, value: '', show: true, band: 'Recordable work related injury', },
        { name: '403-9-a-vii', label: 'Numbers of hours worked', type: controlType.string, value: '', show: true, band: '', },
    ]

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "403-9-a": JSON.stringify(a1),
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setA1(value)}
                    columns={columns}
                    obj={{ name: "", value: a1 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            <div className="mb-3 fw-bold">
                <div className="mb-3">
                    Main type of work related injury
                </div>
                <textarea rows={3} className="form-control" value={a2} onChange={(e: any) => setA2(e.target.value)} />
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1JSON = answer ? JSON.parse(answer.answer["403-9-a"]) : [];
    const a2JSON = answer ? answer.answer["403-9-a"] : '';

    return (
        <>
            <div style={{ overflow: 'auto' }} className="mb-3">
                <table className="table text-nowrap table-bordered border-primary">
                    <thead>
                        <tr>
                            <th rowSpan={2}>
                                #
                            </th>
                            <th colSpan={2}>
                                Fatalities (Work Related Injury)
                            </th>
                            <th colSpan={2}>
                                High - consequence work related injury - (Excluding Fatalities)
                            </th>
                            <th colSpan={2}>
                                Recordable work related injury
                            </th>
                            <th rowSpan={2}>
                                Numbers of hours worked
                            </th>
                        </tr>
                        <tr>
                            <th>
                                Total Number
                            </th>
                            <th>
                                Rate
                            </th>
                            <th>
                                Total Number
                            </th>
                            <th>
                                Rate
                            </th>
                            <th>
                                Total Number
                            </th>
                            <th>
                                Rate
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            a1JSON.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            a1JSON.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td className="">{item['403-9-a-i'] ? item['403-9-a-i'] : '-'}</td>
                                    <td className="">{item['403-9-a-ii'] ? item['403-9-a-ii'] : '-'}</td>
                                    <td className="">{item['403-9-a-iii'] ? item['403-9-a-iii'] : '-'}</td>
                                    <td className="">{item['403-9-a-iv'] ? item['403-9-a-iv'] : '-'}</td>
                                    <td className="">{item['403-9-a-v'] ? item['403-9-a-v'] : '-'}</td>
                                    <td className="">{item['403-9-a-vi'] ? item['403-9-a-vi'] : '-'}</td>
                                    <td className="">{item['403-9-a-vii'] ? item['403-9-a-vii'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="">
                <div className="fw-bold">
                    Main type of work related injury
                </div>
                {a2JSON ? a2JSON : '-'}
            </div>
        </>
    )
}