import moment from "moment";
import { useState } from "react";
import { Table, controlType } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [stateValue, setStateValue] = useState(props.value ? JSON.parse(props.value.answer) : []);
    const columns: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: '302-1-b-i', label: 'Fuel Type', type: controlType.string, value: '', show: true, band: '', },
        { name: '302-1-b-ii', label: 'UoM', type: controlType.string, value: '', show: true, band: '', },
        { name: '302-1-b-iii', label: 'Total Consumption', type: controlType.float, value: '', show: true, band: '', },
    ]

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: JSON.stringify(stateValue),
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <Table
                onChange={(name: string, value: any) => setStateValue(value)}
                columns={columns}
                obj={{ name: "302-1-b", value: stateValue }}
                showSerial={false}
                showAddButton={true}
                showDeleteButton={true}
            />
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    let answerJSON = answer ? JSON.parse(answer.answer) : [];
    return (
        <>
            <div style={{ overflow: 'auto' }}>
                <table className="table text-nowrap table-bordered border-primary">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Fuel Type
                            </th>
                            <th>
                                UoM
                            </th>
                            <th className="text-end">
                                Total Consumption
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            answerJSON.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            answerJSON.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td className="">{item['302-1-b-i'] ? item['302-1-b-i'] : '-'}</td>
                                    <td className="">{item['302-1-b-ii'] ? item['302-1-b-ii'] : '-'}</td>
                                    <td className="text-end">{item['302-1-b-iii'] ? getFormattedFloat(item['302-1-b-iii']) : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}