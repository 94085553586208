// import { Preview as Form1012bPreview } from "./101-2-b"
// import { Preview as Form1015bPreview } from "./101-5-b"

interface IPropType {
    customFormId: any,
    answer: any,
}

function CustomFormButton(props: IPropType) {
    const { answer, customFormId } = props;
    const formComponent = require(`./${customFormId}`);
    return (
        <>
            <formComponent.Preview
                answer={answer}
            />
            {/* {
                customFormId === "101-2-b" && <Form1012bPreview answer={answer} />
            }
            {
                customFormId === "101-5-b" && <Form1015bPreview answer={answer} />
            } */}
        </>
    )
}

export default CustomFormButton;