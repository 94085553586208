import Chart from "react-apexcharts";
import constant from "../../../constant";
import Shimmer from "../../common/Shimmer";
import { getFormattedFloat, getFormattedNumber } from "../../../utils";


export function Chart1(props: any) {
    const series: any = [
        {
            name: 'Male',
            type: 'column',
            data: [65000, 55000, 60000, 75000, 70000, 80000]
        },
        {
            name: 'Female',
            type: 'column',
            data: [20000, 25000, 30000, 20000, 15000, 20000]
        },
    ];
    const xaxis = ['2018', '2019', '2020', '2021', '2022', '2023'];
    const yLeftTitle = "Numbers";
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)"];

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            stacked: true,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: yLeftTitle
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 0, 0, 1.5],
            curve: 'straight',
            dashArray: [0, 0, 0, 5],
        },
        plotOptions: {
            bar: {
                borderRadiusWhenStacked: "last",
                columnWidth: "15%",
                borderRadius: 5
            },
        },
        colors: colors
    };
    return (
        <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="300"
        />
    )
}

export function Chart2(props: any) {
    const series = [
        {
            type: 'line',
            name: 'Women New Hires',
            data: [
                {
                    x: '2018',
                    y: 20.2

                },
                {
                    x: '2019',
                    y: 18.2

                },
                {
                    x: '2020',
                    y: 13.2

                },
                {
                    x: '2021',
                    y: 15.3

                },
                {
                    x: '2022',
                    y: 16.1

                },
                {
                    x: '2023',
                    y: 18.2
                }
            ]
        },
    ]
    const options = {
        chart: {
            height: 310,
            animations: {
                speed: 500
            },
            toolbar: {
                show: false
            },
            dropShadow: {
                enabled: true,
                enabledOnSeries: undefined,
                top: 8,
                left: 0,
                blur: 3,
                color: '#000',
                opacity: 0.1
            },
        },
        colors: ["rgba(15, 115, 198, 1.0)"],
        dataLabels: {
            enabled: true
        },
        grid: {
            borderColor: '#f1f1f1',
            strokeDashArray: 3
        },
        stroke: {
            curve: 'smooth',
            width: [2],
            dashArray: [5],
        },
        yaxis: {
            labels: {
                formatter: function (value: any) {
                    return "" + value + '%';
                }
            },
        },
        markers: {
            hover: {
                sizeOffset: 5
            }
        }
    };
    return (
        <div>
            <Chart
                options={options}
                series={series}
                width="100%"
                height="300"
            />
        </div>
    )
}

export function Chart3() {
    const series = [70000, 30000];
    var options = {
        chart: {
            type: "donut",
        },
        legend: {
            position: "bottom",
        },
        labels: ["Local National", "Foreigners"],
        colors: [
            "rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)"
        ],
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
    };
    return <Chart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height="345"
    />
}

export function Chart4() {
    const series = [70000, 20000, 10000];
    var options = {
        chart: {
            type: "donut",
        },
        legend: {
            position: "bottom",
        },
        labels: ["Below 30", "30-50", "Above 50"],
        colors: [
            "rgba(15, 115, 198, 1.0)", 'rgba(20, 150, 241, 1.0)', "rgba(66, 182, 245, 1)",
        ],
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
    };
    return <Chart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height="345"
    />
}

export function Chart5(props: any) {
    const series: any = [
        {
            name: 'Male Salary',
            type: 'column',
            data: [100, 120, 130, 150, 160, 145]
        },
        {
            name: 'Female Salary',
            type: 'column',
            data: [70, 80, 90, 100, 120, 80]
        },
        {
            name: 'Salary Gap',
            type: 'line',
            data: [30, 40, 50, 50, 40, 65]
        }];

    const options = {
        chart: {
            height: 320,
            type: 'line',
            toolbar: {
                show: false
            }
        },
        stroke: {
            curve: 'smooth',
            width: [0, 0, 2],
            dashArray: [0, 0, 5],
        },
        grid: {
            borderColor: '#f2f5f7',
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [2]
        },
        colors: ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)", "rgba(230, 83, 60, 0.8)"],

        labels: ['2018', '2019', '2020', '2021', '2022', '2023'],
        yaxis: [{
            title: {
                text: 'Salary (In Million)',
            }
        },
        {
            opposite: true,
            title: {
                text: 'Salary Gap (In Million)',
            }
        }],
        plotOptions: {
            bar: {
                columnWidth: "25%",
                borderRadiusApplication: "end",
                borderRadius: 5,   
            },
        },
    };

    return (
        <div>
            <Chart
                options={options}
                series={series}
                width="100%"
                height="285"
            />
        </div>
    )
}

// export function StackChart(props: any) {
//     const series =
//         [{
//             name: 'Designing',
//             data: [44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43]
//         }, {
//             name: 'Development',
//             data: [13, 23, 20, 8, 13, 27, 13, 23, 20, 8, 13, 27]
//         }, {
//             name: 'SEO',
//             data: [11, 17, 15, 15, 21, 14, 11, 17, 15, 15, 21, 14]
//         }]
//     const options = {
//         chart: {
//             stacked: true,
//             toolbar: {
//                 show: true
//             },
//             zoom: {
//                 enabled: true
//             }
//         },
//         grid: {
//             borderColor: '#f1f1f1',
//             strokeDashArray: 3
//         },
//         responsive: [{
//             breakpoint: 480,
//             options: {
//                 legend: {
//                     position: 'bottom',
//                     offsetX: -10,
//                     offsetY: 0
//                 }
//             }
//         }],
//         colors: ["rgba(132, 90, 223, 1)", "rgba(132, 90, 223, 0.5)", "rgba(132, 90, 223, 0.3)"],
//         legend: {
//             show: true,
//             position: 'bottom'
//         },
//         plotOptions: {
//             bar: {
//                 columnWidth: "20%",
//                 borderRadius: 1
//             }
//         },
//         dataLabels: {
//             enabled: false
//         },
//         xaxis: {
//             categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
//         },
//         fill: {
//             opacity: 1
//         }
//     };
//     return (
//         <div>
//             <Chart
//                 options={options}
//                 series={series}
//                 type="bar"
//                 width="100%"
//                 height="310"
//             />
//         </div>
//     )
// }

// export function StackChartWithLine(props: any) {
//     const series = [{
//         name: 'Projects',
//         type: 'column',
//         data: [1.8, 2.5, 2.5, 1.5, 2.5, 2.8, 3.8]
//     }, {
//         name: 'Tasks',
//         type: 'column',
//         data: [1.1, 2.2, 3.1, 4, 4.1, 4.9, 6.5]
//     },
//     {
//         name: 'SEO',
//         type: 'column',
//         data: [1.1, 2.2, 3.1, 4, 4.1, 4.9, 6.5]
//     },
//     {
//         name: 'Revenue',
//         type: 'line',
//         data: [2, 6, 1.5, 12, 8, 10, 5],
//     },
//     ]
//     const options = {
//         chart: {
//             toolbar: {
//                 show: false
//             },
//             height: 300,
//             type: 'line',
//             stacked: true,
//         },
//         grid: {
//             borderColor: '#f5f4f4',
//             strokeDashArray: 3
//         },
//         dataLabels: {
//             enabled: false
//         },
//         title: {
//             text: undefined,
//         },
//         xaxis: {
//             categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
//         },
//         tooltip: {
//             enabled: true,
//         },
//         legend: {
//             show: true,
//             position: 'bottom',
//             offsetX: 40,
//             fontSize: '13px',
//             fontWeight: 'normal',
//             labels: {
//                 colors: '#acb1b1',
//             },
//             markers: {
//                 width: 10,
//                 height: 10,
//             },
//         },
//         stroke: {
//             width: [0, 0, 0, 1.5],
//             curve: 'straight',
//             dashArray: [0, 0, 0, 5],
//         },
//         plotOptions: {
//             bar: {
//                 columnWidth: "10%",
//                 borderRadius: 1
//             }
//         },
//         colors: ["rgb(132, 90, 223)", "rgba(132, 90, 223, 0.5)", "#ededed", "#23b7e5"]
//     };
//     return (
//         <div>
//             <Chart
//                 options={options}
//                 series={series}
//                 // type="bar"
//                 width="100%"
//                 height="310"
//             />
//         </div>
//     )
// }

// export function BarChart(props: any) {
//     const series =
//         [{
//             name: 'Designing',
//             data: [44, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43]
//         }]
//     const options = {
//         chart: {
//             stacked: false,
//             toolbar: {
//                 show: true
//             },
//             zoom: {
//                 enabled: true
//             }
//         },
//         grid: {
//             borderColor: '#f1f1f1',
//             strokeDashArray: 3
//         },
//         responsive: [{
//             breakpoint: 480,
//             options: {
//                 legend: {
//                     position: 'bottom',
//                     offsetX: -10,
//                     offsetY: 0
//                 }
//             }
//         }],
//         colors: ["rgba(132, 90, 223, 1)"],
//         legend: {
//             show: true,
//             position: 'bottom'
//         },
//         plotOptions: {
//             bar: {
//                 columnWidth: "25%",
//                 borderRadius: 1
//             }
//         },
//         dataLabels: {
//             enabled: false
//         },
//         xaxis: {
//             categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
//         },
//         fill: {
//             opacity: 1
//         }
//     };
//     return (
//         <div>
//             <Chart
//                 options={options}
//                 series={series}
//                 type="bar"
//                 width="100%"
//                 height="310"
//             />
//         </div>
//     )
// }

// export function LineChart(props: any) {
//     const series = [
//         {
//             type: 'line',
//             name: 'Profit',
//             data: [
//                 {
//                     x: 'Jan',
//                     y: 100
//                 },
//                 {
//                     x: 'Feb',
//                     y: 210
//                 },
//                 {
//                     x: 'Mar',
//                     y: 180
//                 },
//                 {
//                     x: 'Apr',
//                     y: 454
//                 },
//                 {
//                     x: 'May',
//                     y: 230
//                 },
//                 {
//                     x: 'Jun',
//                     y: 320
//                 },
//                 {
//                     x: 'Jul',
//                     y: 656
//                 },
//                 {
//                     x: 'Aug',
//                     y: 830
//                 },
//                 {
//                     x: 'Sep',
//                     y: 350
//                 },
//                 {
//                     x: 'Oct',
//                     y: 350
//                 },
//                 {
//                     x: 'Nov',
//                     y: 210
//                 },
//                 {
//                     x: 'Dec',
//                     y: 410
//                 }
//             ]
//         },
//         {
//             type: 'line',
//             name: 'Revenue',
//             chart: {
//                 dropShadow: {
//                     enabled: true,
//                     enabledOnSeries: undefined,
//                     top: 5,
//                     left: 0,
//                     blur: 3,
//                     color: '#000',
//                     opacity: 0.1
//                 }
//             },
//             data: [
//                 {
//                     x: 'Jan',
//                     y: 180
//                 },
//                 {
//                     x: 'Feb',
//                     y: 620
//                 },
//                 {
//                     x: 'Mar',
//                     y: 476
//                 },
//                 {
//                     x: 'Apr',
//                     y: 220
//                 },
//                 {
//                     x: 'May',
//                     y: 520
//                 },
//                 {
//                     x: 'Jun',
//                     y: 780
//                 },
//                 {
//                     x: 'Jul',
//                     y: 435
//                 },
//                 {
//                     x: 'Aug',
//                     y: 515
//                 },
//                 {
//                     x: 'Sep',
//                     y: 738
//                 },
//                 {
//                     x: 'Oct',
//                     y: 454
//                 },
//                 {
//                     x: 'Nov',
//                     y: 525
//                 },
//                 {
//                     x: 'Dec',
//                     y: 230
//                 }
//             ]
//         }
//     ]
//     const options = {
//         chart: {
//             height: 350,
//             animations: {
//                 speed: 500
//             },
//             toolbar: {
//                 show: false
//             },
//             zoom: {
//                 enabled: true
//             },
//             dropShadow: {
//                 enabled: true,
//                 enabledOnSeries: undefined,
//                 top: 8,
//                 left: 0,
//                 blur: 3,
//                 color: '#000',
//                 opacity: 0.1
//             },
//         },
//         colors: ["rgb(132, 90, 223)", "rgba(35, 183, 229, 0.85)"],
//         dataLabels: {
//             enabled: false
//         },
//         grid: {
//             borderColor: '#f1f1f1',
//             strokeDashArray: 3
//         },
//         stroke: {
//             curve: 'smooth',
//             width: [2, 2],
//             dashArray: [0, 5],
//         },
//         xaxis: {
//             axisTicks: {
//                 show: false,
//             },
//         },
//         yaxis: {
//             labels: {
//                 formatter: function (value: any) {
//                     return "$" + value;
//                 }
//             },
//         },
//         tooltip: {
//             y: [{
//                 formatter: function (e: any) {
//                     return void 0 !== e ? "$" + e.toFixed(0) : e
//                 }
//             }, {
//                 formatter: function (e: any) {
//                     return void 0 !== e ? "$" + e.toFixed(0) : e
//                 }
//             }, {
//                 formatter: function (e: any) {
//                     return void 0 !== e ? e.toFixed(0) : e
//                 }
//             }]
//         },
//         legend: {
//             show: true
//         },
//         markers: {
//             hover: {
//                 sizeOffset: 5
//             }
//         }
//     };
//     return (
//         <div>
//             <Chart
//                 options={options}
//                 series={series}
//                 // type="bar"
//                 width="100%"
//                 height="310"
//             />
//         </div>
//     )
// }

// export function AreaChart(props: any) {
//     const series = [
//         {
//             type: 'area',
//             data: [
//                 {
//                     x: 'Jan',
//                     y: 200
//                 },
//                 {
//                     x: 'Feb',
//                     y: 530
//                 },
//                 {
//                     x: 'Mar',
//                     y: 110
//                 },
//                 {
//                     x: 'Apr',
//                     y: 130
//                 },
//                 {
//                     x: 'May',
//                     y: 480
//                 },
//                 {
//                     x: 'Jun',
//                     y: 520
//                 },
//                 {
//                     x: 'Jul',
//                     y: 780
//                 },
//                 {
//                     x: 'Aug',
//                     y: 435
//                 },
//                 {
//                     x: 'Sep',
//                     y: 475
//                 },
//                 {
//                     x: 'Oct',
//                     y: 738
//                 },
//                 {
//                     x: 'Nov',
//                     y: 454
//                 },
//                 {
//                     x: 'Dec',
//                     y: 480
//                 }
//             ]
//         }
//     ];
//     const options = {
//         chart: {
//             height: 350,
//             toolbar: {
//                 show: false
//             },
//             animations: {
//                 speed: 500
//             }
//         },
//         colors: ["rgb(132, 90, 223)"],
//         dataLabels: {
//             enabled: false
//         },
//         grid: {
//             borderColor: '#f1f1f1',
//             strokeDashArray: 3
//         },
//         stroke: {
//             width: [1.2],
//             curve: ['smooth']
//         },
//         xaxis: {
//             axisTicks: {
//                 show: true,
//             },
//         },
//         yaxis: {
//             labels: {
//                 formatter: function (value: any) {
//                     return "$" + value;
//                 }
//             },
//         },
//         tooltip: {
//             enabled: true,
//         },
//         legend: {
//             show: true,
//         },
//         markers: {
//             hover: {
//                 sizeOffset: 5
//             }
//         },
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shadeIntensity: 0.1,
//                 opacityFrom: 0.5,
//                 opacityTo: 0.6,
//                 stops: [0, 100]
//             }
//         }

//     };
//     return (
//         <div>
//             <Chart
//                 options={options}
//                 series={series}
//                 width="100%"
//                 height="310"
//             />
//         </div>
//     )
// }

// export function TreeMapChart() {
//     const series = [
//         {
//             data: [
//                 {
//                     x: 'New Delhi',
//                     y: 218
//                 },
//                 {
//                     x: 'Kolkata',
//                     y: 149
//                 },
//                 {
//                     x: 'Mumbai',
//                     y: 184
//                 },
//                 {
//                     x: 'Ahmedabad',
//                     y: 55
//                 },
//                 {
//                     x: 'Bangaluru',
//                     y: 84
//                 },
//                 {
//                     x: 'Pune',
//                     y: 31
//                 },
//                 {
//                     x: 'Chennai',
//                     y: 70
//                 },
//                 {
//                     x: 'Jaipur',
//                     y: 30
//                 },
//                 {
//                     x: 'Surat',
//                     y: 44
//                 },
//                 {
//                     x: 'Hyderabad',
//                     y: 68
//                 },
//                 {
//                     x: 'Lucknow',
//                     y: 28
//                 },
//                 {
//                     x: 'Indore',
//                     y: 19
//                 },
//                 {
//                     x: 'Kanpur',
//                     y: 29
//                 }
//             ]
//         }
//     ]
//     const options = {
//         legend: {
//             show: false
//         },
//         chart: {
//             height: 350,
//             type: 'treemap',
//             toolbar: {
//                 show: false
//             },
//         },
//         colors: ["#845adf"],
//     };
//     return (
//         <div>
//             <Chart
//                 options={options}
//                 series={series}
//                 type='treemap'
//                 width="100%"
//                 height="310"
//             />
//         </div>
//     )
// }