import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { GridComponent, LegendComponent, TooltipComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import echartConstant from '../../echart-constant';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, PieChart, CanvasRenderer]);


export default function CustomPieChart(props: { data: any, total: any, showLegend: boolean, unit?: string }) {

    const { data, total, showLegend } = props;
    // data attributes: value and name
    const option2: any = {
        legend: {
            show: showLegend,
            top: 'middle',
            left: 'right',
            orient: 'vertical',
        },
        
        // height: '250',
        // color: [echartConstant.utils.getColors().primary, echartConstant.utils.getColors().info, echartConstant.utils.getGrays()[300]],
        tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: echartConstant.utils.getGrays()['100'],
            borderColor: echartConstant.utils.getGrays()['300'],
            textStyle: {
                color: echartConstant.utils.getColors().dark
            },
            borderWidth: 1,
            transitionDuration: 0,
            formatter: function formatter(params: any) {
                return "<strong>".concat(params.data.name, ":</strong> ").concat(params.percent, "%");
            }
        },
        position: function position(pos: any, params: any, dom: any, rect: any, size: any) {
            return echartConstant.utils.getPosition(pos, params, dom, rect, size);
        },
        series: [{
            type: 'pie',
            radius: ['100%', '80%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            itemStyle: {
                borderWidth: 2,
                borderColor: echartConstant.utils.getColor('gray-100')
            },
            label: {
                normal: {
                    show: false,
                    position: 'center',
                    textStyle: {
                        fontSize: '20',
                        fontWeight: '500',
                        color: echartConstant.utils.getGrays()['100']
                    }
                },
                emphasis: {
                    show: false
                }
            },
            labelLine: {
                normal: {
                    show: false
                }
            },
            data: data
        }]
    }
    // const option = {
    //     legend: {
    //         show: showLegend,
    //         top: 'middle',
    //         left: 'right',
    //         orient: 'vertical',
    //     },
    //     // color: [
    //     //   echartConstant.utils.getColors().primary,
    //     //   echartConstant.utils.rgbaColor(echartConstant.utils.getColors().primary, 0.3),
    //     //   echartConstant.utils.getColors().warning, 
    //     //   echartConstant.utils.getColors().info, 
    //     //   echartConstant.utils.rgbaColor(echartConstant.utils.getColors().primary, 0.6)
    //     // ],
    //     tooltip: {
    //         trigger: 'item',
    //         padding: [7, 10],
    //         backgroundColor: echartConstant.utils.getGrays()['100'],
    //         borderColor: echartConstant.utils.getGrays()['300'],
    //         textStyle: {
    //             color: echartConstant.utils.getColors().dark
    //         },
    //         borderWidth: 1,
    //         transitionDuration: 0,
    //         formatter: function formatter(params: any) {
    //             return params.percent
    //                 ? "<strong>".concat(params.data.name, ":</strong> ").concat(params.percent, "%")
    //                 : ""
    //         }
    //     },
    //     position: function position(pos: any, params: any, dom: any, rect: any, size: any) {
    //         return echartConstant.getPosition(pos, params, dom, rect, size);
    //     },
    //     series: [
    //         {
    //             type: 'pie',
    //             radius: ['40%', '70%'],
    //             avoidLabelOverlap: false,
    //             hoverAnimation: false,
    //             itemStyle: {
    //                 borderWidth: 2,
    //                 borderColor: echartConstant.utils.getColor('gray-100')
    //             },
    //             label: {
    //                 normal: {
    //                     show: false,
    //                     position: 'center',
    //                     textStyle: {
    //                         fontSize: '20',
    //                         fontWeight: '500',
    //                         color: echartConstant.utils.getGrays()['700']
    //                     }
    //                 },
    //                 emphasis: {
    //                     show: false
    //                 }
    //             },
    //             labelLine: {
    //                 normal: {
    //                     show: false
    //                 }
    //             },
    //             data: data,
    //         }],
    // }

    if (showLegend) {
        option2.series.forEach((element: any) => {
            element.center = ['30%', '50%'];
        });
    }

    return <div className="position-relative">
        <ReactEChartsCore
            echarts={echarts}
            option={option2}
            lazyUpdate={true}
            theme={'theme_name'}
            opts={{}}
        />

        {/* <div className={`position-absolute top-50 translate-middle text-1100 fs-7 ${showLegend ? 'start-30 ' : 'start-50 '}`} > */}
        <div className={`position-absolute top-50 translate-middle text-center ${showLegend ? 'start-30 ' : 'start-50 '}`} >
            <p className="fs-2 mb-0 font-sans-serif fw-medium mt-n2" style={{ top: '42%' }} >
                {total}
            </p>
            <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium" >
                {props.unit ? props.unit : "kgCO2e"}
            </p>
        </div>
    </div>
}