import { getDateRange } from "../utils";

export const category1SupplierList = [
    { id: 1, name: 'Oil Company' },
]

export const category2SupplierList = [
    { id: 1, name: 'Oil Company' },
]

export const locationList = [
    { id: 1, name: 'Golden Ridge Oil Field' },
    { id: 2, name: 'Silver Creek Oil Field' },
    { id: 3, name: 'Emerald Valley Oil Field' },
]

export const getStationaryEmission = () => {
    const activityStartDate = "2023-06-01";
    const activityEndDate = "2024-06-10";
    const emissionSourceList = [{ name: 'DEFRA', year: '2023' }]

    const data = [
        {
            "dated": "Jun'23",
            "locationName": "Golden Ridge Oil Field",
            "equipmentTypeName": "XLNT 13000E  650KW  Generator",
            "emissionSourceName": "Power plants",
            "fuelTypeName": "Petrol",
            "activityData": 1100,
            "uom": "l",
            "kgCO2": 2888.6,
            "gCH4": 0.32032,
            "gN2O": 36.38859,
            "kgCO2e": 2585,
            "emissionFactor": 2.35
        },
        {
            "dated": "Jul'23",
            "locationName": "Silver Creek Oil Field",
            "equipmentTypeName": "GENSET MODEL LP 320 (320 kVA)",
            "emissionSourceName": "Industrial boilers",
            "fuelTypeName": "Diesel",
            "activityData": 100,
            "uom": "l",
            "kgCO2": 262.6,
            "gCH4": 0.02912,
            "gN2O": 3.30805,
            "kgCO2e": 265.94,
            "emissionFactor": 2.66
        },
        {
            "dated": "Aug'23",
            "locationName": "Emerald Valley Oil Field",
            "equipmentTypeName": "XLNT 13000E  650KW  Generator",
            "emissionSourceName": "Energy used in Facility",
            "fuelTypeName": "Petrol",
            "activityData": 1200,
            "uom": "l",
            "kgCO2": 3151.2,
            "gCH4": 0.34944,
            "gN2O": 39.69664,
            "kgCO2e": 2820,
            "emissionFactor": 2.35
        },
        {
            "dated": "Aug'23",
            "locationName": "Golden Ridge Oil Field",
            "equipmentTypeName": "Caterpillar 3516B",
            "emissionSourceName": "Power plants",
            "fuelTypeName": "Diesel",
            "activityData": 210,
            "uom": "l",
            "kgCO2": 551.46,
            "gCH4": 0.06115,
            "gN2O": 6.94691,
            "kgCO2e": 558.47,
            "emissionFactor": 2.66
        },
        {
            "dated": "Aug'23",
            "locationName": "Silver Creek Oil Field",
            "equipmentTypeName": "GENSET MODEL LP 320 (320 kVA)",
            "emissionSourceName": "Industrial boilers",
            "fuelTypeName": "Diesel",
            "activityData": 110,
            "uom": "l",
            "kgCO2": 288.86,
            "gCH4": 0.03203,
            "gN2O": 3.63886,
            "kgCO2e": 292.53,
            "emissionFactor": 2.66
        },
        {
            "dated": "Sep'23",
            "locationName": "Emerald Valley Oil Field",
            "equipmentTypeName": "Caterpillar 3516B",
            "emissionSourceName": "Power plants",
            "fuelTypeName": "Diesel",
            "activityData": 200,
            "uom": "l",
            "kgCO2": 525.2,
            "gCH4": 0.05824,
            "gN2O": 6.61611,
            "kgCO2e": 531.87,
            "emissionFactor": 2.66
        },
        {
            "dated": "Sep'23",
            "locationName": "Golden Ridge Oil Field",
            "equipmentTypeName": "GENSET MODEL LP 320 (320 kVA)",
            "emissionSourceName": "Industrial boilers",
            "fuelTypeName": "Diesel",
            "activityData": 150,
            "uom": "l",
            "kgCO2": 393.9,
            "gCH4": 0.04368,
            "gN2O": 4.96208,
            "kgCO2e": 398.91,
            "emissionFactor": 2.66
        },
        {
            "dated": "Sep'23",
            "locationName": "Silver Creek Oil Field",
            "equipmentTypeName": "GENSET MODEL LP 320 (320 kVA)",
            "emissionSourceName": "Industrial boilers",
            "fuelTypeName": "Diesel",
            "activityData": 210,
            "uom": "l",
            "kgCO2": 551.46,
            "gCH4": 0.06115,
            "gN2O": 6.94691,
            "kgCO2e": 558.47,
            "emissionFactor": 2.66
        },
        {
            "dated": "Oct'23",
            "locationName": "Emerald Valley Oil Field",
            "equipmentTypeName": "XLNT 13000E  650KW  Generator",
            "emissionSourceName": "Power plants",
            "fuelTypeName": "Petrol",
            "activityData": 1500,
            "uom": "l",
            "kgCO2": 3939,
            "gCH4": 0.4368,
            "gN2O": 49.62081,
            "kgCO2e": 3525,
            "emissionFactor": 2.35
        },
        {
            "dated": "Oct'23",
            "locationName": "Golden Ridge Oil Field",
            "equipmentTypeName": "Caterpillar 3516B",
            "emissionSourceName": "Power plants",
            "fuelTypeName": "Diesel",
            "activityData": 200,
            "uom": "l",
            "kgCO2": 525.2,
            "gCH4": 0.05824,
            "gN2O": 6.61611,
            "kgCO2e": 531.87,
            "emissionFactor": 2.66
        },
        {
            "dated": "Oct'23",
            "locationName": "Silver Creek Oil Field",
            "equipmentTypeName": "GENSET MODEL LP 320 (320 kVA)",
            "emissionSourceName": "Industrial boilers",
            "fuelTypeName": "Diesel",
            "activityData": 210,
            "uom": "l",
            "kgCO2": 551.46,
            "gCH4": 0.06115,
            "gN2O": 6.94691,
            "kgCO2e": 558.47,
            "emissionFactor": 2.66
        },
        {
            "dated": "Nov'23",
            "locationName": "Emerald Valley Oil Field",
            "equipmentTypeName": "XLNT 13000E  650KW  Generator",
            "emissionSourceName": "Industrial boilers",
            "fuelTypeName": "Petrol",
            "activityData": 1200,
            "uom": "l",
            "kgCO2": 3151.2,
            "gCH4": 0.34944,
            "gN2O": 39.69664,
            "kgCO2e": 2820,
            "emissionFactor": 2.35
        },
        {
            "dated": "Nov'23",
            "locationName": "Golden Ridge Oil Field",
            "equipmentTypeName": "Caterpillar 3516B",
            "emissionSourceName": "Industrial boilers",
            "fuelTypeName": "Diesel",
            "activityData": 300,
            "uom": "l",
            "kgCO2": 787.8,
            "gCH4": 0.08736,
            "gN2O": 9.92416,
            "kgCO2e": 797.81,
            "emissionFactor": 2.66
        },
        {
            "dated": "Oct'23",
            "locationName": "Silver Creek Oil Field",
            "equipmentTypeName": "GENSET MODEL LP 320 (320 kVA)",
            "emissionSourceName": "Surface Treatment",
            "fuelTypeName": "Diesel",
            "activityData": 550,
            "uom": "l",
            "kgCO2": 1444.3,
            "gCH4": 0.16016,
            "gN2O": 18.1943,
            "kgCO2e": 1462.65,
            "emissionFactor": 2.66
        },
        {
            "dated": "Nov'23",
            "locationName": "Emerald Valley Oil Field",
            "equipmentTypeName": "Portable Petrol Generator",
            "emissionSourceName": "Welding and Metalworking",
            "fuelTypeName": "Petrol",
            "activityData": 150,
            "uom": "l",
            "kgCO2": 393.9,
            "gCH4": 0.04368,
            "gN2O": 4.96208,
            "kgCO2e": 352.5,
            "emissionFactor": 2.35
        },
        {
            "dated": "Dec'23",
            "locationName": "Golden Ridge Oil Field",
            "equipmentTypeName": "XLNT 13000E 10KW Industrial Use Petrol Generator",
            "emissionSourceName": "Testing and Quality Control",
            "fuelTypeName": "Petrol",
            "activityData": 250,
            "uom": "l",
            "kgCO2": 656.5,
            "gCH4": 0.0728,
            "gN2O": 8.27013,
            "kgCO2e": 587.5,
            "emissionFactor": 2.35
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "equipmentTypeName": "Process Heater",
            "emissionSourceName": "Power plants",
            "fuelTypeName": "Diesel",
            "activityData": 350,
            "uom": "l",
            "kgCO2": 919.1,
            "gCH4": 0.10192,
            "gN2O": 11.57819,
            "kgCO2e": 930.78,
            "emissionFactor": 2.66
        },
        {
            "dated": "Feb'24",
            "locationName": "Emerald Valley Oil Field",
            "equipmentTypeName": "Combined Heat and Power (CHP) Systems",
            "emissionSourceName": "CHP System",
            "fuelTypeName": "Diesel",
            "activityData": 380,
            "uom": "l",
            "kgCO2": 997.88,
            "gCH4": 0.11066,
            "gN2O": 12.5706,
            "kgCO2e": 1010.56,
            "emissionFactor": 2.66
        },
        {
            "dated": "Mar'24",
            "locationName": "Golden Ridge Oil Field",
            "equipmentTypeName": "Gas Generator",
            "emissionSourceName": "Power plants",
            "fuelTypeName": "CNG",
            "activityData": 250,
            "uom": "kg",
            "kgCO2": 639.383,
            "gCH4": 0.9625,
            "gN2O": 0.2975,
            "kgCO2e": 640.6425,
            "emissionFactor": 2.5
        }
    ]
    const dates = getDateRange(activityStartDate, activityEndDate);
    const assignDetail = (i: any, index: number) => {
        const emissionFactor = emissionSourceList[Math.floor(Math.random() * emissionSourceList.length)];

        i.locationName = locationList[Math.floor(Math.random() * locationList.length)].name;
        i.dated = dates[Math.floor(Math.random() * dates.length)];
        i.id = index + 1;
        i.emissionSource = emissionFactor.name;
        i.emissionSourceYear = emissionFactor.year;
        return i;
    }

    const _pending = data.map((i: any, index: number) => assignDetail(i, index))
    const _approved = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 2))
    const _rejected = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 4))

    return { _pending, _approved, _rejected }
}

export const getMobileEmission = () => {
    const activityStartDate = "2023-06-01";
    const activityEndDate = "2024-06-10";
    const emissionSourceList = [{ name: 'DEFRA', year: '2023' }, { name: 'USA Fuel Economy', year: '' }, { name: 'NAICS', year: '2021' }]

    const data = [
        {
            "dated": "Nov'23",
            "locationName": "Golden Ridge Oil Field",
            "approach": "Fuel Based",
            "vehicleTypeName": "Car",
            "fuelTypeName": "Diesel",
            "emissionSourceName": "Mahindra XUV500",
            "activityData": 200,
            "uom": "l",
            "emissionFactor": 2.659371737,
            "emission": 531.8743474
        },
        {
            "dated": "Nov'23",
            "locationName": "Silver Creek Oil Field",
            "approach": "Specific Vehicle Based",
            "vehicleTypeName": "Car",
            "fuelTypeName": "Gasoline",
            "emissionSourceName": "Heavy-duty trucks",
            "activityData": 150,
            "uom": "l",
            "emissionFactor": 0.165,
            "emission": 24.75
        },
        {
            "dated": "Nov'23",
            "locationName": "Emerald Valley Oil Field",
            "approach": "Fuel Based",
            "vehicleTypeName": "Car",
            "fuelTypeName": "Diesel",
            "emissionSourceName": "Tanker trucks",
            "activityData": 125,
            "uom": "l",
            "emissionFactor": 2.659371737,
            "emission": 332.4214671
        },
        {
            "dated": "Dec'23",
            "locationName": "Golden Ridge Oil Field",
            "approach": "Distance Based",
            "vehicleTypeName": "Bike",
            "fuelTypeName": "Petrol",
            "emissionSourceName": "Hero Splender",
            "activityData": 50,
            "uom": "km",
            "emissionFactor": 0.101078357,
            "emission": 5.053917852
        },
        {
            "dated": "Dec'23",
            "locationName": "Silver Creek Oil Field",
            "approach": "Specific Vehicle Based",
            "vehicleTypeName": "Bike",
            "fuelTypeName": "Electricity",
            "emissionSourceName": "Heavy-duty trucks",
            "activityData": 180,
            "uom": "kWh",
            "emissionFactor": 0,
            "emission": 0
        },
        {
            "dated": "Dec'23",
            "locationName": "Emerald Valley Oil Field",
            "approach": "Fuel Based",
            "vehicleTypeName": "Bus",
            "fuelTypeName": "Diesel",
            "emissionSourceName": "Tanker trucks",
            "activityData": 160,
            "uom": "l",
            "emissionFactor": 2.659371737,
            "emission": 425.4994779
        },
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "approach": "Distance Based",
            "vehicleTypeName": "Bike",
            "fuelTypeName": "Petrol",
            "emissionSourceName": "Hero Splender",
            "activityData": 50,
            "uom": "km",
            "emissionFactor": 0.101078357,
            "emission": 5.053917852
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "approach": "Specific Vehicle Based",
            "vehicleTypeName": "Car",
            "fuelTypeName": "Electricity",
            "emissionSourceName": "Heavy-duty trucks",
            "activityData": 200,
            "uom": "kWh",
            "emissionFactor": 0,
            "emission": 0
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "approach": "Distance Based",
            "vehicleTypeName": "Bike",
            "fuelTypeName": "Petrol",
            "emissionSourceName": "Tanker trucks",
            "activityData": 50,
            "uom": "km",
            "emissionFactor": 0.101078357,
            "emission": 5.053917852
        },
        {
            "dated": "Feb'24",
            "locationName": "Golden Ridge Oil Field",
            "approach": "Specific Vehicle Based",
            "vehicleTypeName": "Car",
            "fuelTypeName": "Gasoline",
            "emissionSourceName": "Pickup trucks",
            "activityData": 170,
            "uom": "l",
            "emissionFactor": 0.165,
            "emission": 28.05
        },
        {
            "dated": "Feb'24",
            "locationName": "Silver Creek Oil Field",
            "approach": "Distance Based",
            "vehicleTypeName": "Car",
            "fuelTypeName": "Petrol",
            "emissionSourceName": "Heavy-duty trucks",
            "activityData": 100,
            "uom": "km",
            "emissionFactor": 0.178188534,
            "emission": 17.81885342
        },
        {
            "dated": "Mar'24",
            "locationName": "Emerald Valley Oil Field",
            "approach": "Spend Based",
            "vehicleTypeName": "Car",
            "fuelTypeName": "CNG",
            "emissionSourceName": "Tanker trucks",
            "activityData": 5000,
            "uom": "INR",
            "emissionFactor": 0.499,
            "emission": 2495
        }
    ]
    const dates = getDateRange(activityStartDate, activityEndDate);
    const assignDetail = (i: any, index: number) => {
        const emissionFactor = emissionSourceList[Math.floor(Math.random() * emissionSourceList.length)];

        i.locationName = locationList[Math.floor(Math.random() * locationList.length)].name;
        i.dated = dates[Math.floor(Math.random() * dates.length)];
        i.id = index + 1;
        i.emissionSource = emissionFactor.name;
        i.emissionSourceYear = emissionFactor.year;
        return i;
    }

    const _pending = data.map((i: any, index: number) => assignDetail(i, index))
    const _approved = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 2))
    const _rejected = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 4))

    return { _pending, _approved, _rejected }
}

export const getFugutiveEmission = () => {
    const activityStartDate = "2023-06-01";
    const activityEndDate = "2024-06-10";
    const emissionSourceList = [{ name: 'IPCC AR5 GWP 100', year: '' }]

    const data = [
        {
            "dated": "Dec'23",
            "locationName": "Golden Ridge Oil Field",
            "EquipmentTypeName": "Machines",
            "fuelTypeName": "HFC-41",
            "emissionSourceName": "Separators",
            "activityData": 100,
            "uom": "kg",
            "emissionFactor": 116,
            "emission": 11600
        },
        {
            "dated": "Dec'23",
            "locationName": "Silver Creek Oil Field",
            "EquipmentTypeName": "Pipeline",
            "fuelTypeName": "CH4",
            "emissionSourceName": "Maintenance and operational venting",
            "activityData": 400,
            "uom": "kg",
            "emissionFactor": 677,
            "emission": 270800
        },
        {
            "dated": "Dec'23",
            "locationName": "Emerald Valley Oil Field",
            "EquipmentTypeName": "Machines",
            "fuelTypeName": "HFC-41",
            "emissionSourceName": "Separators",
            "activityData": 140,
            "uom": "kg",
            "emissionFactor": 116,
            "emission": 16240
        },
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "EquipmentTypeName": "Pipeline",
            "fuelTypeName": "R32",
            "emissionSourceName": "Maintenance and operational venting",
            "activityData": 420,
            "uom": "kg",
            "emissionFactor": 677,
            "emission": 284340
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "EquipmentTypeName": "Pipeline",
            "fuelTypeName": "R32",
            "emissionSourceName": "Separators",
            "activityData": 200,
            "uom": "kg",
            "emissionFactor": 677,
            "emission": 135400
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "EquipmentTypeName": "Machines",
            "fuelTypeName": "HFC-41",
            "emissionSourceName": "Pressure relief devices",
            "activityData": 150,
            "uom": "kg",
            "emissionFactor": 116,
            "emission": 17400
        },
        {
            "dated": "Feb'24",
            "locationName": "Golden Ridge Oil Field",
            "EquipmentTypeName": "Pipeline",
            "fuelTypeName": "CH4",
            "emissionSourceName": "Separators",
            "activityData": 100,
            "uom": "kg",
            "emissionFactor": 28,
            "emission": 2800
        },
        {
            "dated": "Feb'24",
            "locationName": "Silver Creek Oil Field",
            "EquipmentTypeName": "Machines",
            "fuelTypeName": "HFC-41",
            "emissionSourceName": "Pressure relief devices",
            "activityData": 450,
            "uom": "kg",
            "emissionFactor": 116,
            "emission": 52200
        },
        {
            "dated": "Feb'24",
            "locationName": "Emerald Valley Oil Field",
            "EquipmentTypeName": "Pipeline",
            "fuelTypeName": "CH4",
            "emissionSourceName": "Separators",
            "activityData": 100,
            "uom": "kg",
            "emissionFactor": 28,
            "emission": 2800
        },
        {
            "dated": "Mar'24",
            "locationName": "Emerald Valley Oil Field",
            "EquipmentTypeName": "Pipeline",
            "fuelTypeName": "R32",
            "emissionSourceName": "Loose connections and fittings",
            "activityData": 500,
            "uom": "kg",
            "emissionFactor": 677,
            "emission": 338500
        },
        {
            "dated": "Mar'24",
            "locationName": "Emerald Valley Oil Field",
            "EquipmentTypeName": "Incinerators",
            "fuelTypeName": "N2O",
            "emissionSourceName": "Waste Material Burnt",
            "activityData": 100,
            "uom": "kg",
            "emissionFactor": 265,
            "emission": 26500
        }
    ]
    const dates = getDateRange(activityStartDate, activityEndDate);
    const assignDetail = (i: any, index: number) => {
        const emissionFactor = emissionSourceList[Math.floor(Math.random() * emissionSourceList.length)];

        i.locationName = locationList[Math.floor(Math.random() * locationList.length)].name;
        i.dated = dates[Math.floor(Math.random() * dates.length)];
        i.id = index + 1;
        i.emissionSource = emissionFactor.name;
        i.emissionSourceYear = emissionFactor.year;
        return i;
    }

    const _pending = data.map((i: any, index: number) => assignDetail(i, index))
    const _approved = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 2))
    const _rejected = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 4))

    return { _pending, _approved, _rejected }
}

export const getPurchasedElectricityEmission = () => {
    const activityStartDate = "2023-06-01";
    const activityEndDate = "2024-06-10";
    const emissionSourceList = [{ name: 'CEA', year: '2022-23' }]

    const data = [
        {
            "dated": "Dec'23",
            "locationName": "Golden Ridge Oil Field",
            "activityData": 10000,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 7160
        },
        {
            "dated": "Dec'23",
            "locationName": "Silver Creek Oil Field",
            "activityData": 20000,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 14320
        },
        {
            "dated": "Dec'23",
            "locationName": "Emerald Valley Oil Field",
            "activityData": 1500,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 1074
        },
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "activityData": 20000,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 14320
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "activityData": 2500,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 1790
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "activityData": 45000,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 32220
        },
        {
            "dated": "Feb'24",
            "locationName": "Golden Ridge Oil Field",
            "activityData": 10000,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 7160
        },
        {
            "dated": "Feb'24",
            "locationName": "Silver Creek Oil Field",
            "activityData": 2000,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 1432
        },
        {
            "dated": "Feb'24",
            "locationName": "Emerald Valley Oil Field",
            "activityData": 3200,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 2291.2
        },
        {
            "dated": "Mar'24",
            "locationName": "Golden Ridge Oil Field",
            "activityData": 10000,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 7160
        },
        {
            "dated": "Mar'24",
            "locationName": "Silver Creek Oil Field",
            "activityData": 16000,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 11456
        },
        {
            "dated": "Mar'24",
            "locationName": "Emerald Valley Oil Field",
            "activityData": 27000,
            "uom": "kWh",
            "emissionFactor": 0.716,
            "emission": 19332
        }
    ]
    const dates = getDateRange(activityStartDate, activityEndDate);
    const assignDetail = (i: any, index: number) => {
        const emissionFactor = emissionSourceList[Math.floor(Math.random() * emissionSourceList.length)];

        i.locationName = locationList[Math.floor(Math.random() * locationList.length)].name;
        i.dated = dates[Math.floor(Math.random() * dates.length)];
        i.id = index + 1;
        i.emissionSource = emissionFactor.name;
        i.emissionSourceYear = emissionFactor.year;
        return i;
    }

    const _pending = data.map((i: any, index: number) => assignDetail(i, index))
    const _approved = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 2))
    const _rejected = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 4))

    return { _pending, _approved, _rejected }
}

export const getCategory1Emission = () => {
    const activityStartDate = "2023-06-01";
    const activityEndDate = "2024-06-10";
    const emissionSourceList = [{ name: 'SUPPLIER SPECIFIC', year: '' }, { name: 'NAICS', year: '2021' }]

    const data = [
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Crude oil",
            "supplierName": "Oil Company",
            "approach": "Supplier Specific",
            "activityData": "1,00,00,000",
            "uom": "barrels",
            "emissionFactor": 0.022,
            "emission": 220000
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Crude oil",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "5,00,000",
            "uom": "INR",
            "emissionFactor": 0.603,
            "emission": 301500
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Crude oil",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "7,00,000",
            "uom": "INR",
            "emissionFactor": 0.603,
            "emission": 422100
        },
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Crude oil",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "1,50,000",
            "uom": "INR",
            "emissionFactor": 0.14,
            "emission": 21000
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Crude oil",
            "supplierName": "Oil Company",
            "approach": "Supplier Specific",
            "activityData": "2,10,000",
            "uom": "barrels",
            "emissionFactor": 0.56,
            "emission": 117600
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Petroleum Product",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "3,00,000",
            "uom": "INR",
            "emissionFactor": 0.603,
            "emission": 180900
        },
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Petroleum Product",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "20,00,000",
            "uom": "INR",
            "emissionFactor": 0.079,
            "emission": 158000
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Petroleum Product",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "10,00,000",
            "uom": "INR",
            "emissionFactor": 0.125,
            "emission": 125000
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Petroleum Product",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "40,00,000",
            "uom": "INR",
            "emissionFactor": 0.225,
            "emission": 900000
        },
        {
            "dated": "Feb'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Crude oil",
            "supplierName": "Oil Company",
            "approach": "Supplier Specific",
            "activityData": "10,00,000",
            "uom": "barrels",
            "emissionFactor": 0.022,
            "emission": 22000
        },
        {
            "dated": "Feb'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Crude oil",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "3,00,000",
            "uom": "INR",
            "emissionFactor": 0.603,
            "emission": 180900
        },
        {
            "dated": "Feb'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Crude oil",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "20,00,000",
            "uom": "INR",
            "emissionFactor": 0.079,
            "emission": 158000
        },
        {
            "dated": "Feb'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Crude oil",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "10,00,000",
            "uom": "INR",
            "emissionFactor": 0.125,
            "emission": 125000
        },
        {
            "dated": "Feb'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Crude oil",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "4,00,000",
            "uom": "INR",
            "emissionFactor": 0.225,
            "emission": 90000
        },
        {
            "dated": "Feb'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Petroleum Product",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "5,00,000",
            "uom": "INR",
            "emissionFactor": 0.603,
            "emission": 301500
        },
        {
            "dated": "Feb'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Petroleum Product",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "7,00,000",
            "uom": "INR",
            "emissionFactor": 0.603,
            "emission": 422100
        },
        {
            "dated": "Feb'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Petroleum Product",
            "supplierName": "Oil Company",
            "approach": "Spend Based",
            "activityData": "1,50,000",
            "uom": "INR",
            "emissionFactor": 0.14,
            "emission": 21000
        },
        {
            "dated": "Feb'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Petroleum Product",
            "supplierName": "Oil Company",
            "approach": "Supplier Specific",
            "activityData": "2,10,000",
            "uom": "barrels",
            "emissionFactor": 0.56,
            "emission": 117600
        }
    ]
    const dates = getDateRange(activityStartDate, activityEndDate);
    const assignDetail = (i: any, index: number) => {
        const emissionFactor = emissionSourceList[Math.floor(Math.random() * emissionSourceList.length)];

        i.locationName = locationList[Math.floor(Math.random() * locationList.length)].name;
        i.dated = dates[Math.floor(Math.random() * dates.length)];
        i.id = index + 1;
        i.emissionSource = emissionFactor.name;
        i.emissionSourceYear = emissionFactor.year;
        return i;
    }

    const _pending = data.map((i: any, index: number) => assignDetail(i, index))
    const _approved = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 2))
    const _rejected = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 4))

    return { _pending, _approved, _rejected }
}

export const getCategory2Emission = () => {
    const activityStartDate = "2023-06-01";
    const activityEndDate = "2024-06-10";
    const emissionSourceList = [{ name: 'SUPPLIER SPECIFIC', year: '' }, { name: 'NAICS', year: '2021' }]

    const data = [
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Tanks",
            "supplierName": "Metal Company",
            "approach": "Supplier Specific",
            "activityData": "10,00,000",
            "uom": "INR",
            "emissionFactor": 0.089,
            "emission": 89000
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Diesel Generator",
            "supplierName": "PowerGen Solutions",
            "approach": "Spend Based",
            "activityData": "3,00,000",
            "uom": "INR",
            "emissionFactor": 0.229,
            "emission": 68700
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Heavy Machinery",
            "supplierName": "PowerGen Solutions",
            "approach": "Spend Based",
            "activityData": "18,00,000",
            "uom": "INR",
            "emissionFactor": 0.27,
            "emission": 486000
        },
        {
            "dated": "Feb'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Tanks",
            "supplierName": "SureShield Solutions",
            "approach": "Spend Based",
            "activityData": "5,00,000",
            "uom": "INR",
            "emissionFactor": 0.176,
            "emission": 88000
        },
        {
            "dated": "Feb'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Office Furniture",
            "supplierName": "Metal Company",
            "approach": "Spend Based",
            "activityData": "2,00,000",
            "uom": "INR",
            "emissionFactor": 0.305,
            "emission": 61000
        },
        {
            "dated": "Feb'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Tanks",
            "supplierName": "Metal Company",
            "approach": "Supplier Specific",
            "activityData": "10,00,000",
            "uom": "INR",
            "emissionFactor": 0.089,
            "emission": 89000
        },
        {
            "dated": "Mar'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Diesel Generator",
            "supplierName": "PowerGen Solutions",
            "approach": "Spend Based",
            "activityData": "3,00,000",
            "uom": "INR",
            "emissionFactor": 0.229,
            "emission": 68700
        },
        {
            "dated": "Mar'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Heavy Machinery",
            "supplierName": "PowerGen Solutions",
            "approach": "Spend Based",
            "activityData": "18,00,000",
            "uom": "INR",
            "emissionFactor": 0.27,
            "emission": 486000
        },
        {
            "dated": "Mar'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Tanks",
            "supplierName": "SureShield Solutions",
            "approach": "Spend Based",
            "activityData": "5,00,000",
            "uom": "INR",
            "emissionFactor": 0.176,
            "emission": 88000
        },
        {
            "dated": "Mar'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Compressor",
            "supplierName": "Metal Company",
            "approach": "Spend Based",
            "activityData": "2,00,000",
            "uom": "INR",
            "emissionFactor": 0.305,
            "emission": 61000
        }
    ]
    const dates = getDateRange(activityStartDate, activityEndDate);
    const assignDetail = (i: any, index: number) => {
        const emissionFactor = emissionSourceList[Math.floor(Math.random() * emissionSourceList.length)];

        i.locationName = locationList[Math.floor(Math.random() * locationList.length)].name;
        i.dated = dates[Math.floor(Math.random() * dates.length)];
        i.id = index + 1;
        i.emissionSource = emissionFactor.name;
        i.emissionSourceYear = emissionFactor.year;
        return i;
    }

    const _pending = data.map((i: any, index: number) => assignDetail(i, index))
    const _approved = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 2))
    const _rejected = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 4))

    return { _pending, _approved, _rejected }
}

export const getCategory3Emission = () => {
    const activityStartDate = "2023-06-01";
    const activityEndDate = "2024-06-10";
    const emissionSourceList = [{ name: 'SUPPLIER SPECIFIC', year: '' }, { name: 'NAICS', year: '2021' }]

    const data = [
        {
            "id": 20,
            "dated": "2024-04-01T00:00:00.000Z",
            "energySourceId": "B3Dq86",
            "emissionSourceName": "Electricity",
            "activityData": "100",
            "uom": "kWh",
            "emission": "2.6489",
            "emissionUnit": "kg CO2e",
            "remarks": "Total Grid Electricity Consumed - Transmission & Distribution Losses from Grid.",
            "userId": 1,
            "createdBy": 1,
            "createdOn": "2024-04-23T06:05:28.135Z",
            "createdAt": null,
            "modifiedBy": 1,
            "modifiedOn": "2024-04-23T10:45:18.934Z",
            "modifiedAt": null,
            "approved": true,
            "approvedBy": 1,
            "approvedOn": "2024-04-23T10:45:18.934Z",
            "approvedAt": null,
            "deleted": false,
            "deletedBy": null,
            "deletedOn": null,
            "deletedAt": null,
            "tenantId": 1,
            "approvedComment": "test",
            "branchId": 102,
            "parentId": 1,
            "parentName": "Purchased-Electricity",
            "childUID": "electricityTransmissionAndDistributionEmission",
            "locationId": null,
            "emissionFactorId": null,
            "month": "2024-04",
            "locationName": "Metroplex Square"
        },
        {
            "id": 19,
            "dated": "2024-04-01T00:00:00.000Z",
            "energySourceId": "B3Dq86",
            "emissionSourceName": "Electricity",
            "activityData": "100",
            "uom": "kWh",
            "emission": "11.2928",
            "emissionUnit": "kg CO2e",
            "remarks": "Total Grid Electricity Consumed - Upstream Emissions from Purchased Electricity.",
            "userId": 1,
            "createdBy": 1,
            "createdOn": "2024-04-23T06:05:28.004Z",
            "createdAt": null,
            "modifiedBy": 1,
            "modifiedOn": "2024-04-23T10:45:19.299Z",
            "modifiedAt": null,
            "approved": true,
            "approvedBy": 1,
            "approvedOn": "2024-04-23T10:45:19.299Z",
            "approvedAt": null,
            "deleted": false,
            "deletedBy": null,
            "deletedOn": null,
            "deletedAt": null,
            "tenantId": 1,
            "approvedComment": "test",
            "branchId": 102,
            "parentId": 1,
            "parentName": "Purchased-Electricity",
            "childUID": "electricityGenerationEmission",
            "locationId": null,
            "emissionFactorId": null,
            "month": "2024-04",
            "locationName": "Central Station"
        },
        {
            "id": 18,
            "dated": "2024-04-01T00:00:00.000Z",
            "energySourceId": "10",
            "emissionSourceName": "Diesel",
            "activityData": "100",
            "uom": "l",
            "emission": "62.409000000000006",
            "emissionUnit": "kgCO2e",
            "remarks": "Fuel Consumed by Truck",
            "userId": 1,
            "createdBy": 1,
            "createdOn": "2024-04-22T12:08:29.430Z",
            "createdAt": null,
            "modifiedBy": 1,
            "modifiedOn": "2024-04-23T10:45:19.713Z",
            "modifiedAt": null,
            "approved": true,
            "approvedBy": 1,
            "approvedOn": "2024-04-23T10:45:19.713Z",
            "approvedAt": null,
            "deleted": false,
            "deletedBy": null,
            "deletedOn": null,
            "deletedAt": null,
            "tenantId": 1,
            "approvedComment": "test",
            "branchId": 102,
            "parentId": 2,
            "parentName": "Mobile Source Truck",
            "childUID": "mobileSourceTruck",
            "locationId": null,
            "emissionFactorId": null,
            "month": "2024-04",
            "locationName": "Central Station"
        },
        {
            "id": 17,
            "dated": "2024-04-01T00:00:00.000Z",
            "energySourceId": "9",
            "emissionSourceName": "Diesel",
            "activityData": "100",
            "uom": "l",
            "emission": "62.409000000000006",
            "emissionUnit": "kgCO2e",
            "remarks": "Fuel Consumed by Bike",
            "userId": 1,
            "createdBy": 1,
            "createdOn": "2024-04-22T11:58:21.823Z",
            "createdAt": null,
            "modifiedBy": 1,
            "modifiedOn": "2024-04-23T10:45:20.104Z",
            "modifiedAt": null,
            "approved": true,
            "approvedBy": 1,
            "approvedOn": "2024-04-23T10:45:20.104Z",
            "approvedAt": null,
            "deleted": false,
            "deletedBy": null,
            "deletedOn": null,
            "deletedAt": null,
            "tenantId": 1,
            "approvedComment": "test",
            "branchId": 102,
            "parentId": 2,
            "parentName": "Mobile Source Bike",
            "childUID": "mobileSourceBike",
            "locationId": null,
            "emissionFactorId": null,
            "month": "2024-04",
            "locationName": "Metroplex Square"
        },
        {
            "id": 15,
            "dated": "2024-04-01T00:00:00.000Z",
            "energySourceId": null,
            "emissionSourceName": null,
            "activityData": "100",
            "uom": "l",
            "emission": "62.409000000000006",
            "emissionUnit": "kgCO2e",
            "remarks": "Fuel Consumed in Stationary Combustion",
            "userId": 1,
            "createdBy": 1,
            "createdOn": "2024-04-19T12:37:20.574Z",
            "createdAt": null,
            "modifiedBy": 1,
            "modifiedOn": "2024-04-23T10:45:20.519Z",
            "modifiedAt": null,
            "approved": true,
            "approvedBy": 1,
            "approvedOn": "2024-04-23T10:45:20.519Z",
            "approvedAt": null,
            "deleted": false,
            "deletedBy": null,
            "deletedOn": null,
            "deletedAt": null,
            "tenantId": null,
            "approvedComment": "test",
            "branchId": 102,
            "parentId": 9,
            "parentName": "Stationary Combustion",
            "childUID": "stationaryCombustion",
            "locationId": null,
            "emissionFactorId": null,
            "month": "2024-04",
            "locationName": "Midtown Center"
        }
    ]
    const dates = getDateRange(activityStartDate, activityEndDate);
    const assignDetail = (i: any, index: number) => {
        const emissionFactor = emissionSourceList[Math.floor(Math.random() * emissionSourceList.length)];

        i.locationName = locationList[Math.floor(Math.random() * locationList.length)].name;
        i.dated = dates[Math.floor(Math.random() * dates.length)];
        i.id = index + 1;
        i.emissionSource = emissionFactor.name;
        i.emissionSourceYear = emissionFactor.year;
        return i;
    }

    const _pending = data.map((i: any, index: number) => assignDetail(i, index))
    const _approved = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 2))
    const _rejected = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 4))

    return { _pending, _approved, _rejected }
}

export const getCategory4Emission = () => {
    const activityStartDate = "2023-06-01";
    const activityEndDate = "2024-06-10";
    const emissionSourceList = [{ name: 'DEFRA', year: '2023' }, { name: 'NAICS', year: '2021' }]

    const data = [
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Light Duty Truck",
            "approach": "Distance Based",
            "activityData": 500,
            "uom": "km",
            "emissionFactor": 0.48019,
            "emission": 240.095
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Medium Duty Truck",
            "approach": "Spend Based",
            "activityData": "20,000",
            "uom": "INR",
            "emissionFactor": 0.499,
            "emission": 9980
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Heavy Duty Truck",
            "approach": "Spend Based",
            "activityData": "50,000",
            "uom": "INR",
            "emissionFactor": 0.499,
            "emission": 24950
        },
        {
            "dated": "Feb'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Light Duty Truck",
            "approach": "Distance Based",
            "activityData": "1,500",
            "uom": "km",
            "emissionFactor": 0.48019,
            "emission": 720.285
        },
        {
            "dated": "Feb'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Medium Duty Truck",
            "approach": "Spend Based",
            "activityData": "10,000",
            "uom": "INR",
            "emissionFactor": 0.499,
            "emission": 4990
        },
        {
            "dated": "Feb'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Heavy Duty Truck",
            "approach": "Spend Based",
            "activityData": "20,000",
            "uom": "INR",
            "emissionFactor": 0.499,
            "emission": 9980
        },
        {
            "dated": "Mar'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Light Duty Truck",
            "approach": "Distance Based",
            "activityData": "2,500",
            "uom": "km",
            "emissionFactor": 0.48019,
            "emission": 1200.475
        },
        {
            "dated": "Mar'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Medium Duty Truck",
            "approach": "Spend Based",
            "activityData": "20,000",
            "uom": "INR",
            "emissionFactor": 0.499,
            "emission": 9980
        },
        {
            "dated": "Mar'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Heavy Duty Truck",
            "approach": "Spend Based",
            "activityData": "30,000",
            "uom": "INR",
            "emissionFactor": 0.499,
            "emission": 14970
        }
    ]
    const dates = getDateRange(activityStartDate, activityEndDate);
    const assignDetail = (i: any, index: number) => {
        const emissionFactor = emissionSourceList[Math.floor(Math.random() * emissionSourceList.length)];

        i.locationName = locationList[Math.floor(Math.random() * locationList.length)].name;
        i.dated = dates[Math.floor(Math.random() * dates.length)];
        i.id = index + 1;
        i.emissionSource = emissionFactor.name;
        i.emissionSourceYear = emissionFactor.year;
        return i;
    }

    const _pending = data.map((i: any, index: number) => assignDetail(i, index))
    const _approved = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 2))
    const _rejected = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 4))

    return { _pending, _approved, _rejected }
}

export const getCategory5Emission = () => {
    const activityStartDate = "2023-06-01";
    const activityEndDate = "2024-06-10";
    const emissionSourceList = [{ name: 'DEFRA', year: '2023' }, { name: 'NAICS', year: '2021' }]

    const data = [
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Oil Waste",
            "activityData": 500,
            "uom": "kg",
            "emissionFactor": 0.021281,
            "emission": 10.6405
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Oil Waste",
            "activityData": 150,
            "uom": "kg",
            "emissionFactor": 0.021281,
            "emission": 3.19215
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Oil Waste",
            "activityData": 400,
            "uom": "kg",
            "emissionFactor": 0.021281,
            "emission": 8.5124
        },
        {
            "dated": "Feb'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Battery waste",
            "activityData": "20,000",
            "uom": "kg",
            "emissionFactor": 0.021281,
            "emission": 425.62
        },
        {
            "dated": "Feb'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Battery waste",
            "activityData": "5,000",
            "uom": "kg",
            "emissionFactor": 0.021281,
            "emission": 106.405
        },
        {
            "dated": "Feb'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Battery waste",
            "activityData": "10,000",
            "uom": "kg",
            "emissionFactor": 0.021281,
            "emission": 212.81
        },
        {
            "dated": "Feb'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "E- Waste",
            "activityData": "50,000",
            "uom": "kg",
            "emissionFactor": 0.021281,
            "emission": 1064.05
        },
        {
            "dated": "Mar'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "E- Waste",
            "activityData": "4,000",
            "uom": "kg",
            "emissionFactor": 0.021281,
            "emission": 85.124
        },
        {
            "dated": "Mar'24",
            "locationName": "Emerald Valley Oil Field",
            "emissionSourceName": "Oil Waste",
            "activityData": 1000,
            "uom": "kg",
            "emissionFactor": 0.008912,
            "emission": 8.912
        },
        {
            "dated": "Mar'24",
            "locationName": "Golden Ridge Oil Field",
            "emissionSourceName": "Oil Waste",
            "activityData": "3,000",
            "uom": "kg",
            "emissionFactor": 0.008912,
            "emission": 26.736
        },
        {
            "dated": "Mar'24",
            "locationName": "Silver Creek Oil Field",
            "emissionSourceName": "Food Waste",
            "activityData": "1,000",
            "uom": "kg",
            "emissionFactor": 0.008912,
            "emission": 8.912
        }
    ]
    const dates = getDateRange(activityStartDate, activityEndDate);
    const assignDetail = (i: any, index: number) => {
        const emissionFactor = emissionSourceList[Math.floor(Math.random() * emissionSourceList.length)];

        i.locationName = locationList[Math.floor(Math.random() * locationList.length)].name;
        i.dated = dates[Math.floor(Math.random() * dates.length)];
        i.id = index + 1;
        i.emissionSource = emissionFactor.name;
        i.emissionSourceYear = emissionFactor.year;
        return i;
    }

    const _pending = data.map((i: any, index: number) => assignDetail(i, index))
    const _approved = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 2))
    const _rejected = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 4))

    return { _pending, _approved, _rejected }
}

export const getCategory6Emission = () => {
    const activityStartDate = "2023-06-01";
    const activityEndDate = "2024-06-10";
    const emissionSourceList = [{ name: 'DEFRA', year: '2023' }, { name: 'NAICS', year: '2021' }]

    const data = [
        {
            "dated": "Nov'23",
            "locationName": "Golden Ridge Oil Field",
            "approach": "Spend Based",
            "emissionSourceName": "Air",
            "activityData": "5,000",
            "uom": "INR",
            "emissionFactor": 0.976,
            "emission": 4880
        },
        {
            "dated": "Nov'23",
            "locationName": "Silver Creek Oil Field",
            "approach": "Spend Based",
            "emissionSourceName": "Air",
            "activityData": "15,000",
            "uom": "INR",
            "emissionFactor": 0.976,
            "emission": 14640
        },
        {
            "dated": "Nov'23",
            "locationName": "Emerald Valley Oil Field",
            "approach": "Distance Based",
            "emissionSourceName": "Car",
            "activityData": 50,
            "uom": "km",
            "emissionFactor": 0.1666,
            "emission": 8.3
        },
        {
            "dated": "Nov'23",
            "locationName": "Golden Ridge Oil Field",
            "approach": "Distance Based",
            "emissionSourceName": "Car",
            "activityData": 100,
            "uom": "km",
            "emissionFactor": 0.1666,
            "emission": 49.9
        },
        {
            "dated": "Nov'23",
            "locationName": "Silver Creek Oil Field",
            "approach": "Spend Based",
            "emissionSourceName": "Rail",
            "activityData": 5000,
            "uom": "INR",
            "emissionFactor": 0.499,
            "emission": 2495
        },
        {
            "dated": "Nov'23",
            "locationName": "Emerald Valley Oil Field",
            "approach": "Hotel Stay",
            "emissionSourceName": "Hotel Stay",
            "activityData": 5,
            "uom": "No. of Nights",
            "emissionFactor": 58.9,
            "emission": 294.5
        },
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "approach": "Spend Based",
            "emissionSourceName": "Air",
            "activityData": "30,000",
            "uom": "INR",
            "emissionFactor": 0.976,
            "emission": 29280
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "approach": "Spend Based",
            "emissionSourceName": "Air",
            "activityData": "50,000",
            "uom": "INR",
            "emissionFactor": 0.976,
            "emission": 48800
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "approach": "Distance Based",
            "emissionSourceName": "Car",
            "activityData": 50,
            "uom": "km",
            "emissionFactor": 0.1666,
            "emission": 8.3
        },
        {
            "dated": "Jan'24",
            "locationName": "Golden Ridge Oil Field",
            "approach": "Distance Based",
            "emissionSourceName": "Car",
            "activityData": 100,
            "uom": "km",
            "emissionFactor": 0.1666,
            "emission": 49.9
        },
        {
            "dated": "Jan'24",
            "locationName": "Silver Creek Oil Field",
            "approach": "Spend Based",
            "emissionSourceName": "Rail",
            "activityData": 5000,
            "uom": "INR",
            "emissionFactor": 0.499,
            "emission": 2495
        },
        {
            "dated": "Jan'24",
            "locationName": "Emerald Valley Oil Field",
            "approach": "Hotel Stay",
            "emissionSourceName": "Hotel Stay",
            "activityData": 10,
            "uom": "No. of Nights",
            "emissionFactor": 58.9,
            "emission": 589
        }
    ]
    const dates = getDateRange(activityStartDate, activityEndDate);
    const assignDetail = (i: any, index: number) => {
        const emissionFactor = emissionSourceList[Math.floor(Math.random() * emissionSourceList.length)];

        i.locationName = locationList[Math.floor(Math.random() * locationList.length)].name;
        i.dated = dates[Math.floor(Math.random() * dates.length)];
        i.id = index + 1;
        i.emissionSource = emissionFactor.name;
        i.emissionSourceYear = emissionFactor.year;
        return i;
    }

    const _pending = data.map((i: any, index: number) => assignDetail(i, index))
    const _approved = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 2))
    const _rejected = data.map((i: any, index: number) => assignDetail(i, index)).filter((i: any, ind: any) => ind <= (data.length / 4))

    return { _pending, _approved, _rejected }
}