import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import constant from "../../../constant";
import { projectListAtom } from "../../../atom/OffsetAtom";
import ProductCard from "../../../component/offset/ProductCard";

function Home() {
    const title = constant.component.offsetMarketPlace.title;
    const list = useRecoilValue(projectListAtom);
    useEffect(() => {
        document.title = title;
    }, []);

    return (
        <>
            <div className="container-fluid">
                {/* title */}
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">{title}</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Offset Emission</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* title */}

                <div className="row">
                    {
                        list.map((item: any, index: number) => {
                            return (
                                <ProductCard detail={item} />
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Home;