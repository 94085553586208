import moment from "moment";
import { useState } from "react";
import { Table, controlType, input, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? props.value.answer["305-5-d-i"] : false);
    const [a2, setA2] = useState(props.value ? props.value.answer["305-5-d-ii"] : false);
    const [a3, setA3] = useState(props.value ? props.value.answer["305-5-d-iii"] : false);
    const [a4, setA4] = useState(props.value ? props.value.answer["305-5-d-iv"] : false);

    const [a5, setA5] = useState(props.value ? props.value.answer["305-5-d-v"] : '');
    const [a6, setA6] = useState(props.value ? props.value.answer["305-5-d-vi"] : '');
    const [a7, setA7] = useState(props.value ? props.value.answer["305-5-d-vii"] : '');

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "305-5-d-i": a1,
                "305-5-d-ii": a2,
                "305-5-d-iiii": a3,
                "305-5-d-iv": a4,
                "305-5-d-v": a5,
                "305-5-d-vi": a6,
                "305-5-d-vii": a7,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    const onCheckAll = (e: any) => {
        const checked = e.target.checked;
        setA1(checked);
        setA2(checked);
        setA3(checked);
        setA7(checked);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="1" checked={a1}
                        onChange={(e: any) => {
                            setA1(e.target.checked);
                            if (!e.target.checked) {
                                setA4('')
                            }
                        }}
                    />
                    <label className="form-check-label" htmlFor="1">
                        Direct(Scope 1)
                    </label>
                </div>
                {
                    a1 && <div className="input-group mb-3">
                        {input({ name: '', value: a4, onChange: (a: any, b: any) => setA4(b) })}
                    </div>
                }
            </div>
            <div className="mb-3">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="2" checked={a2}
                        onChange={(e: any) => {
                            setA2(e.target.checked);
                            if (!e.target.checked) {
                                setA5('')
                            }
                        }}
                    />
                    <label className="form-check-label" htmlFor="2">
                        Energy Indirect (Scope 2)
                    </label>
                </div>
                {
                    a2 && <div className="input-group mb-3">
                        {input({ name: '', value: a5, onChange: (a: any, b: any) => setA5(b) })}
                    </div>
                }
            </div>
            <div className="mb-3">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="3" checked={a3}
                        onChange={(e: any) => {
                            setA3(e.target.checked);
                            if (!e.target.checked) {
                                setA6('')
                            }
                        }}
                    />
                    <label className="form-check-label" htmlFor="3">
                        Other Indirect (Scope 3)
                    </label>
                </div>

                {
                    a3 && <div className="input-group mb-3">
                        {input({ name: '', value: a6, onChange: (a: any, b: any) => setA6(b) })}
                    </div>
                }
            </div>
            <div className="mb-3">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="7" checked={a7} onChange={onCheckAll} />
                    <label className="form-check-label" htmlFor="7">
                        All
                    </label>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1 = answer ? answer.answer["305-5-d-i"] : '';
    const a2 = answer ? answer.answer["305-5-d-ii"] : '';
    const a3 = answer ? answer.answer["305-5-d-iii"] : '';
    const a4 = answer ? answer.answer["305-5-d-iv"] : '';
    const a5 = answer ? answer.answer["305-5-d-v"] : '';
    const a6 = answer ? answer.answer["305-5-d-vi"] : '';
    const a7 = answer ? answer.answer["305-5-d-vii"] : '';
    return (
        <>

            <div className="mb-3">
                {
                    a1 && <div className="fw-bold">
                        Direct(Scope 1) {a4 && <span className="">: {a4}</span>}
                    </div>
                }
            </div>
            <div className="mb-3">
                {
                    a2 && <div className="fw-bold">
                        Energy Indirect (Scope 2) {a5 && <span className="">: {a5}</span>}
                    </div>
                }
            </div>
            <div className="mb-3">
                {
                    a3 && <div className="fw-bold">
                        Other Indirect (Scope 3) {a6 && <span className="">: {a6}</span>}
                    </div>
                }
            </div>
        </>
    )
}