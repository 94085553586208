import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, number } from "../../common/Controls";
import { getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? JSON.parse(props.value.answer["403-8-a"]) : []);

    const columns: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: '403-8-a-i', label: 'Number of all employees and workers covered', type: controlType.string, value: '', show: true, band: 'Total', },
        { name: '403-8-a-ii', label: 'Percentage of all employees and worker covered', type: controlType.string, value: '', show: true, band: 'Total', },
        { name: '403-8-a-iii', label: 'Number of all employees and workers covered', type: controlType.string, value: '', show: true, band: 'Internally Audited', },
        { name: '403-8-a-iv', label: 'Percentage of all employees and worker covered', type: controlType.string, value: '', show: true, band: 'Internally Audited', },
        { name: '403-8-a-v', label: 'Number of all employees and workers covered', type: controlType.string, value: '', show: true, band: 'Audited or Certified by an External Party', },
        { name: '403-8-a-vi', label: 'Percentage of all employees and worker covered', type: controlType.string, value: '', show: true, band: 'Audited or Certified by an External Party', },
    ]

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "403-8-a": JSON.stringify(a1),
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setA1(value)}
                    columns={columns}
                    obj={{ name: "", value: a1 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1JSON = answer ? JSON.parse(answer.answer["403-8-a"]) : [];

    return (
        <>
            <div style={{ overflow: 'auto' }} className="mb-3">
                {/* { name: '403-8-a-i', label: 'Number of all employees and workers covered', type: controlType.string, value: '', show: true, band: 'Total', },
        { name: '403-8-a-ii', label: 'Percentage of all employees and worker covered', type: controlType.string, value: '', show: true, band: 'Total', },
        { name: '403-8-a-iii', label: 'Number of all employees and workers covered', type: controlType.string, value: '', show: true, band: 'Internally Audited', },
        { name: '403-8-a-iv', label: 'Percentage of all employees and worker covered', type: controlType.string, value: '', show: true, band: 'Internally Audited', },
        { name: '403-8-a-v', label: 'Number of all employees and workers covered', type: controlType.string, value: '', show: true, band: 'Audited or Certified by an External Party', },
        { name: '403-8-a-vi', label: 'Percentage of all employees and worker covered', type: controlType.string, value: '', show: true, band: 'Audited or Certified by an External Party', }, */}
                <table className="table text-nowrap table-bordered border-primary">
                    <thead>
                        <tr>
                            <th rowSpan={2}>
                                #
                            </th>
                            <th colSpan={2}>
                                Total
                            </th>
                            <th colSpan={2}>
                                Internally Audited
                            </th>
                            <th colSpan={2}>
                                Audited or Certified by an External Party
                            </th>

                        </tr>
                        <tr>
                            <th>
                                Number of all employees and workers covered
                            </th>
                            <th>
                                Percentage of all employees and worker covered
                            </th>
                            <th>
                                Number of all employees and workers covered
                            </th>
                            <th>
                                Percentage of all employees and worker covered
                            </th>
                            <th>
                                Number of all employees and workers covered
                            </th>
                            <th>
                                Percentage of all employees and worker covered
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            a1JSON.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            a1JSON.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td className="">{item['403-8-a-i'] ? item['403-8-a-i'] : '-'}</td>
                                    <td className="">{item['403-8-a-ii'] ? item['403-8-a-ii'] : '-'}</td>
                                    <td className="">{item['403-8-a-iii'] ? item['403-8-a-iii'] : '-'}</td>
                                    <td className="">{item['403-8-a-iv'] ? item['403-8-a-iv'] : '-'}</td>
                                    <td className="">{item['403-8-a-v'] ? item['403-8-a-v'] : '-'}</td>
                                    <td className="">{item['403-8-a-vi'] ? item['403-8-a-vi'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}