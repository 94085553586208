import { Modal, ModalHeader, ModalBody } from 'reactstrap';

interface IPropType {
    css?: string,
    backdrop: "static" | true | false,
    onHideModal: any,
    title?: string,
    children: any,
    size?: any
}

function ModalWindow(props: IPropType) {
    const { css, backdrop, onHideModal } = props;
    const backdropAttribute = backdrop ? 'static' : true;
    const toggle = () => {
        onHideModal();
    };

    return (
        <Modal size={props.size} isOpen={true} toggle={toggle} className={css} backdrop={backdropAttribute}>
            <ModalHeader toggle={toggle}>{props.title}</ModalHeader>
            <ModalBody>
                {props.children}
            </ModalBody>

        </Modal>
    );
}

export default ModalWindow;