import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, number } from "../../common/Controls";
import { getFormattedNumber, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a, setA] = useState(props.value ? props.value.answer["302-2-a"] : '');
    const [a1, setA1] = useState(props.value ? props.value.answer["302-2-a-a1"] : '');
    const [a2, setA2] = useState(props.value ? props.value.answer["302-2-a-a2"] : '');
    const [a3, setA3] = useState(props.value ? props.value.answer["302-2-a-a3"] : '');
    const [a4, setA4] = useState(props.value ? props.value.answer["302-2-a-a4"] : '');

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "302-2-a": a,
                "302-2-a-a1": a1,
                "302-2-a-a2": a2,
                "302-2-a-a3": a3,
                "302-2-a-a4": a4,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <textarea rows={3} className="form-control" onChange={(e: any) => setA(e.target.value)} value={a} />
            </div>
            {
                a.length > 0 && <>
                    <div className="mb-3">
                        <div className="mb-3 fw-bold">
                            a-i. How standards for facilities operating in locations with no local discharge requirements were determined
                        </div>
                        <div className="mb-3">
                            <textarea rows={3} className="form-control" onChange={(e: any) => setA1(e.target.value)} value={a1} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="mb-3 fw-bold">
                            a-ii. Any internally developed water quality standards or guidelines
                        </div>
                        <div className="mb-3">
                            <textarea rows={3} className="form-control" onChange={(e: any) => setA2(e.target.value)} value={a2} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="mb-3 fw-bold">
                            a-iii. Any sector-secific standards considered
                        </div>
                        <div className="mb-3">
                            <textarea rows={3} className="form-control" onChange={(e: any) => setA3(e.target.value)} value={a3} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="mb-3 fw-bold">
                            a-iv. Whether the profile of the receiving waterbody was considered
                        </div>
                        <div className="mb-3">
                            <textarea rows={3} className="form-control" onChange={(e: any) => setA4(e.target.value)} value={a4} />
                        </div>
                    </div>
                </>
            }
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a = answer ? answer.answer["302-2-a"] : '';
    const a1 = answer ? answer.answer["302-2-a-a1"] : '';
    const a2 = answer ? answer.answer["302-2-a-a2"] : '';
    const a3 = answer ? answer.answer["302-2-a-a3"] : '';
    const a4 = answer ? answer.answer["302-2-a-a4"] : '';

    return (
        <>

            <div className="fw-bold">
                Describe minimum standards set for effluent discharge quality and their determination process
            </div>
            <div className="">
                <p>{a ? a : '-'}</p>
            </div>
            {
                a.length > 0 && <>
                    <div className="mb-3">
                        <div className="fw-bold">
                            a-i. How standards for facilities operating in locations with no local discharge requirements were determined
                        </div>
                        <div className="">
                            <p>{a1 ? a1 : '-'}</p>
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="fw-bold">
                            a-ii. Any internally developed water quality standards or guidelines
                        </div>
                        <div className="">
                            <p>{a2 ? a2 : '-'}</p>
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="fw-bold">
                            a-iii. Any sector-secific standards considered
                        </div>
                        <div className="">
                            <p>{a3 ? a3 : '-'}</p>
                        </div>
                    </div>

                    <div className="mb-3">
                        <div className="fw-bold">
                            a-iv. Whether the profile of the receiving waterbody was considered
                        </div>
                        <div className="">
                            <p>{a4 ? a4 : '-'}</p>
                        </div>
                    </div>
                </>
            }
        </>
    )
}