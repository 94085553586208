export default function Analytics(props: any) {
    const { list } = props;
    return (
        <>
            <div className="row">
                <div className="col-4">
                    <div className="card custom-card">
                        <div className="card-header">
                            <div className="card-title">Summary</div>
                        </div>
                        <div className="card-body p-0">

                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card custom-card">
                        <div className="card-header">
                            <div className="card-title">Emission Distribution By Fuel</div>
                        </div>
                        <div className="card-body p-0">

                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card custom-card">
                        <div className="card-header">
                            <div className="card-title">Emission Distribution By Location</div>
                        </div>
                        <div className="card-body p-0">

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}