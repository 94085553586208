import { atom, } from 'recoil';

const languageList = [
    { id: 'en', name: 'English', dir: "ltr" },
    { id: 'hi', name: 'हिन्दी', dir: "ltr" },
    { id: 'ar', name: 'العربية', dir: "rtl" },
]

export const languageListAtom = atom({
    key: 'languageListAtom',
    default: languageList
});

export const activeLanguageAtom = atom({
    key: 'activeLanguageAtom',
    default: 'en'
});