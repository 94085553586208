import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, input, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? props.value.answer["410-1-b-a1"] : '');
    const [a2, setA2] = useState(props.value ? props.value.answer["410-1-b-a2"] : '');

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "410-1-b-a1": a1,
                "410-1-b-a2": a2,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>

            <div className="">
                {boolean({ name: '', value: a1, onChange: (a: any, b: any) => { setA1(b) }, trueLabel: 'Yes', falseLabel: 'No' })}
            </div>

            <div className="mb-3">
                <div className="fw-bold">
                    Remarks
                </div>
                <textarea rows={3} className="form-control" value={a2} onChange={(e: any) => setA2(e.target.value)} />
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1JSON = answer ? answer.answer["410-1-b-a1"] : '';
    const a2JSON = answer ? answer.answer["410-1-b-a2"] : '';

    return (
        <>
            <div className="">
                {a1JSON ? (a1JSON) : 'Not Answered'}
            </div>
            <div className="mt-3">
                <div className="fw-bold">
                    Remarks
                </div>
                {a2JSON ? (a2JSON) : '-'}
            </div>
        </>
    )
}