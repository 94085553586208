import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, number } from "../../common/Controls";
import { getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? JSON.parse(props.value.answer["306-3-b"]) : []);

    const columns: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: '306-3-b-i', label: 'Name of Spill', type: controlType.string, value: '', show: true, band: '', },
        { name: '306-3-b-ii', label: 'Oil Spill', type: controlType.string, value: '', show: true, band: '', },
        { name: '306-3-b-iii', label: 'Fuel Spill', type: controlType.string, value: '', show: true, band: '', },
        { name: '306-3-b-iv', label: 'Spill of Waste', type: controlType.string, value: '', show: true, band: '', },
        { name: '306-3-b-v', label: 'Spill of Chemicals', type: controlType.string, value: '', show: true, band: '', },
        { name: '306-3-b-vi', label: 'Others', type: controlType.string, value: '', show: true, band: '', },
    ]

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "306-3-b": JSON.stringify(a1),
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setA1(value)}
                    columns={columns}
                    obj={{ name: "", value: a1 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1JSON = answer ? JSON.parse(answer.answer["306-3-b"]) : [];
    
    return (
        <>
            <div style={{ overflow: 'auto' }} className="mb-3">
                <table className="table text-nowrap table-bordered border-primary">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Name of Spill
                            </th>
                            <th>
                                Oil Spill
                            </th>
                            <th>
                                Fuel Spill
                            </th>
                            <th>
                                Spill of Waste
                            </th>
                            <th>
                                Spill of Chemicals
                            </th>
                            <th>
                                Others
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            a1JSON.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            a1JSON.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td className="">{item['306-3-b-i'] ? item['306-3-b-i'] : '-'}</td>
                                    <td className="">{item['306-3-b-ii'] ? item['306-3-b-ii'] : '-'}</td>
                                    <td className="">{item['306-3-b-iii'] ? item['306-3-b-iii'] : '-'}</td>
                                    <td className="">{item['306-3-b-iv'] ? item['306-3-b-iv'] : '-'}</td>
                                    <td className="">{item['306-3-b-v'] ? item['306-3-b-v'] : '-'}</td>
                                    <td className="">{item['306-3-b-vi'] ? item['306-3-b-vi'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}