import Chart from "react-apexcharts";
import { getFormattedNumber } from "../../../utils";
import { min } from "moment";

export function Chart1() {
    const series = [{
        name: 'Renewable Energy',
        type: 'column',
        data: [10000, 12000, 15000, 20000, 15000, 18000]
    }, {
        name: 'Non-Renewable Energy',
        type: 'column',
        data: [20000, 19000, 19500, 21000, 40000, 45000]
    },
    {
        name: 'Energy Purchased',
        type: 'column',
        data: [40000, 38000, 35000, 38000, 38000, 37000]
    },
    {
        name: 'Emissions',
        type: 'line',
        data: [6000, 15000, 25000, 18000, 35000, 30000],
    },
    ]
    const options = {
        chart: {
            toolbar: {
                show: false
            },
            stacked: false,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: ['2018', '2019', '2020', '2021', '2022', '2023'],
        },
        yaxis: [{
            title: {
                text: "Energy Consumption (GJ)"
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        },
        ],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [1, 1, 1, 2.0],
            curve: 'straight',
            dashArray: [0, 0, 0, 4],
        },
        plotOptions: {
            bar: {
                columnWidth: "40%",
                borderRadiusApplication: "end",
                borderRadius: 5,                
            }
        },
        colors: ["rgba(20, 150, 241, 1.0)", "rgba(66, 182, 245, 1)", "rgba(15, 115, 198, 1.0)", "rgba(230, 83, 60, 0.8)"]
    };
    return (
        <div>
            <Chart
                options={options}
                series={series}
                width="100%"
                height="310"
            />
        </div>
    )
}

export function Chart2() {
    const series = [
        {
            data: [
                {
                    x: 'Galveston Bay',
                    y: 100
                },
                {
                    x: 'Beaumont',
                    y: 149
                },
                {
                    x: 'Port Arthur',
                    y: 120
                },
                {
                    x: 'Garyville',
                    y: 55
                },
                {
                    x: 'Baytown',
                    y: 84
                },
                {
                    x: 'Baton Rouge',
                    y: 40
                },
                {
                    x: 'Lake Charles',
                    y: 70
                },
                {
                    x: 'Los Angeles',
                    y: 42
                },
                {
                    x: 'Pascagoula',
                    y: 44
                },
                {
                    x: 'Whiting',
                    y: 43
                }
            ]
        }
    ]
    const options = {
        legend: {
            show: false
        },
        chart: {
            height: 350,
            type: 'treemap',
            toolbar: {
                show: false
            },
        },
        colors: [
            'rgba(15, 115, 198, 1.0)',
            'rgba(20, 150, 241, 1.0)',
            'rgba(66, 182, 245, 1.0)',
            'rgba(113, 202, 248, 1.0)',
            'rgba(166, 223, 252, 1.0)',
            'rgba(23, 175, 202, 1.0)',
            'rgba(67, 199, 217, 1.0)',
            'rgba(113, 215, 229, 1.0)',
            'rgba(54, 146, 243, 1.0)',
            'rgba(128, 189, 248, 1.0)',
        ],
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false
            }
        }
    };
    return (
        <div>
            <Chart
                options={options}
                series={series}
                type='treemap'
                width="100%"
                height="310"
            />
        </div>
    )
}

export function Chart3() {
    const series = [1754, 1234, 878, 800, 500, 270];
    var options = {
        chart: {
            type: "donut",
        },
        legend: {
            position: "bottom",
        },
        labels: ["Electricity", "Heating", "Cooling", "Steam", "Renewables", "Non-Renewables"],
        colors: [
            'rgba(15, 115, 198, 1.0)',
            'rgba(15, 115, 198, 0.8)',
            'rgba(20, 150, 241, 1.0)',
            'rgba(66, 182, 245, 1.0)',
            'rgba(113, 202, 248, 1.0)',
            'rgba(166, 223, 252, 1.0)',            
        ],
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
    };
    return <Chart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height="395"
    />
}

export function Chart4() {
    const series = [{
        name: 'Energy Intensity',
        data: [18, 14, 10, 9, 6, 4, 3, 3, 1, 1]
    }]
    const xaxis: any = [
        'Beaumont',
        'Galveston Bay',
        'Port Arthur',
        'Garyville',
        'Baytown',
        'Whiting',
        'Baton Rouge',
        'Lake Charles',
        'Los Angeles',
        'Pascagoula'
    ]
    const options = {
        chart: {
            stacked: false,
            toolbar: {
                show: false
            },
        },
        grid: {
            borderColor: '#f1f1f1',
            strokeDashArray: 3
        },
        colors: ["rgba(15, 115, 198, 0.9)"],
        plotOptions: {
            bar: {
                horizontal: "true",
                // columnWidth: "10%",
                barHeight: "50%",
                borderRadiusApplication: "end",
                borderRadius: 4,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis
        },
    };

    return (
        <div>
            <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
                height="350"
            />
        </div>
    )
}