import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Welcome from "./container/misc/Welcome";
import ESGDetail from "./container/esg/Detail";
import ComingSoon from "./container/misc/ComingSoon";
import FourZeroFour from "./container/misc/FourZeroFour";

// ghg 
import StationaryEmission from "./container/ghg/scope-1/StationaryEmission";
import MobileCombustionEmission from "./container/ghg/scope-1/MobileCombustion";
import FugutiveEmission from "./container/ghg/scope-1/FugutiveEmission";

import PurchasedElectricityEmission from "./container/ghg/scope-2/PurchasedElectricity";

import Category1Emission from "./container/ghg/scope-3/Category-1";
import Category2Emission from "./container/ghg/scope-3/Category-2";
import Category3Emission from "./container/ghg/scope-3/Category-3";
import Category4Emission from "./container/ghg/scope-3/Category-4";
import Category5Emission from "./container/ghg/scope-3/Category-5";
import Category6Emission from "./container/ghg/scope-3/Category-6";
import Category7Emission from "./container/ghg/scope-3/Category-7";
import Category8Emission from "./container/ghg/scope-3/Category-8";
// ghg 

//esg
import ESGChartTemplate from "./container/esg/ChartTemplate";

import ESGEnvironmentEnergyDashboard from "./container/esg/environment/dashboard/EnergyDashboard";
import ESGEnvironmentWasteDashboard from "./container/esg/environment/dashboard/WasteDashboard";
import ESGEnvironmentWaterDashboard from "./container/esg/environment/dashboard/WaterDashboard";

import ESGSocialDiversityDashboard from "./container/esg/social/dashboard/DiversityDashboard";
import ESGSocialEmployementDashboard from "./container/esg/social/dashboard/EmployementDashboard";
import ESGSocialOccupationalDashboard from "./container/esg/social/dashboard/OccupationalDashboard";

import ESGGovernanceSummaryDashboard from "./container/esg/governance/dashboard/SummaryDashboard";
// esg

import constant from "./constant";
import Wrapper from "./component/common/Wrapper";
import Login from "./container/auth/Login";
import Logout from "./container/auth/Logout";
import OrganisationEmissionDashboard from "./container/ghg/dashboard/OrganisationEmission";
import BranchEmissionDashboard from "./container/ghg/dashboard/BranchEmission";


// compliance
import ComplianceGRI from "./container/compliance/GRI";
// compliance

import OffsetMarketPlace from "./container/offset/marketplace/List";
import OffsetCart from "./container/offset/marketplace/Cart";
import OffsetCheckout from "./container/offset/marketplace/Checkout";
import OffsetPurchaseHistory from "./container/offset/marketplace/PurchaseHistory";
import OffsetFavourite from "./container/offset/marketplace/Favourite";
import OffsetDetail from "./container/offset/marketplace/Detail";

import DecarbonizationActionLibrary from "./container/decarbonization/ActionLibrary";
import DecarbonizationNetZeroGoalTracker from "./container/decarbonization/NetZeroGoalTracker";
import DecarbonizationNetZeroRoadmap from "./container/decarbonization/NetZeroRoadmap";
import EmissionDatabase from "./container/settings/EmissionDatabase";

function Router() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path="*" element={<FourZeroFour />} />

          {/* auth */}
          <Route path="/" element={<Login />} />
          <Route path={constant.component.login.url} element={<Login />} />
          <Route path={constant.component.logout.url} element={<Logout />} />
          {/* auth */}

          {/* misc */}
          <Route path={constant.component.welcome.url} element={<Welcome />} />
          <Route path={constant.component.comingSoon.url} element={<ComingSoon />} />
          {/* misc */}

          <Route path={constant.component.esgDetail.url} element={<ESGDetail />} />

          {/* ghg */}
          <Route path={constant.component.ghgStationaryEmission.url} element={<StationaryEmission />} />
          <Route path={constant.component.ghgMobileCombustion.url} element={<MobileCombustionEmission />} />
          <Route path={constant.component.ghgFugutiveEmission.url} element={<FugutiveEmission />} />

          <Route path={constant.component.ghgPurchasedElectricity.url} element={<PurchasedElectricityEmission />} />

          <Route path={constant.component.ghgCategory1Emission.url} element={<Category1Emission />} />
          <Route path={constant.component.ghgCategory2Emission.url} element={<Category2Emission />} />
          <Route path={constant.component.ghgCategory3Emission.url} element={<Category3Emission />} />
          <Route path={constant.component.ghgCategory4Emission.url} element={<Category4Emission />} />
          <Route path={constant.component.ghgCategory5Emission.url} element={<Category5Emission />} />
          <Route path={constant.component.ghgCategory6Emission.url} element={<Category6Emission />} />
          <Route path={constant.component.ghgCategory7Emission.url} element={<Category7Emission />} />
          <Route path={constant.component.ghgCategory8Emission.url} element={<Category8Emission />} />
          {/* ghg */}

          {/* compliance */}
          <Route path={constant.component.complianceGRI.url} element={<ComplianceGRI />} />
          {/* compliance */}

          {/* ghg dashboard */}
          <Route path={constant.component.ghgOrganisationEmissionDashboard.url} element={<OrganisationEmissionDashboard />} />
          <Route path={constant.component.ghgBranchEmissionDashboard.url} element={<BranchEmissionDashboard />} />
          {/* ghg dashboard */}

          {/* esg dashboard */}
          <Route path="/esg/template" element={<ESGChartTemplate />} />
          <Route path={constant.component.esgWasteDashboard.url} element={<ESGEnvironmentWasteDashboard />} />
          <Route path={constant.component.esgWaterDashboard.url} element={<ESGEnvironmentWaterDashboard />} />
          <Route path={constant.component.esgEnergyDashboard.url} element={<ESGEnvironmentEnergyDashboard />} />

          <Route path={constant.component.esgSocialDiversityDashboard.url} element={<ESGSocialDiversityDashboard />} />
          <Route path={constant.component.esgSocialEmployementDashboard.url} element={<ESGSocialEmployementDashboard />} />
          <Route path={constant.component.esgSocialOccupationalDashboard.url} element={<ESGSocialOccupationalDashboard />} />

          <Route path={constant.component.esgGovernanceSummaryDashboard.url} element={<ESGGovernanceSummaryDashboard />} />
          {/* esg dashboard */}

          {/* offset */}
          <Route path={constant.component.offsetMarketPlace.url} element={<OffsetMarketPlace />} />
          <Route path={constant.component.offsetCart.url} element={<OffsetCart />} />
          <Route path={constant.component.offsetCheckout.url} element={<OffsetCheckout />} />
          <Route path={constant.component.offsetPurchaseHistory.url} element={<OffsetPurchaseHistory />} />
          <Route path={constant.component.offsetFavourite.url} element={<OffsetFavourite />} />
          <Route path={constant.component.offsetDetail.url} element={<OffsetDetail />} />
          {/* offset */}

          {/* action library */}
          <Route path={constant.component.decarbonizationActionLibrary.url} element={<DecarbonizationActionLibrary />} />
          <Route path={constant.component.decarbonizationNetZeroGoalTracker.url} element={<DecarbonizationNetZeroGoalTracker />} />
          <Route path={constant.component.decarbonizationNetZeroRoadMap.url} element={<DecarbonizationNetZeroRoadmap />} />
          {/* action library */}

          {/* settings */}
          <Route path="/settings/emission-database" element={<EmissionDatabase />} />
          {/* settings */}



        </Routes>
      </Wrapper>
    </BrowserRouter>
  )
}

export default Router;