import { atom, } from 'recoil';

const locationList = [
    { id: 1, name: 'Golden Ridge Oil Field' },
    { id: 2, name: 'Silver Creek Oil Field' },
    { id: 3, name: 'Emerald Valley Oil Field' },
]

// const fuelTypeList = [
//     { id: 1, name: 'Petrol' },
//     { id: 2, name: 'Diesel' },
//     { id: 3, name: 'Gas' },
// ]

// const stationaryEquipmentType = [
//     { id: 1, name: 'XLNT 13000E  650KW  Generator' },
//     { id: 2, name: 'GENSET MODEL LP 320 (320 kVA)' },
//     { id: 3, name: 'Caterpillar 3516B' },
//     { id: 4, name: 'Portable Petrol Generator' },
//     { id: 5, name: 'XLNT 13000E 10KW Industrial Use Petrol Generator' },
//     { id: 6, name: 'Process Heater' },
//     { id: 7, name: 'Combined Heat and Power (CHP) Systems' },
//     { id: 8, name: 'Gas Generator' },
// ]

// const stationaryEmissionSource = [
//     { id: 1, name: 'Energy used in Facility' },
//     { id: 2, name: 'Material Handling and Storage' },
//     { id: 3, name: 'Packaging Machines' },
//     { id: 4, name: 'Surface Treatment' },
//     { id: 5, name: 'Welding and Metalworking' },
//     { id: 6, name: 'Testing and Quality Control' },
//     { id: 7, name: 'Drying Process' },
//     { id: 8, name: 'CHP System' },
// ]

const emissionFactorList = [
    { id: 1, name: 'Oil & Gas' },
    { id: 2, name: 'Goal' },
    { id: 3, name: 'Agriculture Aquaculture and Fishing' },
    { id: 4, name: 'Mining' },
]

const emissionSourceList = [
    { id: 1, name: 'Oil & Gas' },
    { id: 2, name: 'Goal' },
    { id: 3, name: 'Agriculture Aquaculture and Fishing' },
    { id: 4, name: 'Mining' },
]

export const emissionFactorListAtom = atom({
    key: 'emissionFactorListAtom',
    default: emissionFactorList
});

export const emissionSourceListAtom = atom({
    key: 'emissionSourceListAtom',
    default: emissionSourceList
});

export const locationListAtom = atom({
    key: 'locationListAtom',
    default: locationList
});

export const branchListAtom = atom({
    key: 'branchListAtom',
    default: locationList
});

export const activeBranchListAtom = atom({
    key: 'activeBranchListAtom',
    default: ''
});