import AddDetailButton from "../../component/decarbonization/net-zero-roadmap/AddDetailButton"
import EditDetailButton from "../../component/decarbonization/net-zero-roadmap/EditDetailButton"
import GridButton from "../../component/decarbonization/net-zero-roadmap/GridButton"
import HelpButton from "../../component/ghg/common/HelpButton";
import constant from "../../constant";
import { getFormattedFloat } from "../../utils"


function CreateRoadMap() {
    const title = constant.component.decarbonizationNetZeroRoadMap.title;
    const data = [
        {
            year: '2024-25',
            list: [
                { l1: '5.3', l2: 'Sustainable Transportation', l3: 'Scope 1' },
                { l1: '1.5 ', l2: 'Energy - Efficient Equipment', l3: 'Scope 2' },

            ]
        },
        {
            year: '2025-26',
            list: [
                { l1: '2', l2: 'e-Waste Management', l3: 'Scope 3' },
                { l1: '8', l2: 'Sustainable Transportation', l3: 'Scope 1,Scope 3' },
                { l1: '2.5', l2: 'Energy - Efficient Equipment', l3: 'Scope 2' },
            ]
        },
        {
            year: '2026-27',
            list: [
                { l1: '2', l2: 'e-Waste Management', l3: 'Scope 3' },
                { l1: '6', l2: 'Sustainable Transportation', l3: 'Scope 1,Scope 3' },
                { l1: '1.5', l2: 'Energy - Efficient Equipment', l3: 'Scope 2' },

                { l1: '6', l2: 'Waste-to-Energy Management', l3: 'Scope 3' },
                { l1: '25', l2: 'Instal Solar Plant of 30 MW', l3: 'Scope 2' },
                { l1: '450', l2: 'Carbon Capture Storage ', l3: 'Scope 1' },
            ]
        },
        {
            year: '2027-28',
            list: [
                { l1: '25', l2: 'Instal Solar Plant of 30 MW', l3: 'Scope 2' },
                { l1: '7', l2: 'Sustainable Transportation', l3: 'Scope 1,Scope 3' },
                { l1: '3', l2: 'Energy - Efficient Equipment', l3: 'Scope 2' },
                { l1: '2', l2: 'Waste Management', l3: 'Scope 3' },
                { l1: '8', l2: 'Install solar plant 10 MW', l3: 'Scope 2' },
                { l1: '20 ', l2: 'Affrostation ', l3: 'Carbon Credits' },
                { l1: '560', l2: 'Carbon Capture Storage ', l3: 'Scope 1' },
            ]
        },
        {
            year: '2028-29',
            list: [
                { l1: '25', l2: 'Instal Solar Plant of 30 MW', l3: 'Scope 2' },
                { l1: '8', l2: 'Sustainable Transportation', l3: 'Scope 1,Scope 3' },
                { l1: '4', l2: 'Energy - Efficient Equipment', l3: 'Scope 2' },
                { l1: '3', l2: 'Waste Management', l3: 'Scope 3' },
                { l1: '8', l2: 'Install solar plant 10 MW', l3: 'Scope 2' },
                { l1: '20 ', l2: 'Affrostation ', l3: 'Carbon Credits' },
                { l1: '850', l2: 'Carbon Capture Storage ', l3: 'Scope 1' },
            ]
        },

        {
            year: '2029-30',
            list: [
                { l1: '25', l2: 'Instal Solar Plant of 30 MW', l3: 'Scope 2' },
                { l1: '10', l2: 'Sustainable Transportation', l3: 'Scope 1,Scope 3' },
                { l1: '6', l2: 'Energy - Efficient Equipment', l3: 'Scope 2' },
                { l1: '7', l2: 'Waste Management', l3: 'Scope 3' },
                { l1: '8', l2: 'Install solar plant 10 MW', l3: 'Scope 2' },
                { l1: '20 ', l2: 'Affrostation ', l3: 'Carbon Credits' },
                { l1: '1500', l2: 'Carbon Capture Storage ', l3: 'Scope 1' },
            ]
        },
    ]

    return (
        <div className="container-fluid">
            <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                <h1 className="page-title fw-semibold fs-18 mb-0">{title}</h1>
                <div className="ms-md-1 ms-0">
                    <nav>
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="javascript:void(0);">Decarbonization</a></li>
                            <li className="breadcrumb-item active" aria-current="page">{title}</li>
                        </ol>
                    </nav>
                </div>
            </div>

            {renderCards()}


            {/* {renderHeader()} */}


            <div className="card custom-card">
                <div className="card-header">
                    <h6 className="mb-0">
                        GHG Reduction and Carbon Credits Roadmap
                    </h6>
                </div>
                <div className="card-body">

                    <div className="d-flex" style={{ overflowX: 'scroll' }}>

                        {
                            data.map((i: any, iIndex: any) => {
                                const total = i.list.reduce((acc: any, item: any) => acc + parseFloat(item.l1 || 0), 0
                                );
                                return <>
                                    <div className="d-flex m-2 p-3"
                                        style={{ flexDirection: 'column', border: '1px dashed #606f81' }}>
                                        <div className="text-center pb-3 mb-3" style={{ borderBottom: '1px dashed #606f81' }}>
                                            <b>{i.year}</b>
                                            <div className="">
                                                Expected Emission Reduction: {getFormattedFloat(total)}
                                            </div>
                                        </div>
                                        <div className="d-flex" style={{ flexDirection: 'column' }}>
                                            {
                                                i.list.map((j: any, jIndex: any) => {
                                                    const arr = j.l3.split(',');
                                                    const arrRender = arr.map((item: any) => {
                                                        // let css = "me-3 badge fw-semi-bold rounded-pill status ";
                                                        let css = "me-3 ";
                                                        if (item.indexOf('1') > -1) {
                                                            css += "text-danger";
                                                        } else if (item.indexOf('2') > -1) {
                                                            css += "text-warning";
                                                        } else if (item.indexOf('3') > -1) {
                                                            css += "text-info";
                                                        } else {
                                                            css += "text-primary";
                                                        }
                                                        return <span className={css}>{item}</span>
                                                    })

                                                    return (
                                                        <div className="mb-3 text-center" style={{
                                                            borderBottom: jIndex === i.list.length ? '' : '1px dashed',
                                                            paddingBottom: jIndex === i.list.length ? '0rem' : '1rem',
                                                            marginBottom: jIndex === i.list.length ? '0rem' : '1rem',
                                                            width: 250
                                                        }}>
                                                            <div>
                                                                <>{j.l1} tonnes</>
                                                                <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                                    <span className="ri-edit-line"></span>
                                                                </EditDetailButton>
                                                            </div>
                                                            <div className="text-primary">{j.l2}</div>
                                                            <div className="d-block">
                                                                <>{arrRender}</>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                </>
                            })
                        }

                    </div>

                </div>
            </div>
        </div>
    )

    function renderCards() {
        const c1 = `Execution Year,Action Category,Action Name,Location,Scope Category,Total Reduction(t) Projection,Yearly Reduction Projection(t)
       2024-25,Sustainable Travel,Sustainable Transportation - Company Owned Vehicles,East Delhi,Mobile Combustion,110,5.3
       2024-25,Sustainable Travel,Sustainable Transportation - Company Owned Vehicles,South Delhi,Mobile Combustion,110,5.3
       2024-25,Sustainable Travel,Sustainable Transportation - Company Owned Vehicles,South-west Delhi,Mobile Combustion,110,5.3
       2024-25,Carbon Capture Storage,Carbon Capture Storage,North Delhi,Fugitive Emissions,800,100
       2025-26,Sustainable Travel,Sustainable Transportation - Company Owned Vehicles,East Delhi,Mobile Combustion,110,7
       2025-26,Carbon Capture Storage,Carbon Capture Storage,South Delhi,Fugitive Emissions,800,250
       2026-27,Carbon Capture Storage,Carbon Capture Storage,South-west Delhi,Fugitive Emissions,800,380
       2027-28,Sustainable Travel,Sustainable Transportation - Company Owned Vehicles,North Delhi,Mobile Combustion,110,12
       2027-28,Sustainable Travel,Sustainable Transportation - Company Owned Vehicles,North Delhi,Mobile Combustion,110,14
       2027-28,Carbon Capture Storage,Carbon Capture Storage,North Delhi,Fugitive Emissions,800,650`;
        const c2 = `Execution Year,Action Category,Action Name,Location,Scope Category,Total Reduction(t) Projection,Yearly Reduction Projection(t)
       2024-25,Energy Efficient,Energy - Efficient Equipment - Upgrade to 5 Star Equipment,East Delhi,Electricity,200,20
       2024-25,Energy Efficient,Energy - Efficient Equipment - Upgrade to 5 Star Equipment,South Delhi,Electricity,200,25
       2024-25,Renewable Energy,Wind Energy Plant - 1000 MegaWatts,South-west Delhi,Electricity,1000,800
       2024-25,Renewable Energy,Solar - 100 Megawatts,North Delhi,Electricity,800,700
       2025-26,Renewable Energy,Wind Energy Plant - 1000 MegaWatts,East Delhi,Electricity,1000,800
       2025-26,Renewable Energy,Solar - 100 Megawatts,South Delhi,Electricity,800,250
       2026-27,Energy Efficient,Energy - Efficient Equipment - Upgrade to 5 Star Equipment,South-west Delhi,Electricity,800,380
       2027-28,Renewable Energy,Wind Energy Plant - 1000 MegaWatts,North Delhi,Electricity,200,800
       2027-28,Renewable Energy,Solar - 100 Megawatts,North Delhi,Electricity,200,700
       2027-28,Renewable Energy,Solar - 50 Megawatts,North Delhi,Electricity,800,500`;
        const c3 = `Execution Year,Action Category,Action Name,Location,Scope Category,Total Reduction(t) Projection,Yearly Reduction Projection(t)
       2024-25,Waste Management,Recycling E- Waste,East Delhi,Waste Generated in Operations,100,30
       2024-25,Sustainabile Travel,Using Rail travel over Air,South Delhi,Business Travel,150,31
       2024-25,Sustainabile Travel,Employees Commuting through Electric Bus,South-west Delhi,Employee Commuting,200,32
       2024-25,Waste Management,Recycling E- Waste,North Delhi,Waste Generated in Operations,100,9
       2025-26,Sustainabile Travel,Employees Commuting through Electric Bus,East Delhi,Employee Commuting,150,7
       2025-26,Waste-to-Energy,Waste-to-Energy,South Delhi,End of Life Treatment,200,25
       2026-27,Sustainabile Travel,Using Rail travel over Air,South-west Delhi,Business Travel,100,38
       2027-28,Waste Management,Recycling E- Waste,North Delhi,Waste Generated in Operations,150,12
       2027-28,Sustainabile Travel,Employees Commuting through Electric Bus,North Delhi,Employee Commuting,200,14
       2027-28,Waste Management,Waste-to-Energy,North Delhi,End of Life Treatment,200,10`;

        return (
            <>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="card custom-card overflow-hidden">
                            <div className="card-body">
                                <div className="row gap-3 gap-sm-0">
                                    <div className="col-sm-8 col-12">
                                        <div className="">
                                            <h4 className="fw-semibold mb-2">Net Zero  <span className="text-primary">Roadmap</span></h4>
                                            <p className="mb-4 text-muted fs-14 op-7"> The Net Zero Roadmap outlines a strategic plan to achieve net-zero emissions while ensuring stable and affordable energy supplies, universal energy access, and economic growth. </p>
                                            <div className="btn-list pt-1">
                                                <AddDetailButton css="btn btn-primary btn-wave m-1 waves-effect waves-light" onSubmitComplete={() => { }}>
                                                    Add Action to Roadmap
                                                </AddDetailButton>
                                                <HelpButton css="btn btn-outline-primary btn-wave m-1 waves-effect waves-light">Help</HelpButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-auto my-auto">
                                        <div className="featured-nft">
                                            <img style={{ objectFit: "cover" }} src="https://www.sclubricants.com/wp-content/uploads/2018/07/Decarbonization.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-6">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                            </div>
                                            <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                <div className="mb-2">Total Expected Reduction by 2030 (tCO2e)</div>
                                                <div className="text-muted mb-1 fs-12">
                                                    <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 2,000 </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                            </div>
                                            <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                <div className="mb-2">Scope 1 Expected Reduction by 2030 (tCO2e)</div>
                                                <div className="text-muted mb-1 fs-12">
                                                    <GridButton css="btn btn-link p-0" csv={c1} title="Scope 1 Action Plan">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 800 </span>
                                                    </GridButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                            </div>
                                            <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                <div className="mb-2">Scope 2 Expected Reduction by 2030 (tCO2e)</div>
                                                <div className="text-muted mb-1 fs-12">
                                                    <GridButton css="btn btn-link p-0" csv={c2} title="Scope 2 Action Plan">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 500 </span>
                                                    </GridButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                            </div>
                                            <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                <div className="mb-2">Scope 3 Expected Reduction by 2030 (tCO2e)</div>
                                                <div className="text-muted mb-1 fs-12">
                                                    <GridButton css="btn btn-link p-0" csv={c3} title="Scope 3 Action Plan">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 700 </span>
                                                    </GridButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CreateRoadMap