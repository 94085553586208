import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? props.value.answer["401-1-b-a1"] : '');

    const [a2, setA2] = useState(props.value ? props.value.answer["401-1-b-a2"] : '');
    const [a3, setA3] = useState(props.value ? props.value.answer["401-1-b-a3"] : '');
    const [a4, setA4] = useState(props.value ? props.value.answer["401-1-b-a4"] : '');
    const [a5, setA5] = useState(props.value ? props.value.answer["401-1-b-a5"] : '');
    const [a6, setA6] = useState(props.value ? props.value.answer["401-1-b-a6"] : '');
    const [a7, setA7] = useState(props.value ? props.value.answer["401-1-b-a7"] : '');

    const [a8, setA8] = useState(props.value ? props.value.answer["401-1-b-a8"] : '');
    const [a9, setA9] = useState(props.value ? props.value.answer["401-1-b-a9"] : '');
    const [a10, setA10] = useState(props.value ? props.value.answer["401-1-b-a10"] : '');
    const [a11, setA11] = useState(props.value ? props.value.answer["401-1-b-a11"] : '');
    const [a12, setA12] = useState(props.value ? props.value.answer["401-1-b-a12"] : '');
    const [a13, setA13] = useState(props.value ? props.value.answer["401-1-b-a13"] : '');

    const [a14, setA14] = useState(props.value ? props.value.answer["401-1-b-a14"] : '');

    const columns: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: '401-1-b14-i', label: 'Region', type: controlType.string, value: '', show: true, band: '', },
        { name: '401-1-b14-ii', label: 'Number of Total Employee Turnover', type: controlType.string, value: '', show: true, band: '', },
        { name: '401-1-b14-iii', label: 'Rate of Employee Turnover', type: controlType.string, value: '', show: true, band: '', },
    ]

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "401-1-b-a1": a1,

                "401-1-b-a2": a2,
                "401-1-b-a3": a3,
                "401-1-b-a4": a4,
                "401-1-b-a5": a5,
                "401-1-b-a6": a6,
                "401-1-b-a7": a7,

                "401-1-b-a8": a8,
                "401-1-b-a9": a9,
                "401-1-b-a10": a10,
                "401-1-b-a11": a11,
                "401-1-b-a12": a12,
                "401-1-b-a13": a13,

                "401-1-b-a14": a14,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-3">Total number and rate of new employee hires</label>
                <div className="input-group mb-3">
                    {number({ name: '', value: a1, onChange: (a: any, b: any) => setA1(b) })}
                    {/* <span className="input-group-text">kg/multiple</span> */}
                </div>
            </div>
            <div className="fw-bold">
                Provide details of total number of new employee hires as categorised below
            </div>
            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Age Group</th>
                            <th>Number of Total Employee Turnover</th>
                            <th>Rate of Employee Turnover</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Under 30 years old </td>
                            <td>
                                {number({ name: '', value: a2, onChange: (a: any, b: any) => { setA2(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a3, onChange: (a: any, b: any) => { setA3(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>30-50 years  old</td>
                            <td>
                                {number({ name: '', value: a4, onChange: (a: any, b: any) => { setA4(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a5, onChange: (a: any, b: any) => { setA5(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Over 50 years old</td>
                            <td>
                                {number({ name: '', value: a6, onChange: (a: any, b: any) => { setA6(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a7, onChange: (a: any, b: any) => { setA7(b) } })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Gender</th>
                            <th>Number of Total Employee Turnover</th>
                            <th>Rate of Employee Turnover</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Male </td>
                            <td>
                                {number({ name: '', value: a8, onChange: (a: any, b: any) => { setA8(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a9, onChange: (a: any, b: any) => { setA9(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Female</td>
                            <td>
                                {number({ name: '', value: a10, onChange: (a: any, b: any) => { setA10(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a11, onChange: (a: any, b: any) => { setA11(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Transgender</td>
                            <td>
                                {number({ name: '', value: a12, onChange: (a: any, b: any) => { setA12(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a13, onChange: (a: any, b: any) => { setA13(b) } })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setA14(value)}
                    columns={columns}
                    obj={{ name: "", value: a14 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1JSON = answer ? answer.answer["401-1-b-a1"] : '';

    const a2JSON = answer ? answer.answer["401-1-b-a2"] : '';
    const a3JSON = answer ? answer.answer["401-1-b-a3"] : '';
    const a4JSON = answer ? answer.answer["401-1-b-a4"] : '';
    const a5JSON = answer ? answer.answer["401-1-b-a5"] : '';
    const a6JSON = answer ? answer.answer["401-1-b-a6"] : '';
    const a7JSON = answer ? answer.answer["401-1-b-a7"] : '';

    const a8JSON = answer ? answer.answer["401-1-b-a"] : '';
    const a9JSON = answer ? answer.answer["401-1-b-a9"] : '';
    const a10JSON = answer ? answer.answer["401-1-b-a10"] : '';
    const a11JSON = answer ? answer.answer["401-1-b-a11"] : '';
    const a12JSON = answer ? answer.answer["401-1-b-a12"] : '';
    const a13JSON = answer ? answer.answer["401-1-b-a13"] : '';

    // const a14JSON = answer ? answer.answer["401-1-b-a14"] : '';
    const a14JSON = answer ? JSON.parse(answer.answer["401-1-b-a14"]) : [];
    return (
        <>
            <div className="mb-3">
                <label className="fw-bold mb-3">Total number and rate of new employee hires</label>
                {a1JSON ? getFormattedFloat(a1JSON) : '-'}
            </div>
            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Age Group</th>
                            <th>Number of Total Employee Turnover</th>
                            <th>Rate of Employee Turnover</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Under 30 years old </td>
                            <td>
                                {a2JSON ? getFormattedFloat(a2JSON) : '-'}
                            </td>
                            <td>
                                {a3JSON ? getFormattedFloat(a3JSON) : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>30-50 years  old</td>
                            <td>
                                {a4JSON ? getFormattedFloat(a4JSON) : '-'}
                            </td>
                            <td>
                                {a5JSON ? getFormattedFloat(a5JSON) : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>Over 50 years old</td>
                            <td>
                                {a6JSON ? getFormattedFloat(a6JSON) : '-'}
                            </td>
                            <td>
                                {a7JSON ? getFormattedFloat(a7JSON) : '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>


            </div>
            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Gender</th>
                            <th>Number of Total Employee Turnover</th>
                            <th>Rate of Employee Turnover</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Male </td>
                            <td>
                                {a8JSON ? getFormattedFloat(a8JSON) : '-'}
                            </td>
                            <td>
                                {a9JSON ? getFormattedFloat(a9JSON) : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>Female</td>
                            <td>
                                {a10JSON ? getFormattedFloat(a10JSON) : '-'}
                            </td>
                            <td>
                                {a11JSON ? getFormattedFloat(a11JSON) : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>Transgender</td>
                            <td>
                                {a12JSON ? getFormattedFloat(a12JSON) : '-'}
                            </td>
                            <td>
                                {a13JSON ? getFormattedFloat(a13JSON) : '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="">
                <div style={{ overflow: 'auto' }} className="mb-3">
                    {/* { name: '401-1-b14-i', label: 'Region', type: controlType.string, value: '', show: true, band: '', },
        { name: '401-1-b14-ii', label: 'Number of Total Employee Turnover', type: controlType.string, value: '', show: true, band: '', },
        { name: '401-1-b14-iii', label: 'Rate of Employee Turnover', type: controlType.string, value: '', show: true, band: '', }, */}
                    <table className="table text-nowrap table-bordered border-primary">
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Region
                                </th>
                                <th>
                                    Number of Total Employee Turnover
                                </th>
                                <th>
                                    Rate of Employee Turnover
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                a14JSON.length === 0 && <tr><td colSpan={10} className="text-center">
                                    No Records Found
                                </td></tr>
                            }
                            {
                                a14JSON.map((item: any, index: number) => {
                                    return <tr>
                                        <td>{index + 1}</td>
                                        <td className="">{item['401-1-b14-i'] ? item['401-1-b14-i'] : '-'}</td>
                                        <td className="">{item['401-1-b14-ii'] ? item['401-1-b14-ii'] : '-'}</td>
                                        <td className="">{item['401-1-b14-iii'] ? item['401-1-b14-iii'] : '-'}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}