import DataGrid, { Toolbar, Item, Column, Grouping, GroupPanel, Paging, SearchPanel, ColumnChooser, HeaderFilter, Selection, Export, FilterPanel, FilterRow } from 'devextreme-react/data-grid';

function EmissionDatabase() {
    const _data = [
        {
            "category": 5,
            "subCategory": "Aug'23 2023",
            "name": "Central Station",
            "uom": "Material Handling and Storage",
            "description": "Material Handling and Storage",
            "attr1": "GENSET MODEL LP 320 (320 kVA)",
            "attr2": "Material Handling and Storage",
            "attr3": "Material Handling and Storage",
            "year": "Material Handling and Storage",
            "region": "Material Handling and Storage",
            "CO2": "Material Handling and Storage",
            "CH4": "Material Handling and Storage",
            "N2O": "Material Handling and Storage",
            "CO2e": "Material Handling and Storage",
            "factorSet": "Material Handling and Storage",
            "status": "Material Handling and Storage",
            "referenceSource": "Material Handling and Storage",
        }
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Emission Factors Database</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Settings</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Emission Database</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between">
                                <div className="card-title">
                                    Emission Factors Database
                                </div>
                                <div className="d-flex">
                                    <div className="me-3">
                                        <button aria-label="button" type="button" className="btn btn-sm btn-primary">
                                            <i className="ri-add-line me-1 d-inline-block align-middle"></i>Add Custom Emission Factor
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="ff-default">
                                    <DataGrid
                                        dataSource={_data}
                                        allowColumnResizing={true}
                                        allowColumnReordering={true}
                                        rowAlternationEnabled={true}
                                        showBorders={true}
                                        showColumnLines={true}
                                        showRowLines={true}
                                    >
                                        <Toolbar>
                                            <Item name="groupPanel" />
                                            <Item name="exportButton" />
                                            <Item name="columnChooserButton" />
                                            <Item name="searchPanel" />
                                        </Toolbar>
                                        <HeaderFilter visible={true} />
                                        <ColumnChooser enabled={true} />
                                        <GroupPanel visible={true} />
                                        <SearchPanel visible={true}/>
                                        <Grouping autoExpandAll={false} />
                                        <Export enabled={true} allowExportSelectedData={true} />
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmissionDatabase;