import moment from "moment";
import { useState } from "react";
import { Table, controlType } from "../../common/Controls";
import { getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [remarks, setRemarks] = useState(props.value ? props.value.answer["305-1-a-remarks"] : '');

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "305-1-a-remarks": remarks,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-3">Gross Direct GHG Emission</label>
                <input className="form-control" onChange={(e: any) => setRemarks(e.target.value)} value={remarks} />
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const answerJSON2 = answer ? answer.answer["305-1-a-remarks"] : '';
    return (
        <>
            {
                <div className="">
                    <label className="fw-bold mb-1">Gross Direct GHG Emission</label>
                    <div className="">
                        {
                            answerJSON2 ? answerJSON2 : '-'
                        }
                    </div>
                </div>
            }
        </>
    )
}