import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, input, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? props.value.answer["404-3-a-a1"] : '');
    const [a2, setA2] = useState(props.value ? props.value.answer["404-3-a-a2"] : '');
    const [a3, setA3] = useState(props.value ? props.value.answer["404-3-a-a3"] : '');

    const [a4, setA4] = useState(props.value ? JSON.parse(props.value.answer["404-3-a-a4"]) : []);

    const columns: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: '404-3-a4-i', label: 'By Employee Category', type: controlType.string, value: '', show: true, band: '', },
        { name: '404-3-a4-ii', label: '% of Total Employees Received a Regular Performance and Career Development', type: controlType.string, value: '', show: true, band: '', },
    ]

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "404-3-a-a1": a1,
                "404-3-a-a2": a2,
                "404-3-a-a3": a3,
                "404-3-a-a4": JSON.stringify(a4)
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>By Gender</th>
                            <th>% of Total Employees Received a Regular Performance and Career Development</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Male </td>
                            <td>
                                {input({ name: '', value: a1, onChange: (a: any, b: any) => { setA1(b) } })}
                            </td>

                        </tr>
                        <tr>
                            <td>Female</td>
                            <td>
                                {input({ name: '', value: a2, onChange: (a: any, b: any) => { setA2(b) } })}
                            </td>

                        </tr>
                        <tr>
                            <td>Transgender</td>
                            <td>
                                {input({ name: '', value: a3, onChange: (a: any, b: any) => { setA3(b) } })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setA4(value)}
                    columns={columns}
                    obj={{ name: "", value: a4 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1JSON = answer ? answer.answer["404-3-a-a1"] : '';

    const a2JSON = answer ? answer.answer["404-3-a-a2"] : '';
    const a3JSON = answer ? answer.answer["404-3-a-a3"] : '';
    
    const a4JSON = answer ? JSON.parse(answer.answer["404-3-a-a4"]) : [];
    return (
        <>
            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>By Gender</th>
                            <th>Average Training Hours</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Male </td>
                            <td>
                                {a1JSON ? (a1JSON) : '-'}
                            </td>

                        </tr>
                        <tr>
                            <td>Female</td>
                            <td>
                                {a2JSON ? (a2JSON) : '-'}
                            </td>

                        </tr>
                        <tr>
                            <td>Transgender</td>
                            <td>
                                {a3JSON ? (a3JSON) : '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb-3">
                {/* { name: '404-3-a4-i', label: 'By Employee Category', type: controlType.string, value: '', show: true, band: '', },
        { name: '404-3-a4-ii', label: 'Average Trainin Hours', type: controlType.string, value: '', show: true, band: '', }, */}
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>By Employee Category</th>
                            <th>Average Trainin Hours</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            a4JSON.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            a4JSON.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td className="">{item['404-3-a4-i'] ? item['404-3-a4-i'] : '-'}</td>
                                    <td className="">{item['404-3-a4-ii'] ? item['404-3-a4-ii'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

        </>
    )
}