import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import { useContext, useEffect } from "react";
import { UIContext } from "../../context/UIContext";

function Wrapper(props: any) {
    const { showSideBar, showHeader }: any = useContext(UIContext);

    useEffect(() => {
        const scriptDefaultMenu = document.createElement('script');
        scriptDefaultMenu.src = "/assets/js/defaultmenu.min.js";
        scriptDefaultMenu.async = false;
        document.body.appendChild(scriptDefaultMenu);

        const scriptSimpleBarLib = document.createElement('script');
        scriptSimpleBarLib.src = "/assets/libs/simplebar/simplebar.min.js";
        scriptSimpleBarLib.async = false;
        document.body.appendChild(scriptSimpleBarLib);

        const scriptSimpleBar = document.createElement('script');
        scriptSimpleBar.src = "/assets/js/simplebar.js";
        scriptSimpleBar.async = false;
        document.body.appendChild(scriptSimpleBar);

        return () => {
            document.body.removeChild(scriptDefaultMenu);
            document.body.removeChild(scriptSimpleBarLib);
            document.body.removeChild(scriptSimpleBar);
        }
    }, []);
    return (
        <div className="page">
            {
                showHeader && <Header />
            }
            {
                showSideBar && <Sidebar />
            }
            <div className={showSideBar ? "main-content app-content" : ""}>
                {props.children}
            </div>
            <Footer showSideBar={showSideBar} />
        </div>
    )
}

export default Wrapper