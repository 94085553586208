import Chart from "react-apexcharts";
import constant from "../../../constant";
import Shimmer from "../../common/Shimmer";
import { getFormattedFloat, getFormattedNumber } from "../../../utils";


export function Chart1(props: any) {
    const series =
        [{
            name: '<30 Years',
            data: [
                4555.398,
                6412.288,
                6591.584,
                7330.05,
            ]
        },
        {
            name: '30-49 Years',
            data: [
                4284.132,
                5662.464,
                6534.75,
                7981.61
            ]
        },
        {
            name: '>49 Years',
            data: [
                514.47,
                866.176,
                1079.656,
                977.34
            ]
        },
        ]
    const xaxis = ['2020', '2021', '2022', '2023'];
    const yLeftTitle = "";
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)", "rgba(20, 150, 241, 1.0)"];
    const options = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: yLeftTitle
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [1, 1, 1],
        },
        plotOptions: {
            bar: {
                columnWidth: "40%",
                borderRadiusApplication: "end",
                borderRadius: 5
            },

        },
        colors: colors
    };
    return (
        <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="300"
        />
    )
}

export function Chart2(props: any) {
    const series =
        [{
            name: 'Deaths',
            data: [
                10109,
                13132,
                12607,
                13987
            ]
        },
        {
            name: 'Dismissals',
            data: [
                2888,
                1982,
                1775,
                2109,
            ]
        },
        {
            name: 'Resignation',
            data: [
                3856,
                6223,
                9241,
                11003
            ]
        },
        {
            name: 'Contract termination by mutual agreement',
            data: [
                3231,
                4821,
                1495,
                763
            ]
        },
        ]
    const xaxis = ['2020', '2021', '2022', '2023'];
    const yLeftTitle = "";
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)", "rgba(20, 150, 241, 1.0)"];
    const options = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: yLeftTitle
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [1, 1, 1],
        },
        plotOptions: {
            bar: {
                columnWidth: "40%",
                borderRadiusApplication: "end",
                borderRadius: 5
            },

        },
        colors: colors
    };
    return (
        <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="300"
        />
    )
}

export function Chart3() {
    const series = [
        63.3,
        10.4,
        6.0,
        13.1,
        6.5,
        0.7
    ]
    const labels = [
        'Europe',
        'Africa',
        'North America',
        'Latin America',
        'Asia-Pacific',
        'Middle East'
    ]
    var options = {
        chart: {
            height: "280",
            type: "pie",
        },
        labels,
        theme: {
            monochrome: {
                enabled: true,
                color: "#0f73c6",
            },
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -5,
                },
            },
        },
        // title: {
        //     // text: "Monochrome Pie",
        //     align: "left",
        //     style: {
        //         fontSize: "13px",
        //         fontWeight: "bold",
        //         color: "#8c9097",
        //     },
        // },
        dataLabels: {
            formatter(val: any, opts: any) {
                const name = opts.w.globals.labels[opts.seriesIndex];
                return [name, val.toFixed(1) + "%"];
            },
            dropShadow: {
                enabled: true,
            },
        },
        legend: {
            show: true,
        },
    };
    return <Chart
        options={options}
        series={series}
        type="pie"
        width="100%"
        height="300"
    />
}

export function Chart4(props: any) {
    const series: any = [
        {
            name: 'Attendance',
            type: 'column',
            data: [82, 87, 88, 91]
        },
        // {
        //     name: 'Women',
        //     type: 'column',
        //     data: [12, 11.5, 12.5, 14]
        // },
        {
            name: 'Rating',
            type: 'line',
            data: [7, 7.3, 7.9, 7.7]
        },
    ];
    const xaxis = ['2020', '2021', '2022', '2023'];
    const options = {
        chart: {
            toolbar: {
                show: false
            },
            stacked: false,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: "Attendance"
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value) + '%';
                }
            },
            min: props.yLeftMin,
            max: props.yLeftMax,
        },
            ,
        {
            opposite: true,
            title: {
                text: "Survey Rating (out ot 10)"
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
            min: 6.4,
            max: 8.4
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            offsetX: 40,
            fontSize: '13px',
            fontWeight: 'normal',
            labels: {
                colors: '#acb1b1',
            },
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 1.5],
            curve: 'straight',
            dashArray: [0, 5],
        },
        plotOptions: {
            bar: {
                columnWidth: "10%",
                borderRadiusApplication: "end",
                borderRadius: 5,
            }
        },
        colors: ["#0f73c6", "rgba(230, 83, 60, 0.8)"]
    };
    return (
        <div>
            <Chart
                options={options}
                series={series}
                width="100%"
                height="310"
            />
        </div>
    )
}