import { getFormattedFloat } from "../../../utils";

export default function StatsCard(props: any) {
    const { pending, approved, rejected } = props;
    return (
        <div className="row">
            <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                <div className="card custom-card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                <span className="rounded p-3 bg-primary-transparent">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z" /></g></svg>
                                </span>
                            </div>
                            <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                <div className="mb-2">Total Emissions (kgCO2e)</div>
                                <div className="text-muted mb-1 fs-12">
                                    <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                        {getFormattedFloat(pending + approved + rejected)}
                                    </span>
                                </div>
                                {/* <div>
                                <span className="fs-12 mb-0">Increase by <span className="badge bg-success-transparent text-success mx-1">+4.2%</span> this month</span>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                <div className="card custom-card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                <span className="rounded p-3 bg-warning-transparent">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-white warning" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z" /></g></svg>
                                </span>
                            </div>
                            <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                <div className="mb-2">Total Pending Emissions (kgCO2e)</div>
                                <div className="text-muted mb-1 fs-12">
                                    <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                        {getFormattedFloat(pending)}
                                    </span>
                                </div>
                                {/* <div>
                                <span className="fs-12 mb-0">Increase by <span className="badge bg-success-transparent text-success mx-1">+4.2%</span> this month</span>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                <div className="card custom-card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                <span className="rounded p-3 bg-danger-transparent">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-white bg-danger" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z" /></g></svg>
                                </span>
                            </div>
                            <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                <div className="mb-2">Total Rejected Emissions (kgCO2e)</div>
                                <div className="text-muted mb-1 fs-12">
                                    <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                        {getFormattedFloat(rejected)}
                                    </span>
                                </div>
                                {/* <div>
                                <span className="fs-12 mb-0">Increase by <span className="badge bg-success-transparent text-success mx-1">+4.2%</span> this month</span>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                <div className="card custom-card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">

                                <span className="rounded p-3 bg-success-transparent">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-white success" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z" /></g></svg>
                                </span>
                            </div>
                            <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                <div className="mb-2">Total Approved Emissions (kgCO2e)</div>
                                <div className="text-muted mb-1 fs-12">
                                    <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                        {getFormattedFloat(approved)}
                                    </span>
                                </div>
                                {/* <div>
                                <span className="fs-12 mb-0">Increase by <span className="badge bg-success-transparent text-success mx-1">+4.2%</span> this month</span>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}