import React, { useState } from "react"
import { useRecoilValue, useRecoilState } from 'recoil';
import { answerListAtom, questionListAtom } from '../../../atom/ESGAtom';
import ModalWindow from "../../common/ModalWindow";

interface IPropType {
    questionId: any,
    customFormId: any,
    children: any,
    css: any,
    onSubmitComplete: any,
}

function CustomFormButton(props: IPropType) {
    const questionList = useRecoilValue(questionListAtom);
    const [answerList, setAnswerList] = useRecoilState(answerListAtom);

    const [showModal, setShowModal] = useState(false);
    const [question, setQuestion] = useState(null);
    const [answer, setAnswer] = useState(null);
    const { css } = props;

    const onShowModal = () => {
        setShowModal(true);
        const questionDetail = questionList.find((i: any) => i.id === props.questionId);
        setQuestion(questionDetail);

        const answerDetail = answerList.find((i: any) => i.questionId === props.questionId);
        setAnswer(answerDetail);
    }

    const onHideModal = () => {
        setShowModal(false);
        setQuestion(null);
        setAnswer(null);
    }

    const onSubmitComplete = (detail: any) => {
        console.log(JSON.stringify(detail));
        const clonedAnswerList = JSON.parse(JSON.stringify(answerList));
        let matchFound = false;

        for (let i = 0; i < clonedAnswerList.length; i++) {
            if (clonedAnswerList[i].questionId === detail.questionId) {
                clonedAnswerList[i] = detail;
                matchFound = true;
                break;
            }
        }
        if (!matchFound) {
            clonedAnswerList.push(detail);
        }
        setAnswerList(clonedAnswerList);
        onHideModal();
        props.onSubmitComplete && props.onSubmitComplete();
    }

    return (
        <React.Fragment>
            {
                showModal && renderModal()
            }
            <button type="button" className={css} onClick={onShowModal}>
                {props.children}
            </button>
        </React.Fragment>
    )

    function renderModal() {
        let css = "modal-xl";
        const formComponent = require(`./${props.customFormId}`);

        return (
            <ModalWindow
                title="Detail"
                backdrop="static"
                css={css}
                onHideModal={onHideModal}
            >
                <formComponent.Editor
                    question={question}
                    value={answer}
                    onSubmitComplete={onSubmitComplete}
                    onCancel={onHideModal}
                />
            </ModalWindow>
        )
    }
}

export default CustomFormButton;