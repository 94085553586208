import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Router from './router';
import { RecoilRoot } from 'recoil';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { getLanguageSettings, setStorage } from './utils';
import { UIContextProvider } from './context/UIContext';
import constant from './constant';
const { lang } = getLanguageSettings();
i18n.changeLanguage(lang);

const ud = { name: 'John Doe', id: 1 }
setStorage(constant.key.userDetail, ud);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <RecoilRoot>
        <UIContextProvider>
          <Router />
        </UIContextProvider>
      </RecoilRoot>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();