import React, { useState } from "react"
// import { deleteDetail } from "../../action/StationaryCombustionAction";
import Loader from "../../../common/Loader";

interface IPropType {
    id: any,
    children: any,
    css: any,
    onSubmitComplete: any,
}

function DeleteDetailButton(props: IPropType) {
    const [showLoader, setShowLoader] = useState(false);
    const { css, id } = props;

    const onDeleteDetail = () => {
        const isSure = window.confirm('Are you sure, you want to delete this record?')
        if (isSure) {
            setShowLoader(true);
            // deleteDetail(id).then((res: any) => {
            //     setShowLoader(false);
            //     if (res.result) {
            //         props.onSubmitComplete(res.result);
            //     }
            // })
        }
    }


    return (
        <React.Fragment>
            {
                showLoader && <Loader />
            }
            <button type="button" className={css} onClick={onDeleteDetail}>
                {props.children}
            </button>
        </React.Fragment>
    )
}

export default DeleteDetailButton;