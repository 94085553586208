import { useEffect, useState } from "react";
import AddDetailButton from "../../component/ghg/emission/stationary-emission/AddDetailButton";
import constant from "../../constant";
import Shimmer from "../../component/common/Shimmer";
import Grid from "../../component/ghg/emission/stationary-emission/Grid";
import StatsCard from "../../component/ghg/common/StatsCard";
import BulkUploadButton from "../../component/ghg/common/BulkUploadButton";
import HelpButton from "../../component/ghg/common/HelpButton";

function Home() {
    const title = constant.component.ghgStationaryEmission.title;
    useEffect(() => {
        document.title = title;
    }, []);

    return (
        <>
            <div className="container-fluid">
                {/* title */}
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Welcome</h1>
                    {/* <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">GHG</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Emission</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div> */}
                </div>
                {/* title */}

            </div>
        </>
    )
}

export default Home;