import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment";

// import WithToastContext from "../../../../context/ToastContext";
import Loader from "../../../common/Loader";
import constant from "../../../../constant";

import { getStorage, onChange, validateForm } from "../../../../utils";
import WithUIContext from "../../../../context/UIContext";
import DropZone from "../../../common/DropZone";
import { locationList } from "../../../../data/oil-and-gas";
// import { addKnownDetail, updateKnownDetail, } from "../../action/StationaryCombustionAction";
const CALCULATION_TYPE = { distance: "1", spend: "2", hotelStay: "3" }

interface IPropType {
    detail: any;
    onSubmitComplete: any;
    onCancel: any;
    context: any;
}

class AddComponent extends React.Component<IPropType, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;

        const etList = [
            { id: 1, name: 'SafeGuard Solutions' },
            { id: 2, name: 'ProVision Partners' },
            { id: 3, name: 'The Flavor Factory' },
            { id: 4, name: 'Gourmet Goods Group' },
            { id: 5, name: 'FlavorFleet Foods' },
            { id: 6, name: 'ExpertEdge Advisors' },
            { id: 7, name: 'Paper Haven' },
            { id: 8, name: 'WriteAway Warehouse' },
        ]

        const esList = [
            { id: 1, name: 'Air' },
            { id: 2, name: 'Car' },
            { id: 3, name: 'Rail' },
            { id: 4, name: 'Hotel Stay' },
        ]

        const ftList = [
            { id: 1, name: 'Diesel' },
            { id: 2, name: 'Gas' },
            { id: 3, name: 'Petrol' },
        ]

        const locList = locationList;

        this.state = {
            id: detail ? detail.id : undefined,
            dated: { name: "dated", required: true, error: "", value: detail ? moment(detail.dated).format("YYYY-MM") : "", type: constant.fieldType.string, },
            remarks: { name: "remarks", required: false, error: "", value: detail ? detail.remarks : "", type: constant.fieldType.string, },
            quantity: { name: "quantity", required: true, error: "", value: detail ? detail.quantity : "", type: constant.fieldType.string, },
            locationId: { name: "locationId", required: false, error: "", value: detail ? detail.locationId : "", options: locList, type: constant.fieldType.string, },
            emissionSourceId: { name: "emissionSourceId", required: true, error: "", value: detail ? detail.equipmentTypeId : "", options: esList, type: constant.fieldType.string, },
            equipmentTypeId: { name: "equipmentTypeId", required: true, error: "", value: detail ? detail.equipmentTypeId : "", options: etList, type: constant.fieldType.string, },
            fuelTypeId: { name: "fuelTypeId", required: true, error: "", value: detail ? detail.fuelTypeId : "", options: ftList, type: constant.fieldType.string, },
            attachment: { name: "attachment", required: false, error: "", value: detail ? detail.attachment : "", type: constant.fieldType.string, },
            calculationTypeId: { name: "calculationTypeId", required: false, error: "", value: detail ? detail.calculationTypeId : CALCULATION_TYPE.distance, type: constant.fieldType.string, },
            doYouKnowAmountOfFuelCombusted: true,
            showLoader: false,
        };
    }

    componentDidMount() {
    }

    render() {
        const { calculationTypeId, showLoader, dated, remarks, quantity, locationId, emissionSourceId, } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                {showLoader && <Loader />}
                <div className="row mb-3">
                    <div className="col">
                        <label className="form-label">Activity Period *</label>
                        <input
                            type="month"
                            name={dated.name}
                            onChange={this.onChange}
                            value={dated.value}
                            className={dated.error.length === 0 ? "form-control" : "form-control is-invalid"}
                        />
                    </div>
                    <div className="col">
                        <label className="form-label">Location</label>
                        <select
                            name={locationId.name}
                            onChange={this.onChange}
                            value={locationId.value}
                            className={locationId.error.length === 0 ? "form-select" : "form-select is-invalid"}
                        >
                            <option value="">Select an option</option>
                            {locationId.options.map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>


                {/* <div className="mb-3">
                    <label className="form-label">Emission Calculation is Based on</label>
                    <div className="">
                        <div className="form-check custom-radio form-check-inline">
                            <input type="radio" id="rb-1" name="rb" className="form-check-input" />
                            <label className="form-check-label" htmlFor="rb-1">Distance</label>
                        </div>
                        <div className="form-check custom-radio form-check-inline">
                            <input type="radio" id="rb-2" name="rb" className="form-check-input" />
                            <label className="form-check-label" htmlFor="rb-2">Spend </label>
                        </div>
                        <div className="form-check custom-radio form-check-inline">
                            <input type="radio" id="rb-2" name="rb" className="form-check-input" />
                            <label className="form-check-label" htmlFor="rb-2">Hotel Stay </label>
                        </div>
                    </div>
                </div> */}
                <div className="mb-3">
                    <label className="form-label">Emission Calculation is Based on</label>
                    <div className="">
                        <div className="form-check custom-radio form-check-inline">
                            <input type="radio" id="rb-2" name={calculationTypeId.name} className="form-check-input" value={CALCULATION_TYPE.distance} onChange={this.onChange} checked={calculationTypeId.value === CALCULATION_TYPE.distance} />
                            <label className="form-check-label" htmlFor="rb-2">Distance</label>
                        </div>
                        <div className="form-check custom-radio form-check-inline">
                            <input type="radio" id="rb-3" name={calculationTypeId.name} className="form-check-input" value={CALCULATION_TYPE.spend} onChange={this.onChange} checked={calculationTypeId.value === CALCULATION_TYPE.spend} />
                            <label className="form-check-label" htmlFor="rb-3">Spend</label>
                        </div>
                        <div className="form-check custom-radio form-check-inline">
                            <input type="radio" id="rb-4" name={calculationTypeId.name} className="form-check-input" value={CALCULATION_TYPE.hotelStay} onChange={this.onChange} checked={calculationTypeId.value === CALCULATION_TYPE.hotelStay} />
                            <label className="form-check-label" htmlFor="rb-4">Hotel Stay</label>
                        </div>
                    </div>
                </div>
                {
                    calculationTypeId.value === CALCULATION_TYPE.distance && <>
                        <div className="row mb-3">
                            <div className="col-6">
                                <label className="form-label">Travel Detail *</label>
                                <select
                                    name={emissionSourceId.name}
                                    onChange={this.onChange}
                                    value={emissionSourceId.value}
                                    className={emissionSourceId.error.length === 0 ? "form-select" : "form-select is-invalid"}
                                >
                                    <option value="">Select an option</option>
                                    {emissionSourceId.options.filter((i: any) => i.id !== 4).map((item: any, index: number) => {
                                        return (
                                            <option key={index} value={item.id}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="col-6">
                                <label className="form-label">Distance Travelled (km) *</label>
                                <NumberFormat
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    className={quantity.error.length === 0 ? "form-control" : "form-control is-invalid"}
                                    value={quantity.value}
                                    onValueChange={(e: any) => { this.onChange({ target: { name: quantity.name, value: e.value }, }); }}
                                />
                            </div>
                        </div>
                    </>
                }
                {
                    calculationTypeId.value === CALCULATION_TYPE.spend && <>
                        <div className="row mb-3">
                            <div className="col-6">
                                <label className="form-label">Travel Detail *</label>
                                <select
                                    name={emissionSourceId.name}
                                    onChange={this.onChange}
                                    value={emissionSourceId.value}
                                    className={emissionSourceId.error.length === 0 ? "form-select" : "form-select is-invalid"}
                                >
                                    <option value="">Select an option</option>
                                    {emissionSourceId.options.filter((i: any) => i.id !== 4).map((item: any, index: number) => {
                                        return (
                                            <option key={index} value={item.id}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="col-6">
                                <label className="form-label">Amount Spent (INR) *</label>
                                <NumberFormat
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    className={quantity.error.length === 0 ? "form-control" : "form-control is-invalid"}
                                    value={quantity.value}
                                    onValueChange={(e: any) => { this.onChange({ target: { name: quantity.name, value: e.value }, }); }}
                                />
                            </div>
                        </div>
                    </>
                }
                {
                    calculationTypeId.value === CALCULATION_TYPE.hotelStay && <>
                        <div className="row mb-3">
                            <div className="col-6">
                                <label className="form-label">Country *</label>
                                <select
                                    name={emissionSourceId.name}
                                    onChange={this.onChange}
                                    value={emissionSourceId.value}
                                    className={emissionSourceId.error.length === 0 ? "form-select" : "form-select is-invalid"}
                                >
                                    <option value="">Select an option</option>
                                    {/* {emissionSourceId.options.filter((i: any) => i.id === 4).map((item: any, index: number) => {
                                        return (
                                            <option key={index} value={item.id}>
                                                {item.name}
                                            </option>
                                        );
                                    })} */}
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Åland Islands">Åland Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antarctica">Antarctica</option>
                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">Bouvet Island</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Territories">French Southern Territories</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-bissau">Guinea-bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jersey">Jersey</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macao">Macao</option>
                                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Pitcairn">Pitcairn</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russian Federation</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Helena">Saint Helena</option>
                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                    <option value="Saint Lucia">Saint Lucia</option>
                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Timor-leste">Timor-leste</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Viet Nam">Viet Nam</option>
                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                    <option value="Western Sahara">Western Sahara</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                            </div>
                            <div className="col-6">
                                <label className="form-label">No. Night Spent *</label>
                                <NumberFormat
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    className={quantity.error.length === 0 ? "form-control" : "form-control is-invalid"}
                                    value={quantity.value}
                                    onValueChange={(e: any) => { this.onChange({ target: { name: quantity.name, value: e.value }, }); }}
                                />
                            </div>
                        </div>
                    </>
                }
                <div className="row mb-3">
                    <div className="col-12">
                        <label className="form-label">Remarks</label>
                        <textarea
                            rows={2}
                            name={remarks.name}
                            onChange={this.onChange}
                            value={remarks.value}
                            className={remarks.error.length === 0 ? "form-control" : "form-control is-invalid"}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="form-label">Attachment(s)</label>
                    <DropZone />
                </div>
                <div className="row">
                    <div className="col">
                        <button type="button" onClick={this.props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-primary px-5" type="submit">Save Changes</button>
                    </div>
                </div>
            </form>
        );
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    };

    onSwitchChange = (name: any, value: any) => {
        onChange(this, name, value);
    };

    onTypeaheadChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    };

    onSubmit = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { detail } = this.props;
            const { id, dated, remarks, quantity, fuelTypeId, doYouKnowAmountOfFuelCombusted, attachment, } = this.state;
            const fuelTypeObj = fuelTypeId.options.find((i: any) => i.code === fuelTypeId.value);
            const branchId = parseFloat(getStorage(constant.key.activeBranch));

            const model: any = {
                id: detail ? detail.id : undefined,
                branchId,
                dated: moment(new Date(dated.value)).format("YYYY-MM-DD"),
                doYouKnowAmountOfFuelCombusted,
                remarks: remarks.value,
                quantity: parseFloat(quantity.value),
                quantityUnit: fuelTypeObj.measuringUnit,
                fuelTypeId: fuelTypeId.value,
                fuelTypeName: fuelTypeObj.name,
                attachment: attachment.value ? attachment.value : undefined,
            };

            const woceModel = {
                key: process.env.REACT_APP_WOCE_KEY,
                fuelCombustedCode: model.fuelTypeId,
                fuelCombustedUnit: "kg",
                quantityCombusted: model.quantity,
            };
            //   const res = await stationary_calculateEmissionKnown(woceModel);
            //   if (res.result) {
            //     const { co2, ch4, n2o, co2e, emission, fuelRelatedEmission, upstream } = res.result;
            //     model.co2 = co2;
            //     model.ch4 = ch4;
            //     model.n2o = n2o;
            //     model.co2e = emission;
            //     model.fuelRelatedEmission = fuelRelatedEmission;
            //     if (upstream) {
            //       model.upstream = {
            //         fuelCode: upstream.input.code,
            //         fuelName: upstream.input.name,
            //         fuelQuantity: upstream.input.quantity,
            //         fuelUnit: upstream.input.unit,

            //         emission: upstream.emission,
            //         emissionUnit: upstream.emissionUnit,
            //       };
            //     } else {
            //       model.upstream = null;
            //     }


            //     if (id) {
            //       this.onUpdateDetail(model);
            //     } else {
            //       this.onAddDetail(model);
            //     }
            //   } else {
            //     this.props.context.toast.error(res.error);
            //   }
        }
    };

    onAddDetail = (model: any) => {
        this.setState({ showLoader: true });
        // addKnownDetail(model).then((res: any) => {
        //   this.setState({ showLoader: false });
        //   if (res.error) {
        //     this.props.context.toast.error(res.error);
        //   } else {
        //     this.props.context.toast.success(constant.message.recordUpdated);
        //     this.props.onSubmitComplete(res.result);
        //   }
        // });
    };

    onUpdateDetail = (model: any) => {
        this.setState({ showLoader: true });
        // updateKnownDetail(model).then((res: any) => {
        //   this.setState({ showLoader: false });
        //   if (res.error) {
        //     this.props.context.toast.error(res.error);
        //   } else {
        //     this.props.context.toast.success(constant.message.recordUpdated);
        //     this.props.onSubmitComplete(res.result);
        //   }
        // });
    };
}

export default WithUIContext(AddComponent);
