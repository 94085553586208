import { Link } from "react-router-dom";
import constant from "../../constant";
import { useRecoilState } from "recoil";
import { projectListAtom } from "../../atom/OffsetAtom";
import { useContext } from "react";
import { UIContext } from "../../context/UIContext";

export default function ProductCard(props: any) {
    const { detail } = props;
    const [list, setList] = useRecoilState(projectListAtom);
    const { toast }: any = useContext(UIContext);

    return (
        <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="card custom-card product-card">
                <div className="card-body">
                    <Link to={constant.component.offsetDetail.url.replace(':id', detail.id)} className="product-image">
                        <img style={{
                            // objectFit: 'none'
                            objectFit: "cover",
                            width: "100%",
                            height: '300px'
                        }} src={detail.img_url} className="card-img mb-3" alt="..." />
                    </Link>
                    {/* <div className="product-icons">
                                                <a href="wishlist.html" className="wishlist bg-white"><i className="ri-heart-line"></i></a>
                                                <a href="cart.html" className="cart bg-white"><i className="ri-shopping-cart-line"></i></a>
                                                <a href="product-details.html" className="view bg-white"><i className="ri-eye-line"></i></a>
                                            </div> */}
                    <p className="product-name fw-semibold mb-0 d-flex align-details-center justify-content-between" title={detail.title}>
                        {detail.title.length > 40 ? detail.title.substring(0, 40) + '...' : detail.title}
                        {/* <span className="float-end text-warning fs-12">4.2<i className="ri-star-s-fill align-middle ms-1 d-inline-block"></i></span> */}
                    </p>
                    <p className="product-description fs-11 text-muted mb-2">{detail.short_description}</p>
                    <p className="mb-1 fw-semibold fs-16 d-flex align-details-center justify-content-between">
                        <span>${detail.price_in_usd} USD/Tonne
                            {/* <span className="text-muted text-decoration-line-through ms-1 d-inline-block op-6">$1,799</span> */}
                        </span>
                        <div className="">
                            {
                                detail.shortlisted
                                    ? <button onClick={() => onShortlistProduct(false)} className="btn btn-icon btn-danger p-0 wishlist"><i className="ri-heart-line"></i></button>
                                    : <button onClick={() => onShortlistProduct(true)} className="btn btn-icon btn-success p-0 wishlist"><i className="ri-heart-line"></i></button>
                            }

                        </div>
                        {/* <span className="badge bg-secondary-transparent float-end fs-10">72% off</span> */}
                    </p>
                    {/* <p className="fs-11 text-success fw-semibold mb-0 d-flex align-details-center"> <i className="ti ti-discount-2 fs-16 me-1"></i>Offer Price $229 </p> */}
                </div>
            </div>
        </div>
    )

    function onShortlistProduct(shortlist: boolean) {
        const clonedList = JSON.parse(JSON.stringify(list));
        for (let i = 0; i < clonedList.length; i++) {
            if (clonedList[i].id === detail.id) {
                clonedList[i].shortlisted = shortlist;
                break;
            }
        }
        setList(clonedList);
        toast.success('Favourite List Updated');
    }
}