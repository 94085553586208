import Chart from "react-apexcharts";
import { getFormattedNumber } from "../../../utils";

export function Chart1() {
    const series: any = [
        {
            name: 'Hazadous Waste',
            type: 'column',
            data: [20, 30, 35, 40, 30, 40]
        },
        {
            name: 'Non-Hazardous Waste',
            type: 'column',
            data: [50, 60, 80, 75, 90, 95]
        },
    ];
    const xaxis = ['2018', '2019', '2020', '2021', '2022', '2023'];
    const yLeftTitle = "Waste Quantity (KT)";
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)"];

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            stacked: true,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: yLeftTitle
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 0, 0, 1.5],
            curve: 'straight',
            dashArray: [0, 0, 0, 5],
        },
        plotOptions: {
            bar: {
                borderRadiusWhenStacked: "last",
                columnWidth: "15%",
                borderRadius: 5
            },

        },
        colors: colors
    };
    return (
        <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="300"
        />
    )
}

export function Chart2() {
    const series: any = [
        {
            name: 'Directed To Disposal',
            type: 'column',
            data: [50, 70, 90, 100, 110, 120]
        },
        {
            name: 'Diverted From Disposal',
            type: 'column',
            data: [20, 20, 25, 15, 10, 15]
        },
    ];
    const xaxis = ['2018', '2019', '2020', '2021', '2022', '2023'];
    const yLeftTitle = "Waste Quantity (KT)";
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)"];

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            stacked: true,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: yLeftTitle
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 0, 0, 1.5],
            curve: 'straight',
            dashArray: [0, 0, 0, 5],
        },
        plotOptions: {
            bar: {
                borderRadiusWhenStacked: "last",
                columnWidth: "15%",
                borderRadius: 5
            },

        },
        colors: colors
    };
    return (
        <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="300"
        />
    )
}

export function Chart3() {
    const series = [20, 20, 8];
    var options = {
        chart: {
            type: "donut",
        },
        legend: {
            position: "bottom",
        },
        labels: ["Reuse", "Recycling", "Other Recovery"],
        colors: [
            'rgba(15, 115, 198, 1.0)',
            'rgba(15, 115, 198, 0.8)',
            'rgba(20, 150, 241, 1.0)',
        ],
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
    };
    return <Chart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height="345"
    />
}

export function Chart4() {
    const series = [40, 20, 12];
    var options = {
        chart: {
            type: "donut",
        },
        legend: {
            position: "bottom",
        },
        labels: ["Landfilling", "Incineration", "Other Disposal"],
        colors: [
            'rgba(15, 115, 198, 1.0)',
            'rgba(15, 115, 198, 0.8)',
            'rgba(20, 150, 241, 1.0)',
        ],
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
    };
    return <Chart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height="345"
    />
}

export function Chart5() {
    const series = [15, 10, 8, 6, 5, 5];
    var options = {
        chart: {
            type: "donut",
        },
        legend: {
            position: "bottom",
        },
        labels: ["Reuse", "Recycle", "Other Recovery", "Landfilling", "Incineration", "Other Disposal"],
        colors: [
            'rgba(15, 115, 198, 1.0)',
            'rgba(15, 115, 198, 0.8)',
            'rgba(20, 150, 241, 1.0)',
            'rgba(66, 182, 245, 1.0)',
            'rgba(113, 202, 248, 1.0)',
            'rgba(166, 223, 252, 1.0)', 
        ],
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
    };
    return <Chart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height="345"
    />
}

export function Chart6() {
    const series = [30, 20, 15, 10, 8, 5];
    var options = {
        chart: {
            type: "donut",
        },
        legend: {
            position: "bottom",
        },
        labels: ["Reuse", "Recycle", "Other Recovery", "Landfilling", "Incineration", "Other Disposal"],
        colors: [
            'rgba(15, 115, 198, 1.0)',
            'rgba(15, 115, 198, 0.8)',
            'rgba(20, 150, 241, 1.0)',
            'rgba(66, 182, 245, 1.0)',
            'rgba(113, 202, 248, 1.0)',
            'rgba(166, 223, 252, 1.0)', 
        ],
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
    };
    return <Chart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height="345"
    />
}

export function Chart7() {
    const series = [
        {
            data: [
                {
                    x: 'Galveston Bay',
                    y: 25
                },
                {
                    x: 'Beaumont',
                    y: 15
                },
                {
                    x: 'Port Arthur',
                    y: 10
                },
                {
                    x: 'Garyville',
                    y: 20
                },
                {
                    x: 'Baytown',
                    y: 10
                },
                {
                    x: 'Baton Rouge',
                    y: 10
                },
                {
                    x: 'Lake Charles',
                    y: 5
                },
                {
                    x: 'Los Angeles',
                    y: 5
                },
                {
                    x: 'Pascagoula',
                    y: 15
                },
                {
                    x: 'Whiting',
                    y: 5
                }
            ]
        }
    ]
    const options = {
        legend: {
            show: false
        },
        chart: {
            height: 350,
            type: 'treemap',
            toolbar: {
                show: false
            },
        },
        colors: [
            'rgba(15, 115, 198, 1.0)',
            'rgba(20, 150, 241, 1.0)',
            'rgba(66, 182, 245, 1.0)',
            'rgba(113, 202, 248, 1.0)',
            'rgba(166, 223, 252, 1.0)',
            'rgba(23, 175, 202, 1.0)',
            'rgba(67, 199, 217, 1.0)',
            'rgba(113, 215, 229, 1.0)',
            'rgba(54, 146, 243, 1.0)',
            'rgba(128, 189, 248, 1.0)',
        ],
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false
            }
        }
    };
    return (
        <div>
            <Chart
                options={options}
                series={series}
                type='treemap'
                width="100%"
                height="310"
            />
        </div>
    )
}

export function Chart22() {
    const series =
        [{
            name: 'Reused Hazardous Waste',
            type: 'column',
            data: [89, 100, 101, 107, 98, 98]
        },
        {
            name: 'Reused Non-Hazardous Waste',
            type: 'column',
            data: [194, 205, 198, 190, 206, 204]
        },
        ]
    const options = {
        chart: {
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        grid: {
            borderColor: '#f1f1f1',
            strokeDashArray: 3
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        colors: ["rgba(132, 90, 223, 1)", "rgba(132, 90, 223, 0.5)", "rgba(132, 90, 223, 0.3)"],
        legend: {
            show: true,
            position: 'bottom'
        },
        plotOptions: {
            bar: {
                columnWidth: "20%",
                borderRadius: 1
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: ['2017', '2018', '2019', '2020', '2021', '2022'],
        },
        yaxis: [{
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
            title: {
                text: "Reused waste (Kt)"
            },
        }],
        fill: {
            opacity: 1
        }
    };
    return (
        <div>
            <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
                height="310"
            />
        </div>
    )
}

export function Chart33() {
    const series = [
        {
            data: [
                {
                    x: 'New York',
                    y: '67Kt'
                },
                {
                    x: 'New Jersey',
                    y: 55
                },
                {
                    x: 'California',
                    y: 24
                },
                {
                    x: 'Texas',
                    y: 23
                },
                {
                    x: 'Pennsylvania',
                    y: 14
                },
                {
                    x: 'Michigan',
                    y: 13
                },

            ]
        }
    ];
    const options = {
        legend: {
            show: false
        },
        chart: {
            height: 350,
            type: 'treemap',
            toolbar: {
                show: false
            },
        },
        colors: ["#845adf"]
    };
    return (
        <div>
            <Chart
                options={options}
                series={series}
                type='treemap'
                width="100%"
                height="310"
            />
        </div>
    )
}

export function Chart44() {
    const series =
        [
            {
                name: 'Reuse',
                type: 'column',
                data: [53, 56, 55, 59, 61, 61]
            },
            {
                name: 'Landfill',
                type: 'column',
                data: [19, 15, 17, 12, 16, 12]
            },
            {
                name: 'Others',
                type: 'column',
                data: [28, 29, 28, 29, 23, 27]
            },
        ]
    const options = {
        chart: {
            stacked: true,
            // stackType: "100%",
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        grid: {
            borderColor: '#f1f1f1',
            strokeDashArray: 3
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        colors: ["rgba(132, 90, 223, 1)", "rgba(132, 90, 223, 0.5)", "rgba(132, 90, 223, 0.3)"],
        legend: {
            show: true,
            position: 'bottom'
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
            // bar: {
            //     columnWidth: "20%",
            //     borderRadius: 1
            // }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: ['2017', '2018', '2019', '2020', '2021', '2022'],
            labels: {
                show: true,
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value) + '%';
                }
            },
        },
        yaxis: [{
            // labels: {
            //     formatter: function (value: any) {
            //         return "" + getFormattedNumber(value);
            //     }
            // },
            min: 0, max: 100,
        }],
        fill: {
            opacity: 1
        }
    };
    return (
        <div>
            <Chart
                options={options}
                series={series}
                type="bar"
                width="100%"
                height="310"
            />
        </div>
    )
}