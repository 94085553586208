import moment from "moment";
import { useState } from "react";
import { Table, controlType } from "../../common/Controls";
import { getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [g1, setG1] = useState(props.value ? props.value.answer["305-4-c-i"] : false);
    const [g2, setG2] = useState(props.value ? props.value.answer["305-4-c-ii"] : false);
    const [g3, setG3] = useState(props.value ? props.value.answer["305-4-c-iii"] : false);
    const [g8, setG8] = useState(props.value ? props.value.answer["305-4-c-viii"] : false);

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "305-4-c-i": g1,
                "305-4-c-ii": g2,
                "305-4-c-n20": g3,
                "305-4-c-viii": g8,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    const onCheckAll = (e: any) => {
        const checked = e.target.checked;
        setG1(checked);
        setG2(checked);
        setG3(checked);
        setG8(checked);
    }
    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <label className="d-block fw-bold mb-3">Gases</label>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="1" checked={g1} onChange={(e: any) => setG1(e.target.checked)} />
                    <label className="form-check-label" htmlFor="1">
                        Direct(Scope 1)
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="2" checked={g2} onChange={(e: any) => setG2(e.target.checked)} />
                    <label className="form-check-label" htmlFor="2">
                        Energy Indirect (Scope 2)
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="3" checked={g3} onChange={(e: any) => setG3(e.target.checked)} />
                    <label className="form-check-label" htmlFor="3">
                        Other Indirect (Scope 3)
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="8" checked={g8} onChange={onCheckAll} />
                    <label className="form-check-label" htmlFor="8">
                        All
                    </label>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1 = answer ? answer.answer["305-4-c-i"] : false;
    const a2 = answer ? answer.answer["305-4-c-ii"] : false;
    const a3 = answer ? answer.answer["305-4-c-iii"] : false;
    const a8 = answer ? answer.answer["305-4-c-viii"] : false;

    return (
        <>
            {
                <div className="">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="1" checked={a1} readOnly />
                        <label className="form-check-label" htmlFor="1">
                            Direct(Scope 1)
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="2" checked={a2} readOnly />
                        <label className="form-check-label" htmlFor="2">
                            Energy Indirect (Scope 2)
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="3" checked={a3} readOnly />
                        <label className="form-check-label" htmlFor="3">
                            Other Indirect (Scope 3)
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="8" checked={a8} readOnly />
                        <label className="form-check-label" htmlFor="8">
                            All
                        </label>
                    </div>
                </div>
            }
        </>
    )
}