import moment from "moment";
import { useState } from "react";
import { Table, controlType } from "../../common/Controls";
import { getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [g1, setG1] = useState(props.value ? props.value.answer["305-4-d-i"] : false);
    const [g2, setG2] = useState(props.value ? props.value.answer["305-4-d-ii"] : false);
    const [g3, setG3] = useState(props.value ? props.value.answer["305-4-d-iii"] : false);
    const [g4, setG4] = useState(props.value ? props.value.answer["305-4-d-iv"] : false);
    const [g5, setG5] = useState(props.value ? props.value.answer["305-4-d-v"] : false);
    const [g6, setG6] = useState(props.value ? props.value.answer["305-4-d-vi"] : false);
    const [g7, setG7] = useState(props.value ? props.value.answer["305-4-d-vii"] : false);
    const [g8, setG8] = useState(props.value ? props.value.answer["305-4-d-viii"] : false);

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "305-4-d-i": g1,
                "305-4-d-ii": g2,
                "305-4-d-iii": g3,
                "305-4-d-iv": g4,
                "305-4-d-v": g5,
                "305-4-d-vi": g6,
                "305-4-d-vii": g7,
                "305-4-d-viii": g8,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    const onCheckAll = (e: any) => {
        const checked = e.target.checked;
        setG1(checked);
        setG2(checked);
        setG3(checked);
        setG4(checked);
        setG5(checked);
        setG6(checked);
        setG7(checked);
        setG8(checked);
    }
    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <label className="d-block fw-bold mb-3">Gases</label>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="1" checked={g1} onChange={(e: any) => setG1(e.target.checked)} />
                    <label className="form-check-label" htmlFor="1">
                        CO2
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="2" checked={g2} onChange={(e: any) => setG2(e.target.checked)} />
                    <label className="form-check-label" htmlFor="2">
                        CH4
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="3" checked={g3} onChange={(e: any) => setG3(e.target.checked)} />
                    <label className="form-check-label" htmlFor="3">
                        N2O
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="4" checked={g4} onChange={(e: any) => setG4(e.target.checked)} />
                    <label className="form-check-label" htmlFor="4">
                        HFCs
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="5" checked={g5} onChange={(e: any) => setG5(e.target.checked)} />
                    <label className="form-check-label" htmlFor="5">
                        PFCs
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="6" checked={g6} onChange={(e: any) => setG6(e.target.checked)} />
                    <label className="form-check-label" htmlFor="6">
                        SF6
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="7" checked={g7} onChange={(e: any) => setG7(e.target.checked)} />
                    <label className="form-check-label" htmlFor="7">
                        NF3
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" value="" id="8" checked={g8} onChange={onCheckAll} />
                    <label className="form-check-label" htmlFor="8">
                        All
                    </label>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    debugger;
    const a1 = answer ? answer.answer["305-4-d-i"] : false;
    const a2 = answer ? answer.answer["305-4-d-ii"] : false;
    const a3 = answer ? answer.answer["305-4-d-iii"] : false;
    const a4 = answer ? answer.answer["305-4-d-iv"] : false;
    const a5 = answer ? answer.answer["305-4-d-v"] : false;
    const a6 = answer ? answer.answer["305-4-d-vi"] : false;
    const a7 = answer ? answer.answer["305-4-d-vii"] : false;
    const a8 = answer ? answer.answer["305-4-d-viii"] : false;

    return (
        <>
            {
                <div className="">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="1" checked={a1} readOnly />
                        <label className="form-check-label" htmlFor="1">
                            CO2
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="2" checked={a2} readOnly />
                        <label className="form-check-label" htmlFor="2">
                            CH4
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="3" checked={a3} readOnly />
                        <label className="form-check-label" htmlFor="3">
                            N2O
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="4" checked={a4} readOnly />
                        <label className="form-check-label" htmlFor="4">
                            HFCs
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="5" checked={a5} readOnly />
                        <label className="form-check-label" htmlFor="5">
                            PFCs
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="6" checked={a6} readOnly />
                        <label className="form-check-label" htmlFor="6">
                            SF6
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="7" checked={a7} readOnly />
                        <label className="form-check-label" htmlFor="7">
                            NF3
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="" id="8" checked={a8} readOnly />
                        <label className="form-check-label" htmlFor="8">
                            All
                        </label>
                    </div>
                </div>
            }
        </>
    )
}