import React, { useState } from "react"
import ModalWindow from "../../common/ModalWindow";
import AddDetailForm from "./BulkUploadForm";

interface IPropType {
    children: any,
    css: any,
    onSubmitComplete: any,
}

function AddDetailButton(props: IPropType) {
    const [showModal, setShowModal] = useState(false);
    const { css } = props;

    const onShowModal = () => {
        setShowModal(true);
    }

    const onHideModal = () => {
        setShowModal(false);
    }

    const onSubmitComplete = (detail: any) => {
        onHideModal();
        props.onSubmitComplete(detail);
    }

    return (
        <React.Fragment>
            {
                showModal && renderModal()
            }
            <button type="button" className={css} onClick={onShowModal}>
                {props.children}
            </button>
        </React.Fragment>
    )

    function renderModal() {
        return (
            <ModalWindow
                title="Bulk Upload Form"
                backdrop="static"
                css="modal-md"
                onHideModal={onHideModal}
            >
                <AddDetailForm
                    onSubmitComplete={onSubmitComplete}
                    onCancel={onHideModal}
                />
            </ModalWindow>
        )
    }
}

export default AddDetailButton;