export default function DropZone(props: any) {
    return (
        <div className="" style={{
            padding: '3rem',
            textAlign: 'center',
            border: '1px dashed gray',
            borderRadius: '0.3rem'
        }}>
            Drop file here to Upload
        </div>
    )
}