import moment from "moment";
import { useState } from "react";
import { Table, controlType, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [remarks, setRemarks] = useState(props.value ? props.value.answer["305-2-a-i"] : '');

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "305-2-a-i": remarks,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-3">Gross location-based GHG Emission</label>
                <div className="input-group mb-3">
                    {number({ name: '', value: remarks, onChange: (a: any, b: any) => setRemarks(b) })}
                    {/* <input type="text" className="form-control" placeholder="" onChange={(e: any) => setRemarks(e.target.value)} value={remarks} /> */}
                    <span className="input-group-text">tCO2e</span>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const answer1 = answer ? answer.answer["305-2-a-i"] : '';
    return (
        <>
            {
                <div className="">
                    <label className="fw-bold mb-1">Gross location-based GHG Emission</label>
                    <div className="">
                        {
                            answer1 ? `${getFormattedFloat(answer1)} tCO2e` : '-'
                        }
                    </div>
                </div>
            }
        </>
    )
}