import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment";

// import WithToastContext from "../../../../context/ToastContext";
import Loader from "../../../common/Loader";
import constant from "../../../../constant";

import { getStorage, onChange, validateForm } from "../../../../utils";
import WithUIContext from "../../../../context/UIContext";
import DropZone from "../../../common/DropZone";
// import { addKnownDetail, updateKnownDetail, } from "../../action/StationaryCombustionAction";


interface IPropType {
    detail: any;
    onSubmitComplete: any;
    onCancel: any;
    context: any;
}

class AddComponent extends React.Component<IPropType, any> {
    constructor(props: any) {
        super(props);
        const detail = props.detail;
        const etList = [
            { id: 1, name: 'GENSET MODEL LP 320 (320 kVA)' },
            { id: 2, name: 'Portable Petrol Generator' },
            { id: 3, name: 'XLNT 13000E 10KW Industrial Use Petrol Generator' },
            { id: 4, name: 'Process Heater' },
            { id: 5, name: 'Combined Heat and Power (CHP) Systems' },
            { id: 6, name: 'Gas Generator' },
        ]

        const esList = [
            { id: 1, name: 'Surface Treatment' },
            { id: 2, name: 'Welding and Metalworking' },
            { id: 3, name: 'Testing and Quality Control' },
            { id: 4, name: 'Drying Process' },
            { id: 5, name: 'CHP System' },
            { id: 6, name: 'Material Handling and Storage' },
        ]

        const ftList = [
            { id: 1, name: 'Diesel' },
            { id: 2, name: 'Gas' },
            { id: 3, name: 'Petrol' },
        ]
        this.state = {
            id: detail ? detail.id : undefined,
            dated: { name: "dated", required: true, error: "", value: detail ? moment(detail.dated).format("YYYY-MM") : "", type: constant.fieldType.string, },
            remarks: { name: "remarks", required: false, error: "", value: detail ? detail.remarks : "", type: constant.fieldType.string, },
            quantity: { name: "quantity", required: true, error: "", value: detail ? detail.quantity : "", type: constant.fieldType.string, },
            emissionSourceId: { name: "emissionSourceId", required: true, error: "", value: detail ? detail.equipmentTypeId : "", options: esList, type: constant.fieldType.string, },
            equipmentTypeId: { name: "equipmentTypeId", required: true, error: "", value: detail ? detail.equipmentTypeId : "", options: etList, type: constant.fieldType.string, },
            fuelTypeId: { name: "fuelTypeId", required: true, error: "", value: detail ? detail.fuelTypeId : "", options: ftList, type: constant.fieldType.string, },
            attachment: { name: "attachment", required: false, error: "", value: detail ? detail.attachment : "", type: constant.fieldType.string, },
            doYouKnowAmountOfFuelCombusted: true,
            showLoader: false,
        };
    }

    componentDidMount() {
    }

    render() {
        const { showLoader, dated, remarks, quantity, equipmentTypeId, emissionSourceId, fuelTypeId, attachment, } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                {showLoader && <Loader />}
                <div className="mb-3">
                    <label className="form-label">Activity Period *</label>
                    <input
                        type="month"
                        name={dated.name}
                        onChange={this.onChange}
                        value={dated.value}
                        className={dated.error.length === 0 ? "form-control" : "form-control is-invalid"}
                    />
                </div>
                <div className="row mb-3">

                    <div className="col-6">
                        <label className="form-label">Equipment Type *</label>
                        <select
                            name={equipmentTypeId.name}
                            onChange={this.onChange}
                            value={equipmentTypeId.value}
                            className={equipmentTypeId.error.length === 0 ? "form-select" : "form-select is-invalid"}
                        >
                            <option value="">Select an option</option>
                            {equipmentTypeId.options.map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-6">
                        <label className="form-label">Emission Source *</label>
                        <select
                            name={emissionSourceId.name}
                            onChange={this.onChange}
                            value={emissionSourceId.value}
                            className={emissionSourceId.error.length === 0 ? "form-select" : "form-select is-invalid"}
                        >
                            <option value="">Select an option</option>
                            {emissionSourceId.options.map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                </div>
                <div className="row mb-3">
                    <div className="col-6">
                        <label className="form-label">Fuel Type *</label>
                        <select
                            name={fuelTypeId.name}
                            onChange={this.onChange}
                            value={fuelTypeId.value}
                            className={fuelTypeId.error.length === 0 ? "form-select" : "form-select is-invalid"}
                        >
                            <option value="">Select an option</option>
                            {fuelTypeId.options.map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-6">
                        <label className="form-label">Quantity *</label>
                        <NumberFormat
                            decimalScale={2}
                            thousandSeparator={true}
                            allowNegative={false}
                            className={quantity.error.length === 0 ? "form-control" : "form-control is-invalid"}
                            value={quantity.value}
                            onValueChange={(e: any) => { this.onChange({ target: { name: quantity.name, value: e.value }, }); }}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <label className="form-label">Remarks</label>
                        <textarea
                            rows={2}
                            name={remarks.name}
                            onChange={this.onChange}
                            value={remarks.value}
                            className={remarks.error.length === 0 ? "form-control" : "form-control is-invalid"}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="form-label">Attachment(s)</label>
                    <DropZone />
                </div>
                <div className="row">
                    <div className="col">
                        <button type="button" onClick={this.props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-primary px-5" type="submit">Save Changes</button>
                    </div>
                </div>
            </form>
        );
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    };

    onSwitchChange = (name: any, value: any) => {
        onChange(this, name, value);
    };

    onTypeaheadChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    };

    onSubmit = async (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { detail } = this.props;
            const { id, dated, remarks, quantity, fuelTypeId, doYouKnowAmountOfFuelCombusted, attachment, } = this.state;
            const fuelTypeObj = fuelTypeId.options.find((i: any) => i.code === fuelTypeId.value);
            const branchId = parseFloat(getStorage(constant.key.activeBranch));

            const model: any = {
                id: detail ? detail.id : undefined,
                branchId,
                dated: moment(new Date(dated.value)).format("YYYY-MM-DD"),
                doYouKnowAmountOfFuelCombusted,
                remarks: remarks.value,
                quantity: parseFloat(quantity.value),
                quantityUnit: fuelTypeObj.measuringUnit,
                fuelTypeId: fuelTypeId.value,
                fuelTypeName: fuelTypeObj.name,
                attachment: attachment.value ? attachment.value : undefined,
            };

            const woceModel = {
                key: process.env.REACT_APP_WOCE_KEY,
                fuelCombustedCode: model.fuelTypeId,
                fuelCombustedUnit: "kg",
                quantityCombusted: model.quantity,
            };
            //   const res = await stationary_calculateEmissionKnown(woceModel);
            //   if (res.result) {
            //     const { co2, ch4, n2o, co2e, emission, fuelRelatedEmission, upstream } = res.result;
            //     model.co2 = co2;
            //     model.ch4 = ch4;
            //     model.n2o = n2o;
            //     model.co2e = emission;
            //     model.fuelRelatedEmission = fuelRelatedEmission;
            //     if (upstream) {
            //       model.upstream = {
            //         fuelCode: upstream.input.code,
            //         fuelName: upstream.input.name,
            //         fuelQuantity: upstream.input.quantity,
            //         fuelUnit: upstream.input.unit,

            //         emission: upstream.emission,
            //         emissionUnit: upstream.emissionUnit,
            //       };
            //     } else {
            //       model.upstream = null;
            //     }


            //     if (id) {
            //       this.onUpdateDetail(model);
            //     } else {
            //       this.onAddDetail(model);
            //     }
            //   } else {
            //     this.props.context.toast.error(res.error);
            //   }
        }
    };

    onAddDetail = (model: any) => {
        this.setState({ showLoader: true });
        // addKnownDetail(model).then((res: any) => {
        //   this.setState({ showLoader: false });
        //   if (res.error) {
        //     this.props.context.toast.error(res.error);
        //   } else {
        //     this.props.context.toast.success(constant.message.recordUpdated);
        //     this.props.onSubmitComplete(res.result);
        //   }
        // });
    };

    onUpdateDetail = (model: any) => {
        this.setState({ showLoader: true });
        // updateKnownDetail(model).then((res: any) => {
        //   this.setState({ showLoader: false });
        //   if (res.error) {
        //     this.props.context.toast.error(res.error);
        //   } else {
        //     this.props.context.toast.success(constant.message.recordUpdated);
        //     this.props.onSubmitComplete(res.result);
        //   }
        // });
    };
}

export default WithUIContext(AddComponent);
