import { Chart1, Chart2, Chart3, Chart4, Chart5  } from "../../../../component/esg/social-dashboard/DiversityChart";

function Home() {
    return (
        <>
            <div className="container-fluid">
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Diversity & Equal Opportunity</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">ESG</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Social</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Diversity and Equal Opportunity</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                {renderTopCards()}

                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Total Workforce
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart1 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    % Women New Hires
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart2 />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Workforce Nationality
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart3 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Age Distribution
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart4 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Salary Analysis
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart5 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    function renderTopCards() {
        return (
            <>
                <div className="row">
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card card-bg-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-light"><i className="ti ti-users fs-25 text-primary"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-fixed-white op-9">Total Employee Count</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">100,000</span>                                            
                                        </div>
                                        <div>
                                            <span className="fs-12 mb-0 text-fixed-white op-8"></span>
                                            <span className="badge bg-light fw-semibold text-success mx-1 fs-12">+7.8%</span>
                                            <span className="fs-12 mb-0 text-fixed-white op-9"> v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card border border-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-info"><i className="ti ti-man fs-30"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Total Male Employees</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">80,000</span>
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">80.0%</span>
                                            <span className="fs-12 mb-0 text-muted">of Total Employees</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card border border-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-secondary"><i className="ti ti-woman fs-30"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Total Female Employees</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">20,000</span>
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">20.0%</span>
                                            <span className="fs-12 mb-0 text-muted">of Total Employees</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card border border-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-teal"><i className="ti ti-user-plus fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Total New Hires</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">8,000</span>
                                        </div>
                                        <div>
                                            <span className="badge bg-success-transparent fw-semibold text-success mx-1 fs-12">+8.0%</span>
                                            <span className="fs-12 mb-0 text-muted">from previous year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;