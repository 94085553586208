import React, { useState } from "react"
import { attachmentListAtom } from '../../atom/ESGAtom';
import ModalWindow from "../common/ModalWindow";
import { useRecoilState } from "recoil";
import DropZone2 from "./DropZone2";

interface IPropType {
    questionId: any,
    children: any,
    css: any,
    onSubmitComplete: any,
}

function EditDetailButton(props: IPropType) {
    const [attachmentList, setAttachmentList] = useRecoilState(attachmentListAtom);
    const [showModal, setShowModal] = useState(false);
    const { css } = props;

    const onShowModal = () => {
        setShowModal(true);
    }

    const onHideModal = () => {
        setShowModal(false);
        setAttachmentList(null);
    }

    const onSubmitComplete = (list: any) => {
        if (attachmentList !== null) {
            const clonedAttachmentList = JSON.parse(JSON.stringify(attachmentList));
            clonedAttachmentList.push(...list);
            setAttachmentList(clonedAttachmentList);
        } else {
            setAttachmentList(list);
        }

        onHideModal();
        if (props.onSubmitComplete) {
            props.onSubmitComplete(list);
        }
    }

    return (
        <React.Fragment>
            {
                showModal && renderModal()
            }
            <button type="button" className={css} onClick={onShowModal}>
                {props.children}
            </button>
        </React.Fragment>
    )

    function renderModal() {
        return (
            <ModalWindow
                title="Upload File(s)"
                backdrop="static"
                css="modal-md"
                onHideModal={onHideModal}
            >
                <DropZone2
                    questionId={props.questionId}
                    onSubmitComplete={onSubmitComplete}
                    onCancel={onHideModal}
                />
            </ModalWindow>
        )
    }
}

export default EditDetailButton;