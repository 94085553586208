import React, { useState } from "react"
import ModalWindow from "../../common/ModalWindow";

interface IPropType {
    children: any,
    css: any,
    csv: any,
    title: string
}

function AddDetailButton(props: IPropType) {
    const CSVDataTable = ({ data }: any) => {
        const headers = data.length > 0 ? Object.keys(data[0]) : [];
        return (
            <>
                {data.length === 0 ? (
                    <p>No data available.</p>
                ) : (
                    <table className="table mb-0 fs--1 border-200 table-bordered">
                        <thead className="bg-light">
                            <tr className="text-800 bg-200">
                                {headers.map((header, index) => (
                                    <th className="text-nowrap" key={index}>
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="border-bottom border-200">
                            {data.map((row: any, index: any) => (
                                <tr key={index}>
                                    {headers.map((header, columnIndex) => (
                                        <td className="align-middle font-sans-serif fw-medium text-nowrap" key={columnIndex}>
                                            {row[header] === '' || row[header] === '0' ? '-' : row[header]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </>
        );
    };
    const parseCSV = (csvText: any) => {
        const lines = csvText.split("\n");
        const headers = lines[0].split(",");
        const parsedData = [];

        for (let i = 1; i < lines.length; i++) {
            const currentLine = lines[i].split(",");

            if (currentLine.length === headers.length) {
                const row: any = {};
                for (let j = 0; j < headers.length; j++) {
                    row[headers[j].trim()] = currentLine[j].trim();
                }
                parsedData.push(row);
            }
        }

        return parsedData;
    };
    const data = parseCSV(props.csv);
    const [showModal, setShowModal] = useState(false);
    const { css } = props;

    const onShowModal = () => {
        setShowModal(true);
    }

    const onHideModal = () => {
        setShowModal(false);
    }

    return (
        <React.Fragment>
            {
                showModal && renderModal()
            }
            <button type="button" className={css} onClick={onShowModal}>
                {props.children}
            </button>
        </React.Fragment>
    )

    function renderModal() {
        return (
            <ModalWindow
                title={props.title}
                backdrop={false}
                css="modal-xxl"
                onHideModal={onHideModal}
            >
                <div className="table-responsive scrollbar">
                    <CSVDataTable data={data} />
                </div>
            </ModalWindow>
        )
    }
}

export default AddDetailButton;