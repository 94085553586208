import moment from "moment";
import { useState } from "react";
import { useRecoilValue } from "recoil";

import { Table, controlType } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import { answerListAtom } from "../../../atom/ESGAtom";
import constant from "../../../constant";

export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [stateValue1, setStateValue1] = useState(props.value ? JSON.parse(props.value.answer["101-6-e-i"]) : []);
    const [stateValue2, setStateValue2] = useState(props.value ? JSON.parse(props.value.answer["101-6-e-ii"]) : []);
    const [stateValue3, setStateValue3] = useState(props.value ? JSON.parse(props.value.answer["101-6-e-iii"]) : []);
    const [stateValue4, setStateValue4] = useState(props.value ? JSON.parse(props.value.answer["101-6-e-iv"]) : []);
    const [stateValue5, setStateValue5] = useState(props.value ? JSON.parse(props.value.answer["101-6-e-v"]) : []);

    // {
    //     "101-6-e-i": [],
    //     "101-6-e-ii": [],
    //     "101-6-e-iii": [],
    //     "101-6-e-iv": [],
    //     "101-6-e-v": []
    // }
    const answerList = useRecoilValue(answerListAtom);
    const answerDetail = answerList.find((i: any) => i.questionId === 25);
    const siteOptions = answerDetail && answerDetail.answer ? JSON.parse(answerDetail.answer).map((i: any) => i.productName) : []

    const columns1: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: 'productName', label: 'Product', type: controlType.select, value: '', show: true, options: siteOptions },
        { name: '101-6-e-i', label: 'Country', type: controlType.string, value: '', show: true, band: '', },
        { name: '101-6-e-ii', label: 'Size(Hectares)', band: 'Natural Ecosystem Converted Since a Cut-off or Reference Date', type: controlType.string, value: '', show: true },
        { name: '101-6-e-iii', label: 'Cut-off Date or Reference Date', band: 'Natural Ecosystem Converted Since a Cut-off or Reference Date', type: controlType.date, value: '', show: true, },
        { name: '101-6-e-iv', label: 'Size (hectares of land sea converted)', type: controlType.float, value: '', show: true, },
    ]

    const columns2: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: 'productName', label: 'Product', type: controlType.select, value: '', show: true, options: siteOptions },
        { name: '101-6-e-i', label: 'Country', type: controlType.string, value: '', show: true, band: '', },
        { name: '101-6-e-ii', label: 'Wild Species Harvested', type: controlType.string, value: '', show: true, band: '', },
        { name: '101-6-e-iii', label: 'Quantity (kg/ tonnes)', type: controlType.float, value: '', show: true, band: '', },
        { name: '101-6-e-iv', label: 'Type', type: controlType.string, value: '', show: true, band: '', },
    ]

    const columns3: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: 'productName', label: 'Product', type: controlType.select, value: '', show: true, options: siteOptions },
        { name: '101-6-e-i', label: 'Country', type: controlType.string, value: '', show: true, band: '', },
        { name: '101-6-e-ii', label: 'Water Withdrawl (ML)', type: controlType.string, value: '', show: true, band: '', },
        { name: '101-6-e-iii', label: 'Water Consumption (ML)', type: controlType.float, value: '', show: true, band: '', },
    ]

    const columns4: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: 'productName', label: 'Product', type: controlType.select, value: '', show: true, options: siteOptions },
        { name: '101-6-e-i', label: 'Country', type: controlType.string, value: '', show: true, band: '', },
        { name: '101-6-e-ii', label: 'Pollutant Generated', type: controlType.string, value: '', show: true, band: '', },
        { name: '101-6-e-iii', label: 'Quantity (kg/ tonnes)', type: controlType.float, value: '', show: true, band: '', },
        { name: '101-6-e-iv', label: 'Type', type: controlType.string, value: '', show: true, band: '', },
    ]

    const columns5: any = [
        { name: 'uid', label: 'UID', type: controlType.string, value: '', show: false },
        { name: 'productName', label: 'Product', type: controlType.select, value: '', show: true, options: siteOptions },
        { name: '101-6-e-i', label: 'Country', type: controlType.string, value: '', show: true, band: '', },
        { name: '101-6-e-ii', label: 'Provide details for each product reported where its activities could lead to invasive allien speices', type: controlType.string, value: '', show: true, band: '', },
    ]

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "101-6-e-i": JSON.stringify(stateValue1),
                "101-6-e-ii": JSON.stringify(stateValue2),
                "101-6-e-iii": JSON.stringify(stateValue3),
                "101-6-e-iv": JSON.stringify(stateValue4),
                "101-6-e-v": JSON.stringify(stateValue5),

            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        console.log(obj);
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setStateValue1(value)}
                    columns={columns1}
                    obj={{ name: "101-6-e-i", value: stateValue1 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setStateValue2(value)}
                    columns={columns2}
                    obj={{ name: "101-6-e-ii", value: stateValue2 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setStateValue3(value)}
                    columns={columns3}
                    obj={{ name: "101-6-e-iii", value: stateValue3 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div >
            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setStateValue4(value)}
                    columns={columns4}
                    obj={{ name: "101-6-e-iv", value: stateValue4 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            <div className="mb-3">
                <Table
                    onChange={(name: string, value: any) => setStateValue5(value)}
                    columns={columns5}
                    obj={{ name: "101-6-e-v", value: stateValue5 }}
                    showSerial={false}
                    showAddButton={true}
                    showDeleteButton={true}
                />
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const answerJSON1 = answer ? JSON.parse(answer.answer["101-6-e-i"]) : [];
    const answerJSON2 = answer ? JSON.parse(answer.answer["101-6-e-ii"]) : [];
    const answerJSON3 = answer ? JSON.parse(answer.answer["101-6-e-iii"]) : [];
    const answerJSON4 = answer ? JSON.parse(answer.answer["101-6-e-iv"]) : [];
    const answerJSON5 = answer ? JSON.parse(answer.answer["101-6-e-v"]) : [];
    return (
        <>
            <div className="mb-3" style={{ overflow: 'auto' }}>
                <table className="table text-nowrap table-bordered border-primary">
                    <thead>
                        <tr>
                            <th rowSpan={2}>
                                #
                            </th>
                            <th rowSpan={2}>
                                Product
                            </th>
                            <th rowSpan={2} className="text-center">
                                Country
                            </th>
                            <th colSpan={2}>
                                Natural Ecosystem Converted Since a Cut-off or Reference Date
                            </th>
                            <th rowSpan={2} className="text-center">
                                Size (hectares of land sea converted)
                            </th>
                        </tr>
                        <tr>
                            <th className="text-center">
                                Size(Hectares)
                            </th>
                            <th className="text-center">
                                Cut-off Date or Reference Date
                            </th>
                        </tr>

                    </thead>
                    <tbody>

                        {
                            answerJSON1.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            answerJSON1.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.productName}</td>
                                    <td className="text-end">{item['101-6-e-i'] ? item['101-6-e-i'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-ii'] ? item['101-6-e-ii'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-iii'] ? item['101-6-e-iii'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-iv'] ? item['101-6-e-iv'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-v'] ? item['101-6-e-v'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div className="mb-3" style={{ overflow: 'auto' }}>
                <table className="table text-nowrap table-bordered border-primary">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Product
                            </th>
                            <th className="text-center">
                                Country
                            </th>
                            <th className="text-center">
                                Wild Species Harvested
                            </th>
                            <th className="text-center">
                                Quantity (kg/ tonnes)
                            </th>
                            <th className="text-center">
                                Type
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            answerJSON2.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            answerJSON2.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.productName}</td>
                                    <td className="text-end">{item['101-6-e-i'] ? item['101-6-e-i'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-ii'] ? item['101-6-e-ii'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-iii'] ? item['101-6-e-iii'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-iv'] ? item['101-6-e-iv'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div className="mb-3" style={{ overflow: 'auto' }}>
                <table className="table text-nowrap table-bordered border-primary">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Product
                            </th>
                            <th className="text-center">
                                Country
                            </th>
                            <th className="text-center">
                                Water Withdrawl (ML)
                            </th>
                            <th className="text-center">
                                Water Consumption (ML)
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            answerJSON3.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            answerJSON3.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.productName}</td>
                                    <td className="text-end">{item['101-6-e-i'] ? item['101-6-e-i'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-ii'] ? item['101-6-e-ii'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-iii'] ? item['101-6-e-iii'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div className="mb-3" style={{ overflow: 'auto' }}>
                <table className="table text-nowrap table-bordered border-primary">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Product
                            </th>
                            <th className="text-center">
                                Country
                            </th>
                            <th className="text-center">
                                Pollutant Generated
                            </th>
                            <th className="text-center">
                                Quantity (kg/ tonnes)
                            </th>
                            <th className="text-center">
                                Type
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            answerJSON4.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            answerJSON4.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.productName}</td>
                                    <td className="text-end">{item['101-6-e-i'] ? item['101-6-e-i'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-ii'] ? item['101-6-e-ii'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-iii'] ? item['101-6-e-iii'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-iv'] ? item['101-6-e-iv'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div className="mb-3" style={{ overflow: 'auto' }}>
                <table className="table text-nowrap table-bordered border-primary">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Product
                            </th>
                            <th className="text-center">
                                Country
                            </th>
                            <th className="text-center">
                                Provide details for each product reported where its activities could lead to invasive allien speices
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            answerJSON5.length === 0 && <tr><td colSpan={10} className="text-center">
                                No Records Found
                            </td></tr>
                        }
                        {
                            answerJSON5.map((item: any, index: number) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.productName}</td>
                                    <td className="text-end">{item['101-6-e-i'] ? item['101-6-e-i'] : '-'}</td>
                                    <td className="text-end">{item['101-6-e-ii'] ? item['101-6-e-ii'] : '-'}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}