import Chart from "react-apexcharts";
import { getFormattedNumber } from "../../../utils";

export function Chart1() {
    const series: any = [
        {
            name: 'Water Consumption',
            type: 'column',
            data: [50000, 60000, 70000, 80000, 85000, 90000]
        },
        {
            name: 'Water Discharge',
            type: 'column',
            data: [10000, 12000, 15000, 20000, 25000, 30000]
        },
    ];
    const xaxis = ['2018', '2019', '2020', '2021', '2022', '2023'];
    const yLeftTitle = "Water Quantity (ML)";
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)"];

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            stacked: true,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: yLeftTitle
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 0, 0, 1.5],
            curve: 'straight',
            dashArray: [0, 0, 0, 5],
        },
        plotOptions: {
            bar: {
                borderRadiusWhenStacked: "last",
                columnWidth: "15%",
                borderRadius: 5
            },

        },
        colors: colors
    };
    return (
        <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="300"
        />
    )
}

export function Chart2() {
    const series: any = [
        {
            name: 'Water Withdrawal',
            type: 'column',
            data: [30000, 35000, 50000, 40000, 45000, 55000]
        },
        {
            name: 'Water Consumed',
            type: 'column',
            data: [20000, 30000, 40000, 20000, 30000, 30000]
        },
        {
            name: 'Water Discharged',
            type: 'column',
            data: [10000, 5000, 10000, 20000, 15000, 25000]
        }
    ];
    const xaxis = ['2018', '2019', '2020', '2021', '2022', '2023'];
    const yLeftTitle = "Water Quantity (ML)";
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)", "rgba(20, 150, 241, 1.0)"];

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: yLeftTitle
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [1, 1, 1],
        },
        plotOptions: {
            bar: {
                columnWidth: "40%",
                borderRadiusApplication: "end",
                borderRadius: 5
            },

        },
        colors: colors
    };
    return (
        <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="300"
        />
    )
}

export function Chart3() {
    const series = [1754, 1234, 878, 270, 500];
    var options = {
        chart: {
            type: "donut",
        },
        legend: {
            position: "bottom",
        },
        labels: ["Surface Water", "Ground Water", "Sea Water", "Produced Water", "Third Party Water"],
        colors: [            
            'rgba(15, 115, 198, 1.0)',
            'rgba(15, 115, 198, 0.8)',
            'rgba(20, 150, 241, 1.0)',
            'rgba(66, 182, 245, 1.0)',
            'rgba(113, 202, 248, 1.0)',
        ],
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
    };
    return <Chart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height="395"
    />
}

export function Chart4() {

    const series: any = [
        {
            name: 'Water Consumption',
            type: 'column',
            data: [50000, 60000, 70000, 80000, 85000, 90000, 45000, 30000, 75000, 60000]
        },
        {
            name: 'Water Discharge',
            type: 'column',
            data: [10000, 12000, 15000, 20000, 25000, 30000, 40000, 20000, 10000, 30000]
        },
    ];
    const xaxis = [
        'Garyville',
        'Pascagoula',
        'Baytown',
        'New York',
        'Baton Rog',
        'Lake Char',
        'Whiting',
        'Port Arth',
        'Galveston',
        'Beaumont'
    ];
    const yLeftTitle = "Water Quantity (ML)";
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)"];

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            stacked: true,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        },
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 0, 0, 1.5],
            curve: 'straight',
            dashArray: [0, 0, 0, 5],
        },
        plotOptions: {
            bar: {
                horizontal: "true",
                barHeight: "40%",
                borderRadiusApplication: "end",
                borderRadius: 4,
            },

        },
        colors: colors
    };
    return (
        <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="350"
        />
    )
}
