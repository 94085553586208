import PieChart from "../../component/charts/PieChart";
import { getFormattedFloat, getFormattedNumber } from "../../utils";
import constant from "../../constant";
import HelpButton from "../../component/ghg/common/HelpButton";
import StackedLineChart from "../../component/decarbonization/net-zero-goal-tracker/StackedLineChart";
import ApexPieChart from "../../component/charts/ApexPieChart";

function NetZeroGoalTracker() {
    const title = constant.component.decarbonizationNetZeroGoalTracker.title;
    return (
        <>
            <div className="container-fluid">
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">{title}</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Decarbonization</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="d-md-flex d-block flex-wrap align-items-center justify-content-between">
                                    <div className="flex-fill">
                                        <ul className="nav nav-pills nav-style-3" role="tablist">
                                            <li className="nav-item" role="presentation"><a className="nav-link active" data-bs-target="#tab-1" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="true">Dashboard</a> </li>
                                            <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#tab-2" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Scope 1 Detailed Report</a> </li>
                                            <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#tab-3" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Scope 2 Detailed Report</a> </li>
                                            <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#tab-4" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Scope 3 Detailed Report</a> </li>
                                        </ul>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center justify-content-evenly">
                                        <HelpButton css="btn btn-sm btn-info align-inline-flex ms-3"><i className="bx bx-help-circle me-1 fw-semibold"></i>Help</HelpButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="tab-content">
                        <div className="tab-pane active p-0" id="tab-1" role="tabpanel">
                            <div className="row">
                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Baseline Emissions (2023-24)</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 2,890 tCO2 </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Targetted Emissions by 2030</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 1,200 tCO2 </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Total Emission Reduction for Net Zero</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <div className="text-muted mb-1 fs-12">
                                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 1,690 tCO2 </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 1 Reductions Net Zero</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 800 tCO2 </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 2 Reductions Net Zero</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 500 tCO2 </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 3 Reductions Net Zero</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <div className="text-muted mb-1 fs-12">
                                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 390 tCO2 </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card custom-card mb-3">
                                <div className="card-header">
                                    <div className=" card-title">
                                        Summary of Decarbonization Roadmap
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <div className="table-responsive scrollbar">
                                        <table className="table mb-0 fs--1 border-200 table-borderless">
                                            <thead className="bg-light">
                                                <tr className="text-800 bg-200">
                                                    <th className="text-nowrap">Year</th>
                                                    <th className="text-end text-nowrap">Estimated GHG Emissions</th>
                                                    <th className="text-end text-nowrap">Emissions after GHG Reduction Initiative(t)</th>
                                                    <th className="text-end text-nowrap">Target Emission</th>
                                                    <th className="text-end text-nowrap">Carbon Credits(t)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="border-bottom border-200">
                                                    <td className="align-middle font-sans-serif fw-medium text-nowrap">2024-25</td>
                                                    <td className="align-middle text-end">2,890</td>
                                                    <td className="align-middle text-end">2,883.20</td>
                                                    <td className="align-middle text-end">2,774.40</td>
                                                    <td className="align-middle text-end">-</td>
                                                </tr>
                                                <tr className="border-bottom border-200">
                                                    <td className="align-middle font-sans-serif fw-medium text-nowrap">2025-26</td>
                                                    <td className="align-middle text-end">3,006</td>
                                                    <td className="align-middle text-end">2,870.70</td>
                                                    <td className="align-middle text-end">2,663.40</td>
                                                    <td className="align-middle text-end">-</td>
                                                </tr>
                                                <tr className="border-bottom border-200">
                                                    <td className="align-middle font-sans-serif fw-medium text-nowrap">2026-27</td>
                                                    <td className="align-middle text-end">3,126</td>
                                                    <td className="align-middle text-end">2,380</td>
                                                    <td className="align-middle text-end">2,556.90</td>
                                                    <td className="align-middle text-end">475</td>
                                                </tr>
                                                <tr className="border-bottom border-200">
                                                    <td className="align-middle font-sans-serif fw-medium text-nowrap">2027-28</td>
                                                    <td className="align-middle text-end">3,251</td>
                                                    <td className="align-middle text-end">1,940</td>
                                                    <td className="align-middle text-end">2,454.6</td>
                                                    <td className="align-middle text-end">605</td>
                                                </tr>
                                                <tr className="border-bottom border-200">
                                                    <td className="align-middle font-sans-serif fw-medium text-nowrap">2028-29</td>
                                                    <td className="align-middle text-end">3,381</td>
                                                    <td className="align-middle text-end">1,755</td>
                                                    <td className="align-middle text-end">2,356.4</td>
                                                    <td className="align-middle text-end">850</td>
                                                </tr>
                                            </tbody>
                                            <tfoot className="bg-200"><tr className="text-700 fw-bold"><td>Total</td><td className=""><div className="d-flex align-items-center justify-content-end"><h6 className="fs--1 text-700 mb-0 fw-bold">41,397</h6></div></td><td className=""><div className="d-flex align-items-center justify-content-end"><h6 className="fs--1 text-700 mb-0 fw-bold">78,509</h6></div></td><td className=""><div className="d-flex align-items-center justify-content-end"><h6 className="fs--1 text-700 mb-0 fw-bold">367,503</h6></div></td><td className="pe-card text-end"><div className="d-flex align-items-center justify-content-end"><h6 className="fs--1 text-700 mb-0 fw-bold">487,409</h6></div></td></tr></tfoot>
                                        </table>
                                    </div>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    {renderTopProjectType()}
                                </div>
                                <div className="col-8">
                                    {renderRoadMapChart()}
                                </div>

                            </div>

                            <div className="row">
                                {renderCategoryDistribution()}
                            </div>
                        </div>
                        <div className="tab-pane p-0" id="tab-2" role="tabpanel">
                            <div className="row">
                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 1 Baseline Emissions(2023-24)</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 1,100 tCO2e </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 1 Current Year Reductions</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 276 tCO2e</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 1 Total Reductions (2024-28)</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <div className="text-muted mb-1 fs-12">
                                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 780 tCO2e </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="table-responsive scrollbar">
                                        {renderDetail().t1()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane p-0" id="tab-3" role="tabpanel">
                            <div className="row">
                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 2 Baseline Emissions(2023-24)</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 1,500 tCO2e </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 2 Current Year Reductions</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 400 tCO2e</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 2 Total Reductions (2024-28)</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <div className="text-muted mb-1 fs-12">
                                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 800 tCO2e </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="table-responsive scrollbar">
                                        {renderDetail().t2()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane p-0" id="tab-4" role="tabpanel">
                            <div className="row">
                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 1 Baseline Emissions(2023-24)</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 1,500 tCO2e </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 1 Current Year Reductions</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 400 tCO2e</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                    <span className="rounded p-3 bg-primary-transparent"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"></path></g></svg> </span>
                                                </div>
                                                <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                    <div className="mb-2">Scope 1 Total Reductions (2024-28)</div>
                                                    <div className="text-muted mb-1 fs-12">
                                                        <div className="text-muted mb-1 fs-12">
                                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom"> 800 tCO2e </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="table-responsive scrollbar">
                                        {renderDetail().t3()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )

    function renderCategoryDistribution() {
        const c1 = [
            { name: 'Stationary Combustion', value: 170 },
            { name: 'Mobile Combustion', value: 80 },
            { name: 'Fugitive Emissions', value: 200 },
        ];
        const c2 = [
            { name: 'Electricity', value: 320 }
        ];
        const c3 = [
            // {name: 'Purchased Goods and Services', value: 40890 },
            // {name: 'Capital Goods', value: 52890 },
            { name: 'Fuels and Energy Related', value: 110 },

            { name: 'Waste Generated in Operations', value: 321 },
            { name: 'Business Travel', value: 145 },
            { name: 'Employee Commuting', value: 80 },

            { name: 'Use of Sold Goods', value: 251 },
            // {name: 'End of lIfe Treatment', value: 35090 },
        ];
        return (
            <>
                <div className="col-xxl-4">
                    <div className="card custom-card" style={{ height: "100%" }}>
                        <div className="card-header d-flex flex-between-center py-3 border-bottom">
                            <div className="card-title">
                                Scope 1 Reduction 2024-2028
                            </div>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-between pb-0">
                            <div className="row align-items-center">
                                <div className="col-md-5 col-xxl-12 mb-xxl-1">
                                    <ApexPieChart labels={c1.map((i: any) => i.name)} series={c1.map((i: any) => i.value)} total={getFormattedNumber('450')} />
                                    {/* <PieChart
                                        data={c1}
                                        total={getFormattedNumber('450')}
                                        showLegend={false}
                                        unit=" "
                                    /> */}
                                </div>
                                <div className="col-xxl-12 col-md-7 ps-0 pe-0">
                                    <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />

                                    <table className="table">
                                        <tbody className="top-selling">
                                            <tr>
                                                <td className="">Stationary Combustion</td>
                                                <td className="text-end">170</td>
                                            </tr>
                                            <tr>
                                                <td className="">Mobile Combustion</td>
                                                <td className="text-end">80</td>
                                            </tr>
                                            <tr>
                                                <td className="">Fugitive Emissions</td>
                                                <td className="text-end">200</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4">
                    <div className="card custom-card" style={{ height: "100%" }}>
                        <div className="card-header d-flex flex-between-center py-3 border-bottom">
                            <div className="card-title">
                                Scope 2 Reduction 2024-2028
                            </div>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-between pb-0">
                            <div className="row align-items-center">
                                <div className="col-md-5 col-xxl-12 mb-xxl-1">
                                    <ApexPieChart labels={c2.map((i: any) => i.name)} series={c2.map((i: any) => i.value)} total={getFormattedNumber('320')} />
                                    {/* <PieChart
                                        data={c2}
                                        total={getFormattedNumber('320')}
                                        showLegend={false}
                                        unit=" "
                                    /> */}
                                </div>
                                <div className="col-xxl-12 col-md-7 ps-0 pe-0">
                                    <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />
                                    <table className="table">
                                        <tbody className="top-selling">
                                            <tr>
                                                <td className="">Electricity</td>
                                                <td className="text-end">320</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4">
                    <div className="card custom-card" style={{ height: "100%" }}>
                        <div className="card-header d-flex flex-between-center py-3 border-bottom">
                            <div className="card-title">
                                Scope 3 Reduction 2024-2028
                            </div>
                        </div>
                        <div className="card-body d-flex flex-column justify-content-between pb-0">
                            <div className="row align-items-center">
                                <div className="col-md-5 col-xxl-12 mb-xxl-1">
                                    <ApexPieChart labels={c3.map((i: any) => i.name)} series={c3.map((i: any) => i.value)} total={getFormattedNumber('907')} />
                                    {/* <PieChart
                                        data={c3}
                                        total={getFormattedNumber('907')}
                                        showLegend={false}
                                        unit=" "
                                    /> */}
                                </div>
                                <div className="col-xxl-12 col-md-7 ps-0 pe-0">
                                    <hr className="mx-ncard mb-0 d-md-none d-xxl-block" />
                                    <table className="table">
                                        <tbody className="top-selling">
                                            <tr>
                                                <td className="">Fuel and Energy Related</td>
                                                <td className="text-end">110</td>
                                            </tr>
                                            <tr>
                                                <td className="">Waste generated in operations</td>
                                                <td className="text-end">321</td>
                                            </tr>
                                            <tr>
                                                <td className="">Business Travel</td>
                                                <td className="text-end">145</td>
                                            </tr>
                                            <tr>
                                                <td className="">Employee Commuting</td>
                                                <td className="text-end">80</td>
                                            </tr>
                                            <tr>
                                                <td className="">Use of Sold Products</td>
                                                <td className="text-end">251</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function renderTopProjectType() {
        const _list2 = [
            { name: 'Carbon Capture', amount: 980 },
            { name: 'Energy Sources', amount: 470 },
            { name: 'Nature Investments', amount: 346 },
            { name: 'Waste Management', amount: 230 },
            { name: 'Travel & Transport', amount: 125 },
            { name: 'Digital', amount: 50 },
        ]
        return (
            <>

                <div className="card custom-card">
                    <div className="card-header d-flex flex-between-center py-3 border-bottom">
                        <div className="card-title">
                            Top Project Types
                        </div>
                    </div>
                    <div className="card-body py-0">
                        <table className="table">
                            <tbody className="top-selling">
                                {_list2.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td className="">
                                                    {item.name}
                                                </td>
                                                <td className="text-end">
                                                    {getFormattedFloat(item.amount)}
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }

    function renderRoadMapChart() {
        return <>
            <div className="card custom-card">
                <div className="card-header d-flex flex-between-center py-3 border-bottom">
                    <div className="card-title">
                        Road Map
                    </div>
                </div>
                <div className="card-body">
                    <StackedLineChart />
                </div>
            </div>
        </>
    }

    function renderDetail() {
        const CSVDataTable = ({ data }: any) => {
            const headers = data.length > 0 ? Object.keys(data[0]) : [];
            return (
                <>
                    {data.length === 0 ? (
                        <p>No data available.</p>
                    ) : (
                        <table className="table mb-0 fs--1 border-200 table-bordered">
                            <thead className="bg-light">
                                <tr className="text-800 bg-200">
                                    {headers.map((header, index) => (
                                        <th className="text-nowrap" key={index}>
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="border-bottom border-200">
                                {data.map((row: any, index: any) => (
                                    <tr key={index}>
                                        {headers.map((header, columnIndex) => (
                                            <td className="align-middle font-sans-serif fw-medium text-nowrap" key={columnIndex}>
                                                {row[header] === '' || row[header] === '0' ? '-' : row[header]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </>
            );
        };
        const parseCSV = (csvText: any) => {
            const lines = csvText.split("\n");
            const headers = lines[0].split(",");
            const parsedData = [];

            for (let i = 1; i < lines.length; i++) {
                const currentLine = lines[i].split(",");

                if (currentLine.length === headers.length) {
                    const row: any = {};
                    for (let j = 0; j < headers.length; j++) {
                        row[headers[j].trim()] = currentLine[j].trim();
                    }
                    parsedData.push(row);
                }
            }

            return parsedData;
        };

        const t1 = () => {
            const csv = `Year,Activity Month,Project Category,Action Goal,Branch,Scope Category,Total Reduction(t) Projection till 2030,Avtivity Period Reduction,Current Achievements
            2024-25,Apr'24,Sustainable Travel,Sustainable Transportation - Company Owned Vehicles,East Delhi,Mobile Combustion,110,4.5,70%
            2024-25,May'24,Sustainable Travel,Sustainable Transportation - Company Owned Vehicles,East-North Delhi,Mobile Combustion,120,5,65%
            2024-25,Jul'24,Sustainable Travel,Sustainable Transportation - Company Owned Vehicles,South Delhi,Mobile Combustion,140,6,70%
            2024-25,Sep'24,Carbon Capture Storage,Sustainable Transportation - Company Owned Vehicles,East Delhi,Mobile Combustion,110,11,68%
            2025-26,Sep'25,Sustainable Travel,Carbon Capture - CCU at North East Facility,East-North Delhi,Fugitive Emission,800,100,80%
            2025-26,Jan'26,Carbon Capture Storage,Sustainable Transportation - Company Owned Vehicles,South Delhi,Mobile Combustion,110,12,56%
            2026-27,Mar'27,Carbon Capture Storage,Carbon Capture - CCU at North East Facility,East Delhi,Fugitive Emission,800,100,80%
            2027-28,Apr'27,Sustainable Travel,Sustainable Transportation - Company Owned Vehicles,East-North Delhi,Mobile Combustion,110,12,70%
            2027-28,Dec'27,Sustainable Travel,Carbon Capture - CCU at North East Facility,South Delhi,Fugitive Emission,800,100,80%
            2027-28,Dec'27,Carbon Capture Storage,Carbon Capture Storage,North Delhi,Fugitive Emissions,900,100,90%
            `;
            const data = parseCSV(csv)
            return <CSVDataTable data={data} />
        }

        const t2 = () => {
            const csv = `Year,Activity Month,Project Category,Action Goal,Branch,Scope Category,Total Reduction(t) Projection till 2030,Avtivity Period Reduction,Current Achievements
            2024-25,Apr'24,Energy Efficient,Energy - Efficient Equipment - Upgrade to 5 Star Equipment,East Delhi,Electricity,200,150,70%
            2024-25,May'24,Energy Efficient,Energy - Efficient Equipment - Upgrade to 5 Star Equipment,East-North Delhi,Electricity,200,100,65%
            2024-25,Jul'24,Renewable Energy,Wind Energy Plant - 1000 MegaWatts,South Delhi,Electricity,200,80,70%
            2024-25,Sep'24,Renewable Energy,Solar - 100 Megawatts,East Delhi,Electricity,800,90,68%
            2025-26,Sep'25,Renewable Energy,Wind Energy Plant - 1000 MegaWatts,East-North Delhi,Electricity,200,120,80%
            2025-26,Jan'26,Renewable Energy,Solar - 100 Megawatts,South Delhi,Electricity,800,140,56%
            2026-27,Mar'27,Energy Efficient,Energy - Efficient Equipment - Upgrade to 5 Star Equipment,East Delhi,Electricity,800,200,80%
            2027-28,Apr'27,Renewable Energy,Wind Energy Plant - 1000 MegaWatts,East-North Delhi,Electricity,200,270,70%
            2027-28,Dec'27,Renewable Energy,Solar - 100 Megawatts,South Delhi,Electricity,200,110,80%
            2027-28,Dec'27,Renewable Energy,Solar - 50 Megawatts,North Delhi,Electricity,800,300,90%
            `;
            const data = parseCSV(csv)
            return <CSVDataTable data={data} />
        }

        const t3 = () => {
            const csv = `Year,Activity Month,Project Category,Action Goal,Location,Scope Category,Total Reduction(t) Projection till 2030,Avtivity Period Reduction,Current Achievements
            2024-25,Apr'24,Waste Management,Recycling E- Waste,East Delhi,Waste Generated in Operations,110,4.5,70%
            2024-25,May'24,Sustainabile Travel,Using Rail travel over Air,South Delhi,Business Travel,120,5,65%
            2024-25,Jul'24,Sustainabile Travel,Employees Commuting through Electric Bus,South-west Delhi,Employee Commuting,140,6,70%
            2024-25,Sep'24,Waste Management,Recycling E- Waste,North Delhi,Waste Generated in Operations,110,11,68%
            2025-26,Sep'25,Sustainabile Travel,Employees Commuting through Electric Bus,East Delhi,Employee Commuting,800,100,80%
            2025-26,Jan'26,Waste-to-Energy,Waste-to-Energy,South Delhi,End of Life Treatment,110,12,56%
            2026-27,Mar'27,Sustainabile Travel,Using Rail travel over Air,South-west Delhi,Business Travel,800,100,80%
            2027-28,Apr'27,Waste Management,Recycling E- Waste,North Delhi,Waste Generated in Operations,110,12,70%
            2027-28,Dec'27,Sustainabile Travel,Employees Commuting through Electric Bus,North Delhi,Employee Commuting,800,100,80%
            2027-28,Dec'27,Waste Management,Waste-to-Energy,North Delhi,End of Life Treatment,900,100,90%
            `;
            const data = parseCSV(csv)
            return <CSVDataTable data={data} />
        }

        <>
            <ul className="nav nav-tabs d-flex justify-content-between" id="myTab" role="tablist">
                <li className="nav-item" role="presentation"><button className="nav-link active fw-normal" id="tab-1" data-bs-toggle="tab" data-bs-target="#option-1" type="button" role="tab" aria-selected="true">Scope 1</button></li>
                <li className="nav-item" role="presentation"><button className="nav-link fw-normal" id="tab-2" data-bs-toggle="tab" data-bs-target="#option-2" type="button" role="tab" aria-selected="false">Scope 2</button></li>
                <li className="nav-item" role="presentation"><button className="nav-link fw-normal" id="tab-3" data-bs-toggle="tab" data-bs-target="#option-3" type="button" role="tab" aria-selected="false">Scope 3</button></li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="option-1" role="tabpanel" aria-labelledby="#tab-1">

                    <div className="card mt-3 mb-3">
                        <div className="card-body">
                            <div className="row">
                                {/* <div className="col-lg-3 border-lg-end border-bottom border-lg-0 pb-3 pb-lg-0">
                                    <div className="d-flex flex-between-center mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-item icon-item-sm bg-soft-primary shadow-none me-2 bg-soft-primary">
                                                <span className="fs--2 fas fa-cloud text-primary"></span>
                                            </div>
                                            <h6 className="mb-0">Total Emission (kgCO2e)</h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">
                                                961,804
                                            </p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-4 border-lg-end border-bottom border-lg-0 py-3 py-lg-0"              >
                                    <div className="d-flex flex-between-center mb-3">
                                        <div className="d-flex align-items-center">
                                            {/* <div className="icon-item icon-item-sm bg-soft-danger shadow-none me-2">
                                                <span className="fs--2 fas fa-cloud text-danger"></span>
                                            </div> */}
                                            <h6 className="mb-0">
                                                Scope 1 Baseline Emissions(2023-24)
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2 text-primary">
                                                1,100 tCO2e
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 border-lg-end border-bottom border-lg-0 py-3 py-lg-0"              >
                                    <div className="d-flex flex-between-center mb-3">
                                        <div className="d-flex align-items-center">
                                            {/* <div className="icon-item icon-item-sm bg-soft-danger shadow-none me-2">
                                                <span className="fs--2 fas fa-cloud text-danger"></span>
                                            </div> */}
                                            <h6 className="mb-0">
                                                Scope 1 Current Year Reductions
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2 text-info">
                                                276 tCO2e
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 border-bottom border-lg-0 py-3 py-lg-0"              >
                                    <div className="d-flex flex-between-center mb-3">
                                        <div className="d-flex align-items-center">
                                            {/* <div className="icon-item icon-item-sm bg-soft-danger shadow-none me-2">
                                                <span className="fs--2 fas fa-cloud text-danger"></span>
                                            </div> */}
                                            <h6 className="mb-0">
                                                Scope 1 Total Reductions (2024-28)
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2 text-warning">
                                                780 tCO2e
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 table-responsive scrollbar">
                        {t1()}
                    </div>

                </div>
                <div className="tab-pane fade show" id="option-2" role="tabpanel" aria-labelledby="#tab-1">

                    <div className="card mt-3 mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 border-lg-end border-bottom border-lg-0 py-3 py-lg-0"              >
                                    <div className="d-flex flex-between-center mb-3">
                                        <div className="d-flex align-items-center">
                                            <h6 className="mb-0">
                                                Scope 2 Baseline Emissions(2023-24)
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2 text-primary">
                                                1,500 tCO2e
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 border-lg-end border-bottom border-lg-0 py-3 py-lg-0"              >
                                    <div className="d-flex flex-between-center mb-3">
                                        <div className="d-flex align-items-center">
                                            <h6 className="mb-0">
                                                Scope 2 Current Year Reductions
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2 text-info">
                                                400 tCO2e
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 border-bottom border-lg-0 py-3 py-lg-0"              >
                                    <div className="d-flex flex-between-center mb-3">
                                        <div className="d-flex align-items-center">
                                            <h6 className="mb-0">
                                                Scope 2 Total Reductions (2024-28)
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2 text-warning">
                                                800 tCO2e
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 table-responsive scrollbar">
                        {t2()}
                    </div>

                </div>
                <div className="tab-pane fade show" id="option-3" role="tabpanel" aria-labelledby="#tab-1">

                    <div className="card mt-3 mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 border-lg-end border-bottom border-lg-0 py-3 py-lg-0"              >
                                    <div className="d-flex flex-between-center mb-3">
                                        <div className="d-flex align-items-center">
                                            <h6 className="mb-0">
                                                Scope 3 Baseline Emissions(2023-24)
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2 text-primary">
                                                1,500 tCO2e
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 border-lg-end border-bottom border-lg-0 py-3 py-lg-0"              >
                                    <div className="d-flex flex-between-center mb-3">
                                        <div className="d-flex align-items-center">
                                            <h6 className="mb-0">
                                                Scope 3 Current Year Reductions
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2 text-info">
                                                400 tCO2e
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 border-bottom border-lg-0 py-3 py-lg-0"              >
                                    <div className="d-flex flex-between-center mb-3">
                                        <div className="d-flex align-items-center">
                                            <h6 className="mb-0">
                                                Scope 3 Total Reductions (2024-28)
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center">
                                            <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2 text-warning">
                                                800 tCO2e
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 table-responsive scrollbar">
                        {t3()}
                    </div>

                </div>
            </div>
        </>

        return { t1, t2, t3 }
    }
}

export default NetZeroGoalTracker