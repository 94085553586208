import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? props.value.answer["306-5-b-a1"] : '');
    const [a2, setA2] = useState(props.value ? props.value.answer["306-5-b-a2"] : '');
    const [a3, setA3] = useState(props.value ? props.value.answer["306-5-b-a3"] : '');
    const [a4, setA4] = useState(props.value ? props.value.answer["306-5-b-a4"] : '');
    const [a5, setA5] = useState(props.value ? props.value.answer["306-5-b-a5"] : '');
    const [a6, setA6] = useState(props.value ? props.value.answer["306-5-b-a6"] : '');
    const [a7, setA7] = useState(props.value ? props.value.answer["306-5-b-a7"] : '');
    const [a8, setA8] = useState(props.value ? props.value.answer["306-5-b-a8"] : '');


    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "306-5-b-a1": a1,
                "306-5-b-a2": a2,
                "306-5-b-a3": a3,
                "306-5-b-a4": a4,
                "306-5-b-a5": a5,
                "306-5-b-a6": a6,
                "306-5-b-a7": a7,
                "306-5-b-a8": a8,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Hazardous</th>
                            <th>Non-Hazardous</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>With Energy Recovery </td>
                            <td>
                                {number({ name: '', value: a1, onChange: (a: any, b: any) => { setA1(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a2, onChange: (a: any, b: any) => { setA2(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Without Energy Recovery</td>
                            <td>
                                {number({ name: '', value: a3, onChange: (a: any, b: any) => { setA3(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a4, onChange: (a: any, b: any) => { setA4(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Landfilling</td>
                            <td>
                                {number({ name: '', value: a5, onChange: (a: any, b: any) => { setA5(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a6, onChange: (a: any, b: any) => { setA6(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Other Disposal Operations</td>
                            <td>
                                {number({ name: '', value: a7, onChange: (a: any, b: any) => { setA7(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a8, onChange: (a: any, b: any) => { setA8(b) } })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1JSON = answer ? answer.answer["306-5-b-a1"] : '';
    const a2JSON = answer ? answer.answer["306-5-b-a2"] : '';
    const a3JSON = answer ? answer.answer["306-5-b-a3"] : '';
    const a4JSON = answer ? answer.answer["306-5-b-a4"] : '';
    const a5JSON = answer ? answer.answer["306-5-b-a5"] : '';
    const a6JSON = answer ? answer.answer["306-5-b-a6"] : '';
    const a7JSON = answer ? answer.answer["306-5-b-a7"] : '';
    const a8JSON = answer ? answer.answer["306-5-b-a8"] : '';

    return (
        <>
            {
                <div className="mb-3">
                    {/* <table className="table table-bordered table-striped">
                        <tbody>
                            <tr>
                                <td>Total water consumption from all areas (ML)</td>
                                <td>
                                    {a1JSON ? getFormattedFloat(a1JSON) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Total water consumption from all areas with water stress (ML)</td>
                                <td>
                                    {a2JSON ? getFormattedFloat(a2JSON) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Change in water storage (ML)</td>
                                <td>
                                    {a3JSON ? getFormattedFloat(a3JSON) : '-'}
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Hazardous</th>
                                <th>Non-Hazardous</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>With Energy Recovery </td>
                                <td>
                                    {a1JSON ? getFormattedFloat(a1JSON) : '-'}
                                </td>
                                <td>
                                    {a2JSON ? getFormattedFloat(a2JSON) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Without Energy Recovery</td>
                                <td>
                                    {a3JSON ? getFormattedFloat(a3JSON) : '-'}
                                </td>
                                <td>
                                    {a4JSON ? getFormattedFloat(a4JSON) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Landfilling</td>
                                <td>
                                    {a5JSON ? getFormattedFloat(a5JSON) : '-'}
                                </td>
                                <td>
                                    {a6JSON ? getFormattedFloat(a6JSON) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Other Disposal Operations</td>
                                <td>
                                    {a7JSON ? getFormattedFloat(a7JSON) : '-'}
                                </td>
                                <td>
                                    {a8JSON ? getFormattedFloat(a8JSON) : '-'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}