import _ from "lodash";
import { Link } from 'react-router-dom';
import uniqolor from 'uniqolor';

import { getFormattedFloat, getFormattedNumber } from "../../../utils";
import StackedAreaChart2 from "../../charts/StackedAreaChart2";
import Shimmer from "../../common/Shimmer";
import constant from '../../../constant';
import ApexLineChart from "../../charts/ApexLineChart";
import ApexBarChart from "../../charts/ApexBarChart";
import ApexPieChart from "../../charts/ApexPieChart";
import moment from "moment";
import { Chart1, Chart2, Chart3 } from "./DashboardChart";

export default function DashboardWrapper(props: { startDate: any, endDate: any, emissionList: any, emissionCategory: any, branchList: any, emissionMapping: any, showBranchSection: any }) {
    const { startDate, endDate, emissionList, emissionCategory, branchList, emissionMapping, showBranchSection } = props;
    const scope1List = emissionList ? emissionList.filter((i: any) => i.categoryId === 1) : [];
    const scope2List = emissionList ? emissionList.filter((i: any) => i.categoryId === 2) : [];
    const scope3List = emissionList ? emissionList.filter((i: any) => i.categoryId === 3) : [];

    const scope1Total = scope1List.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    const scope2Total = scope2List.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
    const scope3Total = scope3List.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);

    const renderBarChart = () => {
        const xAxis = [2020, 2021, 2022, 2023, 2024];
        const yAxis = [
            {
                name: "Scope 1",
                type: 'column',
                data: [8500, 7000, 6000, 9500, 8000]
            },
            {
                name: "Scope 2",
                type: 'column',
                data: [11000, 9250, 9500, 9000, 10000]
            },
            {
                name: "Scope 3",
                type: 'column',
                data: [31000, 33000, 28000, 30000, 22000]
            },
            {
                name: "Scope 1 Baseline 2019",
                type: 'line',
                data: [9000, 9000, 9000, 9000, 9000]
            },
            {
                name: "Scope 2 Baseline 2019",
                type: 'line',
                data: [12000, 12000, 12000, 12000, 12000]
            },
            {
                name: "Scope 3 Baseline 2019",
                type: 'line',
                data: [32000, 32000, 32000, 32000, 32000]
            }
        ]
        // const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#A5978B'];
        return <>
            <div className="card custom-card">
                <div className="card-header">
                    <div className="card-title">Comparison Bar Chart by Year</div>
                </div>
                <div className="card-body">
                    <div id="projectAnalysis">
                        <Chart1 xAxis={xAxis} yAxis={yAxis} />
                    </div>
                </div>
            </div>

        </>
    }

    const renderCards = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card custom-card mb-3">
                            <div className="card-body">
                                <div className="d-flex flex-wrap align-items-top gap-2">
                                    <div className="me-1"> <span className="avatar avatar-lg bg-primary"> <i className="ti ti-wallet fs-20"></i> </span>
                                    </div>
                                    <div className="flex-fill">
                                        <h5 className="d-inline-block fw-semibold fs-18 mb-1">2.66</h5>
                                        <span className="fs-10 ms-1 fw-semibold text-muted">kgCO2e</span>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-muted fs-12">GHG Emission/sq. ft.</div>
                                            {/* <div className="text-success"><i className="ti ti-trending-up fs-16 me-1 align-middle d-inline-flex"></i>+2.02%</div> */}
                                        </div>
                                        {/* <a href="javascript:void(0);" className="text-primary fs-12">View All<i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block"></i></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card custom-card mb-3">
                            <div className="card-body">
                                <div className="d-flex flex-wrap gap-2 align-items-top">
                                    <div className="me-1">
                                        <span className="avatar avatar-lg bg-secondary"> <i className="ti ti-users fs-20"></i> </span>
                                    </div>
                                    <div className="flex-fill">
                                        <h5 className="d-inline-block fw-semibold fs-18 mb-1">8</h5>
                                        <span className="fs-10 ms-1 fw-semibold text-muted">kgCO2e</span>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-muted fs-12">GHG Emission/Employee</div>
                                            {/* <div className="text-danger"><i className="ti ti-trending-down fs-16 me-1 align-middle d-inline-flex"></i>-0.24%</div> */}
                                        </div>
                                        {/* <a href="javascript:void(0);" className="text-secondary fs-12">View All<i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block"></i></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card custom-card mb-3">
                            <div className="card-body">
                                <div className="d-flex flex-wrap gap-2 align-items-top">
                                    <div className="me-1"> <span className="avatar avatar-lg bg-warning"> <i className="ti ti-id fs-20"></i> </span> </div>
                                    <div className="flex-fill">
                                        <h5 className="d-inline-block fw-semibold fs-18 mb-1">1.2</h5>
                                        <span className="fs-10 ms-1 fw-semibold text-muted">GJ</span>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-muted fs-12">Energy Intensity</div>
                                            {/* <div className="text-danger"><i className="ti ti-trending-down fs-16 me-1 align-middle d-inline-flex"></i>-1.32%</div> */}
                                        </div>
                                        {/* <a href="javascript:void(0);" className="text-warning fs-12">View All<i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block"></i></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card custom-card mb-3">
                            <div className="card-body">
                                <div className="d-flex flex-wrap gap-2 align-items-top">
                                    <div className="me-1">
                                        <span className="avatar avatar-lg bg-danger"> <i className="ti ti-gift fs-20"></i> </span>
                                    </div>
                                    <div className="flex-fill">
                                        <h5 className="d-inline-block fw-semibold fs-18 mb-1">0.04</h5>
                                        <span className="fs-10 ms-1 fw-semibold text-muted">kgCO2e</span>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-muted fs-12"> GHG Emission/Revenue</div>
                                            {/* <div className="text-success"><i className="ti ti-trending-up fs-16 me-1 align-middle d-inline-flex"></i>+0.89%</div> */}
                                        </div>
                                        {/* <a href="javascript:void(0);" className="text-danger fs-12">View All<i className="ti ti-arrow-narrow-right ms-2 fw-semibold d-inline-block"></i></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card overflow-hidden">
                            <div className="card-header justify-content-between">
                                <div className="card-title"> My Courses </div>
                                <div> <button type="button" className="btn btn-light btn-sm">View All</button> </div>
                            </div>
                            <div className="card-body p-0">
                                
                            </div>
                        </div>
                    </div>
                </div> */}
            </>
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="tab-content">
                        <div className="tab-pane p-0 border-0 active show" id="div-1" role="tabpanel">
                            {renderStats()}
                            <div className="row mb-3">
                                <div className="col-9">
                                    {renderBarChart()}

                                </div>
                                <div className="col-3">
                                    {renderCards()}
                                </div>
                            </div>
                            {
                                showBranchSection && <div className="row">
                                    <div className="col-xxl-8 col-xl-8">
                                        {renderBranchEmissionList()}
                                    </div>
                                    <div className="col-xxl-4 col-xl-4">
                                        {renderBranchEmissionPieChart()}
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-xxl-12">
                                    {renderEmissionTrend(emissionList, emissionCategory)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xxl-4">
                                    {renderTopEmissionCategory()}
                                </div>
                                <div className="col-xxl-4">
                                    {renderTopEmissionSource()}
                                </div>

                                <div className="col-xxl-4">
                                    {renderTopSupplier()}
                                </div>
                            </div>
                            {renderCategoryDistribution()}
                        </div>
                        <div className="tab-pane p-0 border-0" id="div-2" role="tabpanel">
                            {scope1Trend()}
                        </div>
                        <div className="tab-pane p-0 border-0" id="div-3" role="tabpanel">
                            {scope2Trend()}
                        </div>
                        <div className="tab-pane p-0 border-0" id="div-4" role="tabpanel">
                            {scope3Trend()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    function scope1Trend() {
        const stationaryCombustion = emissionList ? scope1List.filter((i: any) => i.emissionId === 1) : [];
        const mobileCombustion = emissionList ? scope1List.filter((i: any) => i.emissionId === 2) : [];
        const fugutiveEmission = emissionList ? scope1List.filter((i: any) => i.emissionId === 3) : [];
        return (
            <>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Stationary Combustion (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={stationaryCombustion}
                                    color="#f5803e"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Mobile Combustion (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={mobileCombustion}
                                    color="#2bda90"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Fugutive Emission (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={fugutiveEmission}
                                    color="#eb5c76"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function scope2Trend() {
        const stationaryCombustion = emissionList ? scope2List.filter((i: any) => i.emissionId === 7) : [];
        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Purchased Electricity from Grid (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2 data={stationaryCombustion}
                                    color="#f5803e"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function scope3Trend() {
        const purchaseGoods = emissionList ? scope3List.filter((i: any) => i.emissionId === 15) : [];
        const capitalGoods = emissionList ? scope3List.filter((i: any) => i.emissionId === 16) : [];
        const workFromHome = emissionList ? scope3List.filter((i: any) => i.emissionId === 17) : [];
        const fuelAndEnergy = emissionList ? scope3List.filter((i: any) => i.emissionId === 13) : [];
        const upstream = emissionList ? scope3List.filter((i: any) => i.emissionId === 11) : [];
        const wasteGenerated = emissionList ? scope3List.filter((i: any) => i.emissionId === 14) : [];
        const businessTravel = emissionList ? scope3List.filter((i: any) => i.emissionId === 9) : [];
        const employeeCommuting = emissionList ? scope3List.filter((i: any) => i.emissionId === 10) : [];

        return (
            <>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Category 1: Purchased Goods and Services (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={purchaseGoods}
                                    color="#f5803e"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Category 2: Capital Goods (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={capitalGoods}
                                    color="#2bda90"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Category 3: Fuel- and Energy-Related Activities (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={fuelAndEnergy}
                                    color="#2fbefd"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Category 4: Upstream Transportation and Distribution (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={upstream}
                                    color="#7d899b"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Category 5: Waste Generated in Operations (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={wasteGenerated}
                                    color="#2e8f61"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Category 6: Business Travel (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={businessTravel}
                                    color="#4d8fe9"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Category 7: Employee Commuting (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={employeeCommuting}
                                    color="#eb5c76"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="card custom-card">
                            <div className="card-header d-sm-flex d-block">
                                <div className="card-title">Category 8: Work From Home (KgCO2e)</div>
                            </div>
                            <div className="card-body p-0">
                                <StackedAreaChart2
                                    data={workFromHome}
                                    color="#eb5c76"
                                    startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6">
                <div className="mb-3">
                  <h6 className="mb-0">Category 7: Employee Commuting<span className="text-800 fs--2">(kgCO2e)</span></h6>
                </div>
                <StackedAreaChart2
                  data={employeeCommuting}
                  color="#e3e6ea"
                  startDate={startDate.format('YYYY-MM-DD')} endDate={endDate.format('YYYY-MM-DD')}
                />
              </div> */}

                </div>
            </>
        )
    }

    function renderStats() {
        if (emissionList === undefined) {
            return <div className="col-lg-12 col-xxl-12">
                <div className="card">
                    <div className="card-body">
                        <Shimmer />
                    </div>
                </div>
            </div>
        }
        const emissionTotal = emissionList ? emissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0) : "0";
        const scope1List = emissionList.filter((i: any) => i.categoryId === 1);
        const scope2List = emissionList.filter((i: any) => i.categoryId === 2);
        const scope3List = emissionList.filter((i: any) => i.categoryId === 3);

        const scope1Total = scope1List ? scope1List.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0) : "0";
        const scope2Total = scope2List ? scope2List.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0) : "0";
        const scope3Total = scope3List ? scope3List.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0) : "0";

        const scope1EmissionPercentage = (scope1Total / emissionTotal) * 100;
        const scope2EmissionPercentage = (scope2Total / emissionTotal) * 100;
        const scope3EmissionPercentage = (scope3Total / emissionTotal) * 100;

        return (
            <>
                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6 pe-0">
                                        <p className="mb-2">
                                            <span className="fs-16">Total Emission</span>
                                        </p>
                                        <p className="mb-2 fs-12">
                                            <span className="fs-25 fw-semibold lh-1 vertical-bottom mb-0">{getFormattedNumber(emissionTotal)}</span>
                                            <span className="ms-1 fs-10 fw-semibold text-muted">kgCO2e</span>
                                        </p>
                                        <a href="javascript:void(0);" className="fs-12 mb-0 text-primary">Show full stats<i className="ti ti-chevron-right ms-1"></i>
                                        </a>
                                    </div>
                                    <div className="col-6">
                                        {/* <p className="badge bg-success-transparent float-end d-inline-flex"><i className="ti ti-caret-up me-1"></i>42%</p> */}
                                        <p className="main-card-icon mb-0"><svg className="svg-primary" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" opacity=".3"></path><path d="M0,0h24v24H0V0z" fill="none"></path><g><path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z"></path><rect height="2" width="6" x="9" y="7"></rect><rect height="2" width="2" x="16" y="7"></rect><rect height="2" width="6" x="9" y="10"></rect><rect height="2" width="2" x="16" y="10"></rect></g></svg>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6 pe-0">
                                        <p className="mb-2">
                                            <span className="fs-16">Scope 1 Emission</span> </p>
                                        <p className="mb-2 fs-12">
                                            <span className="fs-25 fw-semibold lh-1 vertical-bottom mb-0">{getFormattedNumber(scope1Total)}</span>
                                            <span className="ms-1 fs-10 fw-semibold text-muted">kgCO2e</span>
                                        </p>
                                        <a href="javascript:void(0);" className="fs-12 mb-0 text-primary">Show full stats<i className="ti ti-chevron-right ms-1"></i></a>
                                    </div>
                                    <div className="col-6">
                                        <p className="badge bg-success-transparent float-end d-inline-flex">{getFormattedNumber(scope1EmissionPercentage)}%</p>
                                        <p className="main-card-icon mb-0">
                                            <svg className="svg-primary" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"></rect></g><g><g><path d="M12,6c-3.87,0-7,3.13-7,7s3.13,7,7,7s7-3.13,7-7S15.87,6,12,6z M13,14h-2V8h2V14z" opacity=".3"></path><rect height="2" width="6" x="9" y="1"></rect><path d="M19.03,7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42,1.42C16.07,4.74,14.12,4,12,4c-4.97,0-9,4.03-9,9 c0,4.97,4.02,9,9,9s9-4.03,9-9C21,10.88,20.26,8.93,19.03,7.39z M12,20c-3.87,0-7-3.13-7-7s3.13-7,7-7s7,3.13,7,7S15.87,20,12,20z"></path><rect height="6" width="2" x="11" y="8"></rect></g></g>
                                            </svg>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6 pe-0">
                                        <p className="mb-2">
                                            <span className="fs-16">Scope 2 Emission</span>
                                        </p>
                                        <p className="mb-2 fs-12">
                                            <span className="fs-25 fw-semibold lh-1 vertical-bottom mb-0">{getFormattedNumber(scope2Total)}</span>
                                            <span className="ms-1 fs-10 fw-semibold text-muted">kgCO2e</span>
                                        </p>
                                        <a href="javascript:void(0);" className="fs-12 mb-0 text-primary">Show full stats<i className="ti ti-chevron-right ms-1"></i></a>
                                    </div>
                                    <div className="col-6">
                                        <p className="badge bg-success-transparent float-end d-inline-flex">{getFormattedNumber(scope2EmissionPercentage)}%</p>
                                        <p className="main-card-icon mb-0">
                                            <svg className="svg-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M13 4H6v16h12V9h-5V4zm3 14H8v-2h8v2zm0-6v2H8v-2h8z" opacity=".3"></path><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"></path>
                                            </svg>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6 pe-0">
                                        <p className="mb-2">
                                            <span className="fs-16">Scope 3 Emission</span> </p>
                                        <p className="mb-2 fs-12">
                                            <span className="fs-25 fw-semibold lh-1 vertical-bottom mb-0">{getFormattedNumber(scope3Total)}</span>
                                            <span className="ms-1 fs-10 fw-semibold text-muted">kgCO2e</span>
                                        </p>
                                        <a href="javascript:void(0);" className="fs-12 mb-0 text-primary">Show full stats<i className="ti ti-chevron-right ms-1"></i></a>
                                    </div>
                                    <div className="col-6"> <p className="badge bg-success-transparent float-end d-inline-flex">{getFormattedNumber(scope3EmissionPercentage)}%</p>
                                        <p className="main-card-icon mb-0">
                                            <svg className="svg-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z" opacity=".3"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"></path>
                                            </svg>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function renderBranchEmissionList() {
        const emissionTotal = emissionList ? emissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0) : "...";
        const renderTableFooter = () => {
            const branchEmissionList = emissionList;
            const branchEmissionTotal = branchEmissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);

            const scope1EmissionList = branchEmissionList.filter((i: any) => i.categoryId === 1);
            const scope1EmissionTotal = scope1EmissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);

            const scope2EmissionList = branchEmissionList.filter((i: any) => i.categoryId === 2);
            const scope2EmissionTotal = scope2EmissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);

            const scope3EmissionList = branchEmissionList.filter((i: any) => i.categoryId === 3);
            const scope3EmissionTotal = scope3EmissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
            return (
                <tr>
                    <td className="">
                        <span className="fw-semibold">
                            Total
                        </span>
                    </td>
                    <td>
                        <span className="fw-semibold">
                            {getFormattedFloat(scope1EmissionTotal)}
                        </span>
                    </td>
                    <td>
                        <span className="fw-semibold">
                            {getFormattedFloat(scope2EmissionTotal)}
                        </span>
                    </td>
                    <td>
                        <span className="fw-semibold">
                            {getFormattedFloat(scope3EmissionTotal)}
                        </span>
                    </td>
                    <td><span className="fw-semibold">{getFormattedFloat(branchEmissionTotal)}</span> </td>
                </tr>
            )
        }
        return (
            <div className="card custom-card">
                <div className="card-header">
                    <div className="card-title">Emission Distribution by Branch</div>
                </div>
                <div className="card-body p-0">


                    <div className="table-responsive">
                        <table className="table text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Branch</th>
                                    <th scope="col">Scope 1 Emission</th>
                                    <th scope="col">Scope 2 Emission</th>
                                    <th scope="col">Scope 3 Emission</th>
                                    <th scope="col">Total Emission</th>
                                </tr>
                            </thead>
                            <tbody className="top-selling">
                                {
                                    branchList && branchList.map((branch: any, index: any) => {
                                        const branchEmissionList = emissionList.filter((i: any) => i.branchId === branch.id);
                                        const branchEmissionTotal = branchEmissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);

                                        const scope1EmissionList = branchEmissionList.filter((i: any) => i.categoryId === 1);
                                        const scope1EmissionTotal = scope1EmissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
                                        const scope1EmissionPercentage = (scope1EmissionTotal / emissionTotal) * 100;

                                        const scope2EmissionList = branchEmissionList.filter((i: any) => i.categoryId === 2);
                                        const scope2EmissionTotal = scope2EmissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
                                        const scope2EmissionPercentage = (scope2EmissionTotal / emissionTotal) * 100;

                                        const scope3EmissionList = branchEmissionList.filter((i: any) => i.categoryId === 3);
                                        const scope3EmissionTotal = scope3EmissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
                                        const scope3EmissionPercentage = (scope3EmissionTotal / emissionTotal) * 100;

                                        return (
                                            <>
                                                <tr>
                                                    <td className="">
                                                        <Link to={`${constant.component.ghgBranchEmissionDashboard.url}?bid=${branch.id}`}>{branch.branchName}</Link>
                                                    </td>
                                                    <td>
                                                        {getFormattedFloat(scope1EmissionTotal)}
                                                        {
                                                            scope1EmissionPercentage !== 0 && <span className="badge bg-primary-transparent text-primary mx-1">{getFormattedNumber(scope1EmissionPercentage)}%</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {getFormattedFloat(scope2EmissionTotal)}
                                                        {
                                                            scope2EmissionPercentage !== 0 && <span className="badge bg-primary-transparent text-primary mx-1">{getFormattedNumber(scope2EmissionPercentage)}%</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {getFormattedFloat(scope3EmissionTotal)}
                                                        {
                                                            scope3EmissionPercentage !== 0 && <span className="badge bg-primary-transparent text-primary mx-1">{getFormattedNumber(scope3EmissionPercentage)}%</span>
                                                        }
                                                    </td>
                                                    <td><span className="fw-semibold">{getFormattedFloat(branchEmissionTotal)}</span> </td>
                                                </tr>
                                            </>

                                        );
                                    })
                                }
                                {emissionList && emissionTotal > 0 && renderTableFooter()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    function renderBranchEmissionPieChart() {
        // const colors = randomColor({ luminosity: 'light', count: branchList ? branchList.length : 0 });
        const data: any = branchList
            ? branchList.map((item: any, index: any) => {
                const value = emissionList.filter((i: any) => i.branchId === item.id).reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
                return { name: item.branchName, value: value, color: constant.colors[index] };
            })
            : [];
        const emissionTotal = emissionList ? emissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0) : "...";
        const labels = data.map((i: any) => i.name);
        const series = data.map((i: any) => i.value);


        return (
            <div className="card custom-card overflow-hidden" >
                <div className="card-header justify-content-between">
                    <div className="card-title">Emission Distribution By Branch</div>
                </div>
                <div className="card-body">
                    <div className="">
                        <Chart2 series={series} labels={labels} total={getFormattedNumber(emissionTotal)} />
                    </div>
                    <div className="row row-cols-12 border-top border-block-start-dashed">
                        {
                            data.map((i: any) => {
                                return (
                                    <div className="col p-0">
                                        <div className="ps-4 py-3 pe-3 text-center border-end border-inline-end-dashed">
                                            <div className="d-flex align-items-center">
                                                <span className="avatar avatar-sm avatar-rounded me-2" style={{ height: "0.5rem", width: "0.5rem", backgroundColor: i.color }}> <i className="fs-16"></i> </span>
                                                <span className="text-muted fs-12">
                                                    {i.name}
                                                </span>
                                            </div>
                                            <div><span className="fs-16 fw-semibold">{getFormattedNumber(i.value)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {/* <div className="col p-0">
                            <div className="ps-4 py-3 pe-3 text-center border-end border-inline-end-dashed">
                                <span className="text-muted fs-12 mb-1 crm-lead-legend mobile d-inline-block">Mobile
                                </span>
                                <div><span className="fs-16 fw-semibold">1,624</span>
                                </div>
                            </div>
                        </div>
                        <div className="col p-0">
                            <div className="p-3 text-center border-end border-inline-end-dashed">
                                <span className="text-muted fs-12 mb-1 crm-lead-legend desktop d-inline-block">Desktop
                                </span>
                                <div><span className="fs-16 fw-semibold">1,267</span></div>
                            </div>
                        </div>
                        <div className="col p-0">
                            <div className="p-3 text-center border-end border-inline-end-dashed">
                                <span className="text-muted fs-12 mb-1 crm-lead-legend laptop d-inline-block">Laptop
                                </span>
                                <div><span className="fs-16 fw-semibold">1,153</span>
                                </div>
                            </div>
                        </div>
                        <div className="col p-0">
                            <div className="p-3 text-center">
                                <span className="text-muted fs-12 mb-1 crm-lead-legend tablet d-inline-block">Tablet
                                </span>
                                <div><span className="fs-16 fw-semibold">679</span></div>
                            </div>
                        </div> */}
                    </div>

                </div>
            </div >
        );
    }

    function renderEmissionTrend(emissionList: any, emissionCategory: any) {
        if (emissionList === undefined) {
            return <Shimmer />
        }

        const months = [];
        // find min and max date
        const datesArray = emissionList.map((i: any) => new Date(i.dated));
        const minDate = new Date(Math.min(...datesArray));
        const maxDate = new Date(Math.max(...datesArray));
        var a = moment(minDate).add(-1, 'months');
        var b = moment(maxDate).add(1, 'months');

        for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'months')) {
            months.push(m.format('YYYY-MM'))
        }

        const cat1Series: any = { type: 'line', name: 'Scope 1', data: [] }
        const cat2Series: any = { type: 'line', name: 'Scope 2', data: [] }
        const cat3Series: any = { type: 'line', name: 'Scope 3', data: [] }

        months.forEach((m: any) => {
            const dt_filter = emissionList.filter((d: any) => moment(d.dated).format('YYYY-MM') === m);
            const cat1_filter = dt_filter.filter((c: any) => c.categoryId === 1);
            const cat2_filter = dt_filter.filter((c: any) => c.categoryId === 2);
            const cat3_filter = dt_filter.filter((c: any) => c.categoryId === 3);

            if (cat1_filter.length > 0) {
                const total = cat1_filter.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
                cat1Series.data.push({ x: m, y: total })
            } else {
                cat1Series.data.push({ x: m, y: 0 })
            }

            if (cat2_filter.length > 0) {
                const total = cat2_filter.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
                cat2Series.data.push({ x: m, y: total })
            } else {
                cat2Series.data.push({ x: m, y: 0 })
            }

            if (cat3_filter.length > 0) {
                const total = cat3_filter.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
                cat3Series.data.push({ x: m, y: total })
            } else {
                cat3Series.data.push({ x: m, y: 0 })
            }
        })
        const series = [cat1Series, cat2Series, cat3Series];
        const legends = ['Scope 1', 'Scope 2', 'Scope 3'];
        return (
            <div className="card custom-card">
                <div className="card-header">
                    <div className="card-title">Emission Trend (kgCO2e)</div>
                </div>
                <div className="card-body">
                    <ApexLineChart series={series} legends={legends} />
                </div>
            </div>
        );
    }

    function renderTopEmissionCategory() {
        if (emissionList === undefined) {
            return <div className="col-xxl-4">
                <div className="card">
                    <div className="card-body">
                        <Shimmer />
                    </div>
                </div>
            </div>
        }
        const _list = [
            { name: 'Waste Generated from the Operation', emission: 7000, color: uniqolor('Waste Generated sssfrom the Operation', { lightness: 60 }).color, },
            { name: 'Purchased Electricity from the Grid', emission: 6500, color: uniqolor('Purchased Electricity from the Grid', { lightness: 60 }).color },
            { name: 'Fugitive Emissions', emission: 5780, color: uniqolor('Fugitive Emissions', { lightness: 60 }).color },
            { name: 'Purchased Goods and Services', emission: 5500, color: uniqolor('Purchased Goods and Services', { lightness: 60 }).color },
            { name: 'Business Travel', emission: 4780, color: uniqolor('Business Travel', { lightness: 60 }).color },
        ]
        const total = _list.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);

        return (
            <>
                <div className="card custom-card">
                    <div className="card-header  justify-content-between">
                        <div className="card-title">Top Emission Category</div>
                    </div>
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                            <h4 className="fw-bold mb-0">{getFormattedNumber(total)}</h4>
                        </div>
                        <div className="progress-stacked progress-animate progress-xs mb-4">
                            {
                                _list.map((i: any) => {
                                    const percentage = (i.emission / total) * 100;
                                    return (
                                        <div className="progress-bar" role="progressbar" style={{ width: `${percentage}%`, backgroundColor: i.color }} />
                                    )
                                })
                            }
                        </div>
                        <div className="table-responsive">
                            <table className="table text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Emission Category</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Emission(tCO2e)</th>
                                    </tr>
                                </thead>
                                <tbody className="top-selling">
                                    {
                                        _list.map((i: any) => {
                                            return (
                                                <tr>
                                                    <td className="d-flex align-items-center">
                                                        <span className="avatar avatar-sm avatar-rounded me-2" style={{ height: "0.5rem", width: "0.5rem", backgroundColor: i.color }}> <i className="fs-16"></i> </span>
                                                        {i.name}
                                                    </td>
                                                    <td className="text-end">{getFormattedNumber(i.emission)}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div >
            </>
        );
    }

    function renderTopEmissionSource() {
        if (emissionList === undefined) {
            return <div className="col-xxl-4">
                <div className="card">
                    <div className="card-body">
                        <Shimmer />
                    </div>
                </div>
            </div>
        }

        const _list = [
            { name: 'Electricity used in the Facilities', emission: 6500, color: uniqolor('Electricity used in the Facilities', { lightness: 60 }).color },
            { name: 'Packaging Operations', emission: 4500, color: uniqolor('Packaging Operations', { lightness: 60 }).color },
            { name: 'Fugitive - Central AC', emission: 3000, color: uniqolor('Fugitive - Central AC', { lightness: 60 }).color },
            { name: 'Purchased - IT Equipment', emission: 2890, color: uniqolor('Purchased - IT Equipment', { lightness: 60 }).color },
            { name: 'E-Waste', emission: 2300, color: uniqolor('E-Waste', { lightness: 60 }).color },
        ]
        const total = _list.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
        return (
            <>
                <div className="card custom-card">
                    <div className="card-header  justify-content-between">
                        <div className="card-title">Top Emission Source</div>
                    </div>
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-3">
                            <h4 className="fw-bold mb-0">{getFormattedNumber(total)}</h4>
                        </div>
                        <div className="progress-stacked progress-animate progress-xs mb-4">
                            {
                                _list.map((i: any) => {
                                    const percentage = (i.emission / total) * 100;
                                    return (
                                        <div className="progress-bar" role="progressbar" style={{ width: `${percentage}%`, backgroundColor: i.color }} />
                                    )
                                })
                            }
                        </div>
                        <div className="table-responsive">
                            <table className="table text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Emission Category</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Emission(tCO2e)</th>
                                    </tr>
                                </thead>
                                <tbody className="top-selling">
                                    {
                                        _list.map((i: any) => {
                                            return (
                                                <tr>
                                                    <td className="d-flex align-items-center">
                                                        <span className="avatar avatar-sm avatar-rounded me-2" style={{ height: "0.5rem", width: "0.5rem", backgroundColor: i.color }}> <i className="fs-16"></i> </span>
                                                        {i.name}
                                                    </td>
                                                    <td className="text-end">{getFormattedNumber(i.emission)}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div >
            </>
        );
    }

    function renderTopSupplier() {
        if (emissionList === undefined) {
            return <div className="col-xxl-4">
                <div className="card">
                    <div className="card-body">
                        <Shimmer />
                    </div>
                </div>
            </div>
        }

        const _list = [
            { name: 'Palm Electronics', amount: '5,570,000', emission: '980.32' },
            { name: 'PowerGen Solutions', amount: '5,050,000', emission: '1363.5' },
            { name: 'The Flavor Factory', amount: '4,000,000', emission: '880' },
            { name: 'OakLeaf Interiors', amount: '3,500,000', emission: '1067.5' },
            { name: 'Gourmet Goods Group', amount: '3,000,000', emission: '840' },
            { name: 'SafeGuard Solutions', amount: '2,800,000', emission: '756' },
            { name: 'Paper Haven', amount: '2,500,000', emission: '440' },
            { name: 'ExpertEdge Advisors', amount: '2,300,000', emission: '200' },
            { name: 'ProVision Partners', amount: '2,000,000', emission: '352' },
            { name: 'WriteAway Warehouse', amount: '1,700,000', emission: '650' }
        ]
        return (
            <>
                <div className="card custom-card">
                    <div className="card-header  justify-content-between">
                        <div className="card-title">Top Emission Supplier</div>
                        {/* <div className="dropdown">
                    <a aria-label="anchor" href="javascript:void(0);" className="btn btn-icon btn-sm btn-light" data-bs-toggle="dropdown" aria-expanded="false"> <i className="fe fe-more-vertical"></i> </a> <ul className="dropdown-menu"> <li><a className="dropdown-item" href="javascript:void(0);">Week</a></li> <li><a className="dropdown-item" href="javascript:void(0);">Month</a></li> <li><a className="dropdown-item" href="javascript:void(0);">Year</a></li> </ul>
                  </div> */}
                    </div>
                    <div className="card-body" style={{ height: '23.8rem', overflowY: "scroll" }}>
                        <div className="table-responsive">
                            <table className="table text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Supplier Name</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Amount(INR)</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Emission(tCO2e)</th>
                                    </tr>
                                </thead>
                                <tbody className="top-selling">
                                    {
                                        _list.map((i: any) => {
                                            return (
                                                <tr>
                                                    <td className=""><a href="/ghg/dashboard/branch-emission?bid=1">{i.name}</a></td>
                                                    <td className="text-end">{i.amount}</td>
                                                    <td className="text-end">{i.emission}</td>
                                                    {/* <td>130,506.16<span className="badge bg-primary-transparent text-primary mx-1">39%</span></td>
                                        <td><span className="fw-semibold">336,139.69</span> </td> */}
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function renderCategoryDistribution() {
        if (emissionCategory === undefined) {
            return <>
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card">
                            <div className="card-body">
                                <Shimmer />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        const renderEmissionDistrbution = (categoryId: any) => {
            const categoryEmissionList = emissionList.filter((i: any) => i.categoryId === categoryId);
            const categoryEmissionTotal = categoryEmissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
            const categoryMapping = emissionMapping.filter((i: any) => i.categoryId === categoryId);
            const data: any = categoryEmissionList.map((item: any) => {
                return {
                    name: item.emissionName,
                    value: item.emission,
                };
            });

            const uniqueDataName = _.groupBy(data, function (item: any) { return item.name; });
            const uniqueNameList = Object.keys(uniqueDataName);
            const newData = uniqueNameList.map((i: any) => {
                const value = uniqueDataName[i].reduce((acc: any, item: any) => acc + parseFloat(item.value), 0);
                return { name: i, value };
            });

            const labels = newData.map((i: any) => i.name);
            const series = newData.map((i: any) => i.value);
            const total = newData.reduce((acc: any, item: any) => acc + parseFloat(item.value), 0);
            const colors = constant.colors.slice(0, series.length);

            // const labels = ['Category 1: Purchased Goods and Services', 'Category 2: Capital Goods', 'Category 3: Fuel- and Energy-Related Activities', 'Category 5: Waste Generated in Operations', 'Category 7: Employee Commuting'];
            // const series = [102553.78114860314, 11459.728625927359, 9314.868021, 24.615250000000003, 7153.168682000001]
            // const total = 100;
            // const colors = constant.colors.slice(0, series.length);

            return (
                <div className="card custom-card">
                    <div className="card-header  justify-content-between">
                        <div className="card-title">Scope {categoryId} Emission Distribution (kgCO2e)</div>
                    </div>
                    <div className="card-body">

                        <Chart3
                            series={series}
                            labels={labels}
                            total={getFormattedNumber(total)}
                        />
                        <ul className="mt-3 mb-0 pt-3 list-unstyled">
                            {
                                categoryMapping.map((categoryItem: any, categoryIndex: number) => {
                                    const scopeEmissionList = categoryEmissionList.filter((i: any) => i.emissionId === categoryItem.emissionId);
                                    const scopeEmissionTotal = scopeEmissionList.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
                                    const scopePercentage = (scopeEmissionTotal / categoryEmissionTotal) * 100;
                                    return (
                                        <>
                                            <li className="mb-3">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center lh-1">
                                                        {/* <span className="avatar avatar-xs me-2 avatar-rounded"> <img src="../assets/images/flags/french_flag.jpg" alt="" /> </span> */}
                                                        <span className="">{categoryItem.name}</span>
                                                    </div>
                                                    {/* <div>
                                                        <span className="badge bg-primary-transparent text-primary mx-1">{getFormattedNumber(scopePercentage)}%</span>
                                                    </div> */}
                                                    <div className="fs-14">
                                                        {getFormattedFloat(scopeEmissionTotal)}
                                                        <span className="badge bg-primary-transparent text-primary mx-1">{getFormattedNumber(scopePercentage)}%</span>
                                                    </div>
                                                </div>
                                            </li>
                                            {/* <li className="d-none">
                                                <div className="d-flex align-items-top flex-wrap">
                                                    <div className="flex-fill">
                                                        <span className="text-muted fs-12">{categoryItem.name}</span>
                                                    </div>
                                                    <div className="fw-semibold fs-15">{getFormattedFloat(scopeEmissionTotal)}</div>
                                                </div>
                                            </li> */}
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            )
        }
        return (
            <div className="row">
                <div className="col-xxl-4">
                    {renderEmissionDistrbution(1)}
                </div>
                <div className="col-xxl-4">
                    {renderEmissionDistrbution(2)}
                </div>
                <div className="col-xxl-4">
                    {renderEmissionDistrbution(3)}
                </div>
            </div>
        );
    }
}