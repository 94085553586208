import { useState } from "react"
import constant from "../../constant";
// import LlamaAI from 'llamaai';
import Loader from "../common/Loader";
import OpenAI from "openai";
import { sleep, sleepMS } from "../../utils";

// LL-9ynTwgUKaM1JwSQTAGl5ifVOcq0T4uycF3QqdIEqQf2o9UPWKraR7zP9ZnyLszPV
const questionList = [
    {
        question: 'Describe policies or commitments to halt and reverse biodiversity loss',
        answer: `Policies and commitments aimed at halting and reversing biodiversity loss involve comprehensive, multi-faceted approaches that engage governments, organizations, and communities at international, national, and local levels. Some notable policies and commitments include:

        ### International Agreements
        1. **Convention on Biological Diversity (CBD)**:
           - **Aichi Biodiversity Targets**: Established in 2010, these 20 global targets aimed to address the underlying causes of biodiversity loss, reduce direct pressures on biodiversity, safeguard ecosystems, species, and genetic diversity, enhance the benefits to all from biodiversity and ecosystem services, and enhance implementation through participatory planning, knowledge management, and capacity building.
           - **Post-2020 Global Biodiversity Framework**: Currently being developed, it aims to be a stepping stone towards achieving the 2050 Vision of "Living in harmony with nature."
        
        2. **Paris Agreement**:
           - While primarily focused on climate change, the Paris Agreement also acknowledges the importance of biodiversity and ecosystems. Many Nationally Determined Contributions (NDCs) include commitments related to protecting and restoring biodiversity as part of climate mitigation and adaptation efforts.
        
        3. **Ramsar Convention on Wetlands**:
           - An international treaty for the conservation and sustainable use of wetlands, recognizing their ecological functions and their economic, cultural, scientific, and recreational value.
        
        ### National Policies
        1. **Protected Areas**:
           - Many countries have established national parks, wildlife reserves, and marine protected areas (MPAs) to conserve critical habitats and species. Examples include the National Park Service in the United States, Natura 2000 network in the European Union, and various protected areas in countries like Brazil, Australia, and Canada.
        
        2. **Biodiversity Action Plans (BAPs)**:
           - National and regional plans that outline strategies and priority actions to conserve species and habitats. For example, the UK’s Biodiversity Action Plan and India's National Biodiversity Action Plan.
        
        3. **Legislation**:
           - Laws aimed at protecting endangered species, such as the Endangered Species Act in the United States, the Wildlife Protection Act in India, and the Habitats Directive in the European Union.
        
        ### Local and Community Initiatives
        1. **Community-Based Conservation**:
           - Programs that involve local communities in the management and stewardship of natural resources, ensuring that they benefit from conservation efforts. Examples include community forest management in Nepal and community conservancies in Kenya.
        
        2. **Sustainable Land Management Practices**:
           - Encouraging sustainable agriculture, forestry, and fishing practices that maintain ecosystem health and productivity. Examples include agroforestry, organic farming, and sustainable fisheries certification like MSC (Marine Stewardship Council).
        
        ### Corporate and NGO Commitments
        1. **Corporate Sustainability Initiatives**:
           - Many corporations have committed to reducing their environmental footprint through sustainable sourcing, reducing deforestation, and supporting conservation projects. Examples include the commitments under the Business for Nature coalition.
        
        2. **Non-Governmental Organizations (NGOs)**:
           - Organizations like the World Wildlife Fund (WWF), Conservation International, and The Nature Conservancy work on projects to protect biodiversity, restore ecosystems, and influence policy.
        
        ### Financial Mechanisms
        1. **Green Bonds and Conservation Finance**:
           - Innovative financial instruments and mechanisms to fund biodiversity conservation projects, such as green bonds, biodiversity offsets, and Payment for Ecosystem Services (PES).
        
        2. **Global Environment Facility (GEF)**:
           - Provides funding to projects that protect the global environment, including biodiversity conservation.
        
        ### Science and Education
        1. **Research and Monitoring**:
           - Investment in scientific research to understand biodiversity patterns, threats, and conservation strategies. Institutions like universities, research centers, and international bodies like IPBES (Intergovernmental Science-Policy Platform on Biodiversity and Ecosystem Services) play crucial roles.
        
        2. **Public Awareness and Education**:
           - Initiatives to raise awareness about biodiversity and the importance of conservation through education programs, campaigns, and media.
        
        By combining these various policies and commitments, there is potential to create a holistic approach to halt and reverse biodiversity loss, promoting a sustainable and biodiverse future.`
    }
]
const AIHelpButton = (props: { question: string, onChange: any, onShowLoader: any, css: string, children: any }) => {
    const [question, setQuestion] = useState(props.question);
    const [answer, setAnswer] = useState('');
    const openai = new OpenAI({
        apiKey: constant.key.openapi,
        dangerouslyAllowBrowser: true
    })

    const handleSubmit = async (e: any) => {
        try {
            const question = props.question;
            const checkExistingAnswer = questionList.find((i: any) => i.question === question);
            if (checkExistingAnswer) {
                sendAnswerInChunks(checkExistingAnswer.answer)
            } else {
                if (props.question === question && answer) {
                    sendAnswerInChunks(answer)
                } else {
                    {
                        // option 1
                        // const params: any = {
                        //     messages: [{ role: 'user', content: question }],
                        //     model: 'gpt-4o',
                        // };
                        // const result: any = await openai.chat.completions.create(params);
                        // const res = result.choices[0].message.content;
                    }
                    {
                        // option 2
                        const params: any = {
                            messages: [{ role: 'user', content: question }],
                            model: 'gpt-4o',
                            stream: true,
                        };
                        const stream: any = await openai.beta.chat.completions.stream(params);

                        stream.on('connect', () => {
                            props.onShowLoader(true);
                        })

                        stream.on('content', (delta: any, snapshot: any) => {
                            props.onChange(snapshot);
                            setAnswer(snapshot)
                        });

                        stream.on('end', () => {
                            props.onShowLoader(false);
                        })

                        stream.on('error', (error: any) => {
                            props.onShowLoader(false);
                            console.log(error);
                        })
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    const sendAnswerInChunks = async (answer: any) => {
        // const chunks = answer.match(/.{1,10}/g);
        // const chunks = answer.match(/[^\r\n]+/g);
        const chunks = answer.match(/[^]{1,4}/g)
        let snapshot = '';
        props.onShowLoader(true);
        for (let i = 0; i < chunks.length; i++) {
            await sleepMS(25)
            snapshot += chunks[i]
            props.onChange(snapshot);
        }
        props.onShowLoader(false);
    }

    return (
        <>
            <button className={props.css} type="button" onClick={handleSubmit}>{props.children}</button>
        </>
    );
};


export default AIHelpButton;