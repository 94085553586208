import AddDetailButton from "../../component/decarbonization/action-library/AddDetailButton"
import EditDetailButton from "../../component/decarbonization/action-library/EditDetailButton"
import HelpButton from "../../component/ghg/common/HelpButton";
import constant from "../../constant";

function ActionLibrary() {
    const title = constant.component.decarbonizationActionLibrary.title;

    const scope1 = () => <span className="badge bg-primary-transparent rounded-pill fs--2 me-1">Scope 1</span>;
    const scope2 = () => <span className="badge bg-primary-transparent rounded-pill fs--2 me-1">Scope 2</span>;
    const scope3 = () => <span className="badge bg-primary-transparent rounded-pill fs--2 me-1">Scope 3</span>;

    const scopeType = (name: any) => <span className="badge bg-primary-transparent rounded-pill fs--2 me-1">{name}</span>;

    return (
        <div className="container-fluid">
            <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                <h1 className="page-title fw-semibold fs-18 mb-0">{title}</h1>
                <div className="ms-md-1 ms-0">
                    <nav>
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="javascript:void(0);">Decarbonization</a></li>
                            <li className="breadcrumb-item active" aria-current="page">{title}</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="d-md-flex d-block flex-wrap align-items-center justify-content-between">
                                <div className="flex-fill">
                                    <ul className="nav nav-pills nav-style-3" role="tablist">
                                        <li className="nav-item" role="presentation"><a className="nav-link active" data-bs-target="#tab-1" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="true">All</a> </li>
                                        <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#tab-2" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Energy Sources</a> </li>
                                        <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#tab-3" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Nature Investments</a> </li>
                                        <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#tab-4" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Waste Management</a> </li>
                                        <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#tab-5" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Digital</a> </li>
                                        <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#tab-6" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Travel & Transport</a> </li>
                                        <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#tab-7" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Carbon Capture</a> </li>
                                    </ul>
                                </div>
                                <div className="d-flex flex-wrap align-items-center justify-content-evenly">
                                    <AddDetailButton onSubmitComplete={() => { }} css="btn btn-sm btn-primary align-items-center d-inline-flex"><i className="ti ti-plus me-1 fw-semibold"></i>Add New Action</AddDetailButton>
                                    {/* <button type="button" className="btn btn-sm btn-secondary align-items-center d-inline-flex ms-3"><i className="bx bxs-file-import me-1 fw-semibold"></i>Bulk Upload</button> */}
                                    <HelpButton css="btn btn-sm btn-info align-items-center d-inline-flex ms-3"><i className="bx bx-help-circle me-1 fw-semibold"></i>Help</HelpButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <div className="tab-content">
                    <div className="tab-pane active p-0" id="tab-1" role="tabpanel">
                        <div className="row mb-3">
                            <div className="col-md-4 mb-3">
                                <div className="card custom-card border border-warning">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-warning"></div>
                                                <h6 className="mb-0">Wind Energy
                                                    <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                        <span className="fas fa-edit"></span>
                                                    </EditDetailButton>
                                                </h6>

                                            </div>
                                            <div className="">
                                                <span className="badge bg-warning-transparent rounded-pill fs--2 ms-1">Energy Sources</span>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="">
                                                {scope2()}
                                                {scopeType('Electricity')}
                                            </div>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Wind is one of the predominant sources of power generation in the Net Zero Emission</p>
                                        <p className="text-primary fw-bold fs--1 text-center">1 MW Energy Produced Saves ~100kgCO2e</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-danger-transparent">Capital Intensive</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Generate Carbon Credits</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card custom-card border border-warning">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-warning"></div>
                                                <h6 className="mb-0">Solar Energy
                                                    <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                        <span className="fas fa-edit"></span>
                                                    </EditDetailButton>
                                                </h6>

                                            </div>
                                            <div className="">
                                                <span className="badge bg-warning-transparent rounded-pill fs--2 ms-1">Energy Sources</span>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="">
                                                {scope2()}
                                                {scopeType('Electricity')}
                                            </div>
                                        </div>

                                        <p className="text-700 fs--1 mt-3">Generate Energy from Solar Panels is one of the predominant sources of power generation in the Net Zero Emission</p>
                                        <p className="text-primary fw-bold fs--1 text-center">1 MW Energy Produced Saves ~80kgCO2e</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-danger-transparent">Capital Intensive</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Generate Carbon Credits</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card custom-card border border-success">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-success"></div>
                                                <h6 className="mb-0">Urban forestation
                                                    <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                        <span className="fas fa-edit"></span>
                                                    </EditDetailButton>
                                                </h6>

                                            </div>
                                            <div className="">
                                                <span className="badge bg-warning-transparent rounded-pill fs--2 ms-1">Nature Investments</span>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="">
                                                {/* {scope2()} */}
                                                {scopeType('Carbon Credit Generation')}
                                            </div>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Trees contribute to carbon storage in urban soils, further enhancing the overall carbon sequestration capacity of urban forests.</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Urban Forests Trees can store ~178 tonnes/ha</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-danger-transparent">Capital Intensive</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Generate Carbon Credits</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card custom-card border border-info">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-info"></div>
                                                <h6 className="mb-0">Sustainable Water Management
                                                    <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                        <span className="fas fa-edit"></span>
                                                    </EditDetailButton>
                                                </h6>

                                            </div>
                                            <div className="">
                                                <span className="badge bg-info-transparent rounded-pill fs--2 ms-1">Waste Management</span>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="">
                                                {scope3()}
                                                {scopeType('Waste Generated From Operation')}
                                            </div>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">optimizing water supply infrastructure and reducing water losses, can lower the energy required for pumping water from sources to treatment plants and distribution systems.</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Reduce energy consumption in water supply systems up to 15-30%</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Economically Viable</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card custom-card border border-info">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-info"></div>
                                                <h6 className="mb-0">Waste To Energy Technology
                                                    <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                        <span className="fas fa-edit"></span>
                                                    </EditDetailButton>
                                                </h6>

                                            </div>
                                            <div className="">
                                                <span className="badge bg-info-transparent rounded-pill fs--2 ms-1">Waste Management</span>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="">
                                                {scope3()}
                                                {scopeType('End of Life Treatment')}
                                            </div>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Implement Technology to use waste materials to generate energy. New devices would  be required to implement this action. One time investment leads to helps in decarbonization</p>
                                        <p className="text-primary fw-bold fs--1 text-center">1 tonne of solid waste can generate  ≈  500-600 kwh</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Economically Viable</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card custom-card border border-info">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-info"></div>
                                                <h6 className="mb-0">Waste Recycling
                                                    <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                        <span className="fas fa-edit"></span>
                                                    </EditDetailButton>
                                                </h6>

                                            </div>
                                            <div className="">
                                                <span className="badge bg-info-transparent rounded-pill fs--2 ms-1">Waste Management</span>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="">
                                                {scope3()}
                                                {scopeType('Waste Generated from Operations')}
                                            </div>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Implement Technology to recycle e-waste. E-waste includes electronics items like computers, printers etc. and batteries. This initiatives helps in decarbonization</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Recycling 1 kg of Electronic item can reduce 1.4 kgCO2e</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Economically Viable</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card custom-card border border-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-primary"></div>
                                                <h6 className="mb-0">Move to cloud computing
                                                    <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                        <span className="fas fa-edit"></span>
                                                    </EditDetailButton>
                                                </h6>

                                            </div>
                                            <div className="">
                                                <span className="badge bg-primary-transparent rounded-pill fs--2 ms-1">Digital</span>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="">
                                                {scope3()}
                                                {scopeType('Purchase Goods & Services')}
                                            </div>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Move to energy efficient cloud service providers instead of local data centers. On demand cloud servers helps in reducing emissions</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Potentially reduction in energy consumption ~20% - 40%</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Economically Viable</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card custom-card border border-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-primary"></div>
                                                <h6 className="mb-0">Encourage Digitization for Day To Day Work
                                                    <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                        <span className="fas fa-edit"></span>
                                                    </EditDetailButton>
                                                </h6>

                                            </div>
                                            <div className="">
                                                <span className="badge bg-primary-transparent rounded-pill fs--2 ms-1">Digital</span>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="">
                                                {scope3()}
                                                {scopeType('Employee Commuting')}
                                            </div>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Encourage users to use digital mode of communication instead of paper based communication. Migrate paper based records to digital platforms</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Potentially reduction in waste and emissions up to ~100kgCO2e</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Economically Viable</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card custom-card border border-secondary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-primary"></div>
                                                <h6 className="mb-0">Use Electrical Vehicles
                                                    <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                        <span className="fas fa-edit"></span>
                                                    </EditDetailButton>
                                                </h6>

                                            </div>
                                            <div className="">
                                                <span className="badge bg-secondary-transparent rounded-pill fs--2 ms-1">Travel & Transport</span>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="">
                                                {scope3()}
                                                {scopeType('Employee Commuting')}
                                            </div>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Travel Sustainably with Electric Vehicle. Local transportation can be switched to electrical vehicles </p>
                                        <p className="text-primary fw-bold fs--1 text-center">Potentially reduction in emissions up to ~50-60% Per Kilometer</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-danger-transparent">Capital Intensive</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card custom-card border border-secondary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-primary"></div>
                                                <h6 className="mb-0">Use Greener Business Travel Approach
                                                    <EditDetailButton id={1} css="btn btn-sm btn-link p-0 ms-1" onSubmitComplete={() => { }}>
                                                        <span className="fas fa-edit"></span>
                                                    </EditDetailButton>
                                                </h6>

                                            </div>
                                            <div className="">
                                                <span className="badge bg-secondary-transparent rounded-pill fs--2 ms-1">Travel & Transport</span>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            <div className="">
                                                {scope3()}
                                                {scopeType('Business Travel')}
                                            </div>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Use Trains instead of air travel for short business travels. Use local public transport instead of private cars</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Potentially reduction in emissions up to ~50-60% Per Kilometer</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-danger-transparent">Capital Intensive</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0" id="tab-2" role="tabpanel">
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <div className="card custom-card border border-warning">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-warning"></div>
                                                <h6 className="mb-0">Wind Energy</h6>
                                            </div>
                                            <span className="badge bg-warning-transparent rounded-pill fs--2">Energy Sources</span>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Wind is one of the predominant sources of power generation in the Net Zero Emission</p>
                                        <p className="text-primary fw-bold fs--1 text-center">1 MW Energy Produced Saves ~100kgCO2e</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-danger-transparent">Capital Intensive</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Generate Carbon Credits</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card custom-card border border-warning">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-warning"></div>
                                                <h6 className="mb-0">Solar Energy</h6>
                                            </div>
                                            <span className="badge bg-warning-transparent rounded-pill fs--2">Energy Sources</span>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Generate Energy from Solar Panels is one of the predominant sources of power generation in the Net Zero Emission</p>
                                        <p className="text-primary fw-bold fs--1 text-center">1 MW Energy Produced Saves ~80kgCO2e</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-danger-transparent">Capital Intensive</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Generate Carbon Credits</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0" id="tab-3" role="tabpanel">
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <div className="card custom-card border border-success">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-success"></div>
                                                <h6 className="mb-0">Nature investment</h6>
                                            </div>
                                            <span className="badge bg-success-transparent rounded-pill fs--2">Nature Investments</span>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Trees contribute to carbon storage in urban soils, further enhancing the overall carbon sequestration capacity of urban forests.</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Urban Forests Trees can store ~178 tonnes/ha</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-danger-transparent">Capital Intensive</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Generate Carbon Credits</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0" id="tab-4" role="tabpanel">
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <div className="card custom-card border border-info">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-info"></div>
                                                <h6 className="mb-0">Sustainable Water Management</h6>
                                            </div>
                                            <span className="badge bg-info-transparent rounded-pill fs--2">Waste Management</span>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">optimizing water supply infrastructure and reducing water losses, can lower the energy required for pumping water from sources to treatment plants and distribution systems.</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Reduce energy consumption in water supply systems up to 15-30%</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Economically Viable</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card custom-card border border-info">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-info"></div>
                                                <h6 className="mb-0">Waste To Energy Technology</h6>
                                            </div>
                                            <span className="badge bg-info-transparent rounded-pill fs--2">Waste Management</span>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Implement Technology to use waste materials to generate energy. New devices would  be required to implement this action. One time investment leads to helps in decarbonization</p>
                                        <p className="text-primary fw-bold fs--1 text-center">1 tonne of solid waste can generate  ≈  500-600 kwh</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Economically Viable</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card custom-card border border-info">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-info"></div>
                                                <h6 className="mb-0">E-Waste Recycling</h6>
                                            </div>
                                            <span className="badge bg-info-transparent rounded-pill fs--2">Waste Management</span>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Implement Technology to recycle e-waste. E-waste includes electronics items like computers, printers etc. and batteries. This initiatives helps in decarbonization</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Recycling 1 kg of Electronic item can reduce 1.4 kgCO2e</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Economically Viable</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0" id="tab-5" role="tabpanel">
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <div className="card custom-card border border-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-primary"></div>
                                                <h6 className="mb-0">Move to cloud computing</h6>
                                            </div>
                                            <span className="badge bg-primary-transparent rounded-pill fs--2">Digital</span>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Move to energy efficient cloud service providers instead of local data centers. On demand cloud servers helps in reducing emissions</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Potentially reduction in energy consumption ~20% - 40%</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Economically Viable</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card custom-card border border-primary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-primary"></div>
                                                <h6 className="mb-0">Encourage Digitization for Day To Day Work</h6>
                                            </div>
                                            <span className="badge bg-primary-transparent rounded-pill fs--2">Digital</span>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Encourage users to use digital mode of communication instead of paper based communication. Migrate paper based records to digital platforms</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Potentially reduction in waste and emissions up to ~100kgCO2e</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Economically Viable</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0" id="tab-6" role="tabpanel">
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <div className="card custom-card border border-secondary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-secondary"></div>
                                                <h6 className="mb-0">Use Electrical Vehicles</h6>
                                            </div>
                                            <span className="badge bg-secondary-transparent rounded-pill fs--2">Travel & Transport</span>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Travel Sustainably with Electric Vehicle. Local transportation can be switched to electrical vehicles </p>
                                        <p className="text-primary fw-bold fs--1 text-center">Potentially reduction in emissions up to ~50-60% Per Kilometer</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-danger-transparent">Capital Intensive</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card custom-card border border-secondary">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between rounded-3 mb-2">
                                            <div className="d-flex gap-2">
                                                <div className="vr rounded ps-1 bg-secondary"></div>
                                                <h6 className="mb-0">Use Greener Business Travel Approach</h6>
                                            </div>
                                            <span className="badge bg-secondary-transparent rounded-pill fs--2">Travel & Transport</span>
                                        </div>
                                        <p className="text-700 fs--1 mt-3">Use Trains instead of air travel for short business travels. Use local public transport instead of private cars</p>
                                        <p className="text-primary fw-bold fs--1 text-center">Potentially reduction in emissions up to ~50-60% Per Kilometer</p>
                                        <div className="text-center">
                                            <span className="badge py-1 me-1 mb-1 bg-danger-transparent">Capital Intensive</span>
                                            <span className="badge py-1 me-1 mb-1 bg-success-transparent">Helps in Decarbonization</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0" id="tab-7" role="tabpanel">
                        <h6>Carbon Capture Tab</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActionLibrary