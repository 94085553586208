import constant from "../../../constant"

function portfolio() {
    const title = constant.component.offsetPurchaseHistory.title;

    return (
        <>
            <div className="container-fluid">
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">{title}</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Offset Emission</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="rounded p-3 bg-primary-transparent">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-white primary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /><path d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z" /></g></svg>
                                        </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2">Total Investment</div>
                                        <div className="text-muted mb-1 fs-12">
                                            <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                                USD 44,000
                                            </span>
                                        </div>
                                        <div>
                                            <span className="fs-12 mb-0">This doesn't include taxes/surcharges</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon secondary  px-0">
                                        <span className="rounded p-3 bg-secondary-transparent">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-white secondary" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0,0h24v24H0V0z" fill="none" /><g><path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M15,20H6c-0.55,0-1-0.45-1-1v-1h10V20z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" /><rect height="2" width="6" x="9" y="7" /><rect height="2" width="2" x="16" y="7" /><rect height="2" width="6" x="9" y="10" /><rect height="2" width="2" x="16" y="10" /></g></svg>
                                        </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2">Carbon Credits</div>
                                        <div className="text-muted mb-1 fs-12">
                                            <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                                6
                                            </span>
                                        </div>
                                        <div>
                                            <span className="fs-12 mb-0">Carbon Credits Earned So far</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon success px-0">
                                        <span className="rounded p-3 bg-success-transparent">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-white success" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" /></svg>
                                        </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2">Portfolio Value</div>
                                        <div className="text-muted mb-1 fs-12">
                                            <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                                USD 480.00
                                            </span>
                                        </div>
                                        <div>
                                            <span className="fs-12 mb-0">Approx Price of CC <span className="badge bg-success-transparent text-success mx-1">USD 80.00</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon warning px-0">
                                        <span className="rounded p-3 bg-warning-transparent">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-white warning" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" /></svg>
                                        </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2">Total P&L</div>
                                        <div className="text-muted mb-1 fs-12">
                                            <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                                35,367
                                            </span>
                                        </div>
                                        <div>
                                            <span className="fs-12 mb-0">Increased by <span className="badge bg-success-transparent text-success mx-1">+2.5%</span> this month</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between">
                                <div className="card-title">
                                    Purchase History
                                </div>
                                <div className="d-sm-flex">
                                    <div className="me-3 mb-3 mb-sm-0">
                                        <input className="form-control form-control-sm" type="text" placeholder="Search" aria-label=".form-control-sm example" />
                                    </div>
                                    <div className="dropdown">
                                        <a href="javascript:void(0);" className="btn btn-primary btn-sm btn-wave waves-effect waves-light" data-bs-toggle="dropdown" aria-expanded="false">
                                            Sort By<i className="ri-arrow-down-s-line align-middle ms-1 d-inline-block"></i>
                                        </a>
                                        <ul className="dropdown-menu" role="menu">
                                            <li><a className="dropdown-item" href="javascript:void(0);">New</a></li>
                                            <li><a className="dropdown-item" href="javascript:void(0);">Popular</a></li>
                                            <li><a className="dropdown-item" href="javascript:void(0);">Relevant</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table text-nowrap table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Project Name</th>
                                                <th scope="col" className="text-center">Quantity</th>
                                                <th scope="col">Unit Price</th>
                                                <th scope="col">Total Amount</th>
                                                <th scope="col">Purchase Date</th>
                                                <th scope="col">Order ID</th>
                                                <th scope="col" className="text-center">Status</th>
                                                <th scope="col" className="text-center">Certificate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-2 lh-1">
                                                            <span className="avatar avatar-sm">
                                                                <img src="https://marketplace.goldstandard.org/cdn/shop/files/MicrosoleSEPT2013_36_540x.jpg?v=1684274864" alt="" />
                                                            </span>
                                                        </div>
                                                        <div className="fs-14">Cleaner, Safer Water in Cambodia</div>
                                                    </div>
                                                </td>
                                                <td className="text-center"><span className="badge bg-secondary-transparent fs-12">100</span></td>
                                                <td>USD 5.00</td>
                                                <td><span className="badge bg-primary-transparent fs-12">USD 500.00</span></td>
                                                <td><span className="">30-Apr-2024</span></td>
                                                <td><span className="">1123487HGYNJUS776</span></td>
                                                <td className="text-center"><span className="badge bg-warning-transparent fs-12">Pending</span></td>
                                                <td className="text-center">
                                                    <div className="align-items-center">
                                                        <a aria-label="anchor" href="javascript:void(0);" className="btn btn-icon btn-wave waves-effect waves-light btn-sm btn-warning-light"><i className="ri-download-2-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-2 lh-1">
                                                            <span className="avatar avatar-sm">
                                                                <img src="https://marketplace.goldstandard.org/cdn/shop/files/photo-mainbanner_360x.jpg?v=1700839088" alt="" />
                                                            </span>
                                                        </div>
                                                        <div className="fs-14">Kenya Biogas Programme</div>
                                                    </div>
                                                </td>
                                                <td className="text-center"><span className="badge bg-secondary-transparent fs-12">200</span></td>
                                                <td>USD 4.00</td>
                                                <td><span className="badge bg-primary-transparent fs-12">USD 800.00</span></td>
                                                <td><span className="">20-Mar-2024</span></td>
                                                <td><span className="">99999HYGDFHYUS776</span></td>
                                                <td className="text-center"><span className="badge bg-success-transparent fs-12">Success</span></td>
                                                <td className="text-center">
                                                    <div className="align-items-center">
                                                        <a aria-label="anchor" href="javascript:void(0);" className="btn btn-icon btn-wave waves-effect waves-light btn-sm btn-success-light"><i className="ri-download-2-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-2 lh-1">
                                                            <span className="avatar avatar-sm">
                                                                <img src="https://marketplace.goldstandard.org/cdn/shop/products/15490905666_740b5e1da9_o_540x.jpg?v=1604591763" alt="" />
                                                            </span>
                                                        </div>
                                                        <div className="fs-14">Efficient and Clean Cooking Stoves</div>
                                                    </div>
                                                </td>
                                                <td className="text-center"><span className="badge bg-secondary-transparent fs-12">300</span></td>
                                                <td>USD 3.00</td>
                                                <td><span className="badge bg-primary-transparent fs-12">USD 900.00</span></td>
                                                <td><span className="">12-Feb-2024</span></td>
                                                <td><span className="">85367899GHSNS9865</span></td>
                                                <td className="text-center"><span className="badge bg-success-transparent fs-12">Available</span></td>
                                                <td className="text-center">
                                                    <div className="align-items-center">
                                                        <a aria-label="anchor" href="javascript:void(0);" className="btn btn-icon btn-wave waves-effect waves-light btn-sm btn-success-light"><i className="ri-download-2-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-2 lh-1">
                                                            <span className="avatar avatar-sm">
                                                                <img src="https://marketplace.goldstandard.org/cdn/shop/products/10biogas_nakuru_540x.jpg?v=1585168585" alt="" />
                                                            </span>
                                                        </div>
                                                        <div className="fs-14">Myanmar Stoves Campaign</div>
                                                    </div>
                                                </td>
                                                <td className="text-center"><span className="badge bg-secondary-transparent fs-12">200</span></td>
                                                <td>USD 5.00</td>
                                                <td><span className="badge bg-primary-transparent fs-12">USD 1,000.00</span></td>
                                                <td><span className="">02-Jan-2024</span></td>
                                                <td><span className="">82536098HGHU78290</span></td>
                                                <td className="text-center"><span className="badge bg-success-transparent fs-12">Available</span></td>
                                                <td className="text-center">
                                                    <div className="align-items-center">
                                                        <a aria-label="anchor" href="javascript:void(0);" className="btn btn-icon btn-wave waves-effect waves-light btn-sm btn-success-light"><i className="ri-download-2-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-2 lh-1">
                                                            <span className="avatar avatar-sm">
                                                                <img src="https://marketplace.goldstandard.org/cdn/shop/files/GS11433-Picture1_540x.jpg?v=1704373301" alt="" />
                                                            </span>
                                                        </div>
                                                        <div className="fs-14">Biogas for Better Life Uganda</div>
                                                    </div>
                                                </td>
                                                <td className="text-center"><span className="badge bg-secondary-transparent fs-12">100</span></td>
                                                <td>USD 8.00</td>
                                                <td><span className="badge bg-primary-transparent fs-12">USD 800.00</span></td>
                                                <td><span className="">10-Oct-2023</span></td>
                                                <td><span className="">567890GHYUN565767</span></td>
                                                <td className="text-center"><span className="badge bg-success-transparent fs-12">Available</span></td>
                                                <td className="text-center">
                                                    <div className="align-items-center">
                                                        <a aria-label="anchor" href="javascript:void(0);" className="btn btn-icon btn-wave waves-effect waves-light btn-sm btn-success-light"><i className="ri-download-2-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex align-items-center">
                                    <div>
                                        Showing 5 Entries <i className="bi bi-arrow-right ms-2 fw-semibold"></i>
                                    </div>
                                    <div className="ms-auto">
                                        <nav aria-label="Page navigation" className="pagination-style-4">
                                            <ul className="pagination mb-0">
                                                <li className="page-item disabled">
                                                    <a className="page-link" href="javascript:void(0);">
                                                        Prev
                                                    </a>
                                                </li>
                                                <li className="page-item active"><a className="page-link" href="javascript:void(0);">1</a></li>
                                                <li className="page-item"><a className="page-link" href="javascript:void(0);">2</a></li>
                                                <li className="page-item">
                                                    <a className="page-link text-primary" href="javascript:void(0);">
                                                        next
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default portfolio