import React from 'react';

import WithUIContext from '../../../context/UIContext';
import Loader from '../../common/Loader';
import constant from '../../../constant';

import { onChange, validateForm } from '../../../utils';


interface IPropType {
    ids?: any,
    approved: boolean,
    onSubmitComplete: any,
    context?: any,
    onCancel: any,
    manageApprovalAPI: any
}

class AddDetailForm extends React.Component<IPropType, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            approvedComment: { name: 'approvedComment', required: true, error: '', value: '', type: constant.fieldType.string },
        }
    }

    render() {
        const { showLoader, approvedComment } = this.state;
        const { approved, ids } = this.props;
        return (
            <>
                {
                    showLoader && <Loader />
                }
                <form onSubmit={this.onSubmit}>
                    <div className="mb-3">
                        {
                            approved
                                ? <div className="text-success">Selected {ids.length} Record(s) will be Approved</div>
                                : <div className="text-danger">Selected {ids.length} Record(s) will be Rejected</div>
                        }
                    </div>
                    <div className="form-group mb-4">
                        <label className="form-label">Remarks *</label>
                        <textarea
                            rows={3}
                            name={approvedComment.name}
                            onChange={this.onChange}
                            value={approvedComment.value}
                            className={approvedComment.error.length === 0 ? "form-control" : "form-control is-invalid"}
                        />
                    </div>
                    <div className="row ">
                        <div className="col">
                            <button type="button" onClick={this.props.onCancel} className="btn btn-secondary">Cancel</button>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary" type="submit">Save Detail</button>
                        </div>
                    </div>
                </form>
            </>
        );
    }

    onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        onChange(this, name, value);
    }

    onSwitchChange = (name: any, value: any) => {
        onChange(this, name, value)
    }

    onTypeaheadChange = (name: string, e: any) => {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        if (validateForm(this)) {
            const { ids, approved } = this.props;
            const { approvedComment } = this.state;
            const model = {
                ids, approved,
                approvedComment: approvedComment.value
            };

            this.setState({ showLoader: true });
            this.props.manageApprovalAPI(model).then((res: any) => {
                this.setState({ showLoader: false });
                if (res.error) {
                    this.props.context.toast.error(res.error);
                    this.setState({ showLoader: false });

                }
                else {
                    this.props.context.toast.success("constant.message.recordUpdated");
                    this.props.onSubmitComplete(res.result);
                    this.setState({ showLoader: false });

                }
            })

        }
    }
}

export default WithUIContext(AddDetailForm);