import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? props.value.answer["306-4-d-a1"] : '');
    const [a2, setA2] = useState(props.value ? props.value.answer["306-4-d-a2"] : '');
    const [a3, setA3] = useState(props.value ? props.value.answer["306-4-d-a3"] : '');
    const [a4, setA4] = useState(props.value ? props.value.answer["306-4-d-a4"] : '');

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "306-4-d-a1": a1,
                "306-4-d-a2": a2,
                "306-4-d-a3": a3,
                "306-4-d-a4": a4,
                // "306-4-d-a5": a5,
                // "306-4-d-a6": a6,
                // "306-4-d-a7": a7,
                // "306-4-d-a8": a8,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Onsite</th>
                            <th>Offsite</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Hazardous </td>
                            <td>
                                {number({ name: '', value: a1, onChange: (a: any, b: any) => { setA1(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a2, onChange: (a: any, b: any) => { setA2(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Non-Hazardous</td>
                            <td>
                                {number({ name: '', value: a3, onChange: (a: any, b: any) => { setA3(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a4, onChange: (a: any, b: any) => { setA4(b) } })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1JSON = answer ? answer.answer["306-4-d-a1"] : '';
    const a2JSON = answer ? answer.answer["306-4-d-a2"] : '';
    const a3JSON = answer ? answer.answer["306-4-d-a3"] : '';
    const a4JSON = answer ? answer.answer["306-4-d-a4"] : '';

    return (
        <>
            {
                <div className="mb-3">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Onsite</th>
                                <th>Offsite</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Hazardous </td>
                                <td>
                                    {a1JSON ? getFormattedFloat(a1JSON) : '-'}
                                </td>
                                <td>
                                    {a2JSON ? getFormattedFloat(a2JSON) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Non-Hazardous</td>
                                <td>
                                    {a3JSON ? getFormattedFloat(a3JSON) : '-'}
                                </td>
                                <td>
                                    {a4JSON ? getFormattedFloat(a4JSON) : '-'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}