import { useEffect, useState } from "react";
// import React, { Component } from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import constant from "../../constant";
import { getStorage, setStorage } from "../../utils";

function Home() {
    const key = "event-data";
    const title = constant.component.complianceGRI.title;
    document.title = title;
    const [text, setText] = useState('');
    useEffect(() => {
        const data = getStorage(key);
        setText(data ? data : '');
    }, []);

    return (
        <>
            <div className="container-fluid">
                {/* title */}
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">{title}</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Compliance Reporting</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* title */}

                <CKEditor
                    editor={Editor}
                    config={{ height: 900 }}
                    // data="<p>Hello from CKEditor&nbsp;5!</p>"
                    data={text}
                    onReady={(editor: any) => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(editor: any, event: any) => {
                        setText(event.getData());
                        setStorage('event-data', event.getData());
                    }}
                    onBlur={(event: any, editor: any) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event: any, editor: any) => {
                        console.log('Focus.', editor);
                    }}
                />
            </div>
        </>
    )

    function onLoadList() {
        // getList().then((res: any) => {
        //     if (res.error) {
        //     } else {
        //         setList({
        //             pending: res.result.pending,
        //             rejected: res.result.rejected,
        //             approved: res.result.approved,
        //         });
        //     }
        // });
    }
}

export default Home;