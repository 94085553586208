import moment from 'moment';
import React from 'react';
import AIHelpButton from './AIHelpButton';

interface IPropType {
    questionId: any,
    question: any,
    answer: any,
    onSubmitComplete: any,
    onCancel: any,
    context?: any
}

class AddComponent extends React.Component<IPropType, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            answer: props.answer ? props.answer.answer : '',
            answerStream: '',
            showLoader: false,
            disableEditor: false
        }
    }

    render() {
        const { disableEditor, answer } = this.state;
        const { question } = this.props;
        return (
            <div>
                <div className="mb-3">
                    {question.name}
                </div>
                <div className="mb-3 text-end">
                    <AIHelpButton css="btn btn-link p-0 ms-3" question={question.name} onChange={this.onGetAIAnswer} onShowLoader={this.onShowLoader}>
                        <button >Get AI Help</button>
                    </AIHelpButton>
                </div>
                <div className="mb-4">
                    <textarea
                        id="div-textarea"
                        disabled={disableEditor}
                        style={{ height: '60vh' }}
                        className='form-control' rows={5} placeholder='Write your answer here' value={answer} onChange={(e: any) => this.setState({ answer: e.target.value })} />
                </div>
                <div className="row">
                    <div className="col">
                        <button type="button" onClick={this.props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-primary px-5" type="button" onClick={this.onSubmit}>Save Changes</button>
                    </div>
                </div>
            </div>
        );
    }

    onGetAIAnswer = (answer: any) => {
        this.setState({ answer }, () => {
            const textarea: any = document.getElementById('div-textarea');
            textarea.scrollTop = textarea.scrollHeight;
            console.log('scrolled', textarea.scrollHeight);
        })
    }

    onShowLoader = (disable: boolean) => {
        this.setState({ disableEditor: disable })
    }

    onSubmit = () => {
        const { question } = this.props;
        const { answer: stateAnswer } = this.state;

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: stateAnswer,
            modifiedOn: moment().format(),
            modifiedByName: 'Rob John'
        }

        this.props.onSubmitComplete(obj);
    }
}

export default AddComponent;