import Chart from "react-apexcharts";
import constant from "../../constant";
import Shimmer from "../common/Shimmer";
import { getFormattedFloat } from "../../utils";

function App(props: { series: any, labels: any, total: any }) {
    const { series, labels, total } = props;
    if (series.length === 0) {
        return <Shimmer />
    }
    const options = {
        // series: [1754, 1234],
        // labels: ["Female", "Male"],
        labels,
        chart: {
            height: 250,
            type: 'donut'
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        // stroke: {
        //     show: true,
        //     curve: 'smooth',
        //     lineCap: 'round',
        //     colors: "#fff",
        //     width: 0,
        //     dashArray: 0,
        // },
        tooltip: {
            y: {
                formatter: function (value: any) {
                    return getFormattedFloat(value);
                }
            }
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: '80%',
                    background: 'transparent',
                    labels: {
                        show: false,
                        name: {
                            show: true,
                            fontSize: '20px',
                            color: '#495057',
                            offsetY: -4
                        },
                        value: {
                            show: true,
                            fontSize: '18px',
                            color: undefined,
                            offsetY: 8,
                            formatter: function (val: any) {
                                return val + "%"
                            }
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            label: 'Total',
                            fontSize: '22px',
                            fontWeight: 600,
                            color: '#495057',
                        }

                    }
                }
            }
        },
        // colors: ["rgb(132, 90, 223)", "#23b7e5"],
        colors: constant.colors
    };

    return (
        <div className="leads-source-chart d-flex align-items-center justify-content-center">
            <Chart
                options={options}
                series={series}
                type="donut"
                width="100%"
                height="300"
            />
            <div className="lead-source-value">
                <span className="d-block fs-14">Total</span>
                <span className="d-block fs-25 fw-bold">{total}</span>
            </div>
        </div>
    )
}

export default App;