import i18next from "i18next";
import { Link } from "react-router-dom"
import constant from "../../constant"

function Sidebar() {
    return (
        <aside className="app-sidebar sticky" id="sidebar">
            <div className="main-sidebar-header">
                <Link to={constant.component.welcome.url} className="header-logo">
                    <img src="/assets/images/brand-logos/desktop-logo.png" alt="logo" className="desktop-logo" />
                    <img src="/assets/images/brand-logos/toggle-logo.png" alt="logo" className="toggle-logo" />
                    <img src="/assets/images/logo.png" alt="logo" className="desktop-dark" />
                    <img src="/assets/images/brand-logos/toggle-dark.png" alt="logo" className="toggle-dark" />
                    <img src="/assets/images/brand-logos/desktop-white.png" alt="logo" className="desktop-white" />
                    <img src="/assets/images/brand-logos/toggle-white.png" alt="logo" className="toggle-white" />
                </Link>
            </div>

            <div className="main-sidebar" id="sidebar-scroll">
                <nav className="main-menu-container nav nav-pills flex-column sub-open">
                    <div className="slide-left" id="slide-left">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"> <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path> </svg>
                    </div>
                    <ul className="main-menu">

                        <li className="slide__category"><span className="category-name">{i18next.t("label.sidebar.esg")}</span></li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">
                                <i className="bx bx-bar-chart-alt-2 side-menu__icon"></i>
                                <span className="side-menu__label">{i18next.t("label.sidebar.dashboard")}</span>
                                <i className="fe fe-chevron-right side-menu__angle"></i>
                            </a>
                            <ul className="slide-menu child1">
                                <li className="slide side-menu__label1">
                                    <a href="javascript:void(0)">Dashboard</a>
                                </li>
                                <li className="slide has-sub">
                                    <a href="javascript:void(0);" className="side-menu__item">Environment<i className="fe fe-chevron-right side-menu__angle"></i></a>
                                    <ul className="slide-menu child2">
                                        <li className="slide">
                                            <Link to={constant.component.esgEnergyDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Energy Analytics">Energy</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgWaterDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Water Analytics">Water</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgWasteDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Waste Analytics">Waste</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="Emission Analytics">Emissions</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="BioDiversity Analytics">BioDiversity</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="slide has-sub">
                                    <a href="javascript:void(0);" className="side-menu__item">Social<i className="fe fe-chevron-right side-menu__angle"></i></a>
                                    <ul className="slide-menu child2">
                                        <li className="slide">
                                            <Link to={constant.component.esgSocialDiversityDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Diversity Analytics">Diversity</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgSocialEmployementDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Employment Analytics">Employment</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgSocialOccupationalDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Occupational Health & Safety">Occupational H&S</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="Training Analytics">Training</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="Incidents Analytics">Incidents</Link>
                                        </li>

                                    </ul>
                                </li>
                                <li className="slide has-sub">
                                    <a href="javascript:void(0);" className="side-menu__item">Governance<i className="fe fe-chevron-right side-menu__angle"></i></a>
                                    <ul className="slide-menu child2">
                                        <li className="slide">
                                            <Link to={constant.component.esgGovernanceSummaryDashboard.url} className="side-menu__item" data-bs-toggle="tooltip" title="Purchased Good and Services">Summary</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="Capital Goods">Performance</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.comingSoon.url} className="side-menu__item" data-bs-toggle="tooltip" title="Capital Goods">Corruption</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">
                                <i className="bx bx-detail side-menu__icon"></i>
                                <span className="side-menu__label">{i18next.t("label.sidebar.capture_esg_data")}</span>
                                <i className="fe fe-chevron-right side-menu__angle"></i>
                            </a>
                            <ul className="slide-menu child1">
                                <li className="slide side-menu__label1">
                                    <a href="javascript:void(0)">Capture ESG Data</a>
                                </li>
                                <li className="slide has-sub">
                                    <a href="javascript:void(0);" className="side-menu__item">Environment<i className="fe fe-chevron-right side-menu__angle"></i></a>
                                    <ul className="slide-menu child2">
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '1')} className="side-menu__item" data-bs-toggle="tooltip" title="Biodiversity 2024">Biodiversity 2024</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '2')} className="side-menu__item" data-bs-toggle="tooltip" title="Material 2016">Material</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '3')} className="side-menu__item" data-bs-toggle="tooltip" title="Energy 2016">Energy</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '4')} className="side-menu__item" data-bs-toggle="tooltip" title="Water & Effulents 2018">Water & Effulents</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '5')} className="side-menu__item" data-bs-toggle="tooltip" title="Biodiversity 2016">Biodiversity 2016</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '6')} className="side-menu__item" data-bs-toggle="tooltip" title="Emissions 2016">Emissions</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '7')} className="side-menu__item" data-bs-toggle="tooltip" title="Effuents and waste 2016">Effuents & waste</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '8')} className="side-menu__item" data-bs-toggle="tooltip" title="Waste 2020">Waste</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '9')} className="side-menu__item" data-bs-toggle="tooltip" title="Supplier Environmental Assessment 2016">Supplier Env. Assess.</Link>
                                        </li>

                                    </ul>
                                </li>
                                <li className="slide has-sub">
                                    <a href="javascript:void(0);" className="side-menu__item">Social<i className="fe fe-chevron-right side-menu__angle"></i></a>
                                    <ul className="slide-menu child2">
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '10')} className="side-menu__item" data-bs-toggle="tooltip" title="Biodiversity 2024">Employment</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '11')} className="side-menu__item" data-bs-toggle="tooltip" title="Labor/Management Relations">Labor/Mgmt Relation</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '12')} className="side-menu__item" data-bs-toggle="tooltip" title="Occupational Health and Safety">Occupational H&S</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '13')} className="side-menu__item" data-bs-toggle="tooltip" title="Training & Education">Training & Education</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '14')} className="side-menu__item" data-bs-toggle="tooltip" title="Diversity & Equal Opportunity">Diversity</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '15')} className="side-menu__item" data-bs-toggle="tooltip" title="Non Discrimination">Non Discrimination</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '16')} className="side-menu__item" data-bs-toggle="tooltip" title="Freedom of Association and Collective Bargaining">Freedom of Assoc.</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '17')} className="side-menu__item" data-bs-toggle="tooltip" title="Child Labor">Child Labor</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '18')} className="side-menu__item" data-bs-toggle="tooltip" title="Focused or Compulsory Labor">Focused Labor</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '19')} className="side-menu__item" data-bs-toggle="tooltip" title="Security Practices">Security Practices</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '20')} className="side-menu__item" data-bs-toggle="tooltip" title="Right of Indigenous People">Right of People</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '21')} className="side-menu__item" data-bs-toggle="tooltip" title="Local Communities">Local Communities</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '22')} className="side-menu__item" data-bs-toggle="tooltip" title="Supplier Social Assessment">Supplier Assessment</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '23')} className="side-menu__item" data-bs-toggle="tooltip" title="Public Policy">Public Policy</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '24')} className="side-menu__item" data-bs-toggle="tooltip" title="Customer Health and Safety">Customer H&S</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '25')} className="side-menu__item" data-bs-toggle="tooltip" title="Marketing & Labeling">Marketing & Labeling</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '26')} className="side-menu__item" data-bs-toggle="tooltip" title="Customer Privacy">Customer Privacy</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="slide has-sub">
                                    <a href="javascript:void(0);" className="side-menu__item">Governance<i className="fe fe-chevron-right side-menu__angle"></i></a>
                                    <ul className="slide-menu child2">
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '27')} className="side-menu__item" data-bs-toggle="tooltip" title="Economic Performance">Eco. Performance</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '28')} className="side-menu__item" data-bs-toggle="tooltip" title="Market Presence">Market Presence</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '29')} className="side-menu__item" data-bs-toggle="tooltip" title="Indirect Economic Impacts">Indirect Eco. Impacts</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '30')} className="side-menu__item" data-bs-toggle="tooltip" title="Procurement Practices">Procurement Practice</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '31')} className="side-menu__item" data-bs-toggle="tooltip" title="Anti-Corruption">Anti-Corruption</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '32')} className="side-menu__item" data-bs-toggle="tooltip" title="Anti-Competitive Behavior">Anti-Competitive B.</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.esgDetail.url.replace(':id', '33')} className="side-menu__item" data-bs-toggle="tooltip" title="Tax">Tax</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">
                                <i className="bx bx-search side-menu__icon"></i>
                                <span className="side-menu__label">{i18next.t("label.sidebar.materiality_config")}</span>
                                <i className="fe fe-chevron-right side-menu__angle"></i>
                            </a>
                            <ul className="slide-menu child1">
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Material Topics</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Sector Configuration</Link>
                                </li>
                            </ul>
                        </li>

                        {/* GHG Accounting Menu Start */}
                        <li className="slide__category"><span className="category-name">{i18next.t("label.sidebar.ghg_accounting")}</span></li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">
                                <i className="bx bx-bar-chart-alt-2 side-menu__icon"></i>
                                <span className="side-menu__label">{i18next.t("label.sidebar.dashboard")}</span>
                                <i className="fe fe-chevron-right side-menu__angle"></i>
                            </a>
                            <ul className="slide-menu child1">
                                <li className="slide side-menu__label1">
                                    <a href="javascript:void(0)">Dashboard</a>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.ghgOrganisationEmissionDashboard.url} className="side-menu__item">Organization Emissions</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.ghgBranchEmissionDashboard.url} className="side-menu__item">Branch Emissions</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">
                                <i className="bx bx-detail side-menu__icon"></i>
                                <span className="side-menu__label">{i18next.t("label.sidebar.capture_activity")}</span>
                                <i className="fe fe-chevron-right side-menu__angle"></i>
                            </a>
                            <ul className="slide-menu child1">
                                <li className="slide side-menu__label1">
                                    <a href="javascript:void(0)">Capture Activity Data</a>
                                </li>
                                <li className="slide has-sub">
                                    <a href="javascript:void(0);" className="side-menu__item">Scope 1<i className="fe fe-chevron-right side-menu__angle"></i></a>
                                    <ul className="slide-menu child2">
                                        <li className="slide">
                                            <Link to={constant.component.ghgStationaryEmission.url} className="side-menu__item">Stationary</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgMobileCombustion.url} className="side-menu__item">Mobile Combustion</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgFugutiveEmission.url} className="side-menu__item">Fugutive Emission</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="slide has-sub">
                                    <a href="javascript:void(0);" className="side-menu__item">Scope 2<i className="fe fe-chevron-right side-menu__angle"></i></a>
                                    <ul className="slide-menu child2">
                                        <li className="slide">
                                            <Link to={constant.component.ghgPurchasedElectricity.url} className="side-menu__item">Purchased Electricity</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="slide has-sub">
                                    <a href="javascript:void(0);" className="side-menu__item">Scope 3<i className="fe fe-chevron-right side-menu__angle"></i></a>
                                    <ul className="slide-menu child2">
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory1Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Purchased Good and Services">Category 1</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory2Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Capital Goods">Category 2</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory3Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Fuel and Energy Related Activities">Category 3</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory4Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Upstream Transportation and Distribution">Category 4</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory5Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Waste Generated in Operations">Category 5</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory6Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Business Travel">Category 6</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory7Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Employee Commuting">Category 7</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory8Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Upstream Leased Assets">Category 8</Link>
                                        </li>
                                        {/* <li className="slide">
                                            <Link to={constant.component.ghgCategory9Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Downstream Transportation and Distribution">Category 9</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory10Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Processing of Sold Products">Category 10</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory11Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Use of Sold Products">Category 11</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory12Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="End-of-life Treatment of Sold Products">Category 12</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory13Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Downstream Leased Assets">Category 13</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory14Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Franchises">Category 14</Link>
                                        </li>
                                        <li className="slide">
                                            <Link to={constant.component.ghgCategory15Emission.url} className="side-menu__item" data-bs-toggle="tooltip" title="Investments">Category 15</Link>
                                        </li> */}
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li className="slide__category"><span className="category-name">Decarbonization</span></li>
                        <li className="slide">
                            <Link to={constant.component.decarbonizationActionLibrary.url} className="side-menu__item">
                                <i className="bx bx-book-open side-menu__icon"></i>
                                <span className="side-menu__label">Action Library</span>
                            </Link>
                        </li>
                        <li className="slide">
                            <Link to={constant.component.decarbonizationNetZeroRoadMap.url} className="side-menu__item">
                                <i className="bx bx-trip side-menu__icon"></i>
                                <span className="side-menu__label">NetZero Roadmap</span>
                            </Link>
                        </li>
                        <li className="slide">
                            <Link to={constant.component.decarbonizationNetZeroGoalTracker.url} className="side-menu__item">
                                <i className="bx bx-bullseye side-menu__icon"></i>
                                <span className="side-menu__label">NetZero Goal Tracker</span>
                            </Link>
                        </li>

                        <li className="slide__category"><span className="category-name">Offset Emissions</span></li>
                        <li className="slide">
                            <Link to={constant.component.offsetMarketPlace.url} className="side-menu__item">
                                <i className="bx bx-cart side-menu__icon"></i>
                                <span className="side-menu__label">Marketplace</span>
                            </Link>
                        </li>
                        <li className="slide">
                            <Link to={constant.component.offsetFavourite.url} className="side-menu__item">
                                <i className="bx bx-bookmark-heart side-menu__icon"></i>
                                <span className="side-menu__label">Favourite Projects</span>
                            </Link>
                        </li>
                        <li className="slide">
                            <Link to={constant.component.offsetPurchaseHistory.url} className="side-menu__item">
                                <i className="bx bx-receipt side-menu__icon"></i>
                                <span className="side-menu__label">Purchase History</span>
                            </Link>
                        </li>

                        <li className="slide__category"><span className="category-name">Compliance Reporting</span></li>
                        <li className="slide">
                            <Link to={constant.component.comingSoon.url} className="side-menu__item">
                                <i className="bx bx-book-content side-menu__icon"></i>
                                <span className="side-menu__label">GHG Report</span>
                            </Link>
                        </li>
                        <li className="slide">
                            <Link to={constant.component.comingSoon.url} className="side-menu__item">
                                <i className="bx bx-book-content side-menu__icon"></i>
                                <span className="side-menu__label">BRSR</span>
                            </Link>
                        </li>
                        <li className="slide">
                            <Link to={constant.component.complianceGRI.url} className="side-menu__item">
                                <i className="bx bx-book-content side-menu__icon"></i>
                                <span className="side-menu__label">GRI</span>
                            </Link>
                        </li>

                        <li className="slide__category"><span className="category-name">System Configuration</span></li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">
                                <i className="bx bx-user side-menu__icon"></i>
                                <span className="side-menu__label">User Management</span>
                                <i className="fe fe-chevron-right side-menu__angle"></i>
                            </a>
                            <ul className="slide-menu child1">
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Manage Roles</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Manage Permissions</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Manage Users</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">
                                <i className="bx bx-map side-menu__icon"></i>
                                <span className="side-menu__label">Location Mgmt</span>
                                <i className="fe fe-chevron-right side-menu__angle"></i>
                            </a>
                            <ul className="slide-menu child1">
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Physical Locations</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Reporting Units</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">
                                <i className="bx bx-layer side-menu__icon"></i>
                                <span className="side-menu__label">Master Data</span>
                                <i className="fe fe-chevron-right side-menu__angle"></i>
                            </a>
                            <ul className="slide-menu child1">
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Fuel Types</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Gas Types</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Waste Types</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Goods and Services</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Transport Types</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Emission Sources</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="slide has-sub">
                            <a href="javascript:void(0);" className="side-menu__item">
                                <i className="bx bx-data side-menu__icon"></i>
                                <span className="side-menu__label">Emission Factors</span>
                                <i className="fe fe-chevron-right side-menu__angle"></i>
                            </a>
                            <ul className="slide-menu child1">
                                <li className="slide">
                                    <Link to='/settings/emission-database' className="side-menu__item" data-bs-toggle="tooltip" title="Fuel Types">Fuel Types</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Gas Types</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Waste</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Electricity</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Transportation</Link>
                                </li>
                                <li className="slide">
                                    <Link to={constant.component.comingSoon.url} className="side-menu__item">Spend Based</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="slide">
                            <Link to={constant.component.comingSoon.url} className="side-menu__item">
                                <i className="bx bx-cog side-menu__icon"></i>
                                <span className="side-menu__label">General Settings</span>
                            </Link>
                        </li>

                    </ul>
                    <div className="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"> <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path> </svg></div>
                </nav>


            </div>


        </aside>
    )
}

export default Sidebar