import moment from "moment";
import { useState } from "react";
import { Table, boolean, checkbox, controlType, number } from "../../common/Controls";
import { getFormattedNumber, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a, setA] = useState(props.value ? props.value.answer["303-3-c-ii-a"] : '');
    const [a1, setA1] = useState(props.value ? props.value.answer["303-3-c-ii-a1"] : '');
    const [a2, setA2] = useState(props.value ? props.value.answer["303-3-c-ii-a2"] : '');
    const [a3, setA3] = useState(props.value ? props.value.answer["303-3-c-ii-a3"] : '');
    const [a4, setA4] = useState(props.value ? props.value.answer["303-3-c-ii-a4"] : '');
    const [a5, setA5] = useState(props.value ? props.value.answer["303-3-c-ii-a5"] : '');
    const [a6, setA6] = useState(props.value ? props.value.answer["303-3-c-ii-a6"] : '');
    const [a7, setA7] = useState(props.value ? props.value.answer["303-3-c-ii-a7"] : '');
    const [a8, setA8] = useState(props.value ? props.value.answer["303-3-c-ii-a8"] : '');
    const [a9, setA9] = useState(props.value ? props.value.answer["303-3-c-ii-a9"] : '');
    const [a10, setA10] = useState(props.value ? props.value.answer["303-3-c-ii-a10"] : '');
    const [a11, setA11] = useState(props.value ? props.value.answer["303-3-c-ii-a11"] : '');
    const [a12, setA12] = useState(props.value ? props.value.answer["303-3-c-ii-a12"] : '');
    const [a13, setA13] = useState(props.value ? props.value.answer["303-3-c-ii-a13"] : '');
    const [a14, setA14] = useState(props.value ? props.value.answer["303-3-c-ii-a14"] : '');
    const [a15, setA15] = useState(props.value ? props.value.answer["303-3-c-ii-a15"] : '');
    const [a16, setA16] = useState(props.value ? props.value.answer["303-3-c-ii-a16"] : '');

    const t1 = (a1 ? parseFloat(a1) : 0)
        + (a3 ? parseFloat(a3) : 0)
        + (a5 ? parseFloat(a5) : 0)
        + (a7 ? parseFloat(a7) : 0);

    const t2 = (a2 ? parseFloat(a2) : 0)
        + (a4 ? parseFloat(a4) : 0)
        + (a6 ? parseFloat(a6) : 0)
        + (a8 ? parseFloat(a8) : 0);

    const t3 = (a9 ? parseFloat(a9) : 0)
        + (a11 ? parseFloat(a11) : 0)
        + (a13 ? parseFloat(a13) : 0)
        + (a15 ? parseFloat(a15) : 0);
    const t4 = (a10 ? parseFloat(a10) : 0)
        + (a12 ? parseFloat(a12) : 0)
        + (a14 ? parseFloat(a14) : 0)
        + (a16 ? parseFloat(a16) : 0);

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "303-3-c-ii-a": a,
                "303-3-c-ii-a1": a1,
                "303-3-c-ii-a2": a2,
                "303-3-c-ii-a3": a3,
                "303-3-c-ii-a4": a4,
                "303-3-c-ii-a5": a5,
                "303-3-c-ii-a6": a6,
                "303-3-c-ii-a7": a7,
                "303-3-c-ii-a8": a8,
                "303-3-c-ii-a9": a9,
                "303-3-c-ii-a10": a10,
                "303-3-c-ii-a11": a11,
                "303-3-c-ii-a12": a12,
                "303-3-c-ii-a13": a13,
                "303-3-c-ii-a14": a14,
                "303-3-c-ii-a15": a15,
                "303-3-c-ii-a16": a16,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <input type="number" className="form-control" value={a} onChange={(e: any) => setA(e.target.value)} />
            </div>
            <div className="">
                <table className="table table-bordered table-striped">
                    <tbody>
                        <tr>
                            <th>
                                Water Sources
                            </th>
                            <th>
                                Water withdrawl from all the areas (in ML)
                            </th>
                            <th>
                                Water withdrawl from all the areas with water stress (in ML)
                            </th>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>
                                {t1 ? getFormattedNumber(t1) : '-'}
                            </td>
                            <td>
                                {t2 ? getFormattedNumber(t2) : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>Surface Water</td>
                            <td>
                                {number({ name: '', value: a1, onChange: (a: any, b: any) => { setA1(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a2, onChange: (a: any, b: any) => { setA2(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Groundwater</td>
                            <td>
                                {number({ name: '', value: a3, onChange: (a: any, b: any) => { setA3(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a4, onChange: (a: any, b: any) => { setA4(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Sea Water</td>
                            <td>
                                {number({ name: '', value: a5, onChange: (a: any, b: any) => { setA5(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a6, onChange: (a: any, b: any) => { setA6(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Produced Water</td>
                            <td>
                                {number({ name: '', value: a7, onChange: (a: any, b: any) => { setA7(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a8, onChange: (a: any, b: any) => { setA8(b) } })}
                            </td>
                        </tr>

                        <tr>
                            <td>Total Third-party Water</td>
                            <td>
                                {t3 ? getFormattedNumber(t3) : '-'}
                            </td>
                            <td>
                                {t4 ? getFormattedNumber(t4) : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td>Surface Water</td>
                            <td>
                                {number({ name: '', value: a9, onChange: (a: any, b: any) => { setA9(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a10, onChange: (a: any, b: any) => { setA10(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Groundwater</td>
                            <td>
                                {number({ name: '', value: a11, onChange: (a: any, b: any) => { setA11(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a12, onChange: (a: any, b: any) => { setA12(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Sea Water</td>
                            <td>
                                {number({ name: '', value: a13, onChange: (a: any, b: any) => { setA13(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a14, onChange: (a: any, b: any) => { setA14(b) } })}
                            </td>
                        </tr>
                        <tr>
                            <td>Produced Water</td>
                            <td>
                                {number({ name: '', value: a15, onChange: (a: any, b: any) => { setA15(b) } })}
                            </td>
                            <td>
                                {number({ name: '', value: a16, onChange: (a: any, b: any) => { setA16(b) } })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div >
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a = answer ? answer.answer["303-3-c-ii-a"] : '';
    const a1 = answer ? answer.answer["303-3-c-ii-a1"] : '';
    const a2 = answer ? answer.answer["303-3-c-ii-a2"] : '';
    const a3 = answer ? answer.answer["303-3-c-ii-a3"] : '';
    const a4 = answer ? answer.answer["303-3-c-ii-a4"] : '';
    const a5 = answer ? answer.answer["303-3-c-ii-a5"] : '';
    const a6 = answer ? answer.answer["303-3-c-ii-a6"] : '';
    const a7 = answer ? answer.answer["303-3-c-ii-a7"] : '';
    const a8 = answer ? answer.answer["303-3-c-ii-a8"] : '';
    const a9 = answer ? answer.answer["303-3-c-ii-a9"] : '';
    const a10 = answer ? answer.answer["303-3-c-ii-a10"] : '';
    const a11 = answer ? answer.answer["303-3-c-ii-a11"] : '';
    const a12 = answer ? answer.answer["303-3-c-ii-a12"] : '';
    const a13 = answer ? answer.answer["303-3-c-ii-a13"] : '';
    const a14 = answer ? answer.answer["303-3-c-ii-a14"] : '';
    const a15 = answer ? answer.answer["303-3-c-ii-a15"] : '';
    const a16 = answer ? answer.answer["303-3-c-ii-a16"] : '';

    const t1 = (a1 ? parseFloat(a1) : 0)
        + (a3 ? parseFloat(a3) : 0)
        + (a5 ? parseFloat(a5) : 0)
        + (a7 ? parseFloat(a7) : 0);

    const t2 = (a2 ? parseFloat(a2) : 0)
        + (a4 ? parseFloat(a4) : 0)
        + (a6 ? parseFloat(a6) : 0)
        + (a8 ? parseFloat(a8) : 0);

    const t3 = (a9 ? parseFloat(a9) : 0)
        + (a11 ? parseFloat(a11) : 0)
        + (a13 ? parseFloat(a13) : 0)
        + (a15 ? parseFloat(a15) : 0);
    const t4 = (a10 ? parseFloat(a10) : 0)
        + (a12 ? parseFloat(a12) : 0)
        + (a14 ? parseFloat(a14) : 0)
        + (a16 ? parseFloat(a16) : 0);

    return (
        <>
            {
                <div className="mb-3">
                    <div className="mb-3">
                        <div className="fw-bold">
                            Other Water (&gt; 1000mg/L Total Dissolved Solids) in ML
                        </div>
                        {a ? a : '-'}
                    </div>
                    <table className="table table-bordered table-striped">
                        <tbody>
                            <tr>
                                <th>
                                    Water Sources
                                </th>
                                <th>
                                    Water withdrawl from all the areas (in ML)
                                </th>
                                <th>
                                    Water withdrawl from all the areas with water stress (in ML)
                                </th>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>
                                    {t1 ? getFormattedNumber(t1) : '-'}
                                </td>
                                <td>
                                    {t2 ? getFormattedNumber(t2) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Surface Water</td>
                                <td>
                                    {a1 ? getFormattedNumber(a1) : '-'}
                                </td>
                                <td>
                                    {a2 ? getFormattedNumber(a2) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Groundwater</td>
                                <td>
                                    {a3 ? getFormattedNumber(a3) : '-'}
                                </td>
                                <td>
                                    {a4 ? getFormattedNumber(a4) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Sea Water</td>
                                <td>
                                    {a5 ? getFormattedNumber(a5) : '-'}
                                </td>
                                <td>
                                    {a6 ? getFormattedNumber(a6) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Produced Water</td>
                                <td>
                                    {a7 ? getFormattedNumber(a7) : '-'}
                                </td>
                                <td>
                                    {a8 ? getFormattedNumber(a8) : '-'}
                                </td>
                            </tr>

                            <tr>
                                <td>Total Third-party Water</td>
                                <td>
                                    {t3 ? getFormattedNumber(t3) : '-'}
                                </td>
                                <td>
                                    {t4 ? getFormattedNumber(t4) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Surface Water</td>
                                <td>
                                    {a9 ? getFormattedNumber(a9) : '-'}
                                </td>
                                <td>
                                    {a10 ? getFormattedNumber(a10) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Groundwater</td>
                                <td>
                                    {a11 ? getFormattedNumber(a11) : '-'}
                                </td>
                                <td>
                                    {a12 ? getFormattedNumber(a12) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Sea Water</td>
                                <td>
                                    {a13 ? getFormattedNumber(a13) : '-'}
                                </td>
                                <td>
                                    {a14 ? getFormattedNumber(a14) : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Produced Water</td>
                                <td>
                                    {a15 ? getFormattedNumber(a15) : '-'}
                                </td>
                                <td>
                                    {a16 ? getFormattedNumber(a16) : '-'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}