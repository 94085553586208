import { atom, } from 'recoil';

const _projectList = [
    { id: 1, title: 'Cleaner, Safer Water in Cambodia', short_description: 'Hydrologic’s purifiers have provided near…roving health and helping the environment.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/hydrologic_540x.jpg?v=1604591295', price_in_usd: 16.00, shortlisted: true },
    { id: 2, title: 'Qori Q’oncha Improved Cookstoves in Peru', short_description: 'Qori Q’oncha Improved Cookstove project p…emissions and pressure on Andean forests. ', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/MicrosoleSEPT2013_36_540x.jpg?v=1684274864', price_in_usd: 16.00, shortlisted: true },
    { id: 3, title: 'Kenya Biogas Programme', short_description: 'Cooking on biogas is fast and smokeless, …o improves crop yields + increases income.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/10biogas_nakuru_540x.jpg?v=1585168585', price_in_usd: 16.00, shortlisted: true },
    { id: 4, title: 'Efficient and Clean Cooking Stoves For Households in The Democratic Republic of Congo (DRC)', short_description: 'BURN’s stove project in DRC saves familie…indoor air pollution and protects forests.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/GS11433-Picture1_540x.jpg?v=1704373301', price_in_usd: 16.00 },
    { id: 5, title: '60 MW Wind Power Project in Karnataka, India', short_description: 'Wind project in India focusing on sustainable development of the local community', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/117981599__d4a6126_primary_picture-web_540x.jpg?v=1677759643', price_in_usd: 16.00 },
    { id: 6, title: 'Myanmar Stoves Campaign', short_description: 'Improving the lives of 250,000 people - s…oving health and decreasing deforestation.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/15490905666_740b5e1da9_o_540x.jpg?v=1604591763', price_in_usd: 16.00 },
    { id: 7, title: 'Biogas for Better Life Uganda', short_description: 'Cooking on biogas is fast and smokeless, … yields, food security and farmers income.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/GS4236---Biogas-for-Better-Life-Uganda4_540x.jpg?v=1681996517', price_in_usd: 16.00 },
    { id: 8, title: 'Buenos Aires Renewable Energy Project, Brazil', short_description: 'This project alleviates deforestation, av…employee livelihoods. Making good, better.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/photo-mainbanner_360x.jpg?v=1700839088', price_in_usd: 16.00 },
    { id: 9, title: 'The Nicaforest High Impact Reforestation Program', short_description: 'Combining reforestation on degraded land …and protection of native forests + animals', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/NicaforestGS4220Project2_540x.jpg?v=1685619557', price_in_usd: 16.00 },
    { id: 10, title: 'Efficient and Clean Cooking for households in Nigeria', short_description: 'Cookstove project providing access to the…h in households and reducing deforestation', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/Womancooking2MRF_360x.jpg?v=1711356847', price_in_usd: 16.00 },
    { id: 11, title: '27 MWP Solar PV Project by MH Technique Solaire India', short_description: 'This solar project in India focuses on th…ower plants with renewable solar energy.  ', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/GS7762-Picture1_720x.jpg?v=1711354117', price_in_usd: 16.00 },
    { id: 12, title: '50 MW Wind Power Project in Madhya Pradesh, India', short_description: 'Wind project in India focusing on sustainable development of the local community', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/117981599_2l1a0081_primary-WEB_540x.jpg?v=1677759459', price_in_usd: 16.00 },
    { id: 13, title: 'Uganda Gender Responsive Safe Water Project', short_description: 'Safe water project which takes a pro-acti…nder equality practices in the community.\n', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/CO2balance3_540x.jpg?v=1693315027', price_in_usd: 16.00 },
    { id: 14, title: 'Climate+ Portfolio: Variety of projects', short_description: 'Purchase carbon credits from a range of G…ard-certified projects all over the globe.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/gs_projects_gallery-branded121212_0dc7da81-20ab-46d3-ae37-358c42ec2dd1_540x.jpg?v=1603833414', price_in_usd: 16.00 },
    { id: 15, title: 'Utsil Naj - healthy homes for all in Mexico', short_description: 'Addressing climate change whilst improvin… most vulnerable populations in Guatemala.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/EstufasMejoradas13_540x.jpg?v=1585649748', price_in_usd: 16.00 },
    { id: 16, title: 'Efficient and Clean Cooking Stoves For Households in Kenya', short_description: '', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/117981599_kenya_jikokoa_user_1_e94f9acc-a440-41bf-b862-ac317e492aa9_540x.jpg?v=1692371292', price_in_usd: 16.00 },
    { id: 17, title: '300 MW Solar PV Plant at Bhadla, Rajasthan', short_description: 'This solar project in India focuses on th… power plants with renewable solar energy.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/GS7726-Picture2_540x.jpg?v=1704804560', price_in_usd: 16.00 },
    { id: 18, title: 'Utsil Naj - healthy homes for all in Guatemala', short_description: 'Addressing climate change whilst improvin… most vulnerable populations in Guatemala.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/UtsilNajGuatemala_540x.jpg?v=1585649584', price_in_usd: 16.00 },
    { id: 19, title: '100 MW Solar Power plant in Maharashtra, India', short_description: 'Solar project in India focusing on sustainable development of the local community.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/GS7533-Solar-1-web_540x.jpg?v=1685445915', price_in_usd: 16.00 },
    { id: 20, title: 'Renewable Energy Power Project by DDWL, India', short_description: 'The Wind Project in Gujarat focuses on the sustainable development of the community.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/GS5673-Picture1_360x.jpg?v=1697191765', price_in_usd: 16.00 },
    { id: 21, title: 'Betulia Energy and Biodiversity Restoration Project in Honduras', short_description: 'Providing rural electrification to local …and renewable energy to the national grid.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/Betulia_IMG_1907_540x.jpg?v=1709037499', price_in_usd: 16.00 },
    { id: 22, title: 'Guatemala Improved Cookstoves', short_description: 'The project uses carbon finance to suppor… cooks to rural communities of Guatemala. ', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/117981599_d5b675c2-58b3-4f75-a1cb-f4561c7c1ee7_540x.jpg?v=1700468686', price_in_usd: 16.00 },
    { id: 23, title: '22.5 MW Wind Power Project in Rajasthan, India', short_description: 'Wind project in India focusing on sustainable development for the local community', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/22.5_mw_wind_power_project_in_rajasthan_india0_540x.jpg?v=1585169035', price_in_usd: 16.00 },
    { id: 24, title: 'Solar Lighting Project in Zambia', short_description: 'Transforming lives through solar power.  …ving without electricity in rural Zambia.\n', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/Kidwithlight_NameneZambia_540x.jpg?v=1704380276', price_in_usd: 16.00 },
    { id: 25, title: '400 MW Solar Power Project at Bhadla, Rajasthan, India', short_description: 'New project that displaces electricity ge… power plants with renewable solar energy.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/EKIsolar-1_540x.jpg?v=1614015649', price_in_usd: 16.00 },
    { id: 26, title: 'Cambodia National Biodigester Programme', short_description: 'Using biogas to create an indigenous, sus… emissions + improving agricultural yields', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/hivos_biogas_cambodia_6035746204_6041b36050_b_540x.jpg?v=1585168123', price_in_usd: 16.00 },
    { id: 27, title: 'West Kisumu Improved Cookstove Project', short_description: 'The project is based around providing fam…to local families in the region of Kisumu.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/GS843-Picture1_540x.jpg?v=1706098735', price_in_usd: 16.00 },
    { id: 28, title: 'Planting Biodiverse Forests in Panama', short_description: 'Re-transforming degraded pastureland into…that offer a safe haven for native animals', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/sloth_tree2_0_540x.jpg?v=1585168966', price_in_usd: 16.00 },
    { id: 29, title: '120 MW Solar PV Plant by Juniper Green Sigma Private Ltd. in Gujarat, India', short_description: 'Solar project in India focusing on sustainable development of the local community.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/GS7572-solor-1_540x.jpg?v=1685446021', price_in_usd: 16.00 },
    { id: 30, title: 'Improved Cookstoves for Rural Zambia', short_description: "  Providing the world's most efficient wood…Zambians. For climate, health and gender. ", img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/JJM_5575_540x.jpg?v=1688621131', price_in_usd: 16.00 },
    { id: 31, title: '30 MW Wind Energy Project by Giriraj Enterprises', short_description: 'The wind project in India focuses on the sustainable development of the community. ', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/117981599_bbv46_4_360x.jpg?v=1711108211', price_in_usd: 16.00 },
    { id: 32, title: 'Ceará Renewable Energy Project, Brazil', short_description: 'Striking out at deforestation: Promoting …ihoods of employees and local communities.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/FIRST_360x.jpg?v=1701428496', price_in_usd: 16.00 },
    { id: 33, title: 'Mozambique Safe Water Project', short_description: 'The Project works with local communities …ken boreholes to provide safe clean water.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/GS7639-Mozmabique-water-project_2web_720x.jpg?v=1675339744', price_in_usd: 16.00 },
    { id: 34, title: 'Chaiyaphum Wind Power Project, Thailand', short_description: 'This wind project in Thailand focuses on … sustainable development of the community.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/117981599__7853_540x.jpg?v=1692951397', price_in_usd: 16.00 },
    { id: 35, title: "M'tetezi Improved Cookstoves Balaka District, Malawi", short_description: 'This project provides highly efficient co…al, financial, and environmental benefits.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/117981599_img_20230925_145157_540x.jpg?v=1695819860', price_in_usd: 16.00 },
    { id: 36, title: 'Indonesia Biogas Programme', short_description: 'Domestic biogas provides a sustainable wa…od and expensive fossil fuels for cooking.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/files/117981603_biogas-indonesia---elis-daryati_51909186202_o_1_540x.jpg?v=1713954291', price_in_usd: 16.00 },
    { id: 37, title: 'Improved Cookstoves for conservation of Ibanda-Makera Gallery Forest in Rwanda', short_description: 'Locally made cookstoves reduce firewood c…help to protect this jewel of biodiversity', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/likano_women-web_540x.jpg?v=1673224612', price_in_usd: 16.00 },
    { id: 38, title: 'Fairtrade Project: Cookstoves for coffee farmers, Ethiopia', short_description: 'This Fairtrade certified project is distr… wood consumption and CO2 emissions by 40%', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/Familyeating_540x.jpg?v=1604590227', price_in_usd: 16.00 },
    { id: 39, title: 'Efficient And Clean Cooking For Households In Somalia', short_description: 'This project provides highly efficient cl…ial, financial, and environmental benefit.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/GS10789-Cleancooking-in-somalia_1web_360x.jpg?v=1675340604', price_in_usd: 16.00 },
    { id: 40, title: 'Institutional Improved Cookstoves for Schools and Institutions in Uganda', short_description: 'Simoshi is a social enterprise dedicated … the installation of improved cook stoves.', img_url: 'https://marketplace.goldstandard.org/cdn/shop/products/simoshi_gs_marketplace_4_540x.jpg?v=1689764713', price_in_usd: 16.00 },
]

export const projectListAtom = atom({
    key: 'projectListAtom',
    default: _projectList
});