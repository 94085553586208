import { Chart1, Chart2, Chart3 } from "../../../../component/esg/governance-dashboard/SummaryChart";

function Home() {
    return (
        <>
            <div className="container-fluid">
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Summary Dashboard</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">ESG</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Governance</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Summary</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <div className="card custom-card card-bg-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-light"><i className="ti ti-bulb fs-25 text-primary"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-fixed-white op-8">Board of Directors</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">5</span>
                                            {/* <span className="fw-semibold fs-14">GJ</span> */}
                                        </div>
                                        <div>
                                            <span className="badge bg-light fw-semibold text-danger mx-1 fs-12">+3</span>
                                            <span className="fs-12 mb-0 text-fixed-white op-8"> v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card custom-card border border-secondary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-secondary"><i className="ti ti-users fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Promoter Board Members</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">3</span>
                                            {/* <span className="text-muted fw-semibold fs-14">GJ</span> */}
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">-4%</span>
                                            <span className="fs-12 mb-0 text-muted"> v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card custom-card border border-warning">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-warning"><i className="ti ti-users fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Independent Menbers in Audit Committee</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">4</span>
                                            {/* <span className="text-muted fw-semibold fs-14">GJ</span> */}
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">+3</span>
                                            <span className="fs-12 mb-0 text-muted"> v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card custom-card border border-danger">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-danger"><i className="ti ti-users fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Board Meetings</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">5</span>
                                            {/* <span className="text-muted fw-semibold fs-14">GJ</span> */}
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">+4</span>
                                            <span className="fs-12 mb-0 text-muted"> v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card custom-card border border-danger">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-danger"><i className="ti ti-users fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">
                                            Ratio of change in CEO compensation to change in Revenue
                                        </div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">0.45</span>
                                            {/* <span className="text-muted fw-semibold fs-14">GJ</span> */}
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">+0.4</span>
                                            <span className="fs-12 mb-0 text-muted"> v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="card custom-card border border-danger">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-danger"><i className="ti ti-users fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">
                                            KMP Shareholding
                                        </div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">6.2%</span>
                                            {/* <span className="text-muted fw-semibold fs-14">GJ</span> */}
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">+4.8%</span>
                                            <span className="fs-12 mb-0 text-muted"> v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Board and Committee Functioning
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart1 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Attendance Rate
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart2 />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    CEO Pay Gap Ratio
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart3 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6 d-none">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Work Days Lost
                                </div>
                            </div>
                            <div className="card-body">
                                {/* <Chart4 /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;