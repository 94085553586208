import { useEffect, useState } from "react";
import AddDetailButton from "../../../component/ghg/emission/category-7/AddDetailButton";
import constant from "../../../constant";
import Shimmer from "../../../component/common/Shimmer";
import Grid from "../../../component/ghg/emission/category-7/Grid";
import StatsCard from "../../../component/ghg/common/StatsCard";
import BulkUploadButton from "../../../component/ghg/common/BulkUploadButton";
import HelpButton from "../../../component/ghg/common/HelpButton";
import Analytics from "../../../component/ghg/emission/category-7/Analytics";

function Home() {
    const title = constant.component.ghgCategory7Emission.title;
    const _list = [
        {
            "id": 1,
            "dated": "Nov'23 2023",
            "emissionSourceName": "Car",
            "noOfEmployees": 100,
            "uom": "km",
            "activityData": 10,
            "emission": 169.8264489
        },
        {
            "id": 2,
            "dated": "Dec'23 2023",
            "emissionSourceName": "Car",
            "noOfEmployees": 100,
            "uom": "km",
            "activityData": 10,
            "emission": 169.8264489
        },
        {
            "id": 3,
            "dated": "Jan'24 2024",
            "emissionSourceName": "Car",
            "noOfEmployees": 80,
            "uom": "km",
            "activityData": 8,
            "emission": 108.6889273
        },
        {
            "id": 4,
            "dated": "Feb'24 2024",
            "emissionSourceName": "Car",
            "noOfEmployees": 90,
            "uom": "km",
            "activityData": 9,
            "emission": 137.5594236
        },
        {
            "id": 5,
            "dated": "Mar'24 2024",
            "emissionSourceName": "Car",
            "noOfEmployees": 95,
            "uom": "km",
            "activityData": 8,
            "emission": 129.0681011
        },
        {
            "id": 6,
            "dated": "Nov'23 2023",
            "emissionSourceName": "Bus",
            "noOfEmployees": 200,
            "uom": "km",
            "activityData": 40,
            "emission": 946.902153
        },
        {
            "id": 7,
            "dated": "Dec'23 2023",
            "emissionSourceName": "Bus",
            "noOfEmployees": 180,
            "uom": "km",
            "activityData": 40,
            "emission": 852.2119377
        },
        {
            "id": 8,
            "dated": "Jan'24 2024",
            "emissionSourceName": "Bus",
            "noOfEmployees": 170,
            "uom": "km",
            "activityData": 40,
            "emission": 804.8668301
        },
        {
            "id": 9,
            "dated": "Feb'24 2024",
            "emissionSourceName": "Bus",
            "noOfEmployees": 160,
            "uom": "km",
            "activityData": 30,
            "emission": 568.1412918
        },
        {
            "id": 10,
            "dated": "Mar'24 2024",
            "emissionSourceName": "Bus",
            "noOfEmployees": 180,
            "uom": "km",
            "activityData": 40,
            "emission": 852.2119377
        },
        {
            "id": 11,
            "dated": "Nov'23 2023",
            "emissionSourceName": "Motorbike",
            "noOfEmployees": 50,
            "uom": "km",
            "activityData": 10,
            "emission": 56.83713221
        },
        {
            "id": 12,
            "dated": "Dec'23 2023",
            "emissionSourceName": "Motorbike",
            "noOfEmployees": 40,
            "uom": "km",
            "activityData": 10,
            "emission": 45.46970577
        },
        {
            "id": 13,
            "dated": "Jan'24 2024",
            "emissionSourceName": "Motorbike",
            "noOfEmployees": 20,
            "uom": "km",
            "activityData": 10,
            "emission": 22.73485289
        },
        {
            "id": 14,
            "dated": "Feb'24 2024",
            "emissionSourceName": "Motorbike",
            "noOfEmployees": 40,
            "uom": "km",
            "activityData": 10,
            "emission": 45.46970577
        },
        {
            "id": 15,
            "dated": "Mar'24 2024",
            "emissionSourceName": "Motorbike",
            "noOfEmployees": 30,
            "uom": "km",
            "activityData": 10,
            "emission": 34.10227933
        },
        {
            "id": 16,
            "dated": "Nov'23 2023",
            "emissionSourceName": "Local Train",
            "noOfEmployees": 10,
            "uom": "km",
            "activityData": 30,
            "emission": 10.63888913
        },
        {
            "id": 17,
            "dated": "Dec'23 2023",
            "emissionSourceName": "Local Train",
            "noOfEmployees": 8,
            "uom": "km",
            "activityData": 30,
            "emission": 8.511111302
        },
        {
            "id": 18,
            "dated": "Jan'24 2024",
            "emissionSourceName": "Local Train",
            "noOfEmployees": 10,
            "uom": "km",
            "activityData": 30,
            "emission": 10.63888913
        },
        {
            "id": 19,
            "dated": "Feb'24 2024",
            "emissionSourceName": "Local Train",
            "noOfEmployees": 10,
            "uom": "km",
            "activityData": 30,
            "emission": 10.63888913
        },
        {
            "id": 20,
            "dated": "Mar'24 2024",
            "emissionSourceName": "Local Train",
            "noOfEmployees": 10,
            "uom": "km",
            "activityData": 30,
            "emission": 10.63888913
        }
    ]
    const [list, setList]: any = useState({ pending: _list, approved: [], rejected: [] });

    let pendingTotal = 0;
    let rejectedTotal = 0;
    let approvedTotal = 0;

    let pendingCount = 0;
    let rejectedCount = 0;
    let approvedCount = 0;

    if (list.pending) {
        pendingTotal = list.pending.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
        pendingCount = list.pending.length;

        rejectedTotal = list.rejected.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
        rejectedCount = list.rejected.length;

        approvedTotal = list.approved.reduce((acc: any, item: any) => acc + parseFloat(item.emission), 0);
        approvedCount = list.approved.length;
    }

    useEffect(() => {
        document.title = title;
        onLoadList();
    }, []);

    return (
        <>
            <div className="container-fluid">
                {/* title */}
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">{title}</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">GHG</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Emission</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* title */}

                {/* cards */}
                <StatsCard pending={pendingTotal} rejected={rejectedTotal} approved={approvedTotal} />

                {/* cards */}

                {/* tabs */}
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="d-md-flex d-block flex-wrap align-items-center justify-content-between">
                                    <div className="flex-fill">
                                        <ul className="nav nav-pills nav-style-3" role="tablist">
                                            <li className="nav-item" role="presentation"><a className="nav-link active" data-bs-target="#div-pending" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="true">Pending ({pendingCount})</a> </li>
                                            <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#div-rejected" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Rejected ({rejectedCount})</a> </li>
                                            <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#div-approved" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Approved ({approvedCount})</a> </li>
                                            <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#div-pending-approval" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Pending Approval ({pendingCount})</a> </li>
                                            <li className="nav-item" role="presentation"><a className="nav-link" data-bs-target="#div-analytics" data-bs-toggle="tab" role="tab" aria-current="page" href="javascript:void(0);" aria-selected="false" tabIndex={-1}>Analytics</a> </li>
                                        </ul>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center justify-content-evenly">
                                        <AddDetailButton css="btn btn-sm btn-primary align-items-center d-inline-flex" onSubmitComplete={onLoadList}>
                                            <i className="ti ti-plus me-1 fw-semibold"></i>Add Detail
                                        </AddDetailButton>

                                        <BulkUploadButton css="btn btn-sm btn-secondary align-items-center d-inline-flex ms-3" onSubmitComplete={onLoadList}>
                                            <i className="bx bxs-file-import me-1 fw-semibold"></i>Bulk Upload
                                        </BulkUploadButton>

                                        <HelpButton css="btn btn-sm btn-info align-items-center d-inline-flex ms-3">
                                            <i className="bx bx-help-circle me-1 fw-semibold"></i>Help
                                        </HelpButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* tabs */}
                <div className="tab-content">
                    <div className="tab-pane p-0  active border-0" id="div-pending">
                        <div className="card custom-card">
                            <div className="card-body">
                                {
                                    list.pending
                                        ? <Grid list={list.pending} enableEditing={true} enableApproval={false} onLoadList={onLoadList} />
                                        : <Shimmer />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0" id="div-rejected">
                        <div className="card custom-card">
                            <div className="card-body">
                                {
                                    list.rejected
                                        ? <Grid list={list.rejected} enableEditing={true} enableApproval={false} onLoadList={onLoadList} />
                                        : <Shimmer />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0" id="div-approved">
                        <div className="card custom-card">
                            <div className="card-body">
                                {
                                    list.approved
                                        ? <Grid list={list.approved} enableEditing={false} enableApproval={false} />
                                        : <Shimmer />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0" id="div-pending-approval">
                        <div className="card custom-card">
                            <div className="card-body">
                                {
                                    list.pending
                                        ? <Grid list={list.pending} enableEditing={false} enableApproval={true} onLoadList={onLoadList} />
                                        : <Shimmer />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane p-0" id="div-analytics">
                        {
                            list.pending
                                ? <Analytics list={list.pending} />
                                : <Shimmer />
                        }
                    </div>
                </div>
                {/* tabs end*/}
            </div>
        </>
    )

    function onLoadList() {
        // getList().then((res: any) => {
        //     if (res.error) {
        //     } else {
        //         setList({
        //             pending: res.result.pending,
        //             rejected: res.result.rejected,
        //             approved: res.result.approved,
        //         });
        //     }
        // });
    }
}

export default Home;