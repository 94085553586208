export function DateForm(props: { onSubmitComplete: any, onCancel: any }) {
    return (
        <>
            <input type="date" className="form-control" />
            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={props.onSubmitComplete} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function DatePreview(props: any) {
    return (
        <>
            <input type="date" />
        </>
    )
}