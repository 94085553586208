import { Link } from "react-router-dom"
import constant from "../../../constant"

function InvestOnline() {
    const title = constant.component.offsetCart.title;
    return (
        <>
            <div className="container-fluid">
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">{title}</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Offset Emission</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxl-9">
                        <div className="card custom-card" id="cart-container-delete">
                            <div className="card-header">
                                <div className="card-title"> Cart Items </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered text-nowrap">
                                        <thead><tr><th> Project Name </th><th> Unit Price </th><th> Quantity </th><th> Total </th><th> Action </th></tr> </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <span className="avatar avatar-xxl bg-light">
                                                                <img src="https://marketplace.goldstandard.org/cdn/shop/files/MicrosoleSEPT2013_36_540x.jpg?v=1684274864" alt="" />
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div className="mb-1 fs-14 fw-semibold">
                                                                <a href="javascript:void(0);">Distribution of Improved Cookstove - Phase III</a>
                                                            </div> <div className="mb-1">
                                                                <span className="me-1">Global Registry:</span>
                                                                <span className="fw-semibold text-muted">Verra</span>
                                                            </div>
                                                            <div className="mb-1">
                                                                <span className="me-1">Registry URL:</span>
                                                                <span className="fw-semibold text-muted">https://registry.verra.org/app/projectDetail/VCS/2424</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="fw-semibold fs-14"> USD 16.00 </div>
                                                </td>
                                                <td className="product-quantity-container">
                                                    <div className="input-group rounded flex-nowrap">
                                                        <button className="btn btn-icon btn-light input-group-text flex-fill product-quantity-minus border-end-0">
                                                            <i className="ri-subtract-line"></i></button>
                                                        <input type="text" className="form-control form-control-sm text-center w-100" aria-label="quantity" id="product-quantity" value="100" />
                                                        <button className="btn btn-icon btn-light input-group-text flex-fill product-quantity-plus border-start-0">
                                                            <i className="ri-add-line"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="fs-14 fw-semibold">USD 1,600</div>
                                                </td>
                                                <td>
                                                    <a href="wishlist.html" className="btn btn-icon btn-success me-1" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Add To Wishlist">
                                                        <i className="ri-heart-line"></i>
                                                    </a>
                                                    <button className="btn btn-icon btn-danger btn-delete" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove From cart">
                                                        <i className="ri-delete-bin-line"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card custom-card d-none" id="cart-empty-cart">
                            <div className="card-header"> <div className="card-title"> Empty Cart </div> </div>
                            <div className="card-body">
                                <div className="cart-empty text-center"> <svg xmlns="http://www.w3.org/2000/svg" className="svg-muted" width="24" height="24" viewBox="0 0 24 24"><path d="M18.6 16.5H8.9c-.9 0-1.6-.6-1.9-1.4L4.8 6.7c0-.1 0-.3.1-.4.1-.1.2-.1.4-.1h17.1c.1 0 .3.1.4.2.1.1.1.3.1.4L20.5 15c-.2.8-1 1.5-1.9 1.5zM5.9 7.1 8 14.8c.1.4.5.8 1 .8h9.7c.5 0 .9-.3 1-.8l2.1-7.7H5.9z"></path><path d="M6 10.9 3.7 2.5H1.3v-.9H4c.2 0 .4.1.4.3l2.4 8.7-.8.3zM8.1 18.8 6 11l.9-.3L9 18.5z"></path><path d="M20.8 20.4h-.9V20c0-.7-.6-1.3-1.3-1.3H8.9c-.7 0-1.3.6-1.3 1.3v.5h-.9V20c0-1.2 1-2.2 2.2-2.2h9.7c1.2 0 2.2 1 2.2 2.2v.4z"></path><path d="M8.9 22.2c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2zm0-3.5c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3.8 0 1.3-.6 1.3-1.3 0-.7-.5-1.3-1.3-1.3zM18.6 22.2c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2c1.2 0 2.2 1 2.2 2.2s-.9 2.2-2.2 2.2zm0-3.5c-.8 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3 0-.7-.5-1.3-1.3-1.3z"></path></svg> <h3 className="fw-bold mb-1">Your Cart is Empty</h3> <h5 className="mb-3">Add some items to make me happy :)</h5> <a href="javascript:void(0);" className="btn btn-primary btn-wave m-3 waves-effect waves-light" data-abc="true">continue shopping <i className="bi bi-arrow-right ms-1"></i></a> </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="card custom-card">
                            <div className="p-3 border-bottom d-block">
                                <div className="alert alert-primary text-center" role="alert">
                                    <span className="text-default">Checkout In</span>
                                    <span className="fw-semibold fs-14 text-primary ms-1">10 Minutes : 00 Seconds</span>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="p-3 border-bottom border-block-end-dashed">
                                    <p className="mb-2 fw-semibold">Estimated Crediting Period:</p>
                                    <p className="mb-0 mt-2 fs-12 text-muted">Carbon Credits will started crediting by 24,Nov 2024</p>
                                </div>
                                <div className="p-3 border-bottom border-block-end-dashed">
                                    <div className="input-group mb-1">
                                        <input type="text" className="form-control form-control-sm" placeholder="Coupon Code" aria-label="coupon-code" aria-describedby="coupons" />
                                        <button className="btn btn-primary input-group-text" id="coupons">Apply</button>
                                    </div>
                                    <a href="javascript:void(0);" className="fs-12 text-success">5% off on first Investment</a>
                                </div>
                                <div className="p-3 border-bottom border-block-end-dashed">
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <div className="text-muted op-7">Sub Total</div>
                                        <div className="fw-semibold fs-14">USD 1,600.00</div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <div className="text-muted op-7">Discount (0%)</div>
                                        <div className="fw-semibold fs-14 text-success">-USD 0.00</div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <div className="text-muted op-7">Platform Charges (5%)</div>
                                        <div className="fw-semibold fs-14">USD 200.00</div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <div className="text-muted op-7">GST (18%)</div>
                                        <div className="fw-semibold fs-14">USD 288.00</div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="text-muted op-7">Total :</div>
                                        <div className="fw-semibold fs-14 text-primary"> USD 2,088.00</div>
                                    </div>
                                </div>
                                <div className="p-3 d-grid">
                                    <Link to={constant.component.offsetCheckout.url} className="btn btn-primary btn-wave mb-2 waves-effect waves-light">Proceed To Checkout</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default InvestOnline