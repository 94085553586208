import { useState } from "react";
import { UncontrolledPopover, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import DataGrid, { Toolbar, Item, Column, Grouping, GroupPanel, Paging, SearchPanel, ColumnChooser, HeaderFilter, Selection, Export } from 'devextreme-react/data-grid';

import ManageApprovalButton from "../ManageApprovalButton";
import EditDetailButton from "./EditDetailButton";
import DeleteDetailButton from "./DeleteDetailButton";

// import { manageApproval } from "../../action/StationaryCombustionAction";
import { getFormattedFloat, getFormattedNumber } from "../../../../utils";
import moment from 'moment';
import constant from '../../../../constant';
import EmissionFactorButton from "../../common/EmissionFactorButton";
import AuditHistoryButton from "../../common/AuditHistoryButton";

interface IPropType {
    list: any,
    enableEditing: boolean,
    enableApproval: boolean,
    onLoadList?: any,
}

export function GridView(props: IPropType) {
    const { list, enableEditing, enableApproval, onLoadList } = props;
    const [selectedRowKeys, setSelectedRowKeys]: any = useState([]);

    const handleOptionChange = (e: any) => {
        if (e.fullName === 'selectedRowKeys') {
            setSelectedRowKeys(e.value)
        }
    }

    return (
        <div className="ff-default">
            <DataGrid
                // columnMinWidth={50}
                // columnAutoWidth={true}

                dataSource={list}
                keyExpr="id"
                allowColumnResizing={true}
                allowColumnReordering={true}
                rowAlternationEnabled={true}
                showBorders={true}
                selectedRowKeys={selectedRowKeys}
                onOptionChanged={handleOptionChange}
            >
                <Toolbar>
                    <Item location="before">
                        {
                            enableApproval && <ManageApprovalButton
                                approved={true}
                                ids={selectedRowKeys}
                                disabled={selectedRowKeys.length === 0}
                                onSubmitComplete={onLoadList}
                                css="btn btn-primary btn-sm px-3"
                                manageApprovalAPI={null}
                            >
                                Approve
                            </ManageApprovalButton>
                        }
                    </Item>
                    <Item location="before">
                        {
                            enableApproval &&
                            <ManageApprovalButton
                                approved={false}
                                ids={selectedRowKeys}
                                disabled={selectedRowKeys.length === 0}
                                onSubmitComplete={onLoadList}
                                css="btn btn-danger btn-sm px-3"
                                manageApprovalAPI={null}
                            >
                                Reject
                            </ManageApprovalButton>
                        }
                    </Item>

                    <Item name="groupPanel" />
                    <Item name="exportButton" />
                    <Item name="columnChooserButton" />
                    <Item name="searchPanel" />
                </Toolbar>

                <Selection mode="multiple" showCheckBoxesMode={enableApproval ? "always" : 'none'} />
                {/* <FilterRow visible={true} applyFilter={true} /> */}
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                {/* <GroupPanel visible={!enableEditing && !enableApproval} /> */}
                <GroupPanel visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Grouping autoExpandAll={false} />

                <Column caption="Timestamp" dataField="dated" width={160} dataType="datetime" cellRender={(e: any) => moment(e.value).format(constant.dateTimeFormat.dateTimeFormat)} visible={false} />
                <Column caption="Activity Period" dataField="dated" allowFiltering={false} dataType="date" cellRender={(e: any) => moment(e.value).format(constant.dateTimeFormat.dateFormat)} />
                <Column caption="Location" dataField="locationName" dataType="string" cellRender={(item: any) => item.value ? item.value : '-'} />
                <Column caption="Purchased Goods/Services" dataField="emissionSourceName" dataType="string" cellRender={(item: any) => item.value ? item.value : '-'} />
                <Column caption="Supplier" dataField="supplierName" dataType="string" cellRender={(item: any) => item.value ? item.value : '-'} />

                {/* <Column caption="Fuel Type" dataField="fuelTypeName" dataType="string" cellRender={(item: any) => item.value ? item.value : '-'} /> */}
                <Column caption="Activity Data" dataField="activityData" dataType="number" cellRender={(item: any) => item.value ? getFormattedFloat(item.value) : '-'} />
                <Column caption="Unit" dataField="uom" dataType="number" cellRender={(item: any) => item.value ? item.value : '-'} />
                <Column caption="Emission Factor (kgCO2e)" dataField="emissionFactor" dataType="number" cellRender={(item: any) => {
                    return <>
                        {getFormattedFloat(item.value)}
                        <EmissionFactorButton detail={<>
                            <table className="table table-striped w-100 table-bordered">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Emission Factor</td>
                                        <td>{item.data.emissionFactor}</td>
                                    </tr>
                                    <tr>
                                        <td>Emission Source</td>
                                        <td>{item.data.emissionSource}</td>
                                    </tr>
                                    <tr>
                                        <td>Year</td>
                                        <td>{item.data.emissionSourceYear ? item.data.emissionSourceYear : '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>}
                            css="btn btn-link p-0 ms-2"
                        >
                            <i className="bx bx-info-circle fs-16"></i>
                        </EmissionFactorButton>
                    </>
                }}
                />
                <Column caption="Remarks" dataField="remarks" dataType="string" cellRender={(cellData: any) => cellData.value ? cellData.value : '-'} visible={false} />

                {/* <Column caption="CO2(kg)" dataField="kgCO2" dataType="number" cellRender={(item: any) => getFormattedFloat(item.value)} />
                <Column caption="CH4(g)" dataField="gCH4" dataType="number" cellRender={(item: any) => getFormattedFloat(item.value)} />
                <Column caption="N2O(g)" dataField="gN2O" dataType="number" cellRender={(item: any) => getFormattedFloat(item.value)} /> */}
                <Column caption="Emission CO2e(kg)" dataField="emission" dataType="number" cellRender={(item: any) => {
                    return (
                        <>
                            {getFormattedFloat(item.value)}
                            {/* <UncontrolledPopover
                                placement="top"
                                target={`tooltip${item.data.id}`}
                            > */}
                            {/* <PopoverHeader>
                                Emission Source
                            </PopoverHeader>
                            <PopoverBody>
                                Name: {item.data.emissionFactor.name}<br />
                                Source: {item.data.emissionFactor.sourceName} {item.data.emissionFactor.sourceYear}<br />
                                Emission Factor<br />
                                CO2: {item.data.emissionFactor.co2}<br />
                                CH4 Factor: {item.data.emissionFactor.ch4}<br />
                                N2O: {item.data.emissionFactor.n2o}<br />
                                CO2e: {item.data.emissionFactor.co2e}<br />
                            </PopoverBody> */}
                            {/* </UncontrolledPopover> */}
                        </>
                    )
                }} />

                {
                    enableEditing && <Column allowFiltering={false} alignment="center" allowExporting={false} visible={enableEditing} dataField="id" caption="Action" dataType="string" cellRender={(e: any) => {
                        return <>
                            <div className="text-center">
                                <EditDetailButton
                                    css="btn btn-link p-0"
                                    onSubmitComplete={onLoadList}
                                    id={e.value}
                                >
                                    <i className="bx bx-edit fs-16"></i>
                                    {/* <span className="text-primary fas fa-edit"></span> */}
                                </EditDetailButton>
                                <DeleteDetailButton
                                    css="btn btn-link p-0 ms-2"
                                    onSubmitComplete={onLoadList}
                                    id={e.value}
                                >
                                    {/* <span className="text-danger fas fa-trash-alt"></span> */}
                                    <i className="bx bx-trash fs-16 text-danger"></i>
                                </DeleteDetailButton>

                                <AuditHistoryButton
                                    css="btn btn-link p-0 ms-2"
                                    id={e.value}
                                >
                                    <i className="bx bx-history fs-16 text-info"></i>
                                </AuditHistoryButton>
                            </div>
                        </>
                    }} />
                }

                <Export enabled={true} allowExportSelectedData={true} />
                <Paging defaultPageSize={25} />
            </DataGrid>
        </div >
    )
}

export default GridView;