import i18next from "i18next";

function Footer(props: any) {
    const { showSideBar } = props;
    const footerCSS = showSideBar ? "mt-auto py-3 bg-white text-center" : "footer mt-auto py-3 bg-white text-center";
    return (
        <footer className={footerCSS}>
            <div className="container">
                <span className="text-muted"> {i18next.t("label.footer.copyright")} © <span id="year">{new Date().getFullYear()}</span>
                    <a href="javascript:void(0);" className="text-dark fw-semibold me-2 ms-2">{i18next.t("label.footer.woce")}</a>
                    {i18next.t("label.footer.all_rights_reserved")}
                </span>
            </div>
        </footer>
    )
}

export default Footer