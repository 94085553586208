import { Chart1, Chart2, Chart3, Chart4 } from "../../../../component/esg/environment-dashboard/EnergyChart";

function Home() {

    return (
        <>
            <div className="container-fluid">
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Energy Dashboard</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">ESG</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Environment</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Energy</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                {renderTopCards()}

                <div className="row">


                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Energy Consumption & Related CO2 Emissions
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart1 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Consumption By Site (In GJ)
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart2 />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Consumption By Type (In GJ)
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart3 />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                        <div className="card custom-card">
                            <div className="card-header justify-content-between align-items-center d-sm-flex d-block">
                                <div className="card-title mb-sm-0 mb-2">
                                    Energy Intensity Per Unit Production Volume (By Site)
                                </div>
                            </div>
                            <div className="card-body">
                                <Chart4 />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

    function renderTopCards() {
        return (
            <>
                <div className="row">
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card bg-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-light"><i className="ti ti-bulb fs-25 text-primary"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-fixed-white op-9">Total Energy Consumption</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2 text-white">387K</span>
                                            <span className="fw-semibold fs-14 text-white op-8">GJ</span>
                                        </div>
                                        <div>
                                            {/* <span className="fs-12 mb-0 text-fixed-white op-8">Increase by</span> */}
                                            <span className="badge bg-light fw-semibold text-danger mx-1 fs-12">+7.8%</span>
                                            <span className="fs-12 mb-0 text-fixed-white op-9"> v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card border border-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-info"><i className="ti ti-currency-dollar fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Energy Intensity Per Unit Revenue</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">1.2K</span>
                                            <span className="text-muted fw-semibold fs-14">GJ/USD</span>
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">+1.8%</span>
                                            <span className="fs-12 mb-0 text-muted">v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card border border-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-secondary"><i className="ti ti-users fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Energy Intensity Per Employee</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">1.2K</span>
                                            <span className="text-muted fw-semibold fs-14">GJ/FTE</span>
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">-2.3%</span>
                                            <span className="fs-12 mb-0 text-muted">v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3">
                        <div className="card custom-card border border-primary">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                        <span className="avatar avatar-lg bg-teal"><i className="ti ti-building-factory-2 fs-20"></i> </span>
                                    </div>
                                    <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                        <div className="mb-2 text-muted">Energy Intensity Per Unit Production</div>
                                        <div className="mb-1 fs-12">
                                            <span className="fw-semibold fs-20 lh-1 vertical-bottom me-2">3K</span>
                                            <span className="text-muted fw-semibold fs-14">GJ/Tonne</span>
                                        </div>
                                        <div>
                                            <span className="badge bg-danger-transparent fw-semibold text-danger mx-1 fs-12">-0.7%</span>
                                            <span className="fs-12 mb-0 text-muted">v/s last year</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home;