import Chart from "react-apexcharts";
import constant from "../../../constant";
import Shimmer from "../../common/Shimmer";
import { getFormattedFloat, getFormattedNumber } from "../../../utils";

export function Chart1(props: any) {
    const series: any = [
        {
            name: 'Company Personnel',
            type: 'column',
            data: [211, 215, 217, 218]
        },
        {
            name: 'Contractors Employees',
            type: 'column',
            data: [178, 174, 175, 177]
        },
        {
            name: 'Occupational Fatalities',
            type: 'line',
            data: [1, 1, 3, 1]
        },
    ];
    const xaxis = ['2020', '2021', '2022', '2023'];
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)", "#eb7563"];
    const options = {
        chart: {
            toolbar: {
                show: true,
                tools: {
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false
                },
            },
            // height: 300,
            // type: 'line',
            stacked: true,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: "Millions of Hours worked"
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
            min: props.yLeftMin,
            max: props.yLeftMax
        },
        {
            opposite: true,
            title: {
                text: "occupational Fatalities"
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
            min: 0,
            max: 5
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            offsetX: 40,
            fontSize: '13px',
            fontWeight: 'normal',
            labels: {
                colors: '#acb1b1',
            },
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 0, 1.5],
            curve: 'straight',
            dashArray: [0, 0, 5],
        },
        plotOptions: {
            bar: {
                // borderRadiusWhenStacked: "last",
                columnWidth: "15%",
                // borderRadius: 5
            },
        },
        colors,//: ["rgba(132, 90, 223, 1)", "rgba(132, 90, 223, 0.5)", "rgba(132, 90, 223, 0.3)"],
    };

    return (
        <div>
            <Chart
                options={options}
                series={series}
                // type="bar"
                width="100%"
                height="310"
            />
        </div>
    )
}

export function Chart2(props: any) {
    const series: any = [
        {
            name: 'Heavy goods Vehicles (trucks)',
            type: 'column',
            data: [27, 20, 12, 10,]
        },
        // {
        //     name: 'Contractors Employees',
        //     type: 'column',
        //     data: [178, 174, 175, 177]
        // },
        {
            name: 'Light Vehicles and public transoortation',
            type: 'line',
            data: [0, 1, 3, 0]
        },
    ];
    const xaxis = ['2020', '2021', '2022', '2023'];
    const colors = ["rgba(15, 115, 198, 1.0)", "#eb7563"];
    const options = {
        chart: {
            toolbar: {
                show: true,
                tools: {
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false
                },
            },
            // height: 300,
            // type: 'line',
            stacked: true,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                // text: "Millions of Hours worked"
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
            min: props.yLeftMin,
            max: props.yLeftMax
        },
        {
            opposite: true,
            title: {
                // text: "occupational Fatalities"
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
            min: 0,
            max: 5
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            offsetX: 40,
            fontSize: '13px',
            fontWeight: 'normal',
            labels: {
                colors: '#acb1b1',
            },
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 1.5],
            curve: 'straight',
            dashArray: [0, 5],
        },
        plotOptions: {
            bar: {
                columnWidth: "20%",
                borderRadius: 1
            }
        },
        colors//: ["rgba(132, 90, 223, 1)", "rgba(132, 90, 223, 0.5)", "rgba(132, 90, 223, 0.3)"],
    };

    return (
        <div>
            <Chart
                options={options}
                series={series}
                // type="bar"
                width="100%"
                height="310"
            />
        </div>
    )
}

export function Chart3(props: any) {
    const series: any = [
        {
            name: 'TRIR',
            type: 'column',
            data: [0.74, 0.73, 0.67, 0.63]
        },
        {
            name: 'LTIR',
            type: 'column',
            data: [0.48, 0.48, 0.45, 0.4]
        },
        {
            name: 'SR',
            type: 'line',
            data: [17, 15, 15, 12]
        },
    ];
    const xaxis = ['2020', '2021', '2022', '2023'];
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)", "#eb7563"];
    const options = {
        chart: {
            toolbar: {
                show: true,
                tools: {
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false
                },
            },
            // height: 300,
            // type: 'line',
            stacked: true,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: "Millions of Hours worked"
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedFloat(value);
                }
            },
            min: 0.3,
            max: 0.8
        },
        {
            opposite: true,
            title: {
                text: "occupational Fatalities"
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
            min: 0,
            max: 20
        }],
        // tooltip: {
        //     enabled: true,
        // },
        legend: {
            show: true,
            position: 'bottom',
            offsetX: 40,
            fontSize: '13px',
            fontWeight: 'normal',
            labels: {
                colors: '#acb1b1',
            },
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 0, 1.5],
            curve: 'straight',
            dashArray: [0, 0, 5],
        },
        plotOptions: {
            bar: {
                columnWidth: "20%",
                borderRadius: 1
            }
        },
        colors//: ["rgba(132, 90, 223, 1)", "rgba(132, 90, 223, 0.5)", "rgba(132, 90, 223, 0.3)"],
    };

    return (
        <div>
            <Chart
                options={options}
                series={series}
                // type="bar"
                width="100%"
                height="310"
            />
        </div>
    )
}

export function Chart4(props: any) {
    const series =
        [{
            name: 'Company Personnel',
            data: [
                3429,
                2703,
                3116,
                2508
            ]
        },
        {
            name: 'Contractors Employees',
            data: [
                3335,
                3277,
                2608,
                2292
            ]
        },

        ]
    const xaxis = ['2020', '2021', '2022', '2023'];
    const yLeftTitle = "";
    const colors = ["rgba(15, 115, 198, 1.0)", "rgba(66, 182, 245, 1)"];

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            stacked: true,
        },
        grid: {
            borderColor: '#f5f4f4',
            strokeDashArray: 3
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: xaxis,
        },
        yaxis: [{
            title: {
                text: yLeftTitle
            },
            labels: {
                formatter: function (value: any) {
                    return "" + getFormattedNumber(value);
                }
            },
        }],
        tooltip: {
            enabled: true,
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 10,
                height: 10,
            },
        },
        stroke: {
            width: [0, 0, 0, 1.5],
            curve: 'straight',
            dashArray: [0, 0, 0, 5],
        },
        plotOptions: {
            bar: {
                // borderRadiusWhenStacked: "last",
                columnWidth: "15%",
                // borderRadius: 5
            },

        },
        colors: colors
    };
    return (
        <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="300"
        />
    )
}