import moment from "moment";
import { useState } from "react";
import { Table, controlType, input, number } from "../../common/Controls";
import { getFormattedFloat, getStorage } from "../../../utils";
import constant from "../../../constant";


export function Editor(props: { value: any, onSubmitComplete: any, onCancel: any, question: any }) {
    const [a1, setA1] = useState(props.value ? props.value.answer["305-6-a-i"] : '');
    const [a2, setA2] = useState(props.value ? props.value.answer["305-6-a-ii"] : '');
    const [a3, setA3] = useState(props.value ? props.value.answer["305-6-a-iii"] : '');
    // const [a4, setA4] = useState(props.value ? props.value.answer["305-6-a-iv"] : '');

    const onSubmit = () => {
        const { question } = props;
        const ud = getStorage(constant.key.userDetail);

        const obj = {
            questionId: question.id, subTopicId: question.subTopicId,
            answer: {
                "305-6-a-i": a1,
                "305-6-a-ii": a2,
                "305-6-a-iiii": a3,
                // "305-6-a-iv": a4,
            },
            modifiedOn: moment().format(),
            modifiedByName: ud.name
        }
        props.onSubmitComplete(obj);
    }

    return (
        <>
            <div className="mb-3 fw-bold">
                Q. {props.question.name}
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-3">Production</label>
                <div className="input-group mb-3">
                    {number({ name: '', value: a1, onChange: (a: any, b: any) => setA1(b) })}
                    <span className="input-group-text">metric tons of CFC-11 equivalent</span>
                </div>
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-3">Imports</label>
                <div className="input-group mb-3">
                    {number({ name: '', value: a2, onChange: (a: any, b: any) => setA2(b) })}
                    <span className="input-group-text">metric tons of CFC-11 equivalent</span>
                </div>
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-3">Exports</label>
                <div className="input-group mb-3">
                    {number({ name: '', value: a3, onChange: (a: any, b: any) => setA3(b) })}
                    <span className="input-group-text">metric tons of CFC-11 equivalent</span>
                </div>
            </div>
            {/* <div className="mb-3">
                <label className="fw-bold mb-3">Any significant changes in emissions that triggered recalculations of base year emissions</label>
                <div className="input-group mb-3">
                    {input({ name: '', value: a4, onChange: (a: any, b: any) => setA4(b) })}
                    <span className="input-group-text">tCO2e</span> 
                </div>
            </div> */}

            <div className="row mt-4">
                <div className="col">
                    <button type="button" onClick={props.onCancel} className="btn btn-secondary px-5">Cancel</button>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary px-5" onClick={onSubmit} type="button">Save Changes</button>
                </div>
            </div>
        </>
    )
}

export function Preview(props: { answer: any }) {
    const { answer } = props;
    const a1 = answer ? answer.answer["305-6-a-i"] : '';
    const a2 = answer ? answer.answer["305-6-a-ii"] : '';
    const a3 = answer ? answer.answer["305-6-a-iii"] : '';
    // const a4 = answer ? answer.answer["305-6-a-iv"] : '';
    return (
        <>

            <div className="mb-3">
                <label className="fw-bold mb-1">Base year</label>
                <div className="">
                    {
                        a1 ? `${getFormattedFloat(a1)} metric tons of CFC-11 equivalent` : '-'
                    }
                </div>
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-1">Emission in the base year </label>
                <div className="">
                    {
                        a2 ? `${getFormattedFloat(a2)} metric tons of CFC-11 equivalent` : '-'
                    }
                </div>
            </div>
            <div className="mb-3">
                <label className="fw-bold mb-1">Rationale for choosing the base year</label>
                <div className="">
                    {
                        a3 ? `${getFormattedFloat(a3)} metric tons of CFC-11 equivalent` : '-'
                    }
                </div>
            </div>
            {/* <div className="mb-3">
                <label className="fw-bold mb-1">Any significant changes in emissions that triggered recalculations of base year emissions</label>
                <div className="">
                    {
                        a4 ? a4 : '-'
                    }
                </div>
            </div> */}

        </>
    )
}